import React from 'react'
import DrawerBottomNavigation from 'components/drawerBottomNavigation'
import Button from 'components/button'
import { CanceledTravelModalContent } from './styles'
import StatusBadge from 'components/statusBadge'
import { StatusEnum } from 'constants/statusEnum'
import { useTranslation } from 'react-i18next'

export type CanceledTravelModalProps = {
  close: () => void
}

const CanceledTravelModal: React.FC<CanceledTravelModalProps> = ({ close }) => {
  const { t } = useTranslation()

  return (
    <CanceledTravelModalContent data-testid="smiles-canceled-travel-modal">
      <div className="main">
        <p className="title">{t('my_travels.cancelled.title')}</p>

        <StatusBadge
          status={StatusEnum.CANCELED}
          text={t('my_travels.cancelled.canceled_by_policy')}
        />

        <div className="reason">
          <p className="reason-title">
            {t('my_travels.cancelled.cancelation_reason')}
          </p>
          <p className="reason-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
      </div>

      <DrawerBottomNavigation>
        <Button width="250px" onClick={close}>
          Sair
        </Button>
      </DrawerBottomNavigation>
    </CanceledTravelModalContent>
  )
}

export default CanceledTravelModal
