export default `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
        <path d="M5.99935 6.58317C6.16463 6.58317 6.30327 6.52717 6.41527 6.41517C6.52688 6.30356 
        6.58268 6.16512 6.58268 5.99984V3.65192C6.58268 3.48664 6.52688 3.35053 6.41527 3.24359C6.30327 
        3.13664 6.16463 3.08317 5.99935 3.08317C5.83407 3.08317 5.69563 3.13898 5.58402 3.25059C5.47202 
        3.36259 5.41602 3.50123 5.41602 3.6665V6.01442C5.41602 6.1797 5.47202 6.31581 5.58402 6.42275C5.69563 
        6.5297 5.83407 6.58317 5.99935 6.58317ZM5.99935 8.9165C6.16463 8.9165 6.30327 8.8605 6.41527 8.7485C6.52688 
        8.63689 6.58268 8.49845 6.58268 8.33317C6.58268 8.16789 6.52688 8.02925 6.41527 7.91725C6.30327 7.80564 6.16463 
        7.74984 5.99935 7.74984C5.83407 7.74984 5.69563 7.80564 5.58402 7.91725C5.47202 8.02925 5.41602 8.16789 5.41602 
        8.33317C5.41602 8.49845 5.47202 8.63689 5.58402 8.7485C5.69563 8.8605 5.83407 8.9165 5.99935 8.9165ZM5.99935 
        11.8332C5.1924 11.8332 4.43407 11.6799 3.72435 11.3735C3.01463 11.0674 2.39727 10.6519 1.87227 10.1269C1.34727 
        9.60192 0.931738 8.98456 0.625682 8.27484C0.319238 7.56512 0.166016 6.80678 0.166016 5.99984C0.166016 5.19289 
        0.319238 4.43456 0.625682 3.72484C0.931738 3.01512 1.34727 2.39775 1.87227 1.87275C2.39727 1.34775 3.01463 0.932032 
        3.72435 0.625587C4.43407 0.319532 5.1924 0.166504 5.99935 0.166504C6.80629 0.166504 7.56463 0.319532 8.27435 0.625587C8.98407 
        0.932032 9.60143 1.34775 10.1264 1.87275C10.6514 2.39775 11.067 3.01512 11.373 3.72484C11.6795 4.43456 11.8327 5.19289 11.8327 
        5.99984C11.8327 6.80678 11.6795 7.56512 11.373 8.27484C11.067 8.98456 10.6514 9.60192 10.1264 10.1269C9.60143 10.6519 8.98407 
        11.0674 8.27435 11.3735C7.56463 11.6799 6.80629 11.8332 5.99935 11.8332ZM5.99935 10.6665C7.29241 10.6665 8.39354 10.2121 9.30277 
        9.30325C10.2116 8.39403 10.666 7.29289 10.666 5.99984C10.666 4.70678 10.2116 3.60564 9.30277 2.69642C8.39354 1.78759 7.29241 
        1.33317 5.99935 1.33317C4.70629 1.33317 3.60535 1.78759 2.69652 2.69642C1.78729 3.60564 1.33268 4.70678 1.33268 5.99984C1.33268 
        7.29289 1.78729 8.39403 2.69652 9.30325C3.60535 10.2121 4.70629 10.6665 5.99935 10.6665Z" />
    </svg>
`
