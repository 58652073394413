import React from 'react'
import { FlightInfo } from 'components/summaryModalContent/styles'
import FlightDetails from 'components/flightDetails'
import StopsAndBaggage from 'components/stopsAndBaggage'
import dayjs from 'dayjs'
import { SMyTravelFlight } from 'shared/interfaces/SMyTravel'
import { RenderFlightsDefaultStyles } from './styles'
import { useTranslation } from 'react-i18next'
export type RenderFlightsDefaultProps = {
  flightData: SMyTravelFlight[]
}

const RenderFlightsDefault: React.FC<RenderFlightsDefaultProps> = ({
  flightData,
}) => {
  const { t } = useTranslation()

  return (
    <RenderFlightsDefaultStyles data-testid="render-cart-flights">
      {flightData?.map((flight, index) => (
        <FlightInfo key={flight.key} data-testid="batata">
          <div className="segment-and-date">
            <p className="segment">
              {`${t('component.summary_modal.ticket_segment')} ${index + 1}`}
            </p>
            <p className="date">
              {dayjs(flight.departure_time).format('ddd. DD/MM/YY')}
            </p>
          </div>
          <FlightDetails flightData={flight} />
          <StopsAndBaggage flightData={flight} />
        </FlightInfo>
      ))}
    </RenderFlightsDefaultStyles>
  )
}

export default RenderFlightsDefault
