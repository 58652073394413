import React from 'react'
import { RequestCostStyles } from './styles'

export type RequestCostProps = {
  unitaryText: string
  unitaryValue: string
  totalText: string
  totalValue: string
}

const RequestCost: React.FC<RequestCostProps> = ({
  unitaryText,
  unitaryValue,
  totalText,
  totalValue,
}) => {
  return (
    <RequestCostStyles data-testid="smiles-request-cost">
      <div className="content_unitary">
        <div className="content_unitary_text">{unitaryText}</div>
        <div className="content_unitary_value">{unitaryValue}</div>
      </div>
      <div className="content_total">
        <div className="content_total_text">{totalText}</div>
        <div className="content_total_value">{totalValue}</div>
      </div>
    </RequestCostStyles>
  )
}

export default RequestCost
