import React from 'react'
import { ListAllTravelsContent } from './styles'
import NextTravelCard from '../nextTravelCard'
import SmallTravelCard from '../smallTravelCard'
import { SMyTravel } from 'shared/interfaces/SMyTravel'
import { TravelSection } from '../MyTravelSection'
import { useTranslation } from 'react-i18next'

export type ListAllTravelsProps = {
  next?: SMyTravel[]
  canceled?: SMyTravel[]
  previous?: SMyTravel[]
  seeAllCallback: (tab: number) => void
}

const ListAllTravels: React.FC<ListAllTravelsProps> = ({
  next = [],
  canceled = [],
  previous = [],
  seeAllCallback,
}) => {
  const { t } = useTranslation()

  return (
    <ListAllTravelsContent data-testid="smiles-list-all-travels-content">
      <TravelSection
        title={t('my_travels.travels.next')}
        travels={next}
        emptyTestId="next-no-data"
        seeAllCallback={() => seeAllCallback(1)}
        cardComponent={NextTravelCard}
        containerClass="next-travels-cards-container"
        limit={3}
      />
      <TravelSection
        title={t('my_travels.travels.your_cancelled_travels')}
        travels={canceled}
        emptyTestId="canceled-no-data"
        seeAllCallback={() => seeAllCallback(2)}
        cardComponent={SmallTravelCard}
        isCanceled
        limit={3}
      />
      <TravelSection
        title={t('my_travels.travels.your_previous_travels')}
        travels={previous}
        emptyTestId="previous-no-data"
        seeAllCallback={() => seeAllCallback(3)}
        cardComponent={SmallTravelCard}
        limit={3}
      />
    </ListAllTravelsContent>
  )
}

export default ListAllTravels
