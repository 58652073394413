import React from 'react'
import { NewGroupContent } from './styles'
import Input from 'components/Input'
import DrawerBottomNavigation from 'components/drawerBottomNavigation'
import Button from 'components/button'
import { Alert, Form } from 'antd'
import PermissionTreeSelect from 'components/permissionTreeSelect'
import { SGroup } from 'shared/interfaces/SGroup'
import { useTranslation } from 'react-i18next'
import { useHandleGroup } from './hooks/useHandleGroup'
import { ACTION } from 'shared/userActionPermissions'
import { usePermissionsContext } from 'contexts/permissionsContext/usePermissionsContext'

export type HandleGroupProps = {
  onFinishCallback: () => void
  selectedGroupToEdit?: SGroup
}

const HandleGroup: React.FC<HandleGroupProps> = ({
  selectedGroupToEdit,
  onFinishCallback,
}) => {
  const { can } = usePermissionsContext()
  const { t, i18n } = useTranslation()
  const { form, treeData, isLoading, checkedKeys, handleFinish } =
    useHandleGroup(selectedGroupToEdit, onFinishCallback, t, i18n.language)
  return (
    <div data-testid="smiles-handle-group">
      <Form form={form} data-testid="smiles-handle-group-form">
        <NewGroupContent>
          <p className="header">
            {t(
              `handleGroup.${
                selectedGroupToEdit ? 'editUserGroups' : 'newUserGroups'
              }`,
            )}
          </p>

          <div>
            <Form.Item
              name="groupName"
              rules={[
                {
                  required: true,
                  message: t('handleGroup.form.groupName.required'),
                },
              ]}
            >
              <Input
                label={t('handleGroup.form.groupName.label')}
                placeholder={t('handleGroup.form.groupName.placeholder')}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name="groupDescription"
              rules={[
                {
                  required: true,
                  message: t('handleGroup.form.groupDescription.label'),
                },
              ]}
            >
              <Input
                label={t('handleGroup.form.groupDescription.label')}
                placeholder={t('handleGroup.form.groupDescription.placeholder')}
                disabled={isLoading}
              />
            </Form.Item>
          </div>

          <div className="permissions">
            <p>{t('handleGroup.groupPermissions')}</p>
            {can(ACTION.permissions.listAll) ? (
              <PermissionTreeSelect
                label={t('handleGroup.form.permissions.label')}
                placeholder={t('handleGroup.form.permissions.placeholder')}
                data={treeData}
                formItemName="permissions"
                isLoading={isLoading}
                value={checkedKeys}
                disabled={
                  isLoading || !can(ACTION.permissions.updateGroupPermissions)
                }
              />
            ) : (
              <Alert
                showIcon
                type="warning"
                description={t(
                  'handleGroup.alert.noPermToViewGroupPermissions',
                )}
              />
            )}

            {!can(ACTION.permissions.updateGroupPermissions) && (
              <Alert
                showIcon
                type="warning"
                description={t(
                  'handleGroup.alert.noPermToUpdateGroupPermissions',
                )}
              />
            )}
          </div>
        </NewGroupContent>

        <DrawerBottomNavigation>
          <Button
            onClick={handleFinish}
            width="300px"
            disabled={isLoading}
            isLoading={isLoading}
          >
            {t('handleGroup.form.confirmButton')}
          </Button>
        </DrawerBottomNavigation>
      </Form>
    </div>
  )
}

export default HandleGroup
