const ptMyRequests = {
  myRequests: {
    'not-found': 'Solicitações não encontradas.',
    requests: 'Solicitações',
    filter: {
      'filter-requests': 'Filtrar solicitações',
      only_my_requests: 'Minhas solicitações',
      only_my_travels: 'Minhas viagens',
      requests_for_other_travelers: 'Solicitações para outros viajantes',
      'pending-approval-travel-requests': 'Solicitações pendentes de aprovação',
    },
    status: {
      'all-statuses': 'Todos os status',
      waiting_for_approval: 'Aguardando aprovação',
      approved: 'Aprovada',
      confirmed: 'Confirmada',
      rejected: 'Reprovada',
      canceled: 'Cancelada',
      opened: 'Aberto',
    },
    order: {
      'order-requests': 'Ordenar',
      'recent-requests': 'Solicitações mais recentes',
      'older-requests': 'Solicitações mais antigas',
      'urgent-requests': 'Solicitações urgentes',
      'close-expiration-requests': 'Solicitações próximas do vencimento',
    },
    'search-travel-request': 'Pesquisar SV',
    card: {
      destination: 'Destino',
      requester: 'Solicitante',
      traveler: 'Viajante',
      'travel-price': 'Valor da viagem',
      'more-information': 'Ver solicitação completa',
    },
  },
}

export default ptMyRequests
