import React, { useState, useEffect } from 'react'
import Drawer from 'components/drawer'
import { useTranslation } from 'react-i18next'
import SimpleTabs from 'components/simpleTabs'
import MainContainer from 'components/mainContainer'
import {
  AddOutsourcedButton,
  ButtonsContainer,
  CustomRadioButton,
  OutsourcedPageHeader,
  SearchCard,
} from './styles'
import { Radio } from 'antd'
import { SmilesIcon } from 'components/icons'
import HandleCompany from './handleCompany'
import HandleEmployee from './handleOutsourced'
import EmployeeDetails from './employeeDetails'
import EmployeesList from './employeesList'
import Input from 'components/Input'
import Skeleton from 'components/skeleton'
import CompaniesList from './companiesList'
import { useManagement } from './hooks/useManagement'

const Management: React.FC = () => {
  const { t } = useTranslation()
  const {
    currentTabIndex,
    changeSelectedRadio,
    fetchEmployees,
    employees,
    fetchCompanies,
    companies,
    setDrawerData,
    pagination,
    tabs,
    onTabClick,
    onItemClick,
    isLoading,
    handleCloseDrawer,
    drawerState,
    setDrawerState,
    selectedItemListId,
    paginationCompanies,
    onCompaniesPageChange,
    isCompaniesLoading,
    onPageChange,
    onEmployeeEditClick,
    isEditMode,
    setEditMode,
  } = useManagement()

  return (
    <MainContainer>
      <SearchCard>
        <OutsourcedPageHeader>
          <p className="header-title">{t('management.title')}</p>
        </OutsourcedPageHeader>
        <Radio.Group
          defaultValue={0}
          value={currentTabIndex}
          buttonStyle="solid"
        >
          <CustomRadioButton value={0} onClick={() => changeSelectedRadio(0)}>
            {t('management.search.add_outsourced')}
          </CustomRadioButton>
          <CustomRadioButton value={1} onClick={() => changeSelectedRadio(1)}>
            {t('management.search.add_company')}
          </CustomRadioButton>
        </Radio.Group>
        <Input
          placeholder={
            currentTabIndex === 0
              ? t('management.search.placeholder')
              : t('management.search.company_placeholder')
          }
          suffix={<SmilesIcon type="lens" size={20} />}
          onChange={(event) => {
            fetchEmployees(
              event.target.value,
              pagination.page,
              pagination.limit,
            )
          }}
        />
        <ButtonsContainer>
          <AddOutsourcedButton
            type="button"
            className="global-button-reset"
            onClick={() => {
              setEditMode(false)
              setDrawerData(true, 'employee')
            }}
          >
            <SmilesIcon size={20} type="plus-rounded" />
            {t('management.search.add_outsourced')}
          </AddOutsourcedButton>
          <AddOutsourcedButton
            type="button"
            className="global-button-reset"
            onClick={() => setDrawerData(true, 'company')}
          >
            <SmilesIcon size={20} type="plus-rounded" />
            {t('management.search.add_company')}
          </AddOutsourcedButton>
        </ButtonsContainer>
      </SearchCard>

      <SimpleTabs
        tabs={tabs}
        onClick={onTabClick}
        currentTab={currentTabIndex}
      />
      {/* {<Skeleton isLoading={isLoading || isCompaniesLoading} />} */}
      {currentTabIndex === 0 && !isLoading && (
        <EmployeesList
          data={employees}
          onItemClick={(id: string) => onItemClick(id, 'employee_details')}
          pagination={{ ...pagination, onPageChange }}
        />
      )}
      {currentTabIndex === 1 && !isCompaniesLoading && (
        <CompaniesList
          data={companies}
          onItemClick={(id: string) => onItemClick(id, 'company_details')}
          pagination={{ ...paginationCompanies, onCompaniesPageChange }}
        />
      )}
      <Drawer
        onClose={handleCloseDrawer}
        open={drawerState.isDrawerOpen}
        width={700}
        data-testid="drawer-component-id"
        destroyOnClose
      >
        {drawerState.currentDrawer === 'employee' && (
          <HandleEmployee
            onFinishCallback={() => {
              setDrawerState({
                isDrawerOpen: false,
                currentDrawer: undefined,
              })
              fetchEmployees()
            }}
            isEditMode={isEditMode}
            employeeId={selectedItemListId}
          />
        )}
        {drawerState.currentDrawer === 'company' && (
          <HandleCompany
            onFinishCallback={() => {
              setDrawerState({
                isDrawerOpen: false,
                currentDrawer: undefined,
              })
              fetchCompanies()
            }}
            isEditMode={false}
          />
        )}
        {drawerState.currentDrawer === 'employee_details' &&
          !!selectedItemListId && (
            <EmployeeDetails
              employeeId={selectedItemListId}
              onEmployeeEditClick={onEmployeeEditClick}
            />
          )}
      </Drawer>
    </MainContainer>
  )
}
export default Management
