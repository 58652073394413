import { TableProps } from 'antd'
import SectionHeader from 'components/sectionHeader'
import Table from 'components/table'
import StatusBadge from 'components/statusBadge'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { SRequestsTable } from 'shared/interfaces/SRequestsTable'
import { StatusEnum } from 'constants/statusEnum'

export type SRequestsTableItemProps = {
  key: string
  svNumber: string
  requester: string
  requestDate: string
  destinationCity: string
  status: StatusEnum
}

export type SRequestsTableItems = {
  items: SRequestsTableItemProps[]
  loading: boolean
}

const b = 'home.requestsTableSection.'

const RquestsTableSection: React.FC<SRequestsTableItems> = ({
  items,
  loading,
}) => {
  const { t } = useTranslation()

  const columns: TableProps<SRequestsTable>['columns'] = [
    {
      title: t(b + 'tHeader.svNumber'),
      dataIndex: 'svNumber',
      key: 'svNumber',
      render: (_, record) => (
        <Link to={`/travel-request/${record.key}`}>
          <p>{record.svNumber}</p>
        </Link>
      ),
    },
    {
      title: t(b + 'tHeader.requester'),
      dataIndex: 'requester',
      key: 'requester',
      render: (text) => <p>{text}</p>,
    },
    {
      title: t(b + 'tHeader.requestDate'),
      dataIndex: 'requestDate',
      key: 'requestDate',
      render: (text) => <p>{text}</p>,
    },
    {
      title: t(b + 'tHeader.destinationCity'),
      dataIndex: 'destinationCity',
      key: 'destinationCity',
      render: (text) => <p>{text}</p>,
    },
    {
      title: t(b + 'tHeader.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text) => <div>{text}</div>,
    },
  ]

  const data = items.map((item) => ({
    ...item,
    status: (
      <StatusBadge
        status={item.status as unknown as StatusEnum}
        text={t(
          `myRequests.status.${(item.status as unknown as StatusEnum)
            .toString()
            .toLowerCase()}`,
        )}
      />
    ),
  }))

  const seeAllRedirectPath = '/requests'

  return (
    <div
      className="home-section-container"
      data-testid="smiles-requests-table-section"
    >
      <SectionHeader title={t(b + 'title')} redirectPath={seeAllRedirectPath}>
        <Table columns={columns} data={data} loading={loading} />
      </SectionHeader>
    </div>
  )
}

export default RquestsTableSection
