export default `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="-5" width="33.6" height="24" rx="2" fill="white"/>
    <mask id="mask0_2736_16585" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="-5" y="0" width="34" height="24">
      <rect x="-5" width="33.6" height="24" rx="2" fill="white"/>
    </mask>
    <g mask="url(#mask0_2736_16585)">
      <rect x="-5" width="33.6" height="24" fill="#05AB41"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.935265 12.5597C-1.34126 12.297 -1.34126 11.703 -0.935266 11.4403L11.4376 
      3.43432C11.6579 3.29171 11.9415 3.29171 12.1619 3.43432L24.5347 11.4403C24.9407 11.703 24.9407 12.297 24.5347 12.5597L12.1619 
      20.5656C11.9415 20.7082 11.6579 20.7082 11.4376 20.5656L-0.935265 12.5597Z" fill="#FDD216"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7992 17.6C14.892 17.6 17.3992 15.0928 17.3992 12C17.3992 8.90723 14.892 
      6.40002 11.7992 6.40002C8.70642 6.40002 6.19922 8.90723 6.19922 12C6.19922 15.0928 8.70642 17.6 11.7992 17.6Z" fill="#053087"/>
      <mask id="mask1_2736_16585" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="6" y="6" width="12" height="12">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7992 17.6C14.892 17.6 17.3992 15.0928 17.3992 12C17.3992 8.90723 
        14.892 6.40002 11.7992 6.40002C8.70642 6.40002 6.19922 8.90723 6.19922 12C6.19922 15.0928 8.70642 17.6 11.7992 17.6Z" fill="white"/>
      </mask>
      <g mask="url(#mask1_2736_16585)">
        <path d="M5.53486 10.5363C6.4141 9.82433 9.25133 10.4988 11.8998 11.2053C14.5483 11.9118 17.3106 13.3926 18.0139 14.2611" 
        stroke="white" stroke-width="1.33333" stroke-linecap="square"/>
      </g>
    </g>
  </svg>
`
