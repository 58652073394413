const esPermissions = {
  permissions: {
    home: 'Hogar',
    flights: 'Vuelos',
    user_groups: 'Grupos de usuarios',
    choose_permissions: 'Seleccionar permisos',
  },
}

export default esPermissions
