import styled from 'styled-components'

export const BookingDetailsStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  padding: 24px 32px 24px 32px;
  gap: 32px;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`
