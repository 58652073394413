import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/global.css'
import App from './App'
import { Auth0Provider } from '@auth0/auth0-react'
import { GlobalContextProvider } from 'contexts/globalContext/useGlobalContext'
import { FlowContextProvider } from 'contexts/flowContext/useFlowContext'
import { config } from 'configs/vars'
import { PermissionsContextProvider } from 'contexts/permissionsContext/usePermissionsContext'
const {
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
} = config()

const onRedirectCallback = (appState: any) => {
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname,
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Auth0Provider
    domain={REACT_APP_AUTH0_DOMAIN}
    clientId={REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{ audience: REACT_APP_AUTH0_AUDIENCE }}
    onRedirectCallback={onRedirectCallback}
  >
    <GlobalContextProvider>
      <PermissionsContextProvider>
        <FlowContextProvider>
          <App />
        </FlowContextProvider>
      </PermissionsContextProvider>
    </GlobalContextProvider>
  </Auth0Provider>,
)
