import styled from 'styled-components'

export const MyTravelsContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  > div > div > div {
    table {
      svg {
        cursor: pointer;
        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
`
