import styled from 'styled-components'

export const StopsAndLuggageStyles = styled.div<{ $isflightlist: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
  justify-content: space-between;
  align-items: end;

  ${({ $isflightlist }) =>
    $isflightlist &&
    ` 
    flex-direction: column; 
  `}

  .amount-luggage {
    display: flex;
    flex-direction: row;
    gap: 8px;

    > div {
      display: flex;
      flex-direction: row;
      gap: 4px;

      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.035px;
    }
  }

  @media screen and (max-width: 745px) {
    font-size: 14px;

    .amount-luggage {
      > div {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.035px;
      }
    }
  }
`
