import React from 'react'
import { useTranslation } from 'react-i18next'
import { RequestSummaryStyles } from './styles'
import TotalRequestValue from './components/TotalRequestValue'
import HeaderCloseButton from './components/HeaderCloseButton'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'
import { formatToReal } from 'utils/formatToReal/formateToReal'
import DrawerBottomNavigation from 'components/drawerBottomNavigation'
import Button from 'components/button'
import FlightRequestSummary from './components/FlightRequestSummary'

export type RequestSummaryProps = {
  onClose?: any
  closeButton?: any
}

const RequestSummary: React.FC<RequestSummaryProps> = ({
  onClose,
  closeButton,
}) => {
  const { t } = useTranslation()
  const { flightsCart } = useFlowContext()

  const totalFlightValue =
    flightsCart?.reduce((total, flight) => total + flight.amount, 0) ?? 0

  return (
    <>
      <HeaderCloseButton closeButton={closeButton} />
      <RequestSummaryStyles data-testid="smiles-request-summary">
        <div className="title">{t('requestStatus.resume')}</div>
        {flightsCart && (
          <FlightRequestSummary
            flights={flightsCart}
            totalFlightValue={totalFlightValue}
          />
        )}
        <TotalRequestValue
          currency="R$"
          total={formatToReal(totalFlightValue)}
        />
      </RequestSummaryStyles>
      <DrawerBottomNavigation>
        <Button width="250px" onClick={onClose}>
          {t('footerButton.return')}
        </Button>
      </DrawerBottomNavigation>
    </>
  )
}

export default RequestSummary
