export default `
    <svg width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.92 5.01049C18.72 4.42049 18.16 4.00049 17.5 4.00049H6.5C5.84 4.00049 
    5.29 4.42049 5.08 5.01049L3 11.0005V19.0005C3 19.5505 3.45 20.0005 4 20.0005H5C5.55 
    20.0005 6 19.5505 6 19.0005V18.0005H18V19.0005C18 19.5505 18.45 20.0005 19 20.0005H20C20.55 
    20.0005 21 19.5505 21 19.0005V11.0005L18.92 5.01049ZM6.85 6.00049H17.14L18.18 9.00049H5.81L6.85 
    6.00049ZM19 16.0005H5V11.3405L5.12 11.0005H18.89L19 11.3405V16.0005Z" />
    <path d="M7.5 15C8.32843 15 9 14.3284 9 13.5C9 12.6716 8.32843 12 7.5 12C6.67157 12 6 12.6716 6 13.5C6 14.3284 6.67157 15 7.5 15Z" />
    <path d="M16.5 15C17.3284 15 18 14.3284 18 13.5C18 12.6716 17.3284 12 16.5 12C15.6716 12 15 12.6716 15 13.5C15 14.3284 15.6716 15 16.5 15Z" />
    </svg>
`
