export default `
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 17 20">
<path d="M8.59961 0.5C4.17961 0.5 0.599609 1 0.599609 4.5V14.5C0.599609 15.38 0.989609 16.17 
1.59961 16.72V18.5C1.59961 19.05 2.04961 19.5 2.59961 19.5H3.59961C4.14961 19.5 4.59961 19.05 
4.59961 18.5V17.5H12.5996V18.5C12.5996 19.05 13.0496 19.5 13.5996 19.5H14.5996C15.1496 19.5 15.5996 
19.05 15.5996 18.5V16.72C16.2096 16.17 16.5996 15.38 16.5996 14.5V4.5C16.5996 1 13.0196 0.5 8.59961 
0.5ZM14.2596 3.49H2.93961C3.48961 2.96 4.90961 2.5 8.59961 2.5C12.2896 2.5 13.7096 2.96 14.2596 
3.49ZM14.5996 5.49V8.5H2.59961V5.49H14.5996ZM14.2596 15.23L13.9696 15.5H3.22961L2.93961 
15.23C2.80961 15.12 2.59961 14.87 2.59961 14.5V10.5H14.5996V14.5C14.5996 14.87 14.3896 15.12 14.2596 15.23Z" />
<path d="M5.09961 14.5C5.92804 14.5 6.59961 13.8284 6.59961 13C6.59961 12.1716 5.92804 11.5 5.09961 
11.5C4.27118 11.5 3.59961 12.1716 3.59961 13C3.59961 13.8284 4.27118 14.5 5.09961 14.5Z" />
<path d="M12.0996 14.5C12.928 14.5 13.5996 13.8284 13.5996 13C13.5996 12.1716 12.928 11.5 12.0996 
11.5C11.2712 11.5 10.5996 12.1716 10.5996 13C10.5996 13.8284 11.2712 14.5 12.0996 14.5Z" />
</svg>
`
