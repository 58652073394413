import styled from 'styled-components'

export const SVSummaryItemStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  .item-label {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.01em;
  }

  .item-value {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
  }
`
