import styled from 'styled-components'

export const SummaryContentStyles = styled.div`
  .data {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 24px 40px 24px;
    margin-bottom: 100px;

    .title {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px;
      letter-spacing: 0.08px;
    }

    .travelers-and-fares {
      display: flex;
      flex-direction: row;
      gap: 24px;

      @media screen and (max-width: 588px) {
        flex-direction: column;
      }
    }
  }
`

export const SummaryBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 16px;
  border: 2px solid var(--Neutral-Neutral-300, #d9d9d9);

  .total-label {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.018px;
  }
`

export const TravelersAndRequester = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .requester-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.018px;
  }

  .requester-name {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.08px;
  }
`

export const Total = styled(TravelersAndRequester)`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .total {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.03px;
  }
  .amount {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    margin-left: 2px;
  }
  .fare-by-segment {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
  }
  .fare-amount {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.08px;
    margin-left: 2px;
  }
`

export const SummaryFlightBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px 32px;
  padding: 24px;
  border-radius: 16px;
  border: 2px solid var(--Neutral-Neutral-300, #d9d9d9);

  .flights-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 64px;
  }
`

export const BoxLabel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.08px;
    }
  }

  div {
    display: flex;
    gap: 16px;
    button {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
      color: var(--Neutral-Neutral-600, #595959);
      font-feature-settings:
        'clig' off,
        'liga' off;
      cursor: pointer;
      &:hover {
        color: #000;
      }
    }
  }
`

export const FlightInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .segment-and-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .segment {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.08px;
    }
    .date {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.035px;
    }
  }

  .details {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 745px) {
    .segment-and-date {
      .segment {
        font-size: 14px;
      }
      .date {
        font-size: 12px;
      }
    }
  }
`

export const MoreServicesBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 16px;
  border: 2px solid var(--Neutral-Neutral-300, #d9d9d9);
  background: var(--Neutral-Neutral-200, #f2f2f2);

  > p {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.03px;
  }

  > div {
    display: flex;
    flex-direction: row;
    gap: 14px;
  }
`

export const SummaryHotelBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  border-radius: 16px;
  border: 2px solid var(--Neutral-Neutral-300, #d9d9d9);

  .hotel-info {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .stars {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
    .address {
      display: flex;
      flex-direction: column;
      .address-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
      }
      .address-map-button {
        display: flex;
        flex-direction: row;
        gap: 8px;
        padding: 8px 0;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.01em;
        cursor: pointer;
      }
    }
    .check {
      display: flex;
      flex-direction: row;
      gap: 16px;
      justify-content: space-between;
      border-bottom: 2px solid #d9d9d9;
      padding: 8px 0;
      .check-in-and-out {
        display: flex;
        flex-direction: row;
        align-items: center;

        .check-in-out-label {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.01em;
          padding: 0 8px;
        }
        .check-in-out-value {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.005em;
          padding: 0 8px;
        }
      }
    }
    .room {
      display: flex;
      flex-direction: row;
      gap: 8px;
      border-bottom: 2px solid #d9d9d9;
      padding-bottom: 24px;

      .room-info {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .room-name {
          align-items: center;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.005em;
        }
        .room-description {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.01em;
        }
      }
    }
    .price {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .price-daily {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        .price-daily-label {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.01em;
        }
        .price-daily-value {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.005em;
        }
      }

      .price-total {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        .price-total-label {
          font-size: 20px;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: 0.0015em;
        }
        .price-total-value {
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
        }
      }
    }
  }
`
