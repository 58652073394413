import SectionHeader from 'components/sectionHeader'
import React from 'react'
import { MyTravelsContainer } from './styles'
import Table from 'components/table'
import { useTranslation } from 'react-i18next'
import { TableProps } from 'antd'
import { SMyTravelsTable } from 'shared/interfaces/SMyTravelsTable'

export type SMyTravelProps = {
  items: SMyTravelsTable[]
  loading: boolean
}

const b = 'home.myTravelsSection.'

const MyTravelsSection: React.FC<SMyTravelProps> = ({ items, loading }) => {
  const { t } = useTranslation()

  const columns: TableProps<SMyTravelsTable>['columns'] = [
    {
      title: t(b + 'tHeader.departureCity'),
      dataIndex: 'departureCity',
      key: 'departureCity',
      render: (text) => <p>{text}</p>,
    },
    {
      title: t(b + 'tHeader.travelDate'),
      dataIndex: 'travelDate',
      key: 'travelDate',
      render: (text) => <p>{text}</p>,
    },
    {
      title: t(b + 'tHeader.locator'),
      dataIndex: 'locator',
      key: 'locator',
      render: (text) => <p>{text}</p>,
    },
    // {
    //   title: '',
    //   dataIndex: 'action',
    //   key: 'action',
    //   width: '100px',
    //   render: () => <SmilesIcon type="arrow-right" />,
    // },
  ]

  const data: SMyTravelsTable[] = items

  const seeAllRedirectPath = '/my-travels'

  return (
    <MyTravelsContainer data-testid="smiles-my-travels-section">
      <SectionHeader title={t(b + 'title')} redirectPath={seeAllRedirectPath}>
        <div>
          <Table columns={columns} data={data} loading={loading} />
        </div>
      </SectionHeader>
    </MyTravelsContainer>
  )
}

export default MyTravelsSection
