import React from 'react'
import { SmilesIcon } from 'components/icons'
import { RoomCardRadioContainer } from './styles'
import { SRoom } from 'shared/interfaces/SRoom'
import { formatToReal } from 'utils/formatToReal/formateToReal'
import { countTotalDays } from 'utils/countTotalDays'
import { useTranslation } from 'react-i18next'

export type RoomCardRadioProps = {
  checked: boolean
  roomData: SRoom
  onClick: () => void
}

const RoomCardRadio: React.FC<RoomCardRadioProps> = ({
  checked,
  roomData,
  onClick,
}) => {
  const { t } = useTranslation()
  const totalFare =
    roomData.fares.find((fare) => fare.type === 'FARE')?.price.amount ?? 0

  const dailyFare =
    totalFare / countTotalDays(roomData.checkIn, roomData.checkOut)

  return (
    <RoomCardRadioContainer
      $checked={checked}
      onClick={onClick}
      data-testid="smiles-room-card-radio"
    >
      <div className="check-container">
        <div>{checked && <SmilesIcon type="check" />}</div>
      </div>

      <div className="room-data">
        <div className="room-data-img">
          <SmilesIcon type="smiles-corp" size={90} />
        </div>
        <div className="room-data-info">
          <p className="info-title">{roomData.roomType.name}</p>
          <p className="info-desc">{roomData.roomType.description}</p>
          <div className="info-accomodations-and-price">
            <p>$ $ $ $ $</p>
            <div className="info-price">
              <p className="info-price-label">{t('hotel.daily_rate')}:</p>
              <p className="info-price-value">{formatToReal(dailyFare)}</p>
            </div>
          </div>
        </div>
      </div>
    </RoomCardRadioContainer>
  )
}

export default RoomCardRadio
