const enMyProfile = {
  myProfile: {
    header: 'My Data',
    personalData: {
      header: 'Personal Data',
      fullName: 'Full Name',
      dateOfBirth: 'Date of Birth',
      cpf: 'CPF (Brazilian Tax ID)',
      rg: 'RG (Identity Card)',
    },
    contact: {
      header: 'Contact',
      cellPhone: 'Cell Phone',
      altPhone: 'Alternative Phone',
    },
    editButton: {
      edit: 'Edit',
      cancel: 'Cancel',
      save: 'Save',
    },
  },
}
export default enMyProfile
