import styled from 'styled-components'

export const UserGroupsScreenContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .page-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
  }

  .spinner-centered {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search-and-table {
    border-radius: 8px;
    padding: 16px;
    background: var(--Neutral-Neutral-100, #fff);
    display: flex;
    flex-direction: column;
    gap: 24px;
    box-shadow:
      0px 0px 24px 0px rgba(0, 0, 0, 0.08),
      0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  }

  .trash-icon {
    cursor: pointer;
    > svg {
      &:hover {
        fill: #ff1f1f;
      }
    }
  }

  .pen-icon {
    cursor: pointer;
    > svg {
      &:hover {
        fill: #42b3f5;
      }
    }
  }
`

export const SearchBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
