import styled from 'styled-components'
import { Radio } from 'antd'
import { Color, Size } from 'styles/global'

export const CustomAntdRadio = styled(Radio)`
  padding-left: 3.5px !important;

  span:first-of-type {
    transform: scale(1.28);
    border: 1px solid #656565;

    span:after {
      background: none;
      width: 6px;
      height: 10px;
      border-bottom: 2px solid black;
      border-right: 2px solid black;
      border-radius: 0;
      transform: rotate(45deg) scale(0.6) !important;
      top: 7px;
      left: 10px;
    }
  }

  label:hover,
  span:first-of-type.ant-radio-checked,
  span:first-of-type:hover {
    border: ${Size.LIGHT_BORDER} solid ${Color.PRIMARY};
  }
`
