import React, { useEffect, useState } from 'react'
import { Empty, Skeleton } from 'antd'
import { RenderHotelStyles } from './styles'
import { useTranslation } from 'react-i18next'
import HotelCardRadio from '../hotelCardRadio'
import MoreOptionsButton from 'components/moreOptionsButton'
import { HotelAvail } from 'shared/interfaces/SHotelAvailability'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'
import { SRoom } from 'shared/interfaces/SRoom'

export type RenderHotelsProps = {
  hotelAvail: HotelAvail[]
  loading: boolean
}

const DEFAULT_VISIBLE_HOTELS = 5

const RenderHotels: React.FC<RenderHotelsProps> = ({ hotelAvail, loading }) => {
  const { t } = useTranslation()
  const { setSelectedHotel, setSelectedRoom } = useFlowContext()

  const [selectedHotelItem, setSelectedHotelItem] = useState<HotelAvail>()
  const [selectedRoomItem, setSelectedRoomItem] = useState<SRoom>()
  const [visibleHotels, setVisibleHotels] = useState<number>(
    DEFAULT_VISIBLE_HOTELS,
  )

  const handleSelectHotelAndRoom = (
    hotelAvailItem: HotelAvail,
    roomItem: SRoom,
  ) => {
    setSelectedHotelItem(hotelAvailItem)
    setSelectedRoomItem(roomItem)
  }

  const loadMoreHotels = () => {
    setVisibleHotels((prevVisibleHotels) => prevVisibleHotels + 5)
  }

  const renderHotelCard = (hotelAvailItem: HotelAvail) =>
    hotelAvailItem && (
      <HotelCardRadio
        key={hotelAvailItem.hotel.id}
        checked={hotelAvailItem.hotel.id === selectedHotelItem?.hotel.id}
        hotelAvail={hotelAvailItem}
        handleSelectHotelAndRoom={handleSelectHotelAndRoom}
      />
    )

  const renderHotelsOrSkeleton = () => {
    if (loading) {
      return <Skeleton active />
    }

    if (hotelAvail.length) {
      return hotelAvail
        .slice(0, visibleHotels)
        .map((item) => item?.hotel && renderHotelCard(item))
    }

    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={t('flights.renderFlights.emptyFlightsDescription')}
        data-testid="smiles-empty-img"
      />
    )
  }

  useEffect(() => {
    if (selectedHotelItem && selectedRoomItem) {
      setSelectedHotel(selectedHotelItem)
      setSelectedRoom(selectedRoomItem)
    }
  }, [selectedHotelItem, selectedRoomItem])

  useEffect(() => {
    if (hotelAvail.length) {
      setSelectedHotelItem(hotelAvail[0])
      setSelectedRoomItem(hotelAvail[0].roomGroups[0].rooms[0])
    }
  }, [hotelAvail])

  return (
    <RenderHotelStyles data-testid="smiles-render-hotels">
      {renderHotelsOrSkeleton()}

      {!loading && hotelAvail.length > 0 && (
        <MoreOptionsButton
          label={t('hotel.see_more_options')}
          onClick={loadMoreHotels}
        />
      )}
    </RenderHotelStyles>
  )
}

export default RenderHotels
