import React from 'react'
import NextTravelCard from '../nextTravelCard'
import SmallTravelCard from '../smallTravelCard'
import { SMyTravel } from 'shared/interfaces/SMyTravel'
import { TravelSection } from '../MyTravelSection'
import { useTranslation } from 'react-i18next'

export type ListFilteredTravelsProps = {
  tab: 'next' | 'canceled' | 'previous'
  next?: SMyTravel[]
  canceled?: SMyTravel[]
  previous?: SMyTravel[]
}

const ListFilteredTravels: React.FC<ListFilteredTravelsProps> = ({
  tab,
  next,
  canceled,
  previous,
}) => {
  const { t } = useTranslation()

  switch (tab) {
    case 'next':
      return (
        <TravelSection
          title={t('my_travels.travels.next')}
          travels={next}
          emptyTestId="next-no-data"
          cardComponent={NextTravelCard}
          containerClass="next-travels-cards-container"
        />
      )
    case 'canceled':
      return (
        <TravelSection
          title={t('my_travels.travels.your_cancelled_travels')}
          travels={canceled}
          emptyTestId="canceled-no-data"
          cardComponent={SmallTravelCard}
        />
      )
    case 'previous':
      return (
        <TravelSection
          title={t('my_travels.travels.your_previous_travels')}
          travels={previous}
          emptyTestId="previous-no-data"
          cardComponent={SmallTravelCard}
        />
      )
    default:
      return <></>
  }
}

export default ListFilteredTravels
