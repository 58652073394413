import React from 'react'
import Button from 'components/button'
import { SmilesIcon } from 'components/icons'
import Drawer from 'components/drawer'
import { DoubleCheckStyles } from './styles'

export type DoubleCheckProps = {
  mainLabel: string
  subLabel: string
  desc: string
  buttonLabel: string
  buttonClick: () => void
  close: () => void
  isOpen: boolean
  width?: string
  height?: string
  placement?: 'right' | 'top' | 'bottom' | undefined
  loading?: boolean
}

const DoubleCheck: React.FC<DoubleCheckProps> = ({
  mainLabel,
  subLabel,
  desc,
  buttonClick,
  buttonLabel,
  close,
  isOpen,
  width = '100%',
  height,
  placement = 'bottom',
  loading = false,
}) => {
  return (
    <Drawer
      onClose={close}
      open={isOpen}
      width={width}
      height={height}
      placement={placement}
      closable={false}
    >
      <DoubleCheckStyles data-testid="smiles-complete-request">
        <div className="header">
          <span>{mainLabel}</span>
          <a className="header_button" onClick={close}>
            <SmilesIcon type="close" color="#FFFFDF" />
          </a>
        </div>
        <div className="text_container">
          <div className="content">
            <span className="text_container_title">{subLabel}</span>
            <span className="text_container_text">{desc}</span>
          </div>
          <Button
            size="md-responsive"
            width="18.333vw"
            onClick={buttonClick}
            disabled={loading}
            isLoading={loading}
          >
            {buttonLabel}
          </Button>
        </div>
      </DoubleCheckStyles>
    </Drawer>
  )
}

export default DoubleCheck
