import React, { memo } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import ProtectedRoute from './ProtectedRoute'

// Pages
import DebtAllocation from 'pages/debt-allocation'
import UserGroupsScreen from 'pages/user-groups'
import AccessDenied from 'pages/access-denied'
import MyTravels from 'pages/my-travels'
import MyRequests from 'pages/my-requests'
import GroupScreen from 'pages/group'
import ViewUser from 'pages/view-user'
import SwitchFlow from 'pages/flow'
import Login from 'pages/login'
import MyProfile from 'pages/my-profile'

// Components
import NavBar from 'components/navBar'

// Shares
import FullRequest from 'pages/full-request'
import Home from 'pages/home'
import { ACTION } from 'shared/userActionPermissions'
import Management from 'pages/management'
import CompanyDetails from 'pages/management/companyDetails'

const Routers = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute element={<Home />} />} />
        <Route
          path="/flow/:flow"
          element={<ProtectedRoute element={<SwitchFlow />} />}
        />
        <Route
          path="/user-groups"
          element={
            <ProtectedRoute
              element={<UserGroupsScreen />}
              requiredPermission={ACTION.permissions.listGroups}
            />
          }
        />
        <Route
          path="/user-groups/group/:groupId"
          element={<ProtectedRoute element={<GroupScreen />} />}
        />
        <Route
          path="/user/:email"
          element={
            <ProtectedRoute
              element={<ViewUser />}
              requiredPermission={ACTION.users.getUserById}
            />
          }
        />
        <Route
          path="/my-profile"
          element={<ProtectedRoute element={<MyProfile />} />}
        />
        <Route
          path="/my-travels"
          element={<ProtectedRoute element={<MyTravels />} />}
        />
        <Route
          path="/requests"
          element={<ProtectedRoute element={<MyRequests />} />}
        />
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route
          path="/debt-allocation"
          element={
            <ProtectedRoute
              element={<DebtAllocation />}
              requiredPermission={ACTION.debitAllocation.addAllocation}
            />
          }
        />
        <Route
          path="/travel-request/:travelRequestId"
          element={
            <ProtectedRoute
              element={<FullRequest />}
              requiredPermission={ACTION.approval.getRequestDetails}
            />
          }
        />
        <Route
          path="/management"
          element={<ProtectedRoute element={<Management />} />}
        />
        <Route
          path="/company/:companyId"
          element={<ProtectedRoute element={<CompanyDetails />} />}
        />
      </Routes>
    </Router>
  )
}
export default memo(Routers)
