import styled from 'styled-components'
import { Color, Size } from 'styles/global'

export const StyledSearchInput = styled.div<{ $width?: string }>`
  width: ${(props) => props.$width ?? '100%'};
  height: ${Size.INPUT_HEIGHT};

  input {
    height: ${Size.INPUT_HEIGHT};
    padding-right: 40px;
    border: ${Size.NORMAL_BORDER} solid ${Color.NORMAL_BORDER};
  }

  svg {
    position: relative;
    top: -70.4%;
    right: -87%;

    &:hover {
      cursor: pointer;
      -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
      filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
    }
  }
`
