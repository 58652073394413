import React, { useEffect, useState } from 'react'
import { SmilesIcon } from 'components/icons'
import {
  HotelCardBottom,
  HotelCardData,
  HotelCardRadioContainer,
  HotelCardTop,
  HotelImage,
} from './styles'
import Drawer from 'components/drawer'
import RoomsModalContent from '../roomsModalContent'
import { HotelAvail } from 'shared/interfaces/SHotelAvailability'
import { formatToReal } from 'utils/formatToReal/formateToReal'
import { SRoom } from 'shared/interfaces/SRoom'
import { countTotalDays } from 'utils/countTotalDays'
import HotelDetailsModalContent from '../hotelDetailsModalContent'
import { useTranslation } from 'react-i18next'

export type HotelCardRadioProps = {
  checked: boolean
  hotelAvail: HotelAvail
  handleSelectHotelAndRoom: (hotelAvail: HotelAvail, room: SRoom) => void
}

const HotelCardRadio: React.FC<HotelCardRadioProps> = ({
  checked,
  hotelAvail,
  handleSelectHotelAndRoom,
}) => {
  const { t } = useTranslation()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [isDetailsDrawer, setIsDetailsDrawer] = useState(false)
  const [selectedRoomItem, setSelectedRoomItem] = useState<SRoom>(
    hotelAvail.roomGroups[0].rooms[0],
  )
  const [hotelImage, setHotelImage] = useState('')

  const formattedAddress = `${hotelAvail.hotel.address?.address}, ${hotelAvail?.hotel.address.city.name}-${hotelAvail?.hotel.address.city.country.code}`
  const isRefundable = selectedRoomItem.cancellationPolicies.refundable
  const roomNameMatch = /^[^,-]*/.exec(selectedRoomItem.roomType.name)
  const roomName = roomNameMatch ? roomNameMatch[0] : null
  const peopleCount = selectedRoomItem.names?.length
  const totalFare =
    selectedRoomItem.fares.find((fare) => fare.type === 'FARE')?.price.amount ??
    0

  const dailyFare =
    totalFare /
    countTotalDays(selectedRoomItem.checkIn, selectedRoomItem.checkOut)

  const handleShowHotelDetails = () => {
    setOpenDrawer(true)
    setIsDetailsDrawer(true)
  }

  const handleCloseDawer = () => {
    setOpenDrawer(false)
    setIsDetailsDrawer(false)
  }

  useEffect(() => {
    if (hotelAvail?.hotel?.images?.length) {
      setHotelImage(hotelAvail?.hotel?.images[0].medium)
    }
  }, [hotelAvail.roomGroups])

  return (
    <HotelCardRadioContainer
      $checked={checked}
      data-testid="smiles-hotel-card-radio"
      onClick={() => handleSelectHotelAndRoom(hotelAvail, selectedRoomItem)}
    >
      <HotelCardTop>
        <HotelImage $urlimage={hotelImage}>
          <div className="check">{checked && <SmilesIcon type="check" />}</div>
        </HotelImage>
        <HotelCardData
          onClick={() => handleShowHotelDetails()}
          data-testid="smiles-hotel-card-radio-data"
        >
          <div className="is-favorite">
            <p>{t('hotel.favorites')}</p>
          </div>
          <p className="hotel-data-name">{hotelAvail?.hotel.name}</p>
          <div className="hotel-data-location">
            <p className="hotel-data-location-desc">{formattedAddress}</p>
            <div className="hotel-data-location-sub">
              <p className="hotel-data-location-b2b">B2B</p>
              <p className="hotel-data-location-id">
                ID: {hotelAvail?.hotel.id}
              </p>
            </div>
          </div>
          <div className="hotel-data-price">
            <p className="hotel-data-price-label">{t('hotel.daily_rate')}:</p>
            <p className="hotel-data-price-value">{formatToReal(dailyFare)}</p>
          </div>
          <div className="hotel-data-accomodations-and-refundable">
            <div className="hotel-data-accomodations">$ $ $ $ $</div>
            <div className="hotel-data-refundable">
              {isRefundable ? (
                <p>{t('hotel.refundable_fare')}</p>
              ) : (
                <p>{t('hotel.non_refundable_fare')}</p>
              )}
            </div>
          </div>
        </HotelCardData>
      </HotelCardTop>

      <HotelCardBottom>
        <div className="hotel-room">
          <div className="hotel-room-bed">
            <SmilesIcon type="sleep" />
            <p>{roomName}</p>
          </div>
          <p className="hotel-people">
            {peopleCount} {t('hotel.person')}
          </p>
        </div>

        <button
          className="global-button-reset hotel-see-others-rooms-button"
          onClick={() => setOpenDrawer(true)}
          data-testid="see-other-rooms-button"
        >
          <p className="hotel-see-others-rooms-text">
            {t('hotel.see_other_rooms')}
          </p>
        </button>
      </HotelCardBottom>

      <Drawer onClose={() => handleCloseDawer()} open={openDrawer} width={700}>
        {isDetailsDrawer ? (
          <HotelDetailsModalContent addHotelCallback={handleCloseDawer} />
        ) : (
          <RoomsModalContent
            rooms={hotelAvail.roomGroups[0].rooms}
            setSelectedRoomCallback={setSelectedRoomItem}
            onClose={handleCloseDawer}
          />
        )}
      </Drawer>
    </HotelCardRadioContainer>
  )
}

export default HotelCardRadio
