const ptUser = {
  user: {
    informations: 'Informações',
    permissions: 'Permissões',
    permissionsTab: {
      treeSelect: {
        label: 'Selecione as permissões do usuário',
        placeholder: 'Permissões',
      },
      updatePermissions: 'Atualizar permissões',
      updateConfirm: {
        title: 'Atualizar permissões',
        description: 'Deseja atualizar as permissões deste usuário?',
      },
      noViewPermissions:
        'Você não tem permissão para visualizar permissões de usuário.',
      noUpdatePermissions:
        'Obs: Não será possível alterar as permissões do usuário, pois você não tem permissão para continuar com esta ação',
    },
    notify: {
      msg: {
        success: 'Sucesso!',
        error: 'Erro!',
      },
      desc: {
        success: 'Permissões atualizadas com sucesso!',
        error:
          'Há algo errado com as permissões, verifique os dados ou tente novamente mais tarde.',
      },
    },
    popConfirm: {
      yes: 'Sim',
      cancel: 'Cancelar',
    },
    noData: 'Não há dados',
  },
}

export default ptUser
