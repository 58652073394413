export default `
<svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_4723_33442)">
    <rect x="1" y="0.5" width="100%" height="100%" rx="19.5" stroke="currentColor"/>
    <path d="M27.498 14.41L26.088 13L20.498 18.59L14.908 13L13.498 14.41L19.088 20L13.498 25.59L14.908
      27L20.498 21.41L26.088 27L27.498 25.59L21.908 20L27.498 14.41Z" fill="currentColor"/>
  </g>
  <defs>
    <clipPath id="clip0_4723_33442">
      <rect width="100%" height="100%" fill="none" transform="translate(0.5)"/>
    </clipPath>
  </defs>
</svg>
`
