import { useCompaniesList } from 'pages/management/companiesList/hooks/useCompaniesList'
import { useEmployeesList } from 'pages/management/employeesList/hooks/useEmployeesList'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type Company = {}

type Employee = {}

type DrawerState = {
  isDrawerOpen: boolean
  currentDrawer: string | undefined
}

export type OutsourcedData = {
  employees: (Company | undefined)[]
  companies: (Employee | undefined)[]
}

export const useManagement = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isEditMode, setEditMode] = useState(false)
  const [drawerState, setDrawerState] = useState<DrawerState>({
    isDrawerOpen: false,
    currentDrawer: undefined,
  })
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [selectedItemListId, setSelectedItemListId] = useState<
    string | undefined
  >(undefined)

  const {
    employees,
    setEmployees,
    fetchEmployees,
    pagination,
    onPageChange,
    isLoading,
  } = useEmployeesList()

  const {
    companies,
    setCompanies,
    fetchCompanies,
    paginationCompanies,
    onCompaniesPageChange,
    isCompaniesLoading,
  } = useCompaniesList()

  useEffect(() => {
    if (currentTabIndex === 0) {
      fetchEmployees(null, pagination.page, pagination.limit)
      return
    }

    if (currentTabIndex === 1) {
      fetchCompanies(null, pagination.page, pagination.limit)
    }
  }, [pagination.page, currentTabIndex])

  const setDrawerData = (isDrawerOpen: boolean, currentDrawer: string) => {
    setDrawerState((prevState: any) => ({
      ...prevState,
      isDrawerOpen,
      currentDrawer,
    }))
  }

  const setIsDrawerOpen = (isDrawerOpen: boolean) => {
    setDrawerState((prevState: any) => ({ ...prevState, isDrawerOpen }))
  }

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false)
  }

  const tabs = [
    { label: t('management.tabs.employees') },
    { label: t('management.tabs.companies') },
  ]

  const onTabClick = (index: number) => {
    if (index === currentTabIndex) return

    if (index === 0) {
      setEmployees([])
    } else {
      setCompanies([])
    }
    setCurrentTabIndex(index)
  }

  const changeSelectedRadio = (value: number) => {
    setCurrentTabIndex(value)
  }

  const onItemClick = (id: string, drawer: string) => {
    if (drawer === 'company_details') {
      navigate(`/company/${id}`)
      return
    }

    setDrawerData(true, drawer)
    setIsDrawerOpen(true)
    setSelectedItemListId(id)
  }

  const onEmployeeEditClick = () => {
    setDrawerData(true, 'employee')
    setEditMode(true)
    setIsDrawerOpen(true)
  }

  return {
    currentTabIndex,
    changeSelectedRadio,
    fetchEmployees,
    fetchCompanies,
    pagination,
    setDrawerData,
    tabs,
    onTabClick,
    onItemClick,
    isLoading,
    handleCloseDrawer,
    employees,
    companies,
    onPageChange,
    drawerState,
    setDrawerState,
    selectedItemListId,
    paginationCompanies,
    onCompaniesPageChange,
    isCompaniesLoading,
    onEmployeeEditClick,
    isEditMode,
    setEditMode,
  }
}
