import React from 'react'
import { ProfileContainer } from './styles'
import { SmilesIcon } from 'components/icons'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'

export type ProfileTypes = {
  onClick?: () => void
}

const Profile: React.FC<ProfileTypes> = ({ onClick }) => {
  const { user } = useAuth0()
  const { t } = useTranslation()

  const firstName = user?.name?.split(' ')[0] ?? 'user'

  return (
    <ProfileContainer onClick={onClick} data-testid="smiles-profile">
      <div className="profile-icon">
        <SmilesIcon type="user" size={18} />
      </div>
      <p>
        {t('navbar.profileDropdown.hello')} {firstName}
      </p>
      <div className="profile-arrow">
        <SmilesIcon type="dropdown-arrow" size={10} />
      </div>
    </ProfileContainer>
  )
}

export default React.memo(Profile)
