import styled from 'styled-components'
import Checkbox from 'antd/lib/checkbox'
import { Color } from 'styles/global'

export const CheckboxStyles = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  input {
    appearance: none;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: 2px solid #595959;
    border-radius: 8px;
    background: white;
    cursor: pointer;

    &:enabled {
      &:hover {
        border-color: ${Color.PRIMARY};
      }

      &:active {
        box-shadow: none;
        background-color: ${Color.PRIMARY};
        border-color: ${Color.PRIMARY};
      }

      &:checked {
        border: none;
        background-color: ${Color.PRIMARY};
      }

      &:focus-visible {
        outline-style: solid;
        outline-width: 1.5px;
        outline-color: ${Color.PRIMARY};
        outline-offset: 2px;
      }
    }

    &:disabled {
      background-color: #e4e6eb;
      border: 1px solid red;
      cursor: not-allowed;

      &:checked {
        background-color: #ced2db;
        border: none;
      }
    }
  }

  > label {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.035px;
  }
`

export const CustomAntdCheckbox = styled(Checkbox)`
  &:hover {
    .ant-checkbox-inner {
      border-color: ${Color.PRIMARY} !important;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${Color.PRIMARY} !important;
    border-color: ${Color.PRIMARY} !important;

    &:after {
      border-color: black;
    }
  }

  .ant-checkbox .ant-checkbox-inner {
    border: 2px solid #999;
    border-radius: 8px;
    width: 24px;
    height: 24px;
  }

  .ant-checkbox .ant-checkbox-inner:after {
    inset-inline-start: 30%;
    display: table;
  }

  .ant-checkbox + span {
    width: 100%;
  }

  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: ${Color.PRIMARY} !important;
      }
    }
  }
`
