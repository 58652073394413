import styled from 'styled-components'

export const TravelsContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media screen and (max-width: 840px) {
    flex-direction: column;
  }
`

export const HomeContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
`
