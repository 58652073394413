export default `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path d="M6.93246 13.688L9.77156 8.9H13.0939C13.5953 8.9 14 8.498 14 8C14 7.502 13.5953 7.1 13.0939 7.1L9.77156 
  7.1L6.93246 2.312C6.82373 2.12 6.61231 2 6.38881 2C5.96596 2 5.66393 2.402 5.7787 2.804L7.04724 7.1L3.73093 
  7.1L2.91545 5.744C2.86108 5.654 2.75839 5.6 2.6557 5.6H2.30534C2.106 5.6 1.96103 5.786 2.00935 5.972L2.45032 
  7.724L2.52281 8L2.46844 8.228L2.40199 8.48L2.11204 9.62L2.00935 10.022C1.96103 10.214 2.106 10.394 2.30534 
  10.394H2.6557C2.76443 10.394 2.86108 10.34 2.91545 10.25L3.73093 8.9L7.05328 8.9L5.78475 13.196C5.66393 13.598 
  5.96596 14 6.38881 14C6.61231 14 6.82373 13.88 6.93246 13.688Z" />
  <path d="M16.0675 10.312L13.2284 15.1H9.90609C9.40472 15.1 9 15.502 9 16C9 16.498 9.40472 16.9 9.90609 
  16.9H13.2284L16.0675 21.688C16.1763 21.88 16.3877 22 16.6112 22C17.034 22 17.3361 21.598 17.2213 
  21.196L15.9528 16.9H19.2691L20.0846 18.256C20.1389 18.346 20.2416 18.4 20.3443 18.4H20.6947C20.894 18.4 
  21.039 18.214 20.9906 18.028L20.5497 16.276L20.4772 16L20.5316 15.772L20.598 15.52L20.888 14.38L20.9906 
  13.978C21.039 13.786 20.894 13.606 20.6947 13.606H20.3443C20.2356 13.606 20.1389 13.66 20.0846 13.75L19.2691 
  15.1L15.9467 15.1L17.2153 10.804C17.3361 10.402 17.034 10 16.6112 10C16.3877 10 16.1763 10.12 16.0675 10.312Z" />
</svg>
`
