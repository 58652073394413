const enNavbar = {
  navbar: {
    links: {
      home: 'Home',
      makeRequest: 'Make request',
      myRequests: 'My requests',
      myTravels: 'My travels',
      favorites: 'Favorites',
      management: 'Management',
    },
    linksDropdown: {
      makeRequest: {
        title: 'Select the desired service',
        requestAir: 'Air',
        requestAccommodation: 'Accommodation',
        requestCar: 'Car',
        requestRoad: 'Road',
        requestOther: 'Other services',
      },
      myTravels: {
        title: 'All your travels',
        changedTrips: 'Changed trips',
        upcomingTrips: 'Upcoming trips',
        previousTrips: 'Previous trips',
      },
      favorites: {
        title: 'Quickly access your favorited items',
        favorites: 'Favorites',
        favoriteFlights: 'Favorite flights',
        favoriteHotels: 'Favorite hotels',
        favoriteTravels: 'Favorite travels',
      },
      management: {
        title: 'Access management data',
        report: 'Report',
        thirdPartyRegistration: 'Third-party registration',
        advanceAndAccountability: 'Advance and accountability',
      },
    },
    languageDropdown: {
      en: 'English',
      es: 'Spanish',
      pt: 'Portuguese',
    },
    profileDropdown: {
      hello: 'Hello,',
      myProfile: 'My profile',
      changeToAdmin: 'Change to admin',
      userGroups: 'User groups',
      logout: 'Logout',
    },
  },
}

export default enNavbar
