import DrawerBottomNavigation from 'components/drawerBottomNavigation'
import Button from 'components/button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NewOutsourcedFormContent } from './styles'
import { useChangeCompanyStatus } from './hooks/useChangeCompanyStatus'
import { Checkbox } from 'components/checkbox'

type ChangeCompanyStatus = {
  onFinishCallback: () => void
  companyId: string
  enabled: boolean
}

const ChangeCompanyStatus: React.FC<ChangeCompanyStatus> = ({
  onFinishCallback,
  companyId,
  enabled,
}) => {
  const { t } = useTranslation()
  const { isConfirmed, setIsConfirmed, isLoading, onChangeStatus } =
    useChangeCompanyStatus(onFinishCallback, companyId, enabled)
  return (
    <div data-testid="smiles-handle-employee">
      <NewOutsourcedFormContent>
        <div className="header">
          {enabled ? (
            <p>{t('change_company_status.disable_title')}</p>
          ) : (
            <p>{t('change_company_status.enable_title')}</p>
          )}
        </div>
        <div className="form-container">
          <div className="description">
            {enabled ? (
              <p>{t('change_company_status.disable_description_1')}</p>
            ) : (
              <p>{t('change_company_status.enable_description_1')}</p>
            )}
            {enabled ? (
              <p>{t('change_company_status.disable_description_2')}</p>
            ) : (
              <p>{t('change_company_status.enable_description_2')}</p>
            )}
          </div>
          <Checkbox
            onChange={(event: any) => {
              setIsConfirmed(event.target.checked)
            }}
            label={t('change_company_status.confirm_checkbox')}
          />
        </div>
      </NewOutsourcedFormContent>
      <div className="drawer-bottom">
        <DrawerBottomNavigation>
          <Button
            onClick={onChangeStatus}
            width="340px"
            disabled={!isConfirmed || isLoading}
          >
            {enabled ? (
              <p>{t('change_company_status.disable_button')}</p>
            ) : (
              <p>{t('change_company_status.enable_button')}</p>
            )}
          </Button>
        </DrawerBottomNavigation>
      </div>
    </div>
  )
}

export default ChangeCompanyStatus
