import { HotelAvail } from 'shared/interfaces/SHotelAvailability'
import { SMyProfile } from 'shared/interfaces/SMyProfile'
import { SRoom } from 'shared/interfaces/SRoom'
import { getFromStorage } from 'utils/localStorage/storageUtils'

export function getDailyRateFromCart(hotel: HotelAvail): number {
  const fare = hotel.lowestFare.fares.find((f: any) => f.type === 'FARE')
  return fare ? fare.price.amount : 0
}

export function getTravelRequestFromCart(destination: string): {
  consultant_id: string
  requester_id: string
  destination: string
  travelers: any[]
} {
  const user: SMyProfile = getFromStorage('my_profile_data')

  return {
    consultant_id: user.id,
    requester_id: user.id,
    destination: destination,
    travelers: [user.id],
  }
}

export function mountHotelCart(
  hotel: HotelAvail,
  rooms: SRoom[],
  travelRequestId?: string,
): SHotelCart {
  const travelRequestData = travelRequestId
    ? { travel_request_id: travelRequestId }
    : {
        travel_request: getTravelRequestFromCart(hotel.hotel.address.city.name),
      }

  return {
    name: hotel.hotel.name,
    address: hotel.hotel.address.address,
    check_in: hotel.lowestFare.checkIn + 'T09:45:00-03:00',
    check_out: hotel.lowestFare.checkOut + 'T09:45:00-03:00',
    daily_rate: getDailyRateFromCart(hotel),
    external_hotel_id: hotel.hotel.id,
    key_detail: hotel.hotel.keyDetail,
    rooms: rooms.map((room: SRoom) => ({
      name: room.roomType.name,
      description: room.roomType.description,
      key: room.roomType.code,
    })),
    ...travelRequestData,
  }
}
