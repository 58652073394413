import dayjs from 'dayjs'

export function countTotalDays(
  startDateStr: string,
  endDateStr: string,
): number {
  const startDate = dayjs(startDateStr)
  const endDate = dayjs(endDateStr)

  if (!startDate.isValid() || !endDate.isValid()) {
    throw new Error('Invalid dates')
  }

  return endDate.diff(startDate, 'day')
}
