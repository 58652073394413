export default `
  <svg height="100%" width="100%" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512" xml:space="preserve">
    <path d="M505.508,475.582l-163.48-163.48c26.405-32.888,42.214-74.647,42.214-120.102c0-106.033-85.967-192-192-192
      s-192,85.967-192,192s85.967,192,192,192c45.207,0,86.759-15.635,119.563-41.78l163.532,163.532
      c8.331,8.331,21.839,8.331,30.17,0C513.84,497.42,513.84,483.913,505.508,475.582z M42.91,192
      c0-82.469,66.865-149.333,149.333-149.333S341.577,109.531,341.577,192c0,41.134-16.637,78.385-43.547,105.394
      c-0.152,0.143-0.313,0.269-0.462,0.417c-0.117,0.117-0.215,0.245-0.329,0.364c-26.977,26.679-64.061,43.158-104.995,43.158
      C109.775,341.333,42.91,274.469,42.91,192z"/>
  </svg>
`
