import styled from 'styled-components'

export const HotelInfoBarContainer = styled.section`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const HotelInfoBarStyles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  border-right: 2px solid #999999;
  padding-right: 8px;
  color: #595959;
  > div {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    span {
      font-family: Nunito;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.08px;
    }
    p {
      font-family: Nunito;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.035px;
    }
  }
`

export const ChangeSearchButton = styled.button`
  border: none;
  background: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.08px;
  margin-left: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #999;
  }
`
