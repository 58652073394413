export default `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32" version="1.1" x="0px" y="0px" width="100%" height="100%">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M15.5,5 C9.70101013,5 5,9.70101013 5,15.5 L5,16.5 C5,22.2989899 9.70101013,27 15.5,27
        L16.5,27 C22.2989899,27 27,22.2989899 27,16.5 L27,15.5 C27,9.70101013 22.2989899,5 16.5,5
        L15.5,5 Z M15.5,3 L16.5,3 C23.4035594,3 29,8.59644063 29,15.5 L29,16.5
        C29,23.4035594 23.4035594,29 16.5,29 L15.5,29 C8.59644063,29 3,23.4035594 3,16.5 L3,15.5
        C3,8.59644063 8.59644063,3 15.5,3 Z" fill="#000000" fill-rule="nonzero"/>
      <path d="M19,11 C19.5522847,11 20,11.4477153 20,12 C20,12.5522847 19.5522847,13 19,13
        L15,13 C14.4477153,13 14,13.4477153 14,14 C14,14.5522847 14.4477153,15 15,15 L17,15
        C18.6568542,15 20,16.3431458 20,18 C20,19.6568542 18.6568542,21 17,21 L13,21
        C12.4477153,21 12,20.5522847 12,20 C12,19.4477153 12.4477153,19 13,19 L17,19
        C17.5522847,19 18,18.5522847 18,18 C18,17.4477153 17.5522847,17 17,17 L15,17
        C13.3431458,17 12,15.6568542 12,14 C12,12.3431458 13.3431458,11 15,11 L19,11 Z"
        fill="#000000" fill-rule="nonzero"/><path d="M15,10 C15,9.44771525 15.4477153,9 16,9
        C16.5522847,9 17,9.44771525 17,10 L17,12 C17,12.5522847 16.5522847,13 16,13
        C15.4477153,13 15,12.5522847 15,12 L15,10 Z" fill="#000000" fill-rule="nonzero"/>
      <path d="M17,22 C17,22.5522847 16.5522847,23 16,23 C15.4477153,23 15,22.5522847 15,22 L15,20
        C15,19.4477153 15.4477153,19 16,19 C16.5522847,19 17,19.4477153 17,20 L17,22 Z"
        fill="#000000" fill-rule="nonzero"/>
    </g>
  </svg>
`
