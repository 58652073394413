import { useEffect, useState, useCallback } from 'react'
import { SGroupUser } from 'shared/interfaces/SGroupUser'
import { getUserByEmail } from 'services/users'
import { notify } from 'components/notify'
import { useParams } from 'react-router-dom'

export const useViewUser = () => {
  const { email } = useParams()
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [userData, setUserData] = useState<SGroupUser>()

  const handleTab = (idx: number) => {
    setCurrentTabIndex(idx)
  }

  const fetchUserData = useCallback(async (userEmail: string) => {
    setIsLoading(true)

    const res = await getUserByEmail(userEmail)
    if (res.data) {
      setUserData(res.data[0])
    } else {
      notify({ desc: res.error, type: 'error' })
    }

    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (email) {
      fetchUserData(email)
    }
  }, [email, fetchUserData])

  return {
    email,
    currentTabIndex,
    isLoading,
    userData,
    handleTab,
    tabs: [{ label: 'user.informations' }, { label: 'user.permissions' }],
  }
}
