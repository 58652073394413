import { Select } from 'antd'
import styled from 'styled-components'

export const StyledAntSelectDropdown = styled(Select)`
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.08px;

  .ant-select-selection-item {
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.08px;
  }
`
