import React from 'react'
import { StopsAndLuggageStyles } from './styles'
import { SmilesIcon } from 'components/icons'
import { Color } from 'styles/global'
import { SMyTravelFlight } from 'shared/interfaces/SMyTravel'
import { useTranslation } from 'react-i18next'

export type StopsAndBaggageProps = {
  flightData: SMyTravelFlight
  isFlightList?: boolean
}

const StopsAndBaggage: React.FC<StopsAndBaggageProps> = ({
  flightData,
  isFlightList = false,
}) => {
  const { t } = useTranslation()

  const stopsText = () => {
    const stopsCount = flightData.stops_count
    switch (stopsCount) {
      case 0:
        return t('component.stops_and_baggage.direct')
      case 1:
        return `1 ${t('component.stops_and_baggage.stop')}`
      default:
        return `${stopsCount} ${t('component.stops_and_baggage.stops')}`
    }
  }

  return (
    <StopsAndLuggageStyles
      $isflightlist={isFlightList}
      data-testid="smiles-stops-and-baggage"
    >
      <p>
        <b>{stopsText()}</b>
      </p>
      <div className="amount-luggage">
        <div>
          <SmilesIcon type="backpack" color={Color.PRIMARY} size={18} />
          <p>
            <b>{flightData.baggage}</b>
          </p>
        </div>
        <div>
          <SmilesIcon type="work" color={Color.PRIMARY} size={18} />
          <p>
            <b>{flightData.baggage}</b>
          </p>
        </div>
        <div>
          <SmilesIcon type="luggage" color={Color.PRIMARY} size={18} />
          <p>
            <b>{flightData.baggage}</b>
          </p>
        </div>
      </div>
    </StopsAndLuggageStyles>
  )
}

export default StopsAndBaggage
