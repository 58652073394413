import styled from 'styled-components'

export const HeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  > p {
    color: #000;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.03px;
  }
  > button {
    align-self: self-end;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.08px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      color: var(--primary-color);
    }
  }
`
