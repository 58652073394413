import { API } from 'services/config'

const url = '/debit-allocation/v1'
const userUrl = '/users/v1'

type CostCenterParams = {
  search: string
}

export type createDebitAllocationParams = {
  travelRequestId?: string
  reasonId: string
  justificationId?: string
  costCentersIds: string[]
  anotherMotive?: string
  additionalInformation?: string
}

const getUserInformations = async (id: string) => {
  try {
    const response = await API.get(`${userUrl}/user/${id}`)
    return response.data
  } catch (error) {
    return { error: 'Error on get User informations' }
  }
}

const getCostCenter = async ({ search }: CostCenterParams) => {
  try {
    const response = await API.get(`${url}/cost-center?search=${search}`)
    return response.data
  } catch (error) {
    return { error: 'Error on get Cost Center' }
  }
}

const getReasons = async () => {
  try {
    const response = await API.get(`${url}/travel-reason`)
    return response.data
  } catch (error) {
    return { error: 'Error on get Reasons' }
  }
}

const createDebitAllocation = async ({
  travelRequestId,
  reasonId,
  costCentersIds,
  justificationId,
  anotherMotive,
  additionalInformation,
}: createDebitAllocationParams) => {
  try {
    const response = await API.post(`${url}/debit-allocation`, {
      travelRequestId,
      reasonId,
      costCentersIds,
      justificationId,
      anotherMotive,
      additionalInformation,
    })
    return response.data
  } catch (error) {
    throw new Error('Error on create Debit Allocation')
  }
}

export { getUserInformations, getCostCenter, getReasons, createDebitAllocation }
