import React, { useEffect, useState } from 'react'
import MainContainer from 'components/mainContainer'
import { SearchBar, UserGroupsScreenContent } from './styles'
import Table from 'components/table'
import Input from 'components/Input'
import Button from 'components/button'
import { SmilesIcon } from 'components/icons'
import { Alert, Popconfirm, Space, TableProps } from 'antd'
import { Link } from 'react-router-dom'
import Drawer from 'components/drawer'
import { deleteGroup, getGroups } from 'services/group'
import Breadcrumb from 'components/breadcrumb'
import HandleGroup from './handleGroup'
import { notifyError, notifySuccess } from 'utils/handleRequestNotify'
import { useTranslation } from 'react-i18next'
import { SGroup } from 'shared/interfaces/SGroup'
import { usePermissionsContext } from 'contexts/permissionsContext/usePermissionsContext'
import { ACTION } from 'shared/userActionPermissions'
import Spinner from 'components/spinner'

const UserGroupsScreen: React.FC = () => {
  const { t } = useTranslation()
  const { can, loadAllUserPermissions, isPermissionsLoading } =
    usePermissionsContext()

  const [searchValue, setSearchValue] = useState<string>('')
  const [groups, setGroups] = useState<SGroup[]>([])
  const [openDrawer, setOpenDrawer] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedGroupToEdit, setSelectedGroupToEdit] = useState<
    SGroup | undefined
  >()

  useEffect(() => {
    if (can(ACTION.permissions.listGroups)) {
      fetchGroups()
    }
  }, [can])

  const fetchGroups = async () => {
    try {
      const res = await getGroups()
      setGroups(res.data)
      setIsLoading(false)
    } catch (error) {
      notifyError()
      setIsLoading(false)
    }
  }

  const handleEditGroup = (group: SGroup) => {
    setSelectedGroupToEdit(group)
    setOpenDrawer(true)
  }

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
  }

  const handleOpenDrawer = () => {
    setSelectedGroupToEdit(undefined)
    setOpenDrawer(true)
  }

  const handleCloseDrawer = () => {
    setOpenDrawer(false)
  }

  const handleDelete = async (id: string) => {
    try {
      await deleteGroup(id)
      fetchGroups()
      notifySuccess(t('userGroups.notify.groupRemoved'))
    } catch (error) {
      notifyError()
    }
  }

  const filteredGroups = groups
    ?.filter((item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase()),
    )
    .map((item) => ({ ...item, key: item.id }))

  const columns: TableProps<SGroup>['columns'] = [
    {
      title: t('userGroups.table.columns.groupName'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <Link to={`/user-groups/group/${record.id}`}>
          <p>{record.name}</p>
        </Link>
      ),
    },
    {
      title: t('userGroups.table.columns.description'),
      dataIndex: 'description',
      key: 'description',
      render: (text) => <p>{text}</p>,
    },
    {
      title: t('userGroups.table.columns.action'),
      key: 'action',
      width: 100,
      render: (_, record) => (
        <Space size="middle">
          {can(ACTION.permissions.updateGroup) && (
            <button
              className="global-button-reset pen-icon"
              data-testid="edit-group-button"
              onClick={() => handleEditGroup(record)}
            >
              <SmilesIcon type="pen" size={18} />
            </button>
          )}
          {can(ACTION.permissions.deleteGroup) && (
            <Popconfirm
              title={t('userGroups.table.deleteGroupPopConfirm.title')}
              description={t(
                'userGroups.table.deleteGroupPopConfirm.description',
              )}
              okText={t('userGroups.table.deleteGroupPopConfirm.okText')}
              cancelText={t(
                'userGroups.table.deleteGroupPopConfirm.cancelText',
              )}
              onConfirm={() => handleDelete(record.id)}
              placement="left"
            >
              <div className="trash-icon">
                <SmilesIcon type="trash" size={20} />
              </div>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ]

  return (
    <div data-testid="smiles-users-groups-screen">
      <MainContainer>
        <UserGroupsScreenContent>
          <Breadcrumb
            items={[
              {
                title: <Link to="/">{t('userGroups.breadcrumb.home')}</Link>,
              },
              {
                title: t('userGroups.breadcrumb.userGroups'),
              },
            ]}
          />

          <p className="page-title">{t('userGroups.pageTitle')}</p>

          {isPermissionsLoading ? (
            <div className="spinner-centered">
              <Spinner />
            </div>
          ) : (
            <div className="search-and-table">
              {can(ACTION.permissions.listGroups) ? (
                <>
                  <SearchBar>
                    <Input
                      width="450px"
                      placeholder={t('userGroups.searchGroupPlaceholder')}
                      suffix={<SmilesIcon type="search" />}
                      onChange={(e) => handleSearchChange(e.target.value)}
                    />
                    <Button
                      buttonType="outline"
                      width="200px"
                      onClick={handleOpenDrawer}
                      data-testid="open-drawer-button"
                      show={can(ACTION.permissions.createGroup)}
                    >
                      <p>{t('userGroups.newGroupButton')}</p>
                    </Button>
                  </SearchBar>
                  <Table
                    columns={columns}
                    data={filteredGroups}
                    loading={isLoading}
                  />
                </>
              ) : (
                <Alert
                  showIcon
                  type="warning"
                  description={t('userGroups.alert.noPermToViewGroupUsers')}
                />
              )}
            </div>
          )}
        </UserGroupsScreenContent>
      </MainContainer>

      <Drawer
        onClose={handleCloseDrawer}
        open={openDrawer}
        width={700}
        data-testid="drawer-component-id"
        destroyOnClose
      >
        <HandleGroup
          onFinishCallback={() => {
            setOpenDrawer(false)
            setSelectedGroupToEdit(undefined)
            loadAllUserPermissions()
            fetchGroups()
            notifySuccess()
          }}
          selectedGroupToEdit={selectedGroupToEdit}
        />
      </Drawer>
    </div>
  )
}

export default UserGroupsScreen
