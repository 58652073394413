export default `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <rect x="-3" width="33.6" height="24" rx="2" fill="white"/>
  <mask id="mask0_2794_20823" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="-3" y="0" width="34" height="24">
    <rect x="-3" width="33.6" height="24" rx="2" fill="white"/>
  </mask>
  <g mask="url(#mask0_2794_20823)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M-3 6.4H30.6V0H-3V6.4Z" fill="#DD172C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M-3 24H30.6V17.6H-3V24Z" fill="#DD172C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M-3 17.6H30.6V6.40002H-3V17.6Z" fill="#FFD133"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.80078 11.2H7.40078V12H5.80078V11.2Z" fill="#FFEDB1"/>
    <path d="M4.19227 11.0944C4.17607 10.9 4.32944 10.7334 4.52445 10.7334H7.07538C7.2704 10.7334 7.42376 10.9 
    7.40756 11.0944L7.16962 13.9497C7.12643 14.468 6.69316 14.8667 6.17307 14.8667H5.42676C4.90667 14.8667 4.4734 
    14.468 4.43021 13.9497L4.19227 11.0944Z" stroke="#A41517" stroke-width="0.666667"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.19922 12H7.39922V12.8H6.59922L5.79922 14.4L4.99922 12.8H4.19922V12Z" fill="#A41517"/>
    <rect x="1.80078" y="9.59998" width="1.6" height="5.6" rx="0.666667" fill="#A41517"/>
    <rect x="8.19922" y="9.59998" width="1.6" height="5.6" rx="0.666667" fill="#A41517"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.19922 9.28C4.19922 8.57308 4.77229 8 5.47922 8H6.11922C6.82614 8 7.39922 
    8.57308 7.39922 9.28C7.39922 9.45673 7.25595 9.6 7.07922 9.6H4.51922C4.34249 9.6 4.19922 9.45673 4.19922 9.28Z" fill="#A41517"/>
  </g>
</svg>
`
