import styled from 'styled-components'

export const NavBarHeader = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  border-bottom: 2px solid #d9d9d9;
  align-items: center;

  svg {
    display: flex !important;
  }
  .close-icon {
    cursor: pointer;
    &:hover {
      color: var(--primary-color);
    }
  }
`
