import { TreeData } from 'components/permissionTreeSelect'
import { SPermission } from 'shared/interfaces/SPermission'

export function formatPermissionsForTreeSelect(
  permissionsFromAPI: SPermission[],
  t?: any,
  language?: string,
): TreeData {
  const groupedPermissions: { [groupName: string]: SPermission[] } = {}

  permissionsFromAPI.forEach((permission: SPermission) => {
    if (permission.path) {
      const pathSegments = permission.path.split('/')
      const groupName = pathSegments[1]
      if (!groupedPermissions[groupName]) {
        groupedPermissions[groupName] = []
      }
      groupedPermissions[groupName].push(permission)
    }
  })
  return Object.keys(groupedPermissions).map((groupName) => ({
    title: t(`handleGroup.permissions.${groupName}`),
    value: groupName,
    key: groupName,
    group: true,
    children: groupedPermissions[groupName].map((permission) => ({
      title: permission.translations?.[language as any] ?? permission.name,
      value: permission.id,
      key: permission.id,
    })),
  }))
}
