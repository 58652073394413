import styled from 'styled-components'

export const MyTravelsPageContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const MyTravelsPageHeader = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .header-title {
    font-size: 48px;
    font-weight: 400;
    line-height: 60px;
  }
`
