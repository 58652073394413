import styled from 'styled-components'

export const NavBarStyled = styled.div`
  position: relative;
  width: 100%;
  height: 72px;
  z-index: 99;
  border-bottom: 2px solid #d9d9d9;
  padding: 0 156px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left-container,
  .right-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .right-container {
    justify-content: flex-end;
  }

  .burger-menu {
    display: none;
    cursor: pointer;
    fill: #d9d9d9;
    &:hover {
      fill: red;
    }
  }

  &.centered-navbar {
    .icon-container {
      display: flex;
      align-items: center;
    }
  }

  @media screen and (max-width: 1270px) {
    padding: 0 24px;
  }

  @media screen and (max-width: 930px) {
    padding: 0 24px;

    .right-container {
      justify-content: space-between;
      gap: 20px;

      .burger-menu {
        display: block;
      }
    }
  }
`

export const NavLinkContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 32px;
  position: relative;
  z-index: 8;

  @media screen and (max-width: 930px) {
    display: none;
  }
`

export const LangAndProfileContainer = styled.div`
  width: fit-content !important;
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 16px;
  min-width: 250px;
  justify-content: flex-end;
`

export const DropdownContainer = styled.div<{ $show: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 24px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 24px 24px;
  z-index: 9;
  display: none;
  ${({ $show }) => $show && `display: block`};
`

export const NavLink = styled.div<{ $iscurrentlink?: boolean }>`
  position: relative;
  display: flex;
  padding: 23px 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 8;
  &:hover {
    border-bottom: 3px solid var(--primary-color);
    margin-top: 3px;
  }
  ${({ $iscurrentlink }) =>
    $iscurrentlink &&
    `
    border-bottom: 3px solid red;
    margin-top: 3px;
    > p { 
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      letter-spacing: 0.08px;
    }
  `}

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`

export const LangAndProfileDropdown = styled.div<{
  $show: boolean
  $islanguage: boolean
}>`
  position: absolute;
  top: 102%;
  left: -28px;
  min-width: 272px;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 6px 8px;
  z-index: 9;
  display: ${({ $show }) => ($show ? 'block' : 'none')};

  ${({ $islanguage }) =>
    $islanguage &&
    `left: -160px;
`};
`
