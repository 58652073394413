import React from 'react'
import { Col, Form, Row } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useDebtAllocation } from './hooks/useDebtAllocation'
import { DebtAllocationStyles } from './styles'
import Header from './components/Header'
import Footer from './components/Footer'
import Select from 'components/select'
import Input from 'components/Input'
import TextArea from 'components/TextArea'
import Drawer from 'components/drawer'
import MainContainer from 'components/mainContainer'
import SearchCostCenter from 'components/SearchCostCenter'
import RequestSummary from 'components/RequestSummary'
import CompleteRequest from 'components/CompleteRequest'
import RequestStatus from 'components/RequestStatus'
import ViewFullReservation from 'components/ViewFullReservation'
import { useTranslation } from 'react-i18next'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'
import { formatSelectOptions } from 'utils/formatSelectOptions'
import { Checkbox } from 'components/checkbox'

const DebtAllocation: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { travelRequestId } = useFlowContext()

  const {
    user,
    form,
    openDrawers,
    selectCostCenterName,
    others,
    formValues,
    canSubmit,
    closeTravelResponse,
    secondCostCenter,
    loadingCostCenters,
    costCentersList,
    reasonsList,
    loadingReasonsList,
    handleSearch,
    setCostCentersList,
    setSecondCostCenter,
    setOthers,
    handleOpenDrawer,
    handleCloseDrawer,
    handleCheckboxChange,
    handleFormSubmit,
    handleSelectCostCenter,
    setCloseTravelResponse,
  } = useDebtAllocation()
  return (
    <div>
      <MainContainer>
        <Form form={form} data-testid="smiles-debt-allocation-form">
          <DebtAllocationStyles>
            <Header
              onClick={() => handleOpenDrawer('requestSummary')}
              name={user?.name}
              role={t('debit_allocation.gol_employee')}
            />
            <div className="form_container">
              <Row gutter={16}>
                <Col span={24} sm={24} md={8}>
                  <Form.Item
                    name="costCenters"
                    rules={[
                      {
                        required: true,
                        message: t('debit_allocation.required_field'),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      label={t('debit_allocation.cost_center')}
                      placeholder={t(
                        'debit_allocation.regional_or_cost_center',
                      )}
                      loading={loadingCostCenters}
                      onSearch={handleSearch}
                      onSelect={(_, item) => handleSelectCostCenter(item)}
                      onBlur={() => setCostCentersList([])}
                      onClear={() => {
                        form.setFieldValue('approver', undefined)
                        setSecondCostCenter(undefined)
                      }}
                      options={formatSelectOptions({
                        data: costCentersList,
                        label: 'name',
                        value: 'id',
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} sm={24} md={8}>
                  <Form.Item name="approver">
                    <Input
                      label={t('debit_allocation.approver')}
                      placeholder="N/A"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={24} sm={24} md={8}>
                  <Form.Item
                    name="reason"
                    rules={[
                      {
                        required: true,
                        message: t('debit_allocation.required_field'),
                      },
                    ]}
                  >
                    <Select
                      label={t('debit_allocation.trip_reason')}
                      placeholder={t('debit_allocation.reason')}
                      onChange={(_, obj: any) =>
                        setOthers(obj.label === 'Outros')
                      }
                      options={formatSelectOptions({
                        data: reasonsList,
                        label: 'name',
                        value: 'id',
                      })}
                      loading={loadingReasonsList}
                      disabled={loadingReasonsList}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="checkbox">
                {/* <Checkbox
                  onChange={handleCheckboxChange}
                  label={t('debit_allocation.split_cost_center')}
                  name="divideCostCenter"
                /> */}

                {secondCostCenter && (
                  <div className="second-cost-center">
                    <p className="text">
                      {t('debit_allocation.shared_with')}
                      <span>{secondCostCenter?.name}</span>
                    </p>
                    <p className="text">
                      {t('debit_allocation.approver')}:
                      <span>
                        {secondCostCenter?.approvals[0]?.user.name ?? 'N/A'}
                      </span>
                    </p>
                  </div>
                )}
              </div>
              {others && (
                <Form.Item
                  name="anotherMotive"
                  rules={[
                    {
                      required: others,
                      message: t('debit_allocation.required_field'),
                      min: 10,
                    },
                  ]}
                >
                  <TextArea
                    label={t('debit_allocation.reason_justification')}
                    minLength={10}
                    maxLength={300}
                    placeholder={t(
                      'debit_allocation.antecedence_justification',
                    )}
                  />
                </Form.Item>
              )}
              <Form.Item
                name="additionalInformation"
                rules={[
                  {
                    min: 10,
                    message: t('debit_allocation.error.min_characters'),
                  },
                ]}
              >
                <TextArea
                  label={t('debit_allocation.complementary_info')}
                  minLength={10}
                  maxLength={300}
                  placeholder={t(
                    'debit_allocation.do_you_need_to_provide_more_info',
                  )}
                />
              </Form.Item>
            </div>
          </DebtAllocationStyles>
        </Form>
      </MainContainer>
      <Footer
        buttonDisabled={!canSubmit || !travelRequestId}
        onClickContinue={handleFormSubmit}
        onClickReturn={() => navigate('/flow/flights')}
      />
      <Drawer
        onClose={() => handleCloseDrawer('searchCostCenter')}
        open={openDrawers.searchCostCenter}
        width="59.444vw"
        closable={false}
        destroyOnClose
      >
        <SearchCostCenter
          selectCostCenterName={selectCostCenterName}
          onClose={() => handleCloseDrawer('searchCostCenter')}
          closeButton={() => handleCloseDrawer('searchCostCenter')}
        />
      </Drawer>
      <Drawer
        onClose={() => handleCloseDrawer('requestSummary')}
        open={openDrawers.requestSummary}
        width={550}
        closable={false}
      >
        <RequestSummary
          onClose={() => handleCloseDrawer('requestSummary')}
          closeButton={() => handleCloseDrawer('requestSummary')}
        />
      </Drawer>
      <Drawer
        onClose={() => handleCloseDrawer('completeRequest')}
        open={openDrawers.completeRequest}
        width="100%"
        height="24.444vw"
        placement="bottom"
        closable={false}
      >
        <CompleteRequest
          handleOpenRequestStatusDrawer={() =>
            handleOpenDrawer('requestStatus')
          }
          closeButton={() => handleCloseDrawer('completeRequest')}
          formProps={formValues}
          setCloseTravelResponseCallback={setCloseTravelResponse}
        />
      </Drawer>
      {closeTravelResponse && (
        <>
          <Drawer
            onClose={() => handleCloseDrawer('requestStatus')}
            open={openDrawers.requestStatus}
            width={550}
            closable={false}
          >
            <RequestStatus
              onClick={() => handleOpenDrawer('viewFullReservation')}
              onClose={() => navigate('/')}
              closeButton={() => handleCloseDrawer('requestStatus')}
              travelData={closeTravelResponse}
            />
          </Drawer>
          <Drawer
            onClose={() => handleCloseDrawer('viewFullReservation')}
            open={openDrawers.viewFullReservation}
            width={600}
            closable={false}
          >
            <ViewFullReservation
              onClose={() => handleCloseDrawer('viewFullReservation')}
              travelData={closeTravelResponse}
            />
          </Drawer>
        </>
      )}
    </div>
  )
}

export default DebtAllocation
