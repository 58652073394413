import styled from 'styled-components'

export const SVSummaryStyles = styled.div`
  width: 100%;
  padding: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
