import React from 'react'
import { UserPermissionsContainer } from './styles'
import PermissionTreeSelect from 'components/permissionTreeSelect'
import { Alert, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useUserPermissions } from './hooks/useUserPermissions'
import { usePermissionsContext } from 'contexts/permissionsContext/usePermissionsContext'
import { ACTION } from 'shared/userActionPermissions'
import ActionBar from './components/updateUserPermsBar'

export type ViewUserDataProps = {
  email: string
}

const UserPermissions: React.FC<ViewUserDataProps> = ({ email }) => {
  const { can } = usePermissionsContext()
  const { t, i18n } = useTranslation()
  const [form] = Form.useForm()

  const { treeData, isLoadingPerms, isLoading, checkedKeys, handleFinish } =
    useUserPermissions(email, form, t, i18n.language)

  if (!can(ACTION.permissions.listUserPermissions)) {
    return (
      <Alert
        showIcon
        type="warning"
        description={t('user.permissionsTab.noViewPermissions')}
      />
    )
  }

  return (
    <UserPermissionsContainer>
      <Form form={form} data-testid="smiles-handle-group-form">
        <PermissionTreeSelect
          label={t('user.permissionsTab.treeSelect.label')}
          placeholder={t('user.permissionsTab.treeSelect.placeholder')}
          data={treeData}
          formItemName="permissions"
          isLoading={isLoadingPerms}
          value={checkedKeys}
          disabled={isLoadingPerms || isLoading}
        />
      </Form>

      {can(ACTION.permissions.updateUserPermissions) ? (
        <ActionBar
          isLoading={isLoadingPerms || isLoading}
          onConfirm={handleFinish}
        />
      ) : (
        <Alert
          type="warning"
          description={t('user.permissionsTab.noUpdatePermissions')}
        />
      )}
    </UserPermissionsContainer>
  )
}

export default UserPermissions
