import styled from 'styled-components'

export const NextTravelCardStyles = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 28px 24px;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  justify-content: space-between;

  .data {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    .cities-and-date {
      display: flex;
      flex-direction: row;
      gap: 16px 32px;
      flex-wrap: wrap;

      .cities {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;

        .city-name {
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
        }
      }
      .date {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        .date-text {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.005em;
        }
      }
    }
    .travel-info {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .travel-info-item {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;

        p {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.005em;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.0025em;
        }
      }
    }
    .locator {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      > p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        margin-top: 2px;
      }
      > span {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.0025em;
        margin-top: 2px;
      }
      button {
        margin-top: 6px;
        cursor: pointer;
      }
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;

    .see-full-request {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.01em;
      cursor: pointer;
    }

    .bottom-buttons {
      display: flex;
      flex-direction: row;
      justify-content: end;
      gap: 16px;
      width: 365px;

      button {
        border-radius: 16px;
      }
    }
  }
`
