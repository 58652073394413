import MainContainer from 'components/mainContainer'
import React from 'react'
import { ViewUserScreenContainer } from './styles'
import { Avatar, Empty } from 'antd'
import SimpleTabs from 'components/simpleTabs'
import ViewUserData from './viewUserData'
import { SmilesIcon } from 'components/icons'
import { useTranslation } from 'react-i18next'
import UserPermissions from './userPermissions'
import Spinner from 'components/spinner'
import Breadcrumb from 'components/breadcrumb'
import { useViewUser } from './hooks/useViewUser'
import { Link } from 'react-router-dom'

const ViewUser: React.FC = () => {
  const { t } = useTranslation()
  const { currentTabIndex, isLoading, userData, handleTab, tabs } =
    useViewUser()

  return (
    <div data-testid="smiles-view-user-screen">
      <MainContainer>
        <ViewUserScreenContainer>
          <Breadcrumb
            items={[
              {
                title: <Link to="/">Início</Link>,
              },
              {
                title: <Link to="/user-groups">Grupos de usuários</Link>,
              },
              {
                title: <Link to="/user-groups">Grupo</Link>,
              },
              {
                title: 'Usuário',
              },
            ]}
          />

          {isLoading ? (
            <div className="spinner-container">
              <Spinner />
            </div>
          ) : (
            <div className="content">
              <div className="data">
                <div>
                  <Avatar
                    className="centered-avatar"
                    shape="square"
                    size={220}
                    icon={<SmilesIcon type="gol" size={130} />}
                  />
                </div>
                <div className="vertical-divider"></div>

                {userData && (
                  <div className="values">
                    <SimpleTabs
                      tabs={tabs.map((tab) => ({ label: t(tab.label) }))}
                      onClick={handleTab}
                    />
                    {currentTabIndex === 0 && (
                      <ViewUserData userData={userData} />
                    )}
                    {currentTabIndex === 1 && (
                      <UserPermissions email={userData.email} />
                    )}
                  </div>
                )}

                {!userData && (
                  <div className="empty-container">
                    <Empty description={t('user.noData')} />
                  </div>
                )}
              </div>
            </div>
          )}
        </ViewUserScreenContainer>
      </MainContainer>
    </div>
  )
}

export default ViewUser
