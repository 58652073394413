import React, { useEffect, useState } from 'react'
import { AddUserContent } from './styles'
import DrawerBottomNavigation from 'components/drawerBottomNavigation'
import Button from 'components/button'
import { Alert, Form } from 'antd'
import { addUserInGroup } from 'services/group'
import Select from 'components/select'
import { getAllUsers } from 'services/users'
import { notifyError } from 'utils/handleRequestNotify'
import { formatSelectOptions } from 'utils/formatSelectOptions'
import { useTranslation } from 'react-i18next'
import { SUser } from 'shared/interfaces/SUser'
import { usePermissionsContext } from 'contexts/permissionsContext/usePermissionsContext'
import { ACTION } from 'shared/userActionPermissions'

export type AddUserProps = {
  groupId?: string
  onAddUserCallback: () => void
}

const AddUser: React.FC<AddUserProps> = ({ groupId, onAddUserCallback }) => {
  const { t } = useTranslation()
  const { can } = usePermissionsContext()

  const [users, setUsers] = useState<SUser[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedUser, setSelectedUser] = useState<any>()
  const [form] = Form.useForm()

  const handleFinish = async () => {
    setIsLoading(true)
    try {
      await form.validateFields()

      if (selectedUser && groupId) {
        const findUser = users.find((user) => user.id === selectedUser.value)
        const response = await addUserInGroup({
          user: findUser,
          groupId: groupId,
        })
        if (!response.error) {
          onAddUserCallback()
        } else {
          const errorMessage =
            response.details === 'User already in group'
              ? t('group.notify.userAlreadyInGroup')
              : undefined
          notifyError(errorMessage)
        }
      }
    } catch (error) {
      notifyError()
    } finally {
      setIsLoading(false)
    }
  }

  const fetchAllUsers = async () => {
    try {
      const res = await getAllUsers()
      setUsers(res)
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchAllUsers()
  }, [can])

  return (
    <div data-testid="smiles-add-user-in-group-screen">
      <Form form={form} data-testid="smiles-new-group-form">
        <AddUserContent>
          <p className="header">{t('addUserToGroup.title')}</p>
          <div>
            <Form.Item
              name="userName"
              rules={[
                {
                  required: true,
                  message: t('addUserToGroup.form.userName.required'),
                },
              ]}
            >
              <Select
                label={t('addUserToGroup.form.userName.label')}
                placeholder={t('addUserToGroup.form.userName.placeholder')}
                showSearch
                options={formatSelectOptions({
                  data: users,
                  label: 'name',
                  value: 'id',
                })}
                loading={isLoading}
                onChange={(_, value) => setSelectedUser(value)}
                disabled={!can(ACTION.users.listAllUsers)}
              />
            </Form.Item>
            {!can(ACTION.users.listAllUsers) && (
              <Alert
                showIcon
                type="warning"
                description={t('addUserToGroup.alert.notHavePermToAdd')}
              />
            )}
          </div>
        </AddUserContent>

        <DrawerBottomNavigation>
          <Button
            onClick={handleFinish}
            width="300px"
            disabled={isLoading || !selectedUser}
            isLoading={isLoading}
          >
            {t('addUserToGroup.form.addButton')}
          </Button>
        </DrawerBottomNavigation>
      </Form>
    </div>
  )
}

export default AddUser
