export default `
    <svg width="100%" height="100%" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.18268 8.04996L4.91393 6.78121C4.80699 6.67427 4.6734 6.62332 4.51318 6.62838C4.35257 
      6.63304 4.21879 6.68885 4.11185 6.79579C4.0049 6.90274 3.95143 7.03885 3.95143 7.20413C3.95143 7.3694 
      4.0049 7.50552 4.11185 7.61246L5.77435 9.27496C5.88129 9.3819 6.01741 9.43538 6.18268 9.43538C6.34796 
      9.43538 6.48407 9.3819 6.59102 9.27496L9.90143 5.96454C10.0084 5.8576 10.0595 5.72382 10.0548 5.56321C10.0498 
      5.40299 9.99379 5.2694 9.88685 5.16246C9.77991 5.05552 9.64379 5.00204 9.47852 5.00204C9.31324 5.00204 9.17713 
      5.05552 9.07018 5.16246L6.18268 8.04996ZM6.99935 12.8333C6.1924 12.8333 5.43407 12.6801 4.72435 12.3736C4.01463 
      12.0676 3.39727 11.652 2.87227 11.127C2.34727 10.602 1.93174 9.98468 1.62568 9.27496C1.31924 8.56524 1.16602 
      7.8069 1.16602 6.99996C1.16602 6.19302 1.31924 5.43468 1.62568 4.72496C1.93174 4.01524 2.34727 3.39788 
      2.87227 2.87288C3.39727 2.34788 4.01463 1.93215 4.72435 1.62571C5.43407 1.31965 6.1924 1.16663 6.99935 
      1.16663C7.80629 1.16663 8.56463 1.31965 9.27435 1.62571C9.98407 1.93215 10.6014 2.34788 11.1264 2.87288C11.6514 
      3.39788 12.067 4.01524 12.373 4.72496C12.6795 5.43468 12.8327 6.19302 12.8327 6.99996C12.8327 7.8069 12.6795 
      8.56524 12.373 9.27496C12.067 9.98468 11.6514 10.602 11.1264 11.127C10.6014 11.652 9.98407 12.0676 9.27435 
      12.3736C8.56463 12.6801 7.80629 12.8333 6.99935 12.8333ZM6.99935 11.6666C8.29241 11.6666 9.39354 11.2122 10.3028 
      10.3034C11.2116 9.39415 11.666 8.29302 11.666 6.99996C11.666 5.7069 11.2116 4.60577 10.3028 3.69654C9.39354 
      2.78771 8.29241 2.33329 6.99935 2.33329C5.70629 2.33329 4.60535 2.78771 3.69652 3.69654C2.78729 4.60577 
      2.33268 5.7069 2.33268 6.99996C2.33268 8.29302 2.78729 9.39415 3.69652 10.3034C4.60535 11.2122 5.70629 11.6666 6.99935 11.6666Z" />
    </svg>
`
