const esHome = {
  home: {
    searchSection: {
      tabs: {
        air: 'Pasajes aéreos',
        hotel: 'Reserva de hotel',
        car: 'Alquiler de coches',
        other: 'Otros servicios',
      },
      flight: {
        filters: {
          flightOptions: {
            depAndReturn: 'Ida y vuelta',
            depOrReturn: 'Solo ida',
          },
          classes: {
            economic: 'Económica',
            executive: 'Ejecutiva',
            allClasses: 'Todas las clases',
          },
        },
        form: {
          departureInputLabel: '¿Desde dónde sales?',
          destinationInputLabel: '¿A dónde vas?',
          start: 'Fecha de salida',
          end: 'Fecha de regreso',
          inputPlaceholder: 'Ciudad, aeropuerto o lugar',
          inputDatePlaceholder: 'dd/mm/aaaa',
          searchButton: 'Buscar pasajes',
        },
      },
      hotel: {
        filters: {
          roomType: {
            single: 'Individual',
            couple: 'Pareja',
          },
        },
        form: {
          destinationInputLabel: '¿Dónde te hospedarás?',
          destinationInputPlaceholder: 'Destino',
          checkInInputLabel: 'Fecha de entrada',
          checkOutInputLabel: 'Fecha de salida',
          checkInInputPlaceholder: 'Entrada',
          checkOutInputPlaceholder: 'Salida',
          roomTypeInputLabel: 'Tipo de habitación',
          searchButton: 'Buscar hotel',
          requiredField: 'Campo obligatorio',
        },
      },
    },
    requestsTableSection: {
      title: 'Solicitudes',
      tHeader: {
        svNumber: 'Número SV',
        requester: 'Solicitante',
        requestDate: 'Fecha de solicitud',
        destinationCity: 'Ciudad de destino',
        status: 'Estado',
      },
    },
    myTravelsSection: {
      title: 'Mis viajes',
      tHeader: {
        departureCity: 'Ciudad de destino',
        travelDate: 'Fecha de viaje',
        locator: 'Localizador',
      },
    },
  },
}

export default esHome
