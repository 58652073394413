import React, { useEffect, useState } from 'react'
import { FlowTabsContainer, InfoBarContainer } from './styles'
import MainContainer from 'components/mainContainer'
import { useTranslation } from 'react-i18next'
import Tab from 'components/tab'
import TabGroup from 'components/TabGroup'
import { iconType } from 'components/icons/svgs/icons'
import Flights from 'pages/flights'
import FlightInfoBar from './flightInfoBar'
import { useNavigate, useParams } from 'react-router-dom'
import Hotel from 'pages/hotel'
import HotelInfoBar from './hotelInfoBar'
import BottomNavigation from 'components/bottomNavigation'
import Drawer from 'components/drawer'
import SummaryModalContent from 'components/summaryModalContent'

export type FlowType = 'flights' | 'hotel' | 'services' | undefined

const SwitchFlow: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { flow } = useParams()
  const [currentFlow, setCurrentFlow] = useState<FlowType>()
  const [openDrawer, setOpenDrawer] = useState(false)

  const tabs = [
    {
      label: t('flow.tabs.air'),
      icon: 'plane-right',
      flow: 'flights',
    },
    // {
    //   label: t('flow.tabs.hotel'),
    //   icon: 'bed',
    //   flow: 'hotel',
    // },
  ]

  const handleFlow = (selectedFlow: FlowType) => {
    setCurrentFlow(selectedFlow)
    navigate(`/flow/${selectedFlow}`)
  }

  const closeDrawer = () => {
    setOpenDrawer(false)
  }

  function filterFlow() {
    let witchFlow: FlowType
    switch (flow) {
      case 'flights':
        witchFlow = 'flights'
        break
      case 'hotel':
        witchFlow = 'hotel'
        break
      case 'services':
        witchFlow = 'services'
        break
      default:
        navigate('/')
        break
    }
    setCurrentFlow(witchFlow)
  }

  useEffect(() => {
    filterFlow()
  }, [flow])

  return (
    <div data-testid="smiles-switch-flow">
      <InfoBarContainer>
        {currentFlow === 'flights' && <FlightInfoBar />}
        {currentFlow === 'hotel' && <HotelInfoBar />}
      </InfoBarContainer>

      <MainContainer clearPaddingX>
        <FlowTabsContainer>
          <TabGroup isFlowTab={true}>
            {tabs.map((data) => {
              return (
                <Tab
                  key={data.label}
                  $active={data.flow === currentFlow}
                  label={data.label}
                  icon={data.icon as iconType}
                  onClick={() => handleFlow(data.flow as FlowType)}
                  isFlowTab={true}
                />
              )
            })}
          </TabGroup>
        </FlowTabsContainer>

        {currentFlow === 'flights' && <Flights />}
        {currentFlow === 'hotel' && <Hotel />}
        {currentFlow === 'services' && (
          <p data-testid="smiles-services-page">hello world!</p>
        )}
      </MainContainer>

      <BottomNavigation onClickButton={() => setOpenDrawer(true)} />

      <Drawer onClose={closeDrawer} open={openDrawer} width={600}>
        <SummaryModalContent goBack={closeDrawer} />
      </Drawer>
    </div>
  )
}

export default SwitchFlow
