import { API } from 'services/config'

const point = '/travel-request/v1'

type HotelRequestParams = {
  start: string
  end: string
  destination: number
  occupancy: number
}

const getHotels = async ({
  start,
  end,
  destination,
  occupancy,
}: HotelRequestParams) => {
  try {
    const response = await API.get(
      `${point}/hotel?start=${start}&end=${end}&destination=${destination}&occupancy=${occupancy}`,
    )

    return response.data
  } catch (error) {
    return { error: 'Error on get hotels' }
  }
}

const postCartHotel = async (hotelCartData: SHotelCart) => {
  try {
    const response = await API.post(`${point}/hotel`, hotelCartData)

    return response.data
  } catch (error) {
    throw new Error('Error on add/create hotel cart')
  }
}

export { getHotels, postCartHotel }
