import React, { useState, useMemo } from 'react'
import RoomCardRadio from '../roomCardRadio'
import DrawerBottomNavigation from 'components/drawerBottomNavigation'
import Button from 'components/button'
import { RoomsModalContentStyles } from './styles'
import { SRoom } from 'shared/interfaces/SRoom'
import { formatToReal } from 'utils/formatToReal/formateToReal'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'
import { countTotalDays } from 'utils/countTotalDays'
import { useTranslation } from 'react-i18next'

export type RoomsModalContentProps = {
  rooms: SRoom[]
  onClose: () => void
  setSelectedRoomCallback: (room: SRoom) => void
}

const RoomsModalContent: React.FC<RoomsModalContentProps> = ({
  rooms,
  onClose,
  setSelectedRoomCallback,
}) => {
  const { t } = useTranslation()
  const { setSelectedRoom } = useFlowContext()
  const [selectedRoom, setSelectedRoomState] = useState<SRoom>(rooms[0])

  const dailyFare = useMemo(() => {
    const totalFare =
      selectedRoom.fares.find((fare) => fare.type === 'FARE')?.price.amount ?? 0
    const days = countTotalDays(selectedRoom.checkIn, selectedRoom.checkOut)
    return totalFare / days
  }, [selectedRoom])

  const handleSelectRoom = () => {
    setSelectedRoomCallback(selectedRoom)
    setSelectedRoom(selectedRoom)
    onClose()
  }

  const renderRoomCard = (room: SRoom, index: number) => (
    <RoomCardRadio
      key={index}
      roomData={room}
      checked={room.roomType.code === selectedRoom.roomType.code}
      onClick={() => setSelectedRoomState(room)}
    />
  )

  return (
    <RoomsModalContentStyles>
      <div className="data" data-testid="smiles-rooms-modal-content">
        <p className="title">{t('hotel.choose_your_room')}</p>
        {rooms.map(renderRoomCard)}
      </div>

      <DrawerBottomNavigation>
        <div className="bottom-navigation-container">
          <div className="price">
            <p className="price-label">{t('hotel.daily_rate')}:</p>
            <p className="price-value">{formatToReal(dailyFare)}</p>
          </div>
          <Button width="264px" onClick={handleSelectRoom}>
            {t('hotel.add')}
          </Button>
        </div>
      </DrawerBottomNavigation>
    </RoomsModalContentStyles>
  )
}

export default RoomsModalContent
