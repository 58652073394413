import React from 'react'
import { BottomNavigationStyled } from './styles'
import Button from 'components/button'
import { formatToReal } from 'utils/formatToReal/formateToReal'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'
import { useTranslation } from 'react-i18next'

export type BottomNavigationProps = {
  onClickButton: () => void
  disableButton?: boolean
}

const BottomNavigation: React.FC<BottomNavigationProps> = ({
  onClickButton,
  disableButton,
}) => {
  const { t } = useTranslation()
  const { totalValue } = useFlowContext()
  const { loading } = useFlowContext()

  return (
    <BottomNavigationStyled data-testid="smiles-bottom-navigation">
      <p>Total: {formatToReal(totalValue)}</p>

      <Button
        disabled={disableButton || loading}
        isLoading={loading}
        width="264px"
        onClick={onClickButton}
      >
        {t('component.bottom_nav.continue')}
      </Button>
    </BottomNavigationStyled>
  )
}

export default BottomNavigation
