const esFlow = {
  flow: {
    tabs: {
      air: 'Vuelos',
      hotel: 'Reserva de hotel',
      car: 'Alquiler de coches',
      other: 'Otros servicios',
    },
  },
}

export default esFlow
