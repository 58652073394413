import React, { useState } from 'react'
import { Col, Form, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { debounce } from 'utils/debounce'
import { notifyError } from 'utils/handleRequestNotify'
import { SLocation } from 'shared/interfaces/SLocation'
import { formatSelectOptions } from 'utils/formatSelectOptions'
import Button from 'components/button'
import DatePicker from 'components/datePicker'
import Select from 'components/select'
import dayjs from 'dayjs'
import { HotelSearchContainer } from './styles'
import {
  SearchHotelParams,
  useFlowContext,
} from 'contexts/flowContext/useFlowContext'
import { getLocations } from 'services/location'
const b = 'home.searchSection.hotel.'

const HotelFormSearch: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { setSearchHotels } = useFlowContext()

  const [form] = Form.useForm()
  const [locations, setLocations] = useState<SLocation[]>([])
  const [selectedCityName, setSelectedCityName] = useState('')

  const requiredFielText = t(b + 'form.requiredField')

  const roomType = [
    {
      value: 'single',
      label: t(b + 'filters.roomType.single'),
    },
    {
      value: 'couple',
      label: t(b + 'filters.roomType.couple'),
    },
  ]

  const handleFinish = async () => {
    try {
      const values = await form.validateFields()

      const formattedHotelValues: SearchHotelParams = {
        start: dayjs(values.start).format('YYYY-MM-DD'),
        end: dayjs(values.end).format('YYYY-MM-DD'),
        destination: values.destination,
        occupancy: 1,
        city: selectedCityName,
      }

      setSearchHotels(formattedHotelValues)

      if (window.location.pathname === '/') {
        navigate('/flow/hotel')
      }
    } catch (error) {
      return
    }
  }

  const searchLocations = debounce(async function (searchValue: string) {
    if (searchValue.length < 3) {
      return
    }
    try {
      const response = await getLocations({
        search: searchValue,
        type: 'HOTEL',
      })
      setLocations(response)
    } catch (e) {
      notifyError(
        t('component.search_form.notifications.failed_to_get_locations'),
      )
    }
  }, 800)

  const handleSearch = (searchValue: string) => {
    searchLocations(searchValue)
  }

  const initialValues = {
    roomType: ['recomended'],
  }

  return (
    <HotelSearchContainer>
      <Form
        form={form}
        initialValues={initialValues}
        data-testid="smiles-hotel-search-form"
      >
        <div className="search-inputs-container">
          <Row gutter={[8, 16]}>
            <Col xs={24} md={12} lg={9}>
              <Form.Item
                name="destination"
                rules={[
                  {
                    required: true,
                    message: requiredFielText,
                  },
                ]}
              >
                <Select
                  showSearch
                  label={t(b + 'form.destinationInputLabel')}
                  placeholder={t(b + 'form.destinationInputPlaceholder')}
                  onSearch={handleSearch}
                  onBlur={() => setLocations([])}
                  onChange={(_, obj: any) => setSelectedCityName(obj.label)}
                  options={formatSelectOptions({
                    data: locations,
                    label: 'nmRegiao',
                    value: 'id',
                  })}
                  data-testid="destination-hotel-select"
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={12} lg={5}>
              <Form.Item
                name="start"
                rules={[{ required: true, message: requiredFielText }]}
              >
                <DatePicker
                  name="start"
                  label={t(b + 'form.checkInInputLabel')}
                  placeholder={t(b + 'form.checkInInputPlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={12} lg={5}>
              <Form.Item
                name="end"
                rules={[{ required: true, message: requiredFielText }]}
              >
                <DatePicker
                  name="end"
                  label={t(b + 'form.checkOutInputLabel')}
                  placeholder={t(b + 'form.checkOutInputPlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={5}>
              <Form.Item
                name="roomType"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select
                  label={t(b + 'form.roomTypeInputLabel')}
                  placeholder={t(b + 'form.roomTypeInputLabel')}
                  options={roomType}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="search-buttom-container">
          <Button name="searchButton" onClick={handleFinish}>
            {t(b + 'form.searchButton')}
          </Button>
        </div>
      </Form>
    </HotelSearchContainer>
  )
}

export default HotelFormSearch
