import styled from 'styled-components'

export const AddTravelerContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 52px 24px 52px;

  .header {
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.08px;
  }

  .text_bold {
    font-family: Nunito;
    font-size: 18px;
    font-weight: 700;
  }

  .traveler_info {
    padding-top: 4px;
    font-size: 18px;
  }

  .added_traveler_info {
    padding-top: 16px;
  }
`

export const RemoveTravelerButton = styled.button`
  background-color: #1a1a1a;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 16px;
  padding: 8px 12px 8px 18px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
`
