import React from 'react'
import { Popconfirm } from 'antd'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'

type ActionBarProps = {
  isLoading: boolean
  onConfirm: () => void
}

const ActionBar: React.FC<ActionBarProps> = ({ isLoading, onConfirm }) => {
  const { t } = useTranslation()

  return (
    <div className="action-bar">
      <Popconfirm
        title={t('user.permissionsTab.updateConfirm.title')}
        description={t('user.permissionsTab.updateConfirm.description')}
        okText={t('user.popConfirm.yes')}
        cancelText={t('user.popConfirm.cancel')}
        onConfirm={onConfirm}
        placement="top"
      >
        <Button
          buttonType="outline"
          width="280px"
          disabled={isLoading}
          isLoading={isLoading}
        >
          {t('user.permissionsTab.updatePermissions')}
        </Button>
      </Popconfirm>
    </div>
  )
}

export default ActionBar
