import React from 'react'
import { MoreOptionsButtonStyles } from './styles'
import { SmilesIcon } from 'components/icons'

export type MoreOptionsButtonProps = {
  onClick?: () => void
  label: string
}

const MoreOptionsButton: React.FC<MoreOptionsButtonProps> = ({
  onClick,
  label,
}) => (
  <MoreOptionsButtonStyles
    onClick={onClick}
    data-testid="smiles-more-options-button"
  >
    <p>{label}</p>
    <SmilesIcon type="dropdown-arrow" size={12} />
  </MoreOptionsButtonStyles>
)

export default MoreOptionsButton
