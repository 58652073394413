import React from 'react'
import { SmilesIcon } from 'components/icons'
import { SVSummaryItemStyles } from './styles'
import { iconType } from 'components/icons/svgs/icons'

export type SVSummaryItemProps = {
  label: string
  value?: string | number | null
  icon: iconType
}

const SVSummaryItem: React.FC<SVSummaryItemProps> = ({
  icon,
  label,
  value = 'N/A',
}) => {
  return (
    <SVSummaryItemStyles data-testid="sv-summary-item">
      <span className="item-label">
        <SmilesIcon type={icon} size={16} />
        <p>{label}</p>
      </span>
      <p>{value}</p>
    </SVSummaryItemStyles>
  )
}

export default SVSummaryItem
