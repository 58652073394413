export default `
<svg width="100%" height="100%" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
<path d="M4.49102 9.50829C4.59796 9.61524 4.73407 9.66871 4.89935 9.66871C5.06463 9.66871 
5.20074 9.61524 5.30768 9.50829L6.99935 7.81663L8.7056 9.52288C8.81254 9.62982 8.94632 9.68077 
9.10693 9.67571C9.26715 9.67104 9.40074 9.61524 9.50768 9.50829C9.61463 9.40135 9.6681 9.26524 
9.6681 9.09996C9.6681 8.93468 9.61463 8.79857 9.50768 8.69163L7.81602 6.99996L9.52227 5.29371C9.62921 
5.18677 9.68015 5.05299 9.6751 4.89238C9.67043 4.73215 9.61463 4.59857 9.50768 4.49163C9.40074 4.38468 
9.26463 4.33121 9.09935 4.33121C8.93407 4.33121 8.79796 4.38468 8.69102 4.49163L6.99935 6.18329L5.2931 
4.47704C5.18615 4.3701 5.05257 4.31896 4.89235 4.32363C4.73174 4.32868 4.59796 4.38468 4.49102 
4.49163C4.38407 4.59857 4.3306 4.73468 4.3306 4.89996C4.3306 5.06524 4.38407 5.20135 4.49102 
5.30829L6.18268 6.99996L4.47643 8.70621C4.36949 8.81315 4.31854 8.94674 4.3236 9.10696C4.32827 
9.26757 4.38407 9.40135 4.49102 9.50829ZM6.99935 12.8333C6.1924 12.8333 5.43407 12.6801 4.72435 
12.3736C4.01463 12.0676 3.39727 11.652 2.87227 11.127C2.34727 10.602 1.93174 9.98468 1.62568 
9.27496C1.31924 8.56524 1.16602 7.8069 1.16602 6.99996C1.16602 6.19302 1.31924 5.43468 1.62568 
4.72496C1.93174 4.01524 2.34727 3.39788 2.87227 2.87288C3.39727 2.34788 4.01463 1.93215 4.72435 
1.62571C5.43407 1.31965 6.1924 1.16663 6.99935 1.16663C7.80629 1.16663 8.56463 1.31965 9.27435 1.62571C9.98407 
1.93215 10.6014 2.34788 11.1264 2.87288C11.6514 3.39788 12.067 4.01524 12.373 4.72496C12.6795 5.43468 12.8327 
6.19302 12.8327 6.99996C12.8327 7.8069 12.6795 8.56524 12.373 9.27496C12.067 9.98468 11.6514 10.602 11.1264 
11.127C10.6014 11.652 9.98407 12.0676 9.27435 12.3736C8.56463 12.6801 7.80629 12.8333 6.99935 12.8333ZM6.99935 
11.6666C8.29241 11.6666 9.39354 11.2122 10.3028 10.3034C11.2116 9.39415 11.666 8.29302 11.666 6.99996C11.666 
5.7069 11.2116 4.60577 10.3028 3.69654C9.39354 2.78771 8.29241 2.33329 6.99935 2.33329C5.70629 2.33329 4.60535 
2.78771 3.69652 3.69654C2.78729 4.60577 2.33268 5.7069 2.33268 6.99996C2.33268 8.29302 2.78729 9.39415 3.69652 
10.3034C4.60535 11.2122 5.70629 11.6666 6.99935 11.6666Z" />
</svg>
`
