import styled from 'styled-components'
import { ButtonSizes, ButtonTypes } from '.'

export const StyledButton = styled.button<{
  width?: string
  disabled?: boolean
  size?: ButtonSizes
  $buttontype?: ButtonTypes
}>`
  width: ${(props) => (props.width ? props.width : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff7020;
  border: none;
  cursor: pointer;
  gap: 8px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #ff7f37;
    box-shadow: 0px 0px 12px -3px #ff7f37;
  }

  ${({ size }) =>
    size === 'sm' &&
    `
    height: 40px;
    border-radius: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;  
    letter-spacing: 0.07px;
  `};

  ${({ size }) =>
    size === 'md' &&
    `
    height: 64px;
    padding: 0px 32px;
    border-radius: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.16px;
  `};

  ${({ size }) =>
    size === 'md-responsive' &&
    `
    height: 4.444vw;
    padding: 0vw 2.222vw;
    border-radius: 0.555vw;
    font-size: 1.111vw;
    font-style: normal;
    font-weight: 700;
    line-height: 1.666vw;
    letter-spacing: 0.011vw;
  `};

  ${({ $buttontype }) =>
    $buttontype === 'secondary' &&
    `
    background: #fff;
    border-radius: 8px;
    border: 2px solid var(--Neutral-Neutral-900, #000);
    transition: all 0.2s ease-in-out;
    &:hover {
      border-color: #ff7f37; 
      color: #ff7f37;
      box-shadow: none;
      background: #fff;
      svg {
        transition: all 0.09s ease-in-out;
        fill: var(--primary-color);
      }
    }
  `};

  ${({ $buttontype }) =>
    $buttontype === 'outline' &&
    `
    background: #fff;
    border-radius: 8px; 
    transition: all 0.2s ease-in-out;
    &:hover { 
      color: #ff7f37;
      box-shadow: none;
      background: #fff;
    }
  `};

  ${({ $buttontype }) =>
    $buttontype === 'outline-black' &&
    `
    background: #fff;
    border: 0.138vw solid black;
    border-radius: 0.555vw; 
    transition: all 0.2s ease-in-out;
    &:hover { 
      color: #ff7f37;
      box-shadow: none;
      background: #fff;
    }
  `};

  ${(props) =>
    props.disabled &&
    `
    background: #F2F2F2;
    color: #595959;
    cursor: not-allowed;
    &:hover {
      background: #F2F2F2;
      box-shadow: none;

      svg { 
        fill: #F2F2F2;
      }
    }
  `};

  ${({ $buttontype }) =>
    $buttontype === 'back-button' &&
    `
    background: #fff;
    padding: 0px;
    transition: all 0.2s ease-in-out;
    &:hover { 
      box-shadow: none;
      background: #fff;
    }
  `};

  ${({ $buttontype, disabled }) =>
    $buttontype === 'secondary' &&
    disabled &&
    `
    color: #595959;
    background: #fff;
    padding: 0px;
    transition: all 0.2s ease-in-out;
    border-color: #595959;
    &:hover { 
      box-shadow: none;
      background: #fff;
      color: #595959;
      border-color: #595959;
    }
  `};
`
