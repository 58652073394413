import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'antd'
import { SmilesIcon } from 'components/icons'
import { useTranslation } from 'react-i18next'
import { Color } from 'styles/global'
import {
  // AddTravelerButton,
  FlightSearchContainer,
  // AddTravelersContainer,
  // RemoveTravelerButton,
} from './styles'
import { useNavigate } from 'react-router-dom'
import { debounce } from 'utils/debounce'
import { notifyError } from 'utils/handleRequestNotify'
import { SLocation } from 'shared/interfaces/SLocation'
import { formatSelectOptions } from 'utils/formatSelectOptions'
import Button from 'components/button'
import DatePicker from 'components/datePicker'
import Select from 'components/select'
import dayjs, { Dayjs } from 'dayjs'
import Dropdown from 'components/dropdown'
import {
  SearchFlightParams,
  useFlowContext,
} from 'contexts/flowContext/useFlowContext'
import Drawer from 'components/drawer'
import AddTravelers from './addTravelersModalContent'
import { getLocations } from 'services/location'

const b = 'home.searchSection.flight.'

const FlightFormSearch: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { setSearchFlights, searchFlights, travelers /*removeTraveler*/ } =
    useFlowContext()

  const [form] = Form.useForm()
  const [locations, setLocations] = useState<SLocation[]>([])
  const [openTravelersDrawer, setOpenTravelersDrawer] = useState(false)
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [isOneWay, setIsOneWay] = useState(false)

  const flightWay = [
    {
      value: 'oneWay',
      label: t(b + 'filters.flightOptions.depOrReturn'),
    },
    {
      value: 'twoWay',
      label: t(b + 'filters.flightOptions.depAndReturn'),
    },
  ]

  const handleFinish = async () => {
    try {
      const values = await form.validateFields()

      const formattedFlightValues: SearchFlightParams = {
        origin: values.origin,
        destination: values.destination,
        start: dayjs(values.start).format('YYYY-MM-DD'),
        occupancy: 1,
        isOneWay,
      }

      if (values.end) {
        formattedFlightValues.end = dayjs(values.end).format('YYYY-MM-DD')
      }

      setSearchFlights(formattedFlightValues)

      if (window.location.pathname === '/') {
        navigate('/flow/flights')
      }
    } catch (error) {
      return
    }
  }

  const disabledStartDate = (current: Dayjs): boolean => {
    return current && current < dayjs().startOf('day')
  }

  const disabledEndDate = (current: Dayjs): boolean => {
    return (
      current &&
      (current < dayjs().startOf('day') ||
        (startDate !== null && current < startDate))
    )
  }

  const handleStartDateChange = (date: Dayjs | null) => {
    setStartDate(date)
    if (date && endDate && date > endDate) {
      setEndDate(null)
      form.setFieldsValue({ end: null })
    }
  }

  const handleEndDateChange = (date: Dayjs | null) => {
    setEndDate(date)
  }

  const handleCloseDrawer = async () => {
    setOpenTravelersDrawer(false)
  }

  const searchAirports = debounce(async function (searchValue: string) {
    if (searchValue.length < 3) {
      return
    }
    try {
      const response = await getLocations({
        search: searchValue,
        type: 'FLIGHT',
      })
      setLocations(response)
    } catch (e) {
      notifyError(
        t('component.search_form.notifications.failed_to_get_airports'),
      )
    }
  }, 800)

  const handleSearch = (searchValue: string) => {
    searchAirports(searchValue)
  }

  const setSavedSearch = () => {
    const path = window.location.pathname

    if (searchFlights && path !== '/') {
      form.setFieldsValue({
        origin: searchFlights.origin,
        destination: searchFlights.destination,
        start: dayjs(searchFlights.start, 'YYYY-MM-DD'),
        occupancy: searchFlights.occupancy,
      })
      setIsOneWay(searchFlights.isOneWay)
      if (searchFlights.end) {
        form.setFieldValue('end', dayjs(searchFlights.end, 'YYYY-MM-DD'))
      }
      setStartDate(dayjs(searchFlights.start, 'YYYY-MM-DD'))
      setEndDate(dayjs(searchFlights.end, 'YYYY-MM-DD'))
    }
  }

  useEffect(() => {
    setSavedSearch()
  }, [searchFlights, travelers])

  return (
    <FlightSearchContainer>
      <Form form={form} data-testid="smiles-flight-search-form">
        <div className="search-dropdowns-container">
          <Dropdown
            leftIcon={
              <SmilesIcon
                type="airplanes"
                className="select-dropdown-left-icon"
                color={Color.PRIMARY}
                size={24}
              />
            }
            onChange={(e) => {
              if (e === 'oneWay') {
                setIsOneWay(true)
                form.setFieldValue('end', undefined)
              } else {
                setIsOneWay(false)
              }
            }}
            value={isOneWay ? flightWay[0].label : flightWay[1].label}
            options={flightWay}
          />
          {/* <AddTravelersContainer>
            {travelers.map((traveler: any) => (
              <RemoveTravelerButton
                type="button"
                className="global-button-reset"
                onClick={() => {
                  removeTraveler(traveler.value)
                }}
                key={traveler?.label}
              >
                {traveler?.label}
                <SmilesIcon size={26} type="close" />
              </RemoveTravelerButton>
            ))}
            {/* {travelers?.length < 2 && (
              <AddTravelerButton
                type="button"
                className="global-button-reset"
                onClick={() => setOpenTravelersDrawer(true)}
              >
                Adicionar viajante <SmilesIcon size={26} type="plus-rounded" />
              </AddTravelerButton>
            )} */}
          {/* </AddTravelersContainer> */}
        </div>
        <div className="search-inputs-container">
          <Row gutter={[8, 16]}>
            <Col xs={24} md={12} lg={7}>
              <Form.Item
                name="origin"
                rules={[
                  {
                    required: true,
                    message: t(
                      'component.search_form.select_departure_airport',
                    ),
                  },
                ]}
              >
                <Select
                  showSearch
                  label={t(b + 'form.departureInputLabel')}
                  placeholder={t(b + 'form.inputPlaceholder')}
                  onSearch={handleSearch}
                  onChange={(_, a) => form.setFieldValue('origin', a)}
                  onBlur={() => setLocations([])}
                  options={formatSelectOptions({
                    data: locations,
                    label: 'nmRegiao',
                    value: 'sgIata',
                  })}
                  data-testid="origin-airport-select"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={7}>
              <Form.Item
                name="destination"
                rules={[
                  {
                    required: true,
                    message: t(
                      'component.search_form.select_destination_airport',
                    ),
                  },
                ]}
              >
                <Select
                  showSearch
                  label={t(b + 'form.destinationInputLabel')}
                  placeholder={t(b + 'form.inputPlaceholder')}
                  onSearch={handleSearch}
                  onBlur={() => setLocations([])}
                  onChange={(_, a) => form.setFieldValue('destination', a)}
                  options={formatSelectOptions({
                    data: locations,
                    label: 'nmRegiao',
                    value: 'sgIata',
                  })}
                  data-testid="destination-airport-select"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={5}>
              <Form.Item
                name="start"
                rules={[
                  {
                    required: true,
                    message: t('component.search_form.select_departure_date'),
                  },
                ]}
              >
                <DatePicker
                  label={t(b + 'form.start')}
                  name="start"
                  placeholder={t(b + 'form.inputDatePlaceholder')}
                  disabledDate={disabledStartDate}
                  onChange={handleStartDateChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={5}>
              <Form.Item
                name="end"
                rules={[
                  {
                    required: !isOneWay,
                    message: t('component.search_form.select_return_date'),
                  },
                ]}
              >
                <DatePicker
                  label={t(b + 'form.end')}
                  name="end"
                  placeholder={t(b + 'form.inputDatePlaceholder')}
                  disabledDate={disabledEndDate}
                  onChange={handleEndDateChange}
                  disabled={isOneWay}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="search-buttom-container">
          <Button onClick={handleFinish}>{t(b + 'form.searchButton')}</Button>
        </div>
      </Form>

      <Drawer
        onClose={handleCloseDrawer}
        open={openTravelersDrawer}
        width={700}
      >
        <AddTravelers onClose={handleCloseDrawer} />
      </Drawer>
    </FlightSearchContainer>
  )
}

export default FlightFormSearch
