import { API } from 'services/config'

const point = '/approval/v1/travel-request'

const approveSV = async (travelRequestId: string, checkRateId: number) => {
  try {
    const response = await API.post(`${point}/approve`, {
      travelRequestId,
      checkRateId,
    })
    return response.data
  } catch (error) {
    throw new Error('Error on approve SV')
  }
}

const disapproveSV = async (
  travelRequestId: string,
  reason: string,
  anotherMotive: string,
) => {
  try {
    const response = await API.post(`${point}/deny`, {
      travelRequestId,
      reason,
      anotherMotive,
    })
    return response.data
  } catch (error) {
    throw new Error('Error on disapprove SV')
  }
}

const cancelSV = async (
  travelRequestId: string,
  reason: string,
  anotherMotive: string,
) => {
  try {
    const response = await API.put(`${point}/cancel`, {
      travelRequestId,
      reason,
      anotherMotive,
    })
    return response.data
  } catch (error) {
    throw new Error('Error on cancel SV')
  }
}

export { approveSV, disapproveSV, cancelSV }
