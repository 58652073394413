import { TravelersAndRequester } from 'components/summaryModalContent/styles'
import React from 'react'

export type RenderTravelerAndRequesterProps = {
  title: string
  name?: string
}

const RenderTravelerAndRequester: React.FC<RenderTravelerAndRequesterProps> = ({
  name,
  title,
}) => (
  <TravelersAndRequester data-testid="render-cart-traveler-and-requester">
    <p className="requester-title">{title}</p>
    <p className="requester-name">{name}</p>
  </TravelersAndRequester>
)

export default RenderTravelerAndRequester
