import { useCallback, useEffect, useState } from 'react'
import { Form, FormInstance } from 'antd'
import { Gender, getNationalities } from 'services/outsourced'
import { notifyError, notifySuccess } from 'utils/handleRequestNotify'
import { useTranslation } from 'react-i18next'
import { debounce } from 'utils/debounce'
import { isValidCPF } from 'utils/validations'
import { z } from 'zod'
import dayjs from 'dayjs'

type FormProps = {
  foreign: boolean
}

type OutsourcedState = {
  form: FormInstance<FormProps>
  isLoading: boolean
}

export const useOutsourced = (
  onFinishCallback: Function,
  setEmployeesList: Function,
) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const isForeigner = Form.useWatch('foreign', form)
  const isConcession = Form.useWatch('isConcession', form)
  const cpf = Form.useWatch('cpf', form)
  const [submittable, setSubmittable] = useState<boolean>(false)
  const [state, setState] = useState<OutsourcedState>({
    form,
    isLoading: false,
  })
  const [nationalities, setNationalities] = useState([])

  const defaultNationalityValue = {
    id: 'da9f193e-da74-4ff4-897b-984a6a2479f9',
    name: 'Brasil',
  }

  const setIsLoading = (isLoading: boolean) => {
    setState((prevState) => ({ ...prevState, isLoading }))
  }

  const fetchNationalities = useCallback(
    debounce(async (search: string) => {
      if (search?.length < 3) {
        setNationalities([])
        return
      }
      setIsLoading(true)
      try {
        const response = await getNationalities(search)
        setNationalities(response.data)
      } catch (error) {
        notifyError()
      } finally {
        setIsLoading(false)
      }
    }, 800),
    [t],
  )

  const getGenderList = () => {
    const genderList = [
      {
        label: t('new_outsourced.form.gender.male.label'),
        value: Gender.MALE,
      },
      {
        label: t('new_outsourced.form.gender.female.label'),
        value: Gender.FEMALE,
      },
    ]

    return genderList
  }

  const handleCPFChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    form.setFieldsValue({ cpf: value })

    const isValid = isValidCPF(value)
    if (isValid) {
      form.setFields([{ name: 'cpf', errors: [] }])
    } else {
      form.setFields([
        {
          name: 'cpf',
          errors: [t('new_outsourced.form.cpf.errors.not_valid')],
        },
      ])
    }
  }

  const buildCreateEmployeeDto = (formValues: any): any => {
    const createEmployeeDto = {
      nationalityId: formValues?.nationality?.id ?? defaultNationalityValue.id,
      isForeigner: formValues.foreign ?? false,
      isConcession: formValues.isConcession ?? false,
      fullName: formValues.name,
      cpf: formValues.cpf,
      rg: formValues.rg,
      passport: formValues.passport,
      job: formValues.position,
      gender: formValues.gender,
      dateOfBirth: formValues?.birthdate?.format('YYYY-MM-DD') ?? null,
      phone: formValues.phone,
      email: formValues.email,
    }
    return createEmployeeDto
  }

  const validateFormData = (formValues: any) => {
    const bodySchema = z.object({
      nationalityId: z.string().optional(),
      isForeigner: z.boolean().refine((val) => val !== undefined),
      isConcession: z.boolean().refine((val) => val !== undefined),
      fullName: z
        .string()
        .min(3)
        .max(100)
        .refine((val) => val !== undefined),
      cpf: z.string().optional(),
      rg: z.string().optional(),
      passport: z.string().optional(),
      job: z.string().optional(),
      gender: z.nativeEnum(Gender).optional(),
      dateOfBirth: z.string(),
      phone: z.string().refine((val) => val !== undefined),
      email: z.string().email(),
    })
    try {
      bodySchema.parse(formValues)
      return { status: 'success' }
    } catch (error) {
      if (error instanceof z.ZodError) {
        error.errors.forEach(({ path, message }) => {
          form.setFields([
            {
              name: path,
              errors: [message],
            },
          ])
        })
      }
      return { status: 'error', error }
    }
  }

  const onValuesChange = (changedValues: unknown, allValues: unknown) => {
    const dto = buildCreateEmployeeDto(allValues)
    const isFormValid = validateFormData(dto)
    if (isFormValid.status === 'success') {
      setSubmittable(true)
      return
    }

    setSubmittable(false)
  }

  const onAddEmployee = async () => {
    const formValues = form.getFieldsValue()
    const createEmployeeDto = buildCreateEmployeeDto(formValues)
    setEmployeesList((previousData: any) => [
      ...previousData,
      createEmployeeDto,
    ])
    onFinishCallback()
  }

  return {
    ...state,
    form,
    nationalities,
    setNationalities,
    fetchNationalities,
    setIsLoading,
    getGenderList,
    submittable,
    onValuesChange,
    isForeigner,
    isConcession,
    cpf,
    handleCPFChange,
    defaultNationalityValue,
    onAddEmployee,
  }
}
