const enFlights = {
  flights: {
    moreOptionsButton: 'Explore more options',
    tabs: {
      outboundFlight: 'Select your outbound flight',
      returnFlight: 'Select your return flight',
    },
    flightCardRadio: {
      classAndFare: {
        class: 'Flight class:',
        farePerSegment: 'Fare per segment',
      },
    },
    flightFilterSection: {
      header: 'Choose your preferences',
      flightTypeGroupboxHeader: 'Flight type',
      flightPriceGroupboxHeader: 'Flight prices',
      flightClassGroupboxHeader: 'Flight class',
      flightDepartureTimeGroupboxHeader: 'Flight departure time',
      flightReturnTimeGroupboxHeader: 'Flight return time',
      form: {
        minValue: {
          label: 'Minimum value',
        },
        maxValue: {
          label: 'Maximum value',
        },
      },
      flightTypes: {
        direct: 'Direct',
        oneStop: '1 stop',
        twoStops: '2 stops',
      },
      flightClasses: {
        economic: 'Economic',
        premiumEconomy: 'Premium economic',
        executive: 'Executive',
        firstClass: 'First class',
      },
      flightTime: {
        dawn: 'Dawn 00:00 - 04:59',
        morning: 'Morning 05:00 - 11:59',
        afternoon: 'Afternoon 12:00 - 17:59',
        night: 'Night 18:00 - 23:59',
      },
      originAirport: 'Origin airport',
      destinationAirport: 'Destination airport',
      lookingQuote: 'Looking for a flight quote?',
      getQuote: 'Get a quote!',
      flightWantedNotFound: "Didn't find the flight you wanted?",
      askTheAgency: 'Ask the agency',
      confirm: 'Confirm',
    },
    renderFlights: {
      emptyFlightsDescription: 'No options for the search',
    },
    flightInfoBar: {
      changeSearch: 'Change search',
    },
  },
}

export default enFlights
