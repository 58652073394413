import { DatePicker as AntDatePicker } from 'antd'
import styled from 'styled-components'

export const DatePickerStyles = styled(AntDatePicker)`
  width: 100%;
  border: 2px solid #999;
  padding: 20px 20px;
  z-index: 1;

  .ant-picker-input {
    > input {
      &::placeholder {
        color: #595959;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.08px;
      }
    }
  }

  &:hover,
  &:focus-within,
  &:focus {
    border: 2px solid #999;
  }
`
