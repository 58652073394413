import { parseCookies, setCookie, destroyCookie } from 'nookies'

type SetCookiesParams = {
  data: any
  expirationInMinutes: number
  cookieName: string
}

export const setCustomCookie = ({
  data,
  expirationInMinutes,
  cookieName,
}: SetCookiesParams) => {
  const expirationTime = expirationInMinutes * 60
  setCookie(null, cookieName, JSON.stringify(data), {
    maxAge: expirationTime,
    path: '/',
  })
}

export const getCustomCookie = (cookieName: string) => {
  const cookies = parseCookies()

  if (cookieName in cookies) {
    return JSON.parse(cookies[cookieName])
  }

  return null
}

export const destroyCustomCookie = (cookieName: string) => {
  destroyCookie(null, cookieName)
}
