import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import Spinner from 'components/spinner'
import { LoaderContainer } from './styles'
import { usePermissionsContext } from 'contexts/permissionsContext/usePermissionsContext'

interface ProtectedRouteProps {
  element: React.ReactElement
  requiredPermission?: string
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  requiredPermission,
}) => {
  const { isAuthenticated, isLoading: isAuthLogin } = useAuth0()
  const {
    isPermissionsLoading,
    userPermissions,
    can,
    setIsPermissionsLoading,
    loadAllUserPermissions,
  } = usePermissionsContext()
  const location = useLocation()

  const [isAllowed, setIsAllowed] = useState<boolean | null>(null)

  const isLoading = isAuthLogin || isPermissionsLoading

  useEffect(() => {
    if (isAuthenticated) {
      loadAllUserPermissions()
    } else {
      setIsPermissionsLoading(false)
    }
  }, [isAuthenticated, location.pathname])

  useEffect(() => {
    if (!isLoading && userPermissions && requiredPermission) {
      const flag = can(requiredPermission)
      setIsAllowed(flag)
    }
  }, [isLoading, userPermissions, requiredPermission])

  if (isLoading) {
    return (
      <LoaderContainer>
        <Spinner />
      </LoaderContainer>
    )
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (!isLoading && isAllowed === false) {
    return <Navigate to="/access-denied" state={{ from: location }} replace />
  }

  return element
}

export default ProtectedRoute
