import styled from 'styled-components'

export const HotelCardRadioContainer = styled.div<{ $checked: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 16px;
  height: fit-content;
  border: 2px solid #999;

  .check {
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    border: 3px solid #999;

    ${({ $checked }) =>
      $checked &&
      `
        background: var(--primary-color);
        border: none;
      `}
  }

  ${({ $checked }) =>
    $checked &&
    ` 
    .check {
      background: var(--primary-color);
      border: none;
    }

    border: 2px solid var(--primary-color);
  `}
`

export const HotelCardTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 16px 16px 0 0;

  .img {
    height: 220px;
    min-width: 240px;
    background-color: yellow;
    border-radius: 14px 0 0 0;
    padding: 8px;
  }

  .is-favorite {
    background-color: #ffeade;
    width: fit-content;

    width: Hug (81px) px;
    height: Fixed (26px) px;
    padding: 4px 10px 4px 10px;
    gap: 0px;
    border-radius: 0px 0px 16px 16px;
    opacity: 0px;

    p {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.005em;
      color: #e65200;
    }
  }
`

export const HotelCardData = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;

  .hotel-data-name {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.001em;
  }

  .hotel-data-location {
    display: flex;
    flex-direction: column;

    .hotel-data-location-desc {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.0025em;
    }
    .hotel-data-location-sub {
      display: flex;
      flex-direction: row;
      gap: 16px;
      padding: 6px 0;

      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.01em;
    }
  }

  .hotel-data-price {
    display: flex;
    flex-direction: column;

    .hotel-data-price-label {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.01em;
    }
    .hotel-data-price-value {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.0015em;
    }
  }

  .hotel-data-accomodations-and-refundable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .hotel-data-accomodations {
    }
    .hotel-data-refundable {
      background: #f2f2f2;
      padding: 4px 8px;
      gap: 0px;
      border-radius: 16px;
      opacity: 0px;

      p {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.01em;
      }
    }
  }

  .is-favorite {
    background-color: #ffeade;
    width: fit-content;

    width: Hug (81px) px;
    height: Fixed (26px) px;
    padding: 4px 10px 4px 10px;
    gap: 0px;
    border-radius: 0px 0px 16px 16px;
    opacity: 0px;

    p {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.005em;
      color: #e65200;
    }
  }
`

export const HotelCardBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 32px;
  border-radius: 0 0 14px 14px;
  border-top: 1px solid #999;

  .hotel-room {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    .hotel-room-bed {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      border-right: 1px solid black;
      padding: 0 9px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.01em;
    }
    .hotel-people {
      color: #999999;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.0025em;
    }
  }

  .hotel-see-others-rooms-button {
    cursor: pointer;
    &:hover {
      color: #e65200;
      .hotel-see-others-rooms-text {
        border-bottom: 3px solid #e65200;
      }
    }
  }

  .hotel-see-others-rooms-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    border-bottom: 3px solid #999999;
  }
`

export const HotelImage = styled.div<{ $urlimage?: string }>`
  height: 220px;
  min-width: 240px;
  border-radius: 14px 0 0 0;
  padding: 8px;
  background: url(${(props) => props.$urlimage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`
