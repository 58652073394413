const ptFlights = {
  flights: {
    moreOptionsButton: 'Conhecer mais opções',
    tabs: {
      outboundFlight: 'Selecione seu voo de ida',
      returnFlight: 'Selecione seu voo de volta',
    },
    flightCardRadio: {
      classAndFare: {
        class: 'Classe do voo:',
        farePerSegment: 'Tarifa por trecho',
      },
    },
    flightFilterSection: {
      header: 'Escolha suas preferências',
      flightTypeGroupboxHeader: 'Tipo de voo',
      flightPriceGroupboxHeader: 'Preço das passagens',
      flightClassGroupboxHeader: 'Classe do voo',
      flightDepartureTimeGroupboxHeader: 'Horário de partida do voo',
      flightReturnTimeGroupboxHeader: 'Horário de retorno do voo',
      form: {
        minValue: {
          label: 'Mínimo',
        },
        maxValue: {
          label: 'Máximo',
        },
      },
      flightTypes: {
        direct: 'Direto',
        oneStop: '1 parada',
        twoStops: '2 paradas',
      },
      flightClasses: {
        economic: 'Econômica',
        premiumEconomy: 'Premium economic',
        executive: 'Executiva',
        firstClass: 'Primeira classe',
      },
      flightTime: {
        dawn: 'Madrugada 00h00 - 04h59',
        morning: 'Manhã 05h00 - 11h59',
        afternoon: 'Tarde 12h00 - 17h59',
        night: 'Noite 18h00 - 23h59',
      },
      originAirport: 'Aeroporto de origem',
      destinationAirport: 'Aeroporto de destino',
      lookingQuote: 'Buscando uma cotação de passagem?',
      getQuote: 'Faça um orçamento!',
      flightWantedNotFound: 'Não achou o voo que queria?',
      askTheAgency: 'Pedir à agência',
      confirm: 'Confirmar',
    },
    renderFlights: {
      emptyFlightsDescription: 'Sem opções para a pesquisa',
    },
    flightInfoBar: {
      changeSearch: 'Alterar busca',
    },
  },
}

export default ptFlights
