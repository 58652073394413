const esHotel = {
  hotel: {
    moreOptionsButton: 'Ver más opciones',
    room: 'Habitación',
    filterSection: {
      header: 'Elige tus preferencias',
      hotelPriceGroupboxHeader: 'Rango de precios',
      hotelTypesGroupboxHeader: 'Tipos',
      hotelAmenitiesGroupboxHeader: 'Comodidades',
      rangeWithInputs: {
        form: {
          minValue: {
            label: 'Mínimo',
          },
          maxValue: {
            label: 'Máximo',
          },
        },
      },
      hotelTypes: {
        peopleWd: 'Adaptado para personas con discapacidad',
        pets: 'Admite mascotas',
        kids: 'Admite niños',
        babies: 'Admite bebés',
      },
      hotelAmenities: {
        doubleBed: 'Cama doble',
        singleBed: 'Cama individual',
        noPreference: 'Sin preferencia',
      },
      lookingQuote: '¿Buscando una cotización de hotel?',
      getQuote: '¡Obtenga un presupuesto!',
      flightWantedNotFound: '¿No encontró el vuelo que quería?',
      askTheAgency: 'Pregunte a la agencia',
    },
    renderHotels: {
      emptyHotelDescription: 'Sin opciones para la búsqueda',
    },
    hotelInfoBar: {
      changeSearch: 'Cambiar búsqueda',
    },
    favorites: 'Favoritos',
    daily_rate: 'Valor de la diaria',
    refundable_fare: 'Tarifa reembolsable',
    non_refundable_fare: 'Tarifa no reembolsable',
    person: 'persona',
    see_other_rooms: 'Ver otras habitaciones',
    hotel_details: 'Detalles del hotel',
    add: 'Añadir',
    only_exib_acomodations_with_food:
      'Mostrar solo alojamientos con comidas incluidas',
    breakfast_alimentation: 'Desayuno / Alimentación',
    see_more_options: 'Ver más opciones',
    choose_your_room: 'Elige tu habitación',
    acomodations_in: 'Alojamientos en',
    recommended: 'Recomendados',
    of: 'de',
    options: 'opciones',
  },
}

export default esHotel
