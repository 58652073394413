import React, { ReactNode } from 'react'
import { DrawerBottomNavigationStyled } from './styles'

export type DrawerBottomNavigationProps = {
  children?: ReactNode
}

const DrawerBottomNavigation: React.FC<DrawerBottomNavigationProps> = ({
  children,
}) => {
  return (
    <DrawerBottomNavigationStyled data-testid="smiles-drawer-bottom-navigation">
      {children}
    </DrawerBottomNavigationStyled>
  )
}

export default DrawerBottomNavigation
