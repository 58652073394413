import React from 'react'
import Select from 'components/select'
import Button from 'components/button'
import TextArea from 'components/TextArea'
import { useTranslation } from 'react-i18next'

interface SVCancelFormProps {
  onCancel: () => void
  onConfirm: () => void
  selectedReason: string
  onReasonChange: (value: string) => void
  anotherMotive: string
  onDescChange: (value: string) => void
}

const SVCancelForm: React.FC<SVCancelFormProps> = ({
  onCancel,
  onConfirm,
  selectedReason,
  onReasonChange,
  anotherMotive,
  onDescChange,
}) => {
  const { t } = useTranslation()
  const isConfirmDisabled =
    !selectedReason || (selectedReason === 'OTHER' && anotherMotive.length < 10)

  const options = [
    {
      label: t('travel_request.cancel_reasons.date_change'),
      value: 'DATE_CHANGE',
    },
    {
      label: t('travel_request.cancel_reasons.flight_change'),
      value: 'FLIGHT_CHANGE',
    },
    {
      label: t('travel_request.cancel_reasons.employee_terminated'),
      value: 'EMPLOYEE_TERMINATED',
    },
    {
      label: t('travel_request.cancel_reasons.incorrect_entries'),
      value: 'INCORRECT_ENTRIES',
    },
    {
      label: t('travel_request.cancel_reasons.duplicate_entries'),
      value: 'DUPLICATE_ENTRIES',
    },
    {
      label: t('travel_request.cancel_reasons.expired_reservations'),
      value: 'EXPIRED_RESERVATIONS',
    },
    {
      label: t('travel_request.cancel_reasons.trip_canceled'),
      value: 'TRIP_CANCELED',
    },
    { label: t('travel_request.cancel_reasons.other'), value: 'OTHER' },
  ]

  return (
    <div className="form">
      <Select
        label={t('travel_request.justify_cancellation')}
        options={options}
        placeholder={t('travel_request.choose_reason')}
        onChange={(e) => onReasonChange(e)}
      />
      {selectedReason === 'OTHER' && (
        <TextArea
          label={t('travel_request.cancellation_description')}
          placeholder={t('travel_request.type_here')}
          height="180px"
          onChange={(e) => onDescChange(e.target.value)}
          minLength={10}
          maxLength={300}
        />
      )}
      <div className="cancel-buttons">
        <Button
          buttonType="secondary"
          size="sm"
          width="100px"
          onClick={onCancel}
        >
          {t('travel_request.cancel')}
        </Button>
        <Button
          size="sm"
          width="165px"
          disabled={isConfirmDisabled}
          onClick={onConfirm}
        >
          {t('travel_request.confirm_answer')}
        </Button>
      </div>
    </div>
  )
}

export default SVCancelForm
