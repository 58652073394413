const enReservation = {
  reservationDetails: {
    traveler: 'Traveler',
    reservationDate: 'Request date',
    approvalDate: 'Approval date',
    status: 'Status',
    costCenter: 'Cost center',
    reservationNumber: 'Reservation number',
    locator: 'Locator',
  },
  reservationCompleted: {
    title: 'Booking processing completed',
    text: 'Now just wait. Your request has been sent to your manager and you will receive an email confirmation of your order.',
  },
  viewReservation: {
    title: 'View complete reservation',
    resumeTitle: 'View booking summary',
  },
  completeRequest: {
    title: 'Complete request',
    question: 'Do you want to confirm your request?',
    text: 'By clicking "Yes", your request will be sent to your immediate supervisor.',
    yes: 'Yes',
  },
  flightRequest: {
    title: 'Air',
    oneWayTicket: 'One-way ticket',
    returnTicket: 'Return ticket',
    stopover: 'stopover',
  },
  hotelRequest: {
    seeOnMap: 'See on map',
  },
  totalRequestValue: {
    totalValue: 'Total value',
  },
  footerButton: {
    return: 'Return',
    returnToHome: 'Return to homepage',
    conclude: 'Conclude',
  },
  searchCostCenter: {
    search: 'Search cost center',
    find: 'Find your cost center',
    select: 'Select cost center',
    selectTwo: 'Select two cost centers to continue',
  },
  requestStatus: {
    resume: 'Request resume',
    status: 'Request Status',
    complete: 'Complete Reservation',
  },
  debit_allocation: {
    others: 'Others',
    required_field: 'Required field',
    cost_center: 'Cost center',
    regional_or_cost_center: 'Regional or cost center',
    approver: 'Approver',
    trip_reason: 'Trip reason',
    reason: 'Reason',
    reason_justification: 'Reason justification',
    antecedence_justification: 'Trip antecedence justification!',
    split_cost_center: 'Split cost center',
    complementary_info: 'Complementary informations',
    do_you_need_to_provide_more_info:
      'Do you need to provide more informations?',
    error: {
      reasons: 'Failed to get reasons.',
      cost_center: 'Failed to get cost centers.',
      min_characters: 'The field must have at least 10 characters.',
    },
    shared_with: 'Shared with:',
    gol_employee: 'Gol Employee',
  },
}

export default enReservation
