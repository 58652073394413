const ptFlow = {
  flow: {
    tabs: {
      air: 'Passagens aéreas',
      hotel: 'Reserva de hotel',
      car: 'Aluguel de carro',
      other: 'Outros serviços',
    },
  },
}

export default ptFlow
