const enUserGroups = {
  userGroups: {
    pageTitle: 'User Groups',
    searchGroupPlaceholder: 'Search group name',
    newGroupButton: 'New group',
    breadcrumb: {
      home: 'Home',
      userGroups: 'User Groups',
    },
    notify: {
      groupRemoved: 'The group has been removed.',
    },
    table: {
      columns: {
        groupName: 'Group Name',
        description: 'Description',
        action: 'Action',
      },
      deleteGroupPopConfirm: {
        title: 'Delete group',
        description: 'This action is irreversible, do you want to continue?',
        okText: 'Yes',
        cancelText: 'Cancel',
      },
    },
    alert: {
      noPermToViewGroupUsers: 'You do not have permission to view user groups.',
    },
  },
  handleGroup: {
    newUserGroups: 'New user group',
    editUserGroups: 'Edit user group',
    groupPermissions: 'Group permissions',
    form: {
      groupName: {
        label: 'Group Name',
        placeholder: 'Enter group name',
        required: 'Required field',
      },
      groupDescription: {
        label: 'Description',
        placeholder: 'Brief description of the group',
        required: 'Required field',
      },
      permissions: {
        label: 'Permissions',
        placeholder: 'Select permissions',
      },
      confirmButton: 'Confirm',
    },
    permissions: {
      permissions: 'Permissions',
      users: 'Users',
      'travel-request': 'Travel request',
      'debit-allocation': 'Debit allocation',
      'close-travel-request': 'Close travel request',
      approval: 'Approval',
    },
    alert: {
      noPermToViewGroupPermissions:
        'You do not have permission to view group permissions.',
      noPermToUpdateGroupPermissions:
        'You do not have permission to update group permissions.',
    },
  },
}

export default enUserGroups
