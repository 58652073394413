import styled from 'styled-components'

export const TotalRequestValueStyles = styled.div`
  width: 100%;
  padding: 24px 32px;
  border: 1px solid #d9d9d9;
  border-radius: 1.111vw;

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .text {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.001em;
  }

  .total {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.0015em;
  }

  .value {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }
`
