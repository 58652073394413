export default `
<svg xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink"
     width="100%" height="100%" viewBox="0 0 300 300" version="1.1">
  <g id="surface1">
    <path style="stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
          d="M 123.25 79.847656 C 123.25 121.191406 122.992188 132.800781 122.070312 132.800781
             C 120.152344 132.800781 59.773438 116.484375 59.070312 115.773438
             C 58.71875 115.417969 55.863281 108.679688 52.726562 100.796875
             L 47.027344 86.464844 L 38.082031 84.003906 L 29.140625 81.546875
             L 28.886719 114.488281 C 28.75 132.605469 28.824219 147.613281 29.054688 147.84375
             C 31.214844 150.003906 243.84375 205.542969 248.660156 205.203125
             C 266.640625 203.929688 271.960938 178.15625 255.949219 169.886719
             C 254.054688 168.910156 236.917969 163.925781 217.867188 158.816406
             L 183.230469 149.523438 L 165.570312 91.71875
             C 155.859375 59.929688 147.78125 33.78125 147.617188 33.613281
             C 147.457031 33.445312 141.90625 31.867188 135.285156 30.101562
             L 123.25 26.894531 L 123.25 79.847656
             M 24.839844 258.441406 L 24.839844 271.335938 L 269.425781 271.335938
             L 269.425781 245.542969 L 24.839844 245.542969 L 24.839844 258.441406"/>
  </g>
</svg>
`
