const enHotel = {
  hotel: {
    moreOptionsButton: 'Explore more options',
    room: 'Room',
    filterSection: {
      header: 'Choose your preferences',
      hotelPriceGroupboxHeader: 'Price range',
      hotelTypesGroupboxHeader: 'Types',
      hotelAmenitiesGroupboxHeader: 'Amenities',
      rangeWithInputs: {
        form: {
          minValue: {
            label: 'Minimum',
          },
          maxValue: {
            label: 'Maximum',
          },
        },
      },
      hotelTypes: {
        peopleWd: 'Adapted for people with disabilities',
        pets: 'Allows pets',
        kids: 'Allows children',
        babies: 'Allows babies',
      },
      hotelAmenities: {
        doubleBed: 'Double bed',
        singleBed: 'Single bed',
        noPreference: 'No preference',
      },
      lookingQuote: 'Looking for a hotel quote?',
      getQuote: 'Get a quote!',
      flightWantedNotFound: "Didn't find the flight you wanted?",
      askTheAgency: 'Ask the agency',
    },
    renderHotels: {
      emptyHotelDescription: 'No options for the search',
    },
    hotelInfoBar: {
      changeSearch: 'Change search',
    },
    favorites: 'Favorites',
    daily_rate: 'Daily rate',
    refundable_fare: 'Refundable fare',
    non_refundable_fare: 'Non-refundable fare',
    person: 'person',
    see_other_rooms: 'See other rooms',
    hotel_details: 'Hotel details',
    add: 'Add',
    only_exib_acomodations_with_food:
      'Show only accommodations with meals included',
    breakfast_alimentation: 'Breakfast / Meals',
    see_more_options: 'See more options',
    choose_your_room: 'Choose your room',
    acomodations_in: 'Accommodations in',
    recommended: 'Recommended',
    of: 'of',
    options: 'options',
  },
}

export default enHotel
