import styled from 'styled-components'

export const ViewUserScreenContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;

  .content {
    width: 100%;
    height: 100%;
    min-height: 350px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    padding: 16px;
    gap: 24px;
    background: var(--Neutral-Neutral-100, #fff);
    box-shadow:
      0px 0px 24px 0px rgba(0, 0, 0, 0.08),
      0px 2px 4px 0px rgba(0, 0, 0, 0.08);

    .data {
      display: flex;
      flex-direction: row;
      min-height: 350px;
      width: 100%;
      gap: 8px;

      .vertical-divider {
        width: 1px;
        background-color: #d9d9d9;
        margin: 0 20px;
        align-self: stretch;
      }

      .centered-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .values {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .empty-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .spinner-container {
    width: 100%;
    padding: 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const ActionBottomBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`
