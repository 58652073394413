import styled from 'styled-components'

export const DropdownProfileContentStyles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  > button {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    font: inherit;
    color: inherit;
    text-align: inherit;
    padding: 16px 24px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    p {
      font-family: Nunito;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    &:hover {
      color: var(--primary-color);
    }
  }
`
