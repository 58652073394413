import styled from 'styled-components'

export const MyProfileContainer = styled.div`
  padding: 1.5rem;
  border: 0.0625rem solid #d9d9d9;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 52.625rem;
  color: #333;
  font-weight: 600;

  @media (max-width: 1024px) {
    width: 100%;
  }

  .container-title {
    font-size: 1.5rem;
    line-height: 2.25rem;

    @media (max-width: 768px) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }

  .container-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 5%;

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  .user-info-box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .user-info-label {
    font-size: 1.25rem;
    line-height: 2rem;

    @media (max-width: 480px) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .user-info {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: #1a1a1a;

    @media (max-width: 480px) {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
`
