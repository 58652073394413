import styled from 'styled-components'

export const TabsWrapper = styled.div<{ $isflowtab?: boolean }>`
  display: flex;
  flex-direction: row;
  width: fit-content;
  background: #f2f2f2;
  border-radius: 16px 16px 0px 0;
  box-shadow:
    0px 0px 24px 0px rgba(0, 0, 0, 0.08),
    0px 2px 4px 0px rgba(0, 0, 0, 0.08);

  ${({ $isflowtab }) =>
    $isflowtab &&
    `
    border-radius: 16px ;
  `}

  @media screen and (max-width: 870px) {
    width: 100%;
  }
`
