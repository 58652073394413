import React from 'react'
import { StyledButton } from './styles'
import Spinner from '../spinner'

export type ButtonSizes = 'sm' | 'md' | 'md-responsive'

export type ButtonTypes =
  | 'primary'
  | 'secondary'
  | 'outline'
  | 'outline-black'
  | 'back-button'

export type ButtonProps = {
  children: any
  disabled?: boolean
  isLoading?: boolean
  width?: string
  isSubmit?: boolean
  onClick?: () => void
  size?: ButtonSizes
  buttonType?: ButtonTypes
  name?: string
  show?: boolean
}

const Button: React.FC<ButtonProps> = ({
  children,
  disabled = false,
  isLoading = false,
  width,
  isSubmit,
  onClick,
  size = 'md',
  buttonType = 'primary',
  name,
  show = true,
}) => {
  if (!show) {
    return <></>
  }
  return (
    <StyledButton
      disabled={disabled}
      width={width}
      as="button"
      type={isSubmit ? 'submit' : 'button'}
      onClick={onClick}
      data-testid="smiles-button"
      size={size}
      $buttontype={buttonType}
      name={name}
    >
      {isLoading ? <Spinner /> : children}
    </StyledButton>
  )
}

export default Button
