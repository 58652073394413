import React from 'react'
import MainContainer from 'components/mainContainer'
import Button from 'components/button'
import { AccessDeniedContent } from './styles'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const AccessDenied: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <MainContainer>
      <AccessDeniedContent data-testid="smiles-access-denied-screen">
        <h1>{t('access_denied.oops')}</h1>
        <p>{t('access_denied.you_do_not_have_permission')}</p>
        <Button width="250px" onClick={() => navigate('/')}>
          {t('access_denied.back_to_beginning')}
        </Button>
      </AccessDeniedContent>
    </MainContainer>
  )
}

export default AccessDenied
