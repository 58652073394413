import { useCallback, useEffect, useState } from 'react'
import { Form, FormInstance } from 'antd'
import {
  Gender,
  getNationalities,
  getCompanies,
  createEmployee,
  getEmployee,
  CreateEmployeeDto,
  updateEmployee,
} from 'services/outsourced'
import { notifyError, notifySuccess } from 'utils/handleRequestNotify'
import { useTranslation } from 'react-i18next'
import { debounce } from 'utils/debounce'
import { isValidCPF } from 'utils/validations'
import { z } from 'zod'
import dayjs from 'dayjs'

type FormProps = {
  foreign: boolean
}

type OutsourcedState = {
  form: FormInstance<FormProps>
  isLoading: boolean
}

export const useOutsourced = (
  onFinishCallback: Function,
  isEditMode: boolean,
  employeeId?: string,
) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const isForeigner = Form.useWatch('foreign', form)
  const isConcession = Form.useWatch('isConcession', form)
  const cpf = Form.useWatch('cpf', form)
  const [submittable, setSubmittable] = useState<boolean>(false)
  const [state, setState] = useState<OutsourcedState>({
    form,
    isLoading: false,
  })
  const [nationalities, setNationalities] = useState([])
  const [companies, setCompanies] = useState([])
  const [prevEmployee, setPrevEmployee] = useState<Partial<CreateEmployeeDto>>(
    {},
  )

  const defaultNationalityValue = {
    id: 'da9f193e-da74-4ff4-897b-984a6a2479f9',
    name: 'Brasil',
  }

  useEffect(() => {
    if (isEditMode && employeeId) {
      fetchEmployee(employeeId)
    }
  }, [])

  const setIsLoading = (isLoading: boolean) => {
    setState((prevState) => ({ ...prevState, isLoading }))
  }

  const fetchNationalities = useCallback(
    debounce(async (search: string) => {
      if (search?.length < 3) {
        setNationalities([])
        return
      }
      setIsLoading(true)
      try {
        const response = await getNationalities(search)
        setNationalities(response.data)
      } catch (error) {
        notifyError()
      } finally {
        setIsLoading(false)
      }
    }, 800),
    [t],
  )

  const fetchCompanies = useCallback(
    debounce(
      async (search: string | undefined, page: number, limit: number) => {
        if (search && search?.length < 3) {
          setCompanies([])
          return
        }
        setIsLoading(true)
        try {
          const response = await getCompanies(search, page, limit)
          setCompanies(response.data)
        } catch (error) {
          notifyError()
        } finally {
          setIsLoading(false)
        }
      },
      500,
    ),
    [],
  )

  const getGenderList = () => {
    const genderList = [
      {
        label: t('new_outsourced.form.gender.male.label'),
        value: Gender.MALE,
      },
      {
        label: t('new_outsourced.form.gender.female.label'),
        value: Gender.FEMALE,
      },
    ]

    return genderList
  }

  const handleCPFChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    form.setFieldsValue({ cpf: value })

    const isValid = isValidCPF(value)
    if (isValid) {
      form.setFields([{ name: 'cpf', errors: [] }])
    } else {
      form.setFields([
        {
          name: 'cpf',
          errors: [t('new_outsourced.form.cpf.errors.not_valid')],
        },
      ])
    }
  }

  const buildCreateEmployeeDto = (formValues: any): any => {
    const createEmployeeDto = {
      company: {
        companyId: formValues.company,
      },
      nationalityId: formValues.nationality.id ?? defaultNationalityValue.id,
      isForeigner: formValues.foreign ?? false,
      isConcession: formValues.isConcession ?? false,
      fullName: formValues.name,
      cpf: formValues.cpf,
      rg: formValues.rg,
      passport: formValues.passport,
      job: formValues.position,
      gender: formValues.gender,
      dateOfBirth: formValues?.birthdate?.format('YYYY-MM-DD') ?? null,
      phone: formValues.phone,
      email: formValues.email,
    }
    return createEmployeeDto
  }

  const validateFormData = (formValues: any) => {
    const companySchema = z.object({
      companyId: z.string().optional(),
      companyName: z.string().optional(),
      document: z.string().optional(),
    })

    const bodySchema = z.object({
      company: companySchema,
      nationalityId: z.string().optional(),
      isForeigner: z.boolean().refine((val) => val !== undefined),
      isConcession: z.boolean().refine((val) => val !== undefined),
      fullName: z
        .string()
        .min(3)
        .max(100)
        .refine((val) => val !== undefined),
      cpf: z.string().optional(),
      rg: z.string().optional(),
      passport: z.string().optional(),
      job: z.string().optional(),
      gender: z.nativeEnum(Gender).optional(),
      dateOfBirth: z.string(),
      phone: z.string().refine((val) => val !== undefined),
      email: z.string().email(),
    })
    try {
      bodySchema.parse(formValues)
      return { status: 'success' }
    } catch (error) {
      if (error instanceof z.ZodError) {
        error.errors.forEach(({ path, message }) => {
          form.setFields([
            {
              name: path,
              errors: [message],
            },
          ])
        })
      }
      return { status: 'error', error }
    }
  }

  const onValuesChange = (changedValues: unknown, allValues: unknown) => {
    if (isEditMode) {
      setSubmittable(true)
      return
    }
    const dto = buildCreateEmployeeDto(allValues)
    const isFormValid = validateFormData(dto)
    if (isFormValid.status === 'success') {
      setSubmittable(true)
      return
    }

    setSubmittable(false)
  }

  const fetchEmployee = async (id: string) => {
    const response = await getEmployee(id)
    const employeeData = {
      isConcession: response.is_concession ?? false,
      isForeigner: response.isForeigner ?? false,
      name: response.full_name,
      cpf: response.cpf ?? '',
      rg: response.rg ?? '',
      passport: response.passport ?? '',
      company: response.company.company_name ?? '',
      gender: response.gender ?? '',
      position: response.job ?? '',
      phone: response.phone ?? '',
      email: response.email ?? '',
      birthdate: dayjs(response.date_of_birth) ?? '',
    }
    setPrevEmployee(employeeData)
    setEmployeeFormData(employeeData)
  }

  const setEmployeeFormData = (employeeData: any) => {
    form.setFieldsValue({ ...employeeData })
  }

  const buildUpdateEmployeeDto = (id: string, changes: any): any => {
    const updateEmployeeDto: any = {}

    const mapping: Record<string, string> = {
      nationalityId: 'nationalityId',
      isForeigner: 'isForeigner',
      isConcession: 'isConcession',
      name: 'fullName',
      cpf: 'cpf',
      rg: 'rg',
      passport: 'passport',
      position: 'job',
      gender: 'gender',
      birthdate: 'dateOfBirth',
      phone: 'phone',
      email: 'email',
    }
    Object.keys(mapping).forEach((key) => {
      if (changes[key] !== undefined) {
        if (key === 'company') {
          updateEmployeeDto[mapping[key]] = { companyId: changes[key] }
        } else if (key === 'nationalityId') {
          updateEmployeeDto[mapping[key]] =
            changes[key]?.id ?? defaultNationalityValue.id
        } else if (key === 'birthdate') {
          updateEmployeeDto[mapping[key]] =
            changes[key]?.format('YYYY-MM-DD') ?? null
        } else {
          updateEmployeeDto[mapping[key]] = changes[key]
        }
      }
    })

    return { id, ...updateEmployeeDto }
  }

  const compareEmployees = (
    prevEmployee: Partial<CreateEmployeeDto>,
    currentEmployee: Partial<CreateEmployeeDto>,
  ): Partial<CreateEmployeeDto> => {
    const changes: any = {}

    ;(Object.keys(currentEmployee) as any).forEach(
      (key: keyof CreateEmployeeDto) => {
        if (key === 'company') {
          if (
            JSON.stringify(currentEmployee.company) !==
            JSON.stringify(prevEmployee.company)
          ) {
            changes[key] = currentEmployee.company
          }
        } else if (currentEmployee[key] !== prevEmployee[key]) {
          changes[key] = currentEmployee[key]
        }
      },
    )

    return changes
  }

  const onFinishCreate = async () => {
    const formValues = form.getFieldsValue()
    const createEmployeeDto = buildCreateEmployeeDto(formValues)

    try {
      setIsLoading(true)
      const response = await createEmployee(createEmployeeDto)
      if (response.status === 'error') {
        const errorDesc = response.error ?? null
        notifyError(errorDesc)
        return
      }
      notifySuccess()
      onFinishCallback()
    } catch {
      notifyError()
    } finally {
      setIsLoading(false)
    }
  }

  const onFinishEdit = async () => {
    if (!employeeId) return

    setSubmittable(false)
    const currentEmployee = form.getFieldsValue()
    const changes = compareEmployees(prevEmployee, currentEmployee)
    const payload = buildUpdateEmployeeDto(employeeId, changes)
    const response = await updateEmployee(payload)
    if (response.status === 'error') {
      const errorDesc = response.error ?? null
      notifyError(errorDesc)
      return
    }
    notifySuccess()
    onFinishCallback()
  }

  return {
    ...state,
    form,
    nationalities,
    setNationalities,
    fetchNationalities,
    companies,
    setCompanies,
    fetchCompanies,
    setIsLoading,
    getGenderList,
    submittable,
    onValuesChange,
    isForeigner,
    isConcession,
    cpf,
    handleCPFChange,
    onFinishCreate,
    onFinishEdit,
    defaultNationalityValue,
    fetchEmployee,
  }
}
