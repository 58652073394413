import React from 'react'
import { TabWrapper } from './styles'
import { iconType } from 'components/icons/svgs/icons'
import { SmilesIcon } from 'components/icons'

export type TabContentTypes = {
  label: string
  icon?: iconType
}

export type TabTypes = TabContentTypes & {
  $active: boolean
  onClick: () => void
  isFlowTab?: boolean
}

const Tab: React.FC<TabTypes> = ({
  $active,
  onClick,
  isFlowTab,
  label,
  icon,
}) => (
  <TabWrapper
    $active={$active}
    onClick={onClick}
    data-testid="smiles-tab"
    $isflowtab={isFlowTab}
  >
    {icon && <SmilesIcon type={icon} />}
    <span>{label}</span>
  </TabWrapper>
)

export default Tab
