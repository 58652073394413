import React from 'react'
import { SmilesIcon } from 'components/icons'
import AirlineIcon from './airlineIcon'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { FlightDDetailsStyles } from './styles'
import { SMyTravelFlight } from 'shared/interfaces/SMyTravel'

dayjs.extend(utc)

export type FlightDetailsProps = {
  flightData: SMyTravelFlight
}

const FlightDetails: React.FC<FlightDetailsProps> = ({ flightData }) => {
  const brTimezoneInMinutes = 180
  return (
    <FlightDDetailsStyles data-testid="smiles-flight-details">
      <AirlineIcon />

      <div className="info-container">
        <div className="travel-info-data">
          <div>
            <p className="airport-and-hour">
              {flightData.origin}
              <b>
                {dayjs(flightData.departure_time)
                  .utcOffset(-brTimezoneInMinutes)
                  .format('H[h]mm')}
              </b>
            </p>
            <p className="travel-city"> {flightData.origin} </p>
          </div>
          <SmilesIcon type="plane-right" size={18} color="#ff7020" />
          <div>
            <p className="airport-and-hour">
              {flightData.destination}
              <b>
                {dayjs(flightData.return_date)
                  .utcOffset(-brTimezoneInMinutes)
                  .format('H[h]mm')}
              </b>
            </p>
            <p className="travel-city"> {flightData.destination}</p>
          </div>
        </div>
      </div>
    </FlightDDetailsStyles>
  )
}

export default FlightDetails
