import ptLogin from './ptLogin'
import ptHome from './ptHome'
import ptNavbar from './ptNavbar'
import ptFlights from './ptFlights'
import ptUserGroups from './ptUserGroups'
import ptGroup from './ptGroup'
import ptFlow from './ptFlow'
import ptReservation from './ptReservation'
import ptHotel from './ptHotel'
import ptMyProfile from './ptMyProfile'
import ptMyRequests from './ptMyRequests'
import ptAccessDenied from './ptAccessDenied'
import ptTravelRequest from './ptTravelRequest'
import ptMyTravels from './ptMyTravels'
import ptPermissions from './ptPermissions'
import ptUser from './ptUser'
import ptComponent from './ptComponent'
import ptOutsourced from './ptOutsourced'

const ptTranslations = {
  translations: {
    ...ptAccessDenied,
    ...ptLogin,
    ...ptHome,
    ...ptNavbar,
    ...ptFlights,
    ...ptUserGroups,
    ...ptGroup,
    ...ptFlow,
    ...ptReservation,
    ...ptHotel,
    ...ptMyProfile,
    ...ptMyRequests,
    ...ptTravelRequest,
    ...ptMyTravels,
    ...ptPermissions,
    ...ptUser,
    ...ptComponent,
    ...ptOutsourced,
  },
}

export default ptTranslations
