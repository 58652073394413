import React from 'react'
import { DropdownProfileContentStyles } from './styles'
import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const DropdownProfileContent: React.FC = () => {
  const { logout } = useAuth0()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID

  // const returnURL = `${window.location.origin}/login`

  return (
    <DropdownProfileContentStyles data-testid="smiles-profile-dropdown-content">
      <button onClick={() => navigate('/my-profile')}>
        <p>{t('navbar.profileDropdown.myProfile')}</p>
      </button>
      <button
        data-testid="button-to-users-groups"
        onClick={() => navigate('/user-groups')}
      >
        <p>{t('navbar.profileDropdown.userGroups')}</p>
      </button>
      <button
        data-testid="logout-buttom"
        onClick={() => {
          logout({
            logoutParams: { returnTo: window.location.origin },
            clientId,
          })
          localStorage.clear()
        }}
      >
        <p>{t('navbar.profileDropdown.logout')}</p>
      </button>
    </DropdownProfileContentStyles>
  )
}

export default DropdownProfileContent
