import styled from 'styled-components'

export const TabWrapper = styled.div<{
  $active?: boolean
  $isflowtab?: boolean
}>`
  transition: all 0.2s ease-in-out;
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.08px;
  border-radius: 16px 16px 0px 0px;
  background: #f2f2f2;
  &:hover {
    background: #e2e2e2;
  }
  svg {
    fill: #000;
  }

  ${({ $active }) =>
    $active &&
    `
    background: #FFFFFF;
    border-radius: 16px 16px 0px 0px;
    &:hover {
      background:  #FFFFFF; 
    }
    svg {
      fill: #ff7020;
    }
  `};

  ${({ $isflowtab }) =>
    $isflowtab &&
    ` 
    border-radius: 16px; 
  `};

  @media screen and (max-width: 870px) {
    width: 100%;
    > span {
      display: none;
    }
  }
`
