import { Form, Switch } from 'antd'
import DrawerBottomNavigation from 'components/drawerBottomNavigation'
import Button from 'components/button'
import Input from 'components/Input'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  NewOutsourcedFormContent,
  StyledFormItem,
  StyledInputMask,
} from './styles'
import Select from 'components/select'
import DatePicker from 'components/datePicker'
import { useOutsourced } from './hooks/useOutsourced'
import { formatSelectOptions } from 'utils/formatSelectOptions'
import { Checkbox } from 'components/checkbox'

type HandleOutsourced = {
  onFinishCallback: () => void
  isEditMode: boolean
  employeeId?: string
}

const HandleOutsourced: React.FC<HandleOutsourced> = ({
  onFinishCallback,
  isEditMode,
  employeeId,
}) => {
  const { t } = useTranslation()
  const {
    form,
    nationalities,
    setNationalities,
    fetchNationalities,
    companies,
    setCompanies,
    fetchCompanies,
    isLoading,
    getGenderList,
    submittable,
    onValuesChange,
    isForeigner,
    isConcession,
    onFinishCreate,
    onFinishEdit,
    handleCPFChange,
    defaultNationalityValue,
    fetchEmployee,
  } = useOutsourced(onFinishCallback, isEditMode, employeeId)

  useEffect(() => {
    if (isEditMode && employeeId) {
      fetchEmployee(employeeId)
    }
  }, [])

  return (
    <div data-testid="smiles-handle-employee">
      <Form
        form={form}
        onValuesChange={onValuesChange}
        data-testid="smiles-handle-employee-form"
        initialValues={{
          conession: false,
          nationality: defaultNationalityValue.name,
        }}
      >
        <NewOutsourcedFormContent>
          <div className="header">
            {isEditMode ? (
              <p>{t('edit_employee.title')}</p>
            ) : (
              <p>{t('new_outsourced.registerOutsourcedEmployee')}</p>
            )}
          </div>
          <div className="form-container">
            <div className="switch-container">
              <StyledFormItem
                name="concession"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: t('new_outsourced.form.required'),
                  },
                ]}
              >
                <Switch />
              </StyledFormItem>
              <span>{t('new_outsourced.form.concession.label')}</span>
            </div>
            <div className="form-row">
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('new_outsourced.form.required'),
                  },
                ]}
              >
                <Input
                  label={t('new_outsourced.form.name.label')}
                  placeholder={t('new_outsourced.form.name.placeholder')}
                />
              </Form.Item>
            </div>
            <div className="form-row two-columns">
              <div>
                <p>{t('new_outsourced.form.cpf.label')}</p>
                <Form.Item
                  name="cpf"
                  rules={[
                    {
                      required: !isForeigner,
                      message: t('new_outsourced.form.required'),
                    },
                  ]}
                >
                  <StyledInputMask
                    mask="999.999.999-99"
                    placeholder={t('new_outsourced.form.cpf.placeholder')}
                    disabled={isForeigner}
                    onBlur={handleCPFChange}
                  />
                </Form.Item>
              </div>
              <div>
                <p>{t('new_outsourced.form.rg.label')}</p>
                <Form.Item name="rg">
                  <StyledInputMask
                    mask="99.999.999-9"
                    placeholder={t('new_outsourced.form.rg.placeholder')}
                    disabled={isForeigner}
                  />
                </Form.Item>
              </div>
            </div>
            <div>
              <Form.Item
                name="foreign"
                valuePropName="checked"
                rules={[
                  {
                    required: false,
                    message: t('new_outsourced.form.required'),
                  },
                ]}
              >
                <Checkbox label={t('new_outsourced.form.foreign.label')} />
              </Form.Item>
            </div>
            <div className="form-row">
              <Form.Item
                name="nationality"
                rules={[
                  {
                    required: false,
                    message: t('new_outsourced.form.required'),
                  },
                ]}
              >
                <Select
                  showSearch
                  label={t('new_outsourced.form.nationality.label')}
                  placeholder={t('new_outsourced.form.nationality.placeholder')}
                  onSearch={fetchNationalities}
                  onBlur={() => setNationalities([])}
                  options={formatSelectOptions({
                    data: nationalities,
                    label: 'name',
                    value: 'id',
                  })}
                  data-testid="outsourced-nationality-select"
                  disabled={!isForeigner}
                />
              </Form.Item>
            </div>
            <div className="form-row">
              <Form.Item
                name="passport"
                rules={[
                  {
                    required: false,
                    message: t('new_outsourced.form.required'),
                  },
                ]}
              >
                <Input
                  label={t('new_outsourced.form.passport.label')}
                  placeholder={t('new_outsourced.form.passport.placeholder')}
                />
              </Form.Item>
            </div>
            <div className="form-row">
              <Form.Item
                name="company"
                rules={[
                  {
                    required: !isConcession,
                    message: t('new_outsourced.form.required'),
                  },
                ]}
              >
                <Select
                  showSearch
                  label={`${t('new_outsourced.form.company.label')} ${
                    isConcession ? t('new_outsourced.form.optional') : ''
                  }`}
                  placeholder={t('new_outsourced.form.company.placeholder')}
                  onSearch={fetchCompanies}
                  onBlur={() => setCompanies([])}
                  options={formatSelectOptions({
                    data: companies,
                    label: 'company_name',
                    value: 'id',
                  })}
                  data-testid="outsourced-company-select"
                />
              </Form.Item>
            </div>
            <div className="form-row">
              <Form.Item
                name="position"
                rules={[
                  {
                    required: false,
                    message: t('new_outsourced.form.required'),
                  },
                ]}
              >
                <Input
                  label={t('new_outsourced.form.position.label')}
                  placeholder={t('new_outsourced.form.position.placeholder')}
                />
              </Form.Item>
            </div>
            <div className="form-row">
              <Form.Item
                name="gender"
                rules={[
                  {
                    required: !isConcession,
                    message: t('new_outsourced.form.required'),
                  },
                ]}
              >
                <Select
                  label={`${t('new_outsourced.form.gender.label')} ${
                    isConcession ? t('new_outsourced.form.optional') : ''
                  }`}
                  placeholder={t('new_outsourced.form.gender.placeholder')}
                  options={formatSelectOptions({
                    data: getGenderList(),
                    label: 'label',
                    value: 'value',
                  })}
                />
              </Form.Item>
            </div>
            <div className="form-row">
              <Form.Item
                name="birthdate"
                rules={[
                  {
                    required: false,
                    message: t('new_outsourced.form.required'),
                  },
                ]}
              >
                <DatePicker
                  label={`${t('new_outsourced.form.birthdate.label')} ${
                    isConcession ? t('new_outsourced.form.optional') : ''
                  }`}
                  name="birthdate"
                />
              </Form.Item>
            </div>
            <div className="form-row">
              <p>{t('new_outsourced.form.phone.label')}</p>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t('new_outsourced.form.required'),
                  },
                ]}
              >
                <StyledInputMask name="phone" mask="(99) 99999 9999" />
              </Form.Item>
            </div>
            <div className="form-row">
              <Form.Item
                name="email"
                rules={[
                  {
                    required: !isConcession,
                    message: t('new_outsourced.form.required'),
                  },
                ]}
              >
                <Input
                  label={t('new_outsourced.form.email.label')}
                  placeholder={t('new_outsourced.form.email.placeholder')}
                />
              </Form.Item>
            </div>
          </div>
        </NewOutsourcedFormContent>
        <div className="drawer-bottom">
          <DrawerBottomNavigation>
            <Button
              onClick={isEditMode ? onFinishEdit : onFinishCreate}
              width="340px"
              disabled={!submittable || isLoading}
            >
              {isEditMode
                ? t('edit_employee.save_button')
                : t('new_outsourced.confirmButton')}
            </Button>
          </DrawerBottomNavigation>
        </div>
      </Form>
    </div>
  )
}

export default HandleOutsourced
