import axios from 'axios'
import { API } from 'services/config'
import { SUser } from 'shared/interfaces/SUser'

const point = '/permissions/v1/group'

type CreateGroupsParams = {
  name: string
  description: string
}

type EditGroupsParams = CreateGroupsParams & {
  id: string
}

type HandleUserParams = {
  groupId: string
  user?: SUser
}

type PatchGroupPermissionsParams = {
  groupId: string
  permissions: { id: string; type: string }[]
}

const getGroups = async () => {
  try {
    const response = await API.get(point)
    return response.data
  } catch (error) {
    return { error: 'Error on get user groups' }
  }
}

const getGroupById = async (id: string) => {
  try {
    const response = await API.get(`${point}/${id}`)
    return response.data
  } catch (error) {
    return { error: 'Error on get group' }
  }
}

const deleteGroup = async (id: string) => {
  try {
    const response = await API.delete(`${point}/${id}`)
    return response.data
  } catch (error) {
    return { error: 'Error on delete group' }
  }
}

const createGroup = async ({ name, description }: CreateGroupsParams) => {
  try {
    const response = await API.post(point, { name, description })
    return response.data
  } catch (error) {
    return { error: 'Error on create group' }
  }
}

const editGroup = async ({ name, description, id }: EditGroupsParams) => {
  try {
    const response = await API.put(`${point}/${id}`, { name, description })
    return response.data
  } catch (error) {
    return { error: 'Error on edit group' }
  }
}

const getGroupUsers = async (id: string) => {
  try {
    const response = await API.get(`${point}/user/${id}`)
    return response.data
  } catch (error) {
    return { error: 'Error on get users' }
  }
}

const addUserInGroup = async ({ groupId, user }: HandleUserParams) => {
  try {
    const response = await API.post(`${point}/user`, {
      groupId,
      email: user?.email,
      name: user?.name,
    })
    if (response.status === 201) {
      return response.data
    }
    return null
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return {
        error: error.response.data.error || 'Error on add user',
        details: error.response.data.details || 'No details provided',
      }
    } else {
      return { error: 'Error on add user', details: 'No response from server' }
    }
  }
}

const removeUserFromGroup = async (user: SUser, groupId: string) => {
  try {
    const response = await API.delete(`${point}/user/${user.id}`, {
      data: {
        groupId,
        email: user.email,
      },
    })
    return response.data
  } catch (error) {
    return { error: 'Error on delete user' }
  }
}

const patchGroupPermissions = async ({
  groupId,
  permissions,
}: PatchGroupPermissionsParams) => {
  try {
    const response = await API.patch(`${point}/permission/${groupId}`, {
      permissions,
    })
    return response.data
  } catch (error) {
    return { error: 'Error on add group permissions' }
  }
}

export {
  getGroups,
  getGroupById,
  deleteGroup,
  createGroup,
  editGroup,
  getGroupUsers,
  addUserInGroup,
  removeUserFromGroup,
  patchGroupPermissions,
}
