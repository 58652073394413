import React from 'react'
import { FlightInfo } from 'components/summaryModalContent/styles'
import { Flight } from 'services/flights/flightModel'
import dayjs from 'dayjs'
import {
  RenderFlightDetails,
  RenderFlightInfoStyles,
  RenderStopsAndLuggageStyles,
} from './styles'
import { SmilesIcon } from 'components/icons'
import AirlineIcon from 'components/flightDetails/airlineIcon'
import { useTranslation } from 'react-i18next'
import { Color } from 'styles/global'

export type RenderFlightInfoProps = {
  flightData: Flight[] | undefined
}

const RenderFlightInfo: React.FC<RenderFlightInfoProps> = ({ flightData }) => {
  const { t } = useTranslation()

  const brTimezoneInMinutes = 180

  const stopsText = (stopsCount: number) => {
    switch (stopsCount) {
      case 0:
        return t('component.stops_and_baggage.direct')
      case 1:
        return `1 ${t('component.stops_and_baggage.stop')}`
      default:
        return `${stopsCount} ${t('component.stops_and_baggage.stops')}`
    }
  }

  return (
    <RenderFlightInfoStyles data-testid="render-cart-flights">
      {flightData?.map((flight, index) => (
        <FlightInfo key={flight.key} data-testid="batata">
          <div className="segment-and-date">
            <p className="segment">
              {' '}
              {t('component.summary_modal.ticket_segment')} {index + 1}
            </p>
            <p className="date">
              {dayjs(flight.departure).format('ddd. DD/MM/YY')}
            </p>
          </div>
          <div className="details">
            <RenderFlightDetails data-testid="smiles-flight-details">
              <AirlineIcon />

              <div className="info-container">
                <div className="travel-info-data">
                  <div>
                    <p className="airport-and-hour">
                      {flight.origin.code}
                      <b>
                        {dayjs(flight.departure)
                          .utcOffset(-brTimezoneInMinutes)
                          .format('H[h]mm')}
                      </b>
                    </p>
                    <p className="travel-city"> {flight.origin.city.name} </p>
                  </div>
                  <SmilesIcon type="plane-right" size={18} color="#ff7020" />
                  <div>
                    <p className="airport-and-hour">
                      {flight.destination.code}
                      <b>
                        {dayjs(flight.arrival)
                          .utcOffset(-brTimezoneInMinutes)
                          .format('H[h]mm')}
                      </b>
                    </p>
                    <p className="travel-city">
                      {flight.destination.city.name}
                    </p>
                  </div>
                </div>
              </div>
            </RenderFlightDetails>
            <RenderStopsAndLuggageStyles>
              <p>
                <b>{stopsText(flight.stopsCount)}</b>
              </p>
              <div className="amount-luggage">
                <div>
                  <SmilesIcon type="backpack" color={Color.PRIMARY} size={18} />
                  <p>
                    <b>{flight.segments[0].baggage.quantity}</b>
                  </p>
                </div>
                <div>
                  <SmilesIcon type="work" color={Color.PRIMARY} size={18} />
                  <p>
                    <b>{flight.segments[0].baggage.quantity}</b>
                  </p>
                </div>
                <div>
                  <SmilesIcon type="luggage" color={Color.PRIMARY} size={18} />
                  <p>
                    <b>{flight.segments[0].baggage.quantity}</b>
                  </p>
                </div>
              </div>
            </RenderStopsAndLuggageStyles>
          </div>
        </FlightInfo>
      ))}
    </RenderFlightInfoStyles>
  )
}

export default RenderFlightInfo
