export default `
<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_4723_32619)">
<rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="black"/>
<path d="M23.2782 15.7071C23.6688 15.3166 24.3019 15.3166 24.6925 15.7071C25.083 16.0976 25.083
 16.7308 24.6925 17.1213L17.6214 24.1924C17.2309 24.5829 16.5977 24.5829 16.2072 24.1924C15.8167
  23.8019 15.8167 23.1687 16.2072 22.7782L23.2782 15.7071Z" fill="#333333"/>
<path d="M24.6923 22.7773C25.0828 23.1678 25.0828 23.801 24.6923 24.1915C24.3018 24.582 23.6686
 24.582 23.2781 24.1915L16.207 17.1204C15.8165 16.7299 15.8165 16.0967 16.207 15.7062C16.5976 
 15.3157 17.2307 15.3157 17.6212 15.7062L24.6923 22.7773Z" fill="#333333"/>
</g>
<defs>
<clipPath id="clip0_4723_32619">
<rect width="40" height="40" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>
`
