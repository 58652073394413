import styled from 'styled-components'

export const FlightSearchContainer = styled.div`
  > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .search-dropdowns-container {
    display: flex;
    flex-direction: row;
    gap: 18px;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 650px) {
      flex-direction: column;
    }
  }
  .search-inputs-container {
    width: 100%;
  }
  .search-buttom-container {
    width: 100%;
    display: flex;
    justify-content: end;
    > {
      button {
        width: 276px;
      }
    }
    @media screen and (max-width: 840px) {
      > button {
        width: 100%;
      }
    }
  }
`

export const AddTravelerButton = styled.button`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #737373;
  border-radius: 16px;
  padding: 8px 12px 8px 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  svg {
    margin-top: 2px;
  }
`

export const AddTravelersContainer = styled.div`
  display: flex;
  gap: 16px;
`
export const RemoveTravelerButton = styled.button`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #737373;
  border-radius: 16px;
  padding: 8px 12px 8px 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  svg {
    margin-top: 2px;
  }
`
