import React from 'react'
import {
  MyProfileContainer,
  PhoneInputWrapper,
  StyledInputMask,
  // EditButton,
  // SaveButton,
  // CancelButton,
} from './styles'
import { formatPhoneNumber } from 'utils/formatters'
import { useTranslation } from 'react-i18next'

interface ContactInfoProps {
  userData: any
  isEditing: boolean
  newPhoneNumber: string
  newSecondaryPhoneNumber: string
  setNewPhoneNumber: (value: string) => void
  setNewSecondaryPhoneNumber: (value: string) => void
  handleEditClick: () => void
  handleCancelClick: () => void
  handleSaveClick: () => void
  isSaveButtonDisabled: () => boolean
}

const ContactInfo: React.FC<ContactInfoProps> = ({
  userData,
  isEditing,
  newPhoneNumber,
  newSecondaryPhoneNumber,
  setNewPhoneNumber,
  setNewSecondaryPhoneNumber,
  handleEditClick,
  handleCancelClick,
  handleSaveClick,
  isSaveButtonDisabled,
}) => {
  const { t } = useTranslation()

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewPhoneNumber(event.target.value)
  }

  const handleSecondaryPhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewSecondaryPhoneNumber(event.target.value)
  }

  return (
    <MyProfileContainer>
      <div className="contact-header">
        <h2 className="container-title">{t('myProfile.contact.header')}</h2>
        {/* Descomentar e aprimorar funcionalidade no pacote 2 */}
        {/* {isEditing ? (
          <div className="button-group">
            <CancelButton onClick={handleCancelClick}>
              {t('myProfile.editButton.cancel')}
            </CancelButton>
            <SaveButton
              onClick={handleSaveClick}
              disabled={isSaveButtonDisabled()}
            >
              {t('myProfile.editButton.save')}
            </SaveButton>
          </div>
        ) : (
          <EditButton onClick={handleEditClick}>
            {t('myProfile.editButton.edit')}
          </EditButton>
        )} */}
      </div>
      <div className="contato-wrapper">
        <div className={`user-info-box ${isEditing ? '' : 'view-mode'}`}>
          <p className="user-info-label">{t('myProfile.contact.cellPhone')}</p>
          {isEditing ? (
            <PhoneInputWrapper>
              <StyledInputMask
                mask="(99) 99999 9999"
                value={newPhoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="(XX) XXXXX XXXX"
              />
            </PhoneInputWrapper>
          ) : (
            <p className="user-info">
              {userData ? formatPhoneNumber(userData.phone_1) : ''}
            </p>
          )}
        </div>
        <div className={`user-info-box ${isEditing ? '' : 'view-mode'}`}>
          <p className="user-info-label">{t('myProfile.contact.altPhone')}</p>
          {isEditing ? (
            <PhoneInputWrapper>
              <StyledInputMask
                mask="(99) 99999 9999"
                value={newSecondaryPhoneNumber}
                onChange={handleSecondaryPhoneNumberChange}
                placeholder="(XX) XXXXX XXXX"
              />
            </PhoneInputWrapper>
          ) : (
            <p className="user-info">
              {userData ? formatPhoneNumber(userData.phone_2) : ''}
            </p>
          )}
        </div>
      </div>
    </MyProfileContainer>
  )
}

export default ContactInfo
