const enLogin = {
  login: {
    accessYourAccount: 'Access your account',
    form: {
      tenantInput: {
        label: 'Organization',
        placeholder: 'Enter your organization name',
        required: '*Mandatory field',
      },
      loginButton: 'Login',
    },
    errors: {
      login: {
        msg: 'Oops!',
        desc: 'Something went wrong, please try again later.',
      },
      tenant: {
        msg: 'Invalid organization!',
        desc: 'Check the information and try again.',
      },
    },
  },
}

export default enLogin
