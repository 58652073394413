import React from 'react'
import { useTranslation } from 'react-i18next'
import { RequestSummaryStyles } from '../RequestSummary/styles'
import BookingCompletedCard from 'components/RequestSummary/components/BookingCompletedCard'
import BookingDetails from 'components/RequestSummary/components/BookingDetails'
import ViewBooking from 'components/RequestSummary/components/ViewBooking'
import HeaderCloseButton from 'components/RequestSummary/components/HeaderCloseButton'
import DrawerBottomNavigation from 'components/drawerBottomNavigation'
import Button from 'components/button'
import { SMyTravel } from 'shared/interfaces/SMyTravel'

export type RequestStatusProps = {
  onClick?: any
  onClose?: any
  closeButton?: any
  travelData: SMyTravel
}

const RequestStatus: React.FC<RequestStatusProps> = ({
  onClick,
  onClose,
  closeButton,
  travelData,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <HeaderCloseButton closeButton={closeButton} />
      <RequestSummaryStyles data-testid="smiles-request-status">
        <div className="title">{t('requestStatus.status')}</div>
        <BookingCompletedCard />
        <BookingDetails travelData={travelData} />
        <ViewBooking onClick={onClick}></ViewBooking>
      </RequestSummaryStyles>
      <DrawerBottomNavigation>
        <Button width="250px" onClick={onClose}>
          {t('footerButton.returnToHome')}
        </Button>
      </DrawerBottomNavigation>
    </>
  )
}

export default RequestStatus
