const esMyProfile = {
  myProfile: {
    header: 'Mis Datos',
    personalData: {
      header: 'Datos Personales',
      fullName: 'Nombre Completo',
      dateOfBirth: 'Fecha de Nacimiento',
      cpf: 'CPF (Identificación Fiscal de Brasil)',
      rg: 'RG (Cédula de Identidad)',
    },
    contact: {
      header: 'Contacto',
      cellPhone: 'Teléfono Móvil',
      altPhone: 'Teléfono Alternativo',
    },
    editButton: {
      edit: 'Editar',
      cancel: 'Cancelar',
      save: 'Guardar',
    },
  },
}
export default esMyProfile
