import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  EmployeeDetailsContent,
  FieldsContainer,
  Label,
  RowContainer,
  SkeletonContainer,
  Value,
} from './styles'
import useEmployeeDetails from './hooks/useEmployeeDetails'
import DrawerBottomNavigation from 'components/drawerBottomNavigation'
import Button from 'components/button'
import Skeleton from 'components/skeleton'

interface EmployeeDetailsProps {
  employeeId: string
  onEmployeeEditClick: () => void
}

const EmployeeDetails: React.FC<EmployeeDetailsProps> = ({
  employeeId,
  onEmployeeEditClick,
}) => {
  const { t } = useTranslation()
  const { fields, loading } = useEmployeeDetails(employeeId)
  return (
    <div data-testid="smiles-employee-details">
      <EmployeeDetailsContent>
        <p className="header">{t('employee_details.title')}</p>
        {loading ? (
          <SkeletonContainer>
            <Skeleton isLoading={loading} />
          </SkeletonContainer>
        ) : (
          <FieldsContainer>
            {Object.entries(fields).map(([key, { label, value }]) => (
              <RowContainer key={key}>
                <Label>{t(label)}</Label>
                <Value>{value}</Value>
              </RowContainer>
            ))}
          </FieldsContainer>
        )}
      </EmployeeDetailsContent>
      <div className="drawer-bottom">
        <DrawerBottomNavigation>
          <Button
            width="340px"
            disabled={loading}
            onClick={onEmployeeEditClick}
          >
            {t('employee_details.bottom_drawer.edit')}
          </Button>
        </DrawerBottomNavigation>
      </div>
    </div>
  )
}

export default EmployeeDetails
