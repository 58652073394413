import { Breadcrumb as AntBreadcrumb, BreadcrumbProps } from 'antd'
import React from 'react'

export type CustomBreadcrumbProps = BreadcrumbProps

const Breadcrumb: React.FC<CustomBreadcrumbProps> = ({ items }) => (
  <AntBreadcrumb items={items} data-testid="smiles-breadcrumb" />
)

export default Breadcrumb
