import React, { ReactElement } from 'react'
import './custom-class.css'
import { StyledAntSelectDropdown } from './styles'

type Options = {
  value: string
  label: string
}

export type DropdownTypes = {
  options: Options[]
  leftIcon?: ReactElement | null
  defaultValue?: string
  width?: number | string
  onChange?: (e?: any) => void
  value?: any
}

const Dropdown: React.FC<DropdownTypes> = ({
  options,
  leftIcon,
  defaultValue,
  width,
  value,
  onChange,
}) => (
  <div className="select-dropdown-container" data-testid="smiles-dropdown">
    {leftIcon}
    <StyledAntSelectDropdown
      defaultValue={defaultValue}
      style={{ width: width }}
      bordered={false}
      options={options}
      onChange={onChange}
      value={value}
    />
  </div>
)

export default Dropdown
