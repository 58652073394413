import { Select } from 'antd'
import styled from 'styled-components'

export const StyledAntdSelect = styled(Select)`
  padding: 33px 0 !important;

  .ant-select-selector {
    width: 100%;
    border: 2px solid #999 !important;
    padding: 31px 20px !important;
    border-radius: 6px;
    position: absolute !important;
    top: 0 !important;

    .ant-select-selection-search {
      padding: 0px 10px !important;
    }
  }
  .ant-select-arrow {
    padding: 2px 14px;
    svg {
      font-size: 14px;
    }
  }
  .ant-select-clear {
    padding: 0 28px;
    svg {
      font-size: 15px;
    }
  }
`
