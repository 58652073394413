import { FlightRoute } from 'services/flights/flightModel'
import { FlightFilters } from '../components/flightFilterSection'

const timeRanges = {
  dawn: { start: 0, end: 4 },
  morning: { start: 5, end: 11 },
  afternoon: { start: 12, end: 17 },
  night: { start: 18, end: 23 },
}

export function getTimeCategory(dateStr: string) {
  const hour = new Date(dateStr).getHours()

  if (hour >= timeRanges.dawn.start && hour <= timeRanges.dawn.end) {
    return 'dawn'
  } else if (
    hour >= timeRanges.morning.start &&
    hour <= timeRanges.morning.end
  ) {
    return 'morning'
  } else if (
    hour >= timeRanges.afternoon.start &&
    hour <= timeRanges.afternoon.end
  ) {
    return 'afternoon'
  } else if (hour >= timeRanges.night.start && hour <= timeRanges.night.end) {
    return 'night'
  }
  return 'undefined'
}

export const filterFlights = (
  flightData: FlightRoute[],
  flightFilters: FlightFilters,
) => {
  return flightData.flatMap((route) => {
    return route?.flights.filter((flight) => {
      const departureTimeCategory = getTimeCategory(flight.departure)
      const returnTimeCategory = flight.arrival
        ? getTimeCategory(flight.arrival)
        : null

      const departureCheck =
        !flightFilters.flightDepartureTime?.length ||
        flightFilters.flightDepartureTime.includes(departureTimeCategory)
      const returnCheck =
        !flightFilters.flightReturnTime?.length ||
        (returnTimeCategory &&
          flightFilters.flightReturnTime.includes(returnTimeCategory))

      return flight.segments.some((segment) => {
        const stopsCheck = flightFilters.flightStops?.length
          ? flightFilters.flightStops.includes(flight.stopsCount)
          : true
        const fareCheck = flight.fares.some((fare) => {
          return (
            fare.type === 'FARE' &&
            (!flightFilters.minValue ||
              fare.price.amount >= flightFilters.minValue) &&
            (!flightFilters.maxValue ||
              fare.price.amount <= flightFilters.maxValue)
          )
        })
        const classCheck =
          !flightFilters.flightClass?.length ||
          flightFilters.flightClass.includes(segment.class.toUpperCase())
        return (
          fareCheck && classCheck && stopsCheck && departureCheck && returnCheck
        )
      })
    })
  })
}
