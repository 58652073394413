import React, { useState } from 'react'
import {
  LoginBackgorundImage,
  LoginContainer,
  LoginFormContainer,
} from './styles'
import LoginImage from 'assets/images/login/login-banner.jpeg'
import Input from 'components/Input'
import Button from 'components/button'
import { Form } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'
import { notify } from 'components/notify'
import { getTenant } from 'services/tenant'
import { useTranslation } from 'react-i18next'
import { notifyError } from 'utils/handleRequestNotify'

type FieldsType = {
  tenant: string
}

const Login: React.FC = () => {
  const { t } = useTranslation()
  const { loginWithRedirect } = useAuth0()
  const [isLoading, setIsLoading] = useState(false)

  const authTenant = async (tenantId: string) => {
    setIsLoading(true)
    try {
      const tenantData = await getTenant(tenantId)
      if (tenantData?.auth0OrganizationId) {
        setIsLoading(false)
        return loginWithRedirect({
          authorizationParams: {
            organization: tenantData.auth0OrganizationId,
            redirect_uri: `${window.location.origin}/`,
          },
        })
      }

      notify({
        msg: t('login.errors.tenant.msg'),
        desc: t('login.errors.tenant.desc'),
        type: 'warning',
      })
      setIsLoading(false)
      return null
    } catch (error: any) {
      notifyError(t('login.errors.login.msg'))
      setIsLoading(false)
      return null
    }
  }

  const onFinish = async (values: FieldsType) => {
    const tenantId = values.tenant
    await authTenant(tenantId)
  }

  return (
    <LoginContainer data-testid="smiles-login-screen">
      <LoginFormContainer>
        <div>
          <p>{t('login.accessYourAccount')}</p>
          <Form name="loginForm" onFinish={onFinish} autoComplete="off">
            <Form.Item<FieldsType>
              name="tenant"
              rules={[
                {
                  required: true,
                  message: t('login.form.tenantInput.required'),
                  min: 3,
                },
              ]}
            >
              <Input
                label={t('login.form.tenantInput.label')}
                placeholder={t('login.form.tenantInput.placeholder')}
                name="tenant"
              />
            </Form.Item>

            <Form.Item>
              <Button isSubmit isLoading={isLoading}>
                {t('login.form.loginButton')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </LoginFormContainer>
      <LoginBackgorundImage $urlimage={LoginImage} />
    </LoginContainer>
  )
}

export default Login
