import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { TableProps } from 'antd'
import { FooterStyles, SearchCostCenterStyles } from './styles'
import iconStyles from '../RequestSummary/components/styles.module.css'
import { SmilesIcon } from 'components/icons'
import { Checkbox } from 'components/checkbox'
import Table from 'components/table'
import Button from 'components/button'
import HeaderCloseButton from 'components/RequestSummary/components/HeaderCloseButton'
import { getCostCenter } from 'services/debtAllocation'
import { debounce } from 'utils/debounce'
import { notifyError } from 'utils/handleRequestNotify'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'
import { SCostCenter } from 'shared/interfaces/SCostCenter'

export interface CostCenterTableTypes {
  key: string
  [key: string]: any
}

export interface SearchCostCenterProps {
  onClose: () => void
  closeButton?: () => void
  selectCostCenterName: string
}

const SearchCostCenter: React.FC<SearchCostCenterProps> = ({
  onClose,
  closeButton,
  selectCostCenterName,
}) => {
  const { t } = useTranslation()
  const { setSelectedCostCenters } = useFlowContext()
  const [costCenterName, setCostCenterName] = useState(selectCostCenterName)
  const [searchResults, setSearchResults] = useState<SCostCenter[]>([])
  const [checkboxesChecked, setCheckboxesChecked] = useState(0)
  const [selectedNewCostCenters, setSelectedNewCostCenters] = useState<
    SCostCenter[]
  >([])

  const [selectedCostCentersIds, setSelectedCostCentersIds] = useState<
    string[]
  >([])

  const columns: TableProps<CostCenterTableTypes>['columns'] = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('component.cost_center.cost_center'),
      dataIndex: 'costCenter',
      key: 'costCenter',
    },
    {
      title: t('component.cost_center.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('component.cost_center.approver'),
      dataIndex: 'approver',
      key: 'approver',
    },
    {
      title: t('component.cost_center.regional'),
      dataIndex: 'regional',
      key: 'regional',
    },
  ]

  const searchCostCenters = useCallback(
    debounce(async (searchValue: string) => {
      if (searchValue.length < 3) {
        setSearchResults([])
        return
      }
      try {
        const response = await getCostCenter({ search: searchValue })
        setSearchResults(response)
      } catch (e) {
        notifyError(
          t('component.cost_center.notifications.failed_to_get_cost_center'),
        )
      }
    }, 800),
    [t],
  )

  const handleSearch = (event: any) => {
    setCostCenterName(event.target.value)
    searchCostCenters(event.target.value)
  }

  const handleCheckboxChange = (event: any, tableItem: SCostCenter) => {
    const isChecked = event.target.checked

    const updatedIds = isChecked
      ? [...selectedCostCentersIds, tableItem.id]
      : selectedCostCentersIds.filter((id) => id !== tableItem.id)

    const updatedSelectedCostCenters =
      isChecked && selectedNewCostCenters
        ? [...selectedNewCostCenters, tableItem]
        : selectedNewCostCenters?.filter(
            (costCenter) => costCenter.id !== tableItem.id,
          )

    if (updatedSelectedCostCenters) {
      setSelectedNewCostCenters(updatedSelectedCostCenters)
    }

    setSelectedCostCentersIds(updatedIds)

    setCheckboxesChecked(updatedIds.length)
  }

  const renderCostCenterTableData = () => {
    const uniqueResults = [...searchResults, ...selectedNewCostCenters].filter(
      (costCenter, index, self) =>
        index === self.findIndex((c) => c.id === costCenter.id),
    )
    return uniqueResults.map((item: SCostCenter, index: number) => ({
      key: index,
      id: (
        <Checkbox
          key={`checkbox-${item.id}`}
          checked={selectedCostCentersIds.includes(item.id)}
          onChange={(event: any) => handleCheckboxChange(event, item)}
        />
      ),
      costCenter: item?.name,
      description: item?.description,
      regional: item?.regional,
    }))
  }

  const handleFinish = () => {
    setSelectedCostCenters(selectedNewCostCenters)
    onClose()
  }

  useEffect(() => {
    searchCostCenters(selectCostCenterName)
  }, [selectCostCenterName, searchCostCenters])

  return (
    <>
      <HeaderCloseButton closeButton={closeButton} />
      <SearchCostCenterStyles data-testid="search-cost-center">
        <div className="header">{t('searchCostCenter.search')}</div>
        <div className="content">
          <div className="input_field">
            <label className="input_field_label">
              {t('searchCostCenter.find')}
            </label>
            <div className="search_bar">
              <input
                value={costCenterName}
                type="search"
                className="input"
                onChange={handleSearch}
                placeholder={t('component.cost_center.search')}
                data-testid="search-bar"
              />
              <SmilesIcon
                className={iconStyles.icon_24}
                type="search"
                color="#7C7C7C"
              />
            </div>
          </div>

          <label className="input_field_label">
            {t('searchCostCenter.selectTwo')}
          </label>

          <Table columns={columns} data={renderCostCenterTableData()} />
        </div>
      </SearchCostCenterStyles>

      <FooterStyles>
        <Button
          width="18.333vw"
          onClick={handleFinish}
          disabled={checkboxesChecked < 2}
        >
          {t('searchCostCenter.select')}
        </Button>
      </FooterStyles>
    </>
  )
}

export default SearchCostCenter
