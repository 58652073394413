import { useEffect, useState } from 'react'
import { Form, FormInstance } from 'antd'
import { Gender, createCompany, updateCompany } from 'services/outsourced'
import { notifyError, notifySuccess } from 'utils/handleRequestNotify'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

type FormProps = {
  foreign: boolean
}

type OutsourcedState = {
  form: FormInstance<FormProps>
  isLoading: boolean
}

const defaultNationalityValue = {
  id: 'da9f193e-da74-4ff4-897b-984a6a2479f9',
  name: 'Brasil',
}

export const useCompany = (onFinishCallback: Function, companyData: any) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isFormVisible, setFormVisibility] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [submittable, setSubmittable] = useState<boolean>(false)
  const [state, setState] = useState<OutsourcedState>({
    form,
    isLoading: false,
  })
  const [employeesList, setEmployeesList] = useState([])

  useEffect(() => {
    if (companyData) {
      form.setFieldsValue({
        companyName: companyData.company_name,
        document: companyData.document,
      })
    }
    if (employeesList.length === 0) return

    const formValues = form.getFieldsValue()
    const dto = buildCreateCompanyDto(formValues, employeesList)
    const isFormValid = validateFormData(dto)
    if (isFormValid.status === 'success') {
      setSubmittable(true)
      return
    }

    setSubmittable(false)
  }, [employeesList])

  const validateFormData = (formValues: any) => {
    const employeeSchema = z.object({
      nationalityId: z.string().optional(),
      isForeigner: z.boolean().refine((val) => val !== undefined),
      isConcession: z.boolean().refine((val) => val !== undefined),
      fullName: z
        .string()
        .min(3)
        .max(100)
        .refine((val) => val !== undefined),
      cpf: z.string().optional(),
      rg: z.string().optional(),
      passport: z.string().optional(),
      job: z.string().optional(),
      gender: z.nativeEnum(Gender).optional(),
      dateOfBirth: z.string(),
      phone: z.string().refine((val) => val !== undefined),
      email: z.string().email(),
    })

    const bodySchema = z.object({
      companyName: z.string(),
      document: z.string(),
      employees: z.array(employeeSchema).refine((val) => val.length > 0, {
        message: 'Employees array is required and cannot be empty',
      }),
    })
    try {
      bodySchema.parse(formValues)
      return { status: 'success' }
    } catch (error) {
      if (error instanceof z.ZodError) {
        error.errors.forEach(({ path, message }) => {
          form.setFields([
            {
              name: path,
              errors: [message],
            },
          ])
        })
      }
      return { status: 'error', error }
    }
  }

  const buildCreateCompanyDto = (formValues: any, employees: any[]): any => {
    const createCompanyDto = {
      companyName: formValues.companyName,
      document: formValues.document,
      employees: employees.map((item) => ({
        nationalityId: item?.nationality?.id ?? defaultNationalityValue.id,
        isForeigner: item.isForeigner ?? false,
        isConcession: item.isConcession ?? false,
        fullName: item.fullName,
        cpf: item.cpf,
        rg: item.rg,
        passport: item.passport,
        job: item.job,
        gender: item.gender,
        dateOfBirth: item?.dateOfBirth ?? null,
        phone: item.phone,
        email: item.email,
      })),
    }
    return createCompanyDto
  }

  const buildUpdateCompanyDto = (formValues: any): any => {
    const updateCompanyDto: any = {
      id: companyData.id,
    }
    if (formValues.companyName !== companyData.company_name) {
      updateCompanyDto.companyName = formValues.companyName
    }
    if (formValues.document !== companyData.document) {
      updateCompanyDto.document = formValues.document
    }
    return updateCompanyDto
  }

  const onValuesChange = (changedValues: unknown, allValues: any) => {
    if (companyData) {
      if (allValues.companyName !== companyData.company_name) {
        setSubmittable(true)
      }
      if (allValues.document !== companyData.document) {
        setSubmittable(true)
      }

      return
    }

    const dto = buildCreateCompanyDto(allValues, employeesList)
    const isFormValid = validateFormData(dto)
    if (isFormValid.status === 'success') {
      setSubmittable(true)
      return
    }

    setSubmittable(false)
  }

  const onRemoveEmployee = (cpf: string) => {
    const updatedItems = employeesList.filter((item: any) => item.cpf !== cpf)
    setEmployeesList(updatedItems)
  }

  const onCreateCompany = async () => {
    setIsLoading(true)
    const formValues = form.getFieldsValue()
    const dto = buildCreateCompanyDto(formValues, employeesList)
    const response = await createCompany(dto)
    if (response.status === 'error') {
      const errorDesc = response.error ?? null
      setIsLoading(false)
      notifyError(errorDesc)
      return
    }
    setIsLoading(false)
    notifySuccess()
    onFinishCallback()
  }

  const onUpdateCompany = async () => {
    setIsLoading(true)
    const formValues = form.getFieldsValue()
    const dto = buildUpdateCompanyDto(formValues)
    const response = await updateCompany(dto)
    if (response.status === 'error') {
      const errorDesc = response.error ?? null
      setIsLoading(false)
      notifyError(errorDesc)
      return
    }
    setIsLoading(false)
    notifySuccess()
    onFinishCallback()
  }

  return {
    ...state,
    form,
    isLoading,
    setIsLoading,
    submittable,
    onValuesChange,
    isFormVisible,
    setFormVisibility,
    employeesList,
    setEmployeesList,
    onRemoveEmployee,
    onCreateCompany,
    onUpdateCompany,
  }
}
