interface Translation {
  [language: string]: string
}

interface Item {
  id: string
  translations: Translation
}

interface Reason {
  id: string
  name: string
}

const traslatedReasons: Item[] = [
  {
    id: '1b881c85-978c-4626-95bf-b95afaf9ceae',
    translations: {
      en: 'Audit',
      pt: 'Auditoria',
      es: 'Auditoría',
    },
  },
  {
    id: 'cb802581-af03-4d70-90f6-785a804986b1',
    translations: {
      en: 'Monitoring FIELD - Service FIELD',
      pt: 'Acompanhamento FIELD - Atendimento FIELD',
      es: 'Seguimiento FIELD - Servicio FIELD',
    },
  },
  {
    id: 'e921f525-62bb-4997-8859-2b575555aea7',
    translations: {
      en: 'Franchisee GOLLOG - meeting',
      pt: 'Franqueado GOLLOG - reunião',
      es: 'Franquiciado GOLLOG - reunión',
    },
  },
  {
    id: '1b974504-593b-4dfd-9758-352c9cda6f0f',
    translations: {
      en: 'Change - Structural Reform (store)',
      pt: 'Mudança - Reforma Estrutural (loja)',
      es: 'Cambio - Reforma Estructural (tienda)',
    },
  },
  {
    id: '18842974-228f-4047-b3ed-978772e8a8a6',
    translations: {
      en: 'Monitoring Chartering',
      pt: 'Acompanhamento Fretamento',
      es: 'Seguimiento Fletamento',
    },
  },
  {
    id: 'a402e91f-6f6c-4975-96d5-5e4bbb25f701',
    translations: {
      en: 'Project',
      pt: 'Projeto',
      es: 'Proyecto',
    },
  },
  {
    id: '6b8beb58-370e-4e6a-9381-d91be8ce8d94',
    translations: {
      en: 'Change - Structural Reform (base)',
      pt: 'Mudança - Reforma Estrutural (base)',
      es: 'Cambio - Reforma Estructural (base)',
    },
  },
  {
    id: 'e61bbe86-2e7b-47d4-920a-fd963151e144',
    translations: {
      en: 'Monitoring PEX - PEX Audit',
      pt: 'Acompanhamento PEX - Auditoria PEX',
      es: 'Seguimiento PEX - Auditoría PEX',
    },
  },
  {
    id: '6c64a155-d708-4475-b49e-c00b7bda2e36',
    translations: {
      en: 'Cargo',
      pt: 'Cargueiro',
      es: 'Carguero',
    },
  },
  {
    id: 'c56471c6-77f8-42b6-93b5-70b532364033',
    translations: {
      en: 'Technical Reinforcement',
      pt: 'Reforço Técnico',
      es: 'Refuerzo Técnico',
    },
  },
  {
    id: '866f4818-5b39-4a0e-a30c-ab1a7df66fa5',
    translations: {
      en: 'AOG',
      pt: 'AOG',
      es: 'AOG',
    },
  },
  {
    id: '378da0a9-16bd-4440-8342-a6bd56c25366',
    translations: {
      en: 'Others',
      pt: 'Outros',
      es: 'Otros',
    },
  },
  {
    id: '7783a443-59e4-4798-8da6-de9d8a71e6de',
    translations: {
      en: 'Course - Training - Seminar',
      pt: 'Curso - Treinamento - Seminário',
      es: 'Curso - Capacitación - Seminario',
    },
  },
  {
    id: 'daeb27c2-15d2-440f-bf3c-fb8cf5ca550b',
    translations: {
      en: 'Events - Workshop',
      pt: 'Eventos -Workshop',
      es: 'Eventos - Taller',
    },
  },
  {
    id: '1fcbd88d-3092-496c-8ffb-e4f11ad73221',
    translations: {
      en: 'Operational Visit',
      pt: 'Visita Operacional',
      es: 'Visita Operacional',
    },
  },
  {
    id: 'a2af8f1c-fd7f-4cd0-b4e9-1e1db405aa75',
    translations: {
      en: 'Meeting',
      pt: 'Reunião',
      es: 'Reunión',
    },
  },
  {
    id: '4c7bf036-1a7a-424b-ac4b-b3bb2fd70980',
    translations: {
      en: 'Delivery Aircraft - Aircraft Redelivery',
      pt: 'Delivery Aeronave - Redelivery Aeronave',
      es: 'Entrega de Aeronave - Reentrega de Aeronave',
    },
  },
  {
    id: '706f3f33-a4ed-4cb2-b384-28abd8413b3d',
    translations: {
      en: 'Operational Reinforcement',
      pt: 'Reforço Operacional',
      es: 'Refuerzo Operacional',
    },
  },
  {
    id: 'a60d49a6-9028-4667-b296-f64694983bc8',
    translations: {
      en: 'Franchisee GOLLOG - visit',
      pt: 'Franqueado GOLLOG - visita',
      es: 'Franquiciado GOLLOG - visita',
    },
  },
  {
    id: '4c4c4a91-3a72-4b51-abeb-2946e1f9e751',
    translations: {
      en: 'Technical Visit',
      pt: 'Visita Técnica',
      es: 'Visita Técnica',
    },
  },
  {
    id: 'a84d92bc-ded4-426f-ba32-26037120dc8d',
    translations: {
      en: 'Postgraduate',
      pt: 'Pós-graduação',
      es: 'Posgrado',
    },
  },
  {
    id: '8f43568f-4772-4dec-a9f7-3895c7d62f42',
    translations: {
      en: 'Project Opening Base',
      pt: 'Projeto Abertura de Base',
      es: 'Proyecto Apertura de Base',
    },
  },
  {
    id: '70a2e127-81d6-4e92-bf19-711c621ac42d',
    translations: {
      en: 'Third-Party Service Provision',
      pt: 'Prestação de Serviços de Terceiro',
      es: 'Prestación de Servicios de Terceros',
    },
  },
  {
    id: 'a57e2f67-f64f-452e-9932-3f0cb0989861',
    translations: {
      en: 'Base Closure Project',
      pt: 'Projeto Fechamento de Base',
      es: 'Proyecto Cierre de Base',
    },
  },
]

const getTranslatedNameById = (
  id: string,
  traslatedReasons: Item[],
  language: string,
): string => {
  const reason: any = traslatedReasons.find((item) => item.id === id)
  return reason.translations?.[language] ?? 'N/A'
}

export const getTranslatedReasons = (
  reasons: any,
  language: string,
): Reason[] => {
  return reasons.map((item: Reason) => ({
    ...item,
    name: getTranslatedNameById(item.id, traslatedReasons, language),
  }))
}
