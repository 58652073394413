const ptHotel = {
  hotel: {
    moreOptionsButton: 'Conhecer mais opções',
    room: 'Quarto',
    filterSection: {
      header: 'Escolha suas preferências',
      hotelPriceGroupboxHeader: 'Faixa de preço',
      hotelTypesGroupboxHeader: 'Tipos',
      hotelAmenitiesGroupboxHeader: 'Acomodações',
      rangeWithInputs: {
        form: {
          minValue: {
            label: 'Mínimo',
          },
          maxValue: {
            label: 'Máximo',
          },
        },
      },
      hotelTypes: {
        peopleWd: 'Adaptado para PCDs',
        pets: 'Permite animais de estimação',
        kids: 'Permite crianças',
        babies: 'Permite bebês',
      },
      hotelAmenities: {
        doubleBed: 'Cama de casal',
        singleBed: 'Cama de solteiro',
        noPreference: 'Sem preferência',
      },
      lookingQuote: 'Buscando uma cotação de passagem?',
      getQuote: 'Faça um orçamento!',
      flightWantedNotFound: 'Não achou o voo que queria?',
      askTheAgency: 'Pedir à agência',
    },
    renderHotels: {
      emptyHotelDescription: 'Sem opções para a pesquisa',
    },
    hotelInfoBar: {
      changeSearch: 'Alterar busca',
    },
    favorites: 'Favoritos',
    daily_rate: 'Valor da diária',
    refundable_fare: 'Tarifa reembolsável',
    non_refundable_fare: 'Tarifa não reembolsável',
    person: 'pessoa',
    see_other_rooms: 'Ver outros quartos',
    hotel_details: 'Detalhes do hotel',
    add: 'Adicionar',
    only_exib_acomodations_with_food:
      'Mostrar apenas acomodações com alimentação inclusa',
    breakfast_alimentation: 'Café da manhã / Alimentação',
    see_more_options: 'Conhecer mais opções',
    choose_your_room: 'Escolha seu quarto',
    acomodations_in: 'Acomodações em',
    recommended: 'Recomendados',
    of: 'de',
    options: 'opções',
  },
}

export default ptHotel
