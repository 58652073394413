type FormatSelectOptionsParams = {
  data?: any[]
  label: string
  value: string
}

type FormatSelectOptionsReturn = {
  label: string
  value: string
}[]

export const formatSelectOptions = ({
  data,
  label,
  value,
}: FormatSelectOptionsParams): FormatSelectOptionsReturn | undefined => {
  if (data && data.length > 0) {
    return data.map((item: any) => {
      return { label: item[label], value: item[value] }
    })
  }
  return undefined
}
