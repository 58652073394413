const ptReservation = {
  reservationDetails: {
    traveler: 'Viajante',
    reservationDate: 'Data da solicitação',
    approvalDate: 'Data da aprovação',
    status: 'Status',
    costCenter: 'Centro de custos',
    reservationNumber: 'Número da reserva',
    locator: 'Localizador',
  },
  reservationCompleted: {
    title: 'Processamento das reservas concluído',
    text: 'Agora é só aguardar. Sua solicitação foi enviada ao seu gestor e você receberá uma confirmação do seu pedido por e-mail.',
  },
  viewReservation: {
    title: 'Visualizar reserva completa',
    resumeTitle: 'Visualizar resumo da reserva',
  },
  completeRequest: {
    title: 'Concluir solicitação',
    question: 'Deseja confirmar sua solicitação?',
    text: 'Ao clicar em “Sim” sua solicitação será enviada para o seu gestor imediato.',
    yes: 'Sim',
  },
  flightRequest: {
    title: 'Aéreo',
    oneWayTicket: 'Passagem de ida',
    returnTicket: 'Passagem de volta',
    stopover: 'parada(s)',
  },
  hotelRequest: {
    seeOnMap: 'Ver no mapa',
  },
  totalRequestValue: {
    totalValue: 'Valor total',
  },
  footerButton: {
    return: 'Voltar',
    returnToHome: 'Voltar para home',
    conclude: 'Concluir',
  },
  searchCostCenter: {
    search: 'Buscar centro de custos',
    find: 'Encontre seu centro de custos',
    select: 'Selecionar centro de custos',
    selectTwo: 'Selecione dois centros de custo para continuar',
  },
  requestStatus: {
    resume: 'Resumo da Solicitação',
    status: 'Status da Solicitação',
    complete: 'Reserva Completa',
  },
  debit_allocation: {
    others: 'Outros',
    required_field: 'Campo obrigatório',
    cost_center: 'Centro de custos',
    regional_or_cost_center: 'Regional ou centro de custos',
    approver: 'Aprovador',
    trip_reason: 'Motivo da viagem',
    reason: 'Motivo',
    reason_justification: 'Justificação do motivo',
    antecedence_justification: 'Justificação da antecedência da viagem!',
    split_cost_center: 'Dividir centro de custos',
    complementary_info: 'Informações complementares',
    do_you_need_to_provide_more_info:
      'Precisa fornecer mais alguma informação?',
    error: {
      reasons: 'Falha ao obter os motivos.',
      cost_center: 'Falha ao obter os centros de custo.',
      min_characters: 'O campo deve possuir ao menos 10 caracteres.',
    },
    shared_with: 'Dividindo com:',
    gol_employee: 'Colaborador Gol',
  },
}

export default ptReservation
