import { API } from 'services/config'

const point = '/notifications/v1/create-subscription'

const createUserSubscription = async (
  auth: string,
  p256dh: string,
  subscriptionEndpoint: string,
) => {
  try {
    const response = await API.post(point, {
      auth,
      p256dh,
      subscriptionEndpoint,
    })
    return response.data
  } catch (error) {
    return { error: 'Error on creating user subscription' }
  }
}

export { createUserSubscription }
