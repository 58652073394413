import React, { useEffect, useState } from 'react'
import Input from 'components/Input'
import Range from 'components/inputRange'

export type RangeWithInputsProps = {
  rangeValues: number[]
  updateFilter?: (values: number[]) => void
  minValueLabel: string
  maxValueLabel: string
  min?: number
  max?: number
  step?: number
}

const RangeWithInputs: React.FC<RangeWithInputsProps> = ({
  rangeValues,
  updateFilter,
  minValueLabel,
  maxValueLabel,
  min,
  max,
  step,
}) => {
  const [minValue, setMinValue] = useState(rangeValues[0])
  const [maxValue, setMaxValue] = useState(rangeValues[1])

  useEffect(() => {
    setMinValue(rangeValues[0])
    setMaxValue(rangeValues[1])
  }, [rangeValues])

  const formatCurrency = (value: number) => {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  const handleInputChange = (index: number, value: string) => {
    const numericValue = Number(value.replace(/\D/g, '')) / 100
    const newRangeValues = [...rangeValues]
    newRangeValues[index] = numericValue
    if (updateFilter) {
      updateFilter(newRangeValues)
    }
  }

  return (
    <>
      <div data-testid="smiles-range-with-inputs">
        <Input
          type="text"
          value={formatCurrency(minValue)}
          onChange={(e) => handleInputChange(0, e.target.value)}
          label={minValueLabel}
          prefix={<span>R$</span>}
        />

        <Input
          type="text"
          value={formatCurrency(maxValue)}
          onChange={(e) => handleInputChange(1, e.target.value)}
          label={maxValueLabel}
          prefix={<span>R$</span>}
        />
      </div>
      <Range
        rangeValues={rangeValues}
        onChange={updateFilter}
        min={min}
        max={max}
        step={step}
      />
    </>
  )
}

export default RangeWithInputs
