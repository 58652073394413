export default `
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2936_25662" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
    <rect y="0.5" width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_2936_25662)">
    <path d="M10 18.5L4 12.5L10 6.5L11.4 7.95L7.85 11.5H20V13.5H7.85L11.4 17.05L10 18.5Z" fill="#1A1A1A"/>
    </g>
    </svg>
`
