import { AxiosResponse } from 'axios'
import { API } from 'services/config'
import { SUserPermission } from 'shared/interfaces/SUserPermission'

interface PermissionApiResponse<T> {
  data?: T
  error?: string
}

const point = '/permissions/v1/permission'
const groupPoint = `/permissions/v1//group/permission`

const getAllSystemPermissions = async () => {
  try {
    const response = await API.get(point)
    return response.data
  } catch (error) {
    return { error: 'Error on get all system permissions' }
  }
}

const getAllUserPermissions = async () => {
  try {
    const response = await API.get(`${point}/user/all`)
    return response.data
  } catch (error) {
    return { error: 'Error on get all user permissions' }
  }
}

const getPermissionsByGroupId = async (groupId: string | undefined) => {
  try {
    const response = await API.get(`${groupPoint}/list?groupId=${groupId}`)
    return response.data
  } catch (error) {
    return { error: 'Error on get group permissions' }
  }
}

const getUserPermissions = async (
  email: string,
): Promise<PermissionApiResponse<SUserPermission[]>> => {
  try {
    const response: AxiosResponse<{ data: SUserPermission[]; count: number }> =
      await API.get(`${point}/user?email=${email}`)
    return { data: response.data?.data }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message ||
      error.response?.data?.details ||
      error.message ||
      'Unexpected error'

    return { error: errorMessage }
  }
}

const patchUserPermissions = async (
  email: string,
  permissions: { id: string; type: string }[],
): Promise<PermissionApiResponse<SUserPermission[]>> => {
  try {
    const response: AxiosResponse<{ data: SUserPermission[]; count: number }> =
      await API.patch(`${point}/user?email=${email}`, { permissions })
    return { data: response.data?.data }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message ||
      error.response?.data?.details ||
      error.message ||
      'Unexpected error'

    return { error: errorMessage }
  }
}

export {
  getAllSystemPermissions,
  getAllUserPermissions,
  getPermissionsByGroupId,
  getUserPermissions,
  patchUserPermissions,
}
