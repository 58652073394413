import React from 'react'
import { useTranslation } from 'react-i18next'

export type RenderModifyButtonsProps = {
  path: string
  navigate: any
  removeFunc: () => void
}

const RenderModifyButtons: React.FC<RenderModifyButtonsProps> = ({
  navigate,
  path,
  removeFunc,
}) => {
  const { t } = useTranslation()

  return (
    <div data-testid="summary-modify-buttons">
      <button
        className="global-button-reset"
        data-testid="b1"
        onClick={() => navigate(path)}
      >
        {t('component.modify_reserve.modify')}
      </button>
      <button
        className="global-button-reset"
        data-testid="b2"
        onClick={() => removeFunc()}
      >
        {t('component.modify_reserve.remove')}
      </button>
    </div>
  )
}

export default RenderModifyButtons
