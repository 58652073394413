import React, { ReactNode } from 'react'
import { DrawerStyles } from './styles'
import { SmilesIcon } from 'components/icons'

export type DrawerProps = {
  open: boolean
  onClose: () => void
  width?: string | number
  height?: string | number
  children: ReactNode
  placement?: 'right' | 'top' | 'bottom'
  closable?: boolean
  destroyOnClose?: boolean
}

const Drawer: React.FC<DrawerProps> = ({
  open,
  width,
  children,
  placement = 'right',
  closable = true,
  height,
  onClose,
  destroyOnClose,
}) => (
  <DrawerStyles
    onClose={onClose}
    open={open}
    width={width}
    placement={placement}
    closable={closable}
    height={height}
    data-testid="smiles-drawer"
    closeIcon={<SmilesIcon type="cancel" color="#999" />}
    destroyOnClose={destroyOnClose}
  >
    {children}
  </DrawerStyles>
)

export default Drawer
