import styled from 'styled-components'
import InputMask from 'react-input-mask'

export const MyProfileContainer = styled.div`
  padding: 1.5rem;
  border: 0.0625rem solid #d9d9d9;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 52.625rem;
  color: #333;
  font-weight: 600;

  @media (max-width: 1024px) {
    width: 100%;
  }

  .contact-header {
    display: flex;
    justify-content: space-between;

    @media (max-width: 480px) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  .button-group {
    display: flex;
    gap: 0.5rem;

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  .contato-wrapper {
    display: flex;
    gap: 1.5rem;

    @media (max-width: 480px) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  .view-mode {
    width: 100%;
  }

  .user-info-box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .user-info-label {
    font-size: 1.25rem;
    line-height: 2rem;

    @media (max-width: 480px) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .user-info {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: #1a1a1a;

    @media (max-width: 480px) {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
`

export const PhoneInputWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 1rem;
  }
`

export const StyledInputMask = styled(InputMask)`
  width: 21.875rem;
  height: 4rem;
  border: 0.125rem solid #999999;
  border-radius: 0.5rem;
  padding: 1.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: #595959;
  outline: none;

  @media (max-width: 820px) {
    width: 100%;
    height: 3rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`

export const EditButton = styled.button`
  background-color: var(--primary-color);
  color: #000;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-weight: 700;
  border: none;
  padding: 0.59375rem 1.5625rem;
  width: 5.625rem;
  height: 2.5rem;
  border-radius: 1rem;
  cursor: pointer;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #ff7f37;
    box-shadow: 0px 0px 0.75rem -0.1875rem #ff7f37;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`

export const SaveButton = styled.button`
  background-color: var(--primary-color);
  color: #000;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-weight: 700;
  border: none;
  padding: 0.59375rem 1.5rem;
  width: 5.625rem;
  height: 2.5rem;
  border-radius: 1rem;
  cursor: pointer;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #ff7f37;
    box-shadow: 0px 0px 0.75rem -0.1875rem #ff7f37;
  }
  &:disabled {
    background-color: #f2f2f2;
    color: #595959;
    cursor: not-allowed;
    box-shadow: none;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`

export const CancelButton = styled.button`
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  border: 0.0625rem solid #000;
  padding: 0.59375rem 1rem;
  height: 2.5rem;
  border-radius: 1rem;
  cursor: pointer;
  &:hover {
    transition: all 0.2s ease-in-out;
    border-color: var(--primary-color);
    box-shadow: 0px 0px 0.75rem -0.1875rem #ff7f37;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`
