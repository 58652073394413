import React from 'react'
import { SmilesIcon } from 'components/icons'
import { Color } from 'styles/global'
import { SVFlightItemStyles } from './styles'
import { FlightTravelRequest } from 'shared/interfaces/STravelRequestDetails'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

type SVFlightItemProps = {
  flightTravelRequest: FlightTravelRequest & {
    originCity: string
    destinationCity: string
  }
  route: string
  width?: string
}

const SVFlightItem: React.FC<SVFlightItemProps> = ({
  flightTravelRequest,
  route,
  width,
}) => {
  const { t } = useTranslation()

  return (
    <SVFlightItemStyles data-testid="sv-flight-item" width={width}>
      <div className="header">
        <p className="route">{route}</p>
        <p className="date">
          {dayjs(flightTravelRequest.departure_time).format('ddd. DD/MM/YY')}
        </p>
        <div className="bagage">
          <div className="bagage-item">
            <SmilesIcon type="backpack" size={16} color={Color.PRIMARY} />
            <p>1</p>
          </div>
          <div className="bagage-item">
            <SmilesIcon type="work" size={16} color={Color.PRIMARY} />
            <p>1</p>
          </div>
          <div className="bagage-item">
            <SmilesIcon type="luggage" size={16} color={Color.PRIMARY} />
            <p>1</p>
          </div>
        </div>
      </div>
      <div className="flight">
        <SmilesIcon type="gol-full" size={48} />
        <div className="route">
          <div>
            <p className="iata-and-hour">
              {flightTravelRequest.origin}{' '}
              <b>
                {dayjs(flightTravelRequest.departure_time).format('HH[h]mm')}
              </b>
            </p>
            <p className="city">{flightTravelRequest.originCity}</p>
          </div>
          <SmilesIcon type="plane-right" color={Color.PRIMARY} size={18} />
          <div>
            <p className="iata-and-hour">
              {flightTravelRequest.destination}{' '}
              <b> {dayjs(flightTravelRequest.return_date).format('HH[h]mm')}</b>
            </p>
            <p className="city">{flightTravelRequest.destinationCity}</p>
          </div>
        </div>

        <div className="stops-and-hour">
          <p className="stops">
            {flightTravelRequest.stops_count === 0 &&
              t('travel_request.flight.direct')}
            {flightTravelRequest.stops_count === 1 &&
              `1 ${t('travel_request.flight.stop')}`}
            {flightTravelRequest.stops_count > 1 &&
              `${flightTravelRequest.stops_count} ${t(
                'travel_request.flight.stops',
              )}`}
          </p>
          <p className="hour">
            {flightTravelRequest.duration}
            {t('travel_request.flight.hours')}
          </p>
        </div>
      </div>
    </SVFlightItemStyles>
  )
}

export default SVFlightItem
