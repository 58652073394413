import styled from 'styled-components'

export const SVCancelStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: end;

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: end;

    .cancel-buttons {
      display: flex;
      justify-content: end;
      gap: 16px;
    }
  }

  button {
    border-radius: 16px;
  }
`
