import React, { useState } from 'react'
import { SnallTravelCardStyles } from './styles'
import StatusBadge from 'components/statusBadge'
import { SmilesIcon } from 'components/icons'
import Drawer from 'components/drawer'
import CanceledTravelModal from '../canceledTravelModal'
import dayjs from 'dayjs'
import { StatusEnum } from 'constants/statusEnum'
import { SMyTravel } from 'shared/interfaces/SMyTravel'
import ViewFullReservation from 'components/ViewFullReservation'
import { useTranslation } from 'react-i18next'

export type SmallTravelCardProps = {
  canceled?: boolean
  travelData: SMyTravel
}

const SmallTravelCard: React.FC<SmallTravelCardProps> = ({
  canceled = false,
  travelData,
}) => {
  const { t } = useTranslation()
  const [openDrawer, setOpenDrawer] = useState(false)

  const open = () => {
    setOpenDrawer(true)
  }

  const close = () => {
    setOpenDrawer(false)
  }

  return (
    <SnallTravelCardStyles data-testid="smiles-small-travel-card">
      <div className="data">
        <div className="city-and-date">
          <p className="city">{travelData.destination}</p>
          <p className="date">
            {dayjs(travelData.created_at).format('DD/MM/YYYY')}
          </p>
        </div>

        {canceled ? (
          <StatusBadge
            status={StatusEnum.CANCELED}
            text={t('my_travels.travels.cancellation_requested_by_employee')}
          />
        ) : (
          <p className="sv-number">{travelData.reservation_number}</p>
        )}
      </div>
      <div className="button-container">
        <button
          className="global-button-reset rounded-button"
          data-testid="open-drawer-button"
          onClick={open}
        >
          <SmilesIcon type="arrow-right" />
        </button>
      </div>

      <Drawer
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        width={600}
      >
        {canceled ? (
          <CanceledTravelModal close={close} />
        ) : (
          <ViewFullReservation
            travelData={travelData}
            onClose={() => setOpenDrawer(false)}
          />
        )}
      </Drawer>
    </SnallTravelCardStyles>
  )
}

export default SmallTravelCard
