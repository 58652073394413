import styled from 'styled-components'
import { Color, Size } from 'styles/global'

export const Subheader = styled.div``

export const Divider = styled.div`
  border: 1px solid #d9d9d9;
`

export const Details = styled.div`
  margin-top: 32px;
  display: flex;
  padding: 16px 0 16px 0;
`

export const Data = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 8px 0 8px 0;
`

export const Info = styled.div`
  flex-grow: 1;
`

export const Label = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const Value = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
`

export const Action = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: end;
  align-items: center;
`

export const ActionButton = styled.button`
  display: flex;
  height: 44px;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #737373;
  border-radius: 16px;
  padding: 8px 16px 8px 18px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  margin-left: 12px;
  svg {
    margin-top: 2px;
  }
`

export const CardList = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Card = styled.div`
  border: 1px solid ${Color.LIGHT_BORDER};
  border-radius: ${Size.BORDER_RADIUS};
  padding: 12px;
`

export const RowContainer = styled.div`
  padding: 8px;
  height: 72px;
  display: flex;
  gap: 32px;
  align-items: center;

  > div:last-child {
    flex-grow: 2;
    display: flex;
    justify-content: end;
  }
`

export const Column = styled.div<{ active: boolean }>`
  p {
    font-size: 16px;
    font-color: ${({ active }) => (active ? '#595959' : '#999999')};
  }
`

export const CardAction = styled.div`
  display: flex;
  gap: 16px;
  cursor: pointer;
`
