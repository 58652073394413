import React from 'react'
import { useTranslation } from 'react-i18next'
import { RequestSummaryStyles } from '../RequestSummary/styles'
import { SmilesIcon } from 'components/icons'
import BookingDetails from 'components/RequestSummary/components/BookingDetails'
import FlightRequestSummary from 'components/RequestSummary/components/FlightRequestSummary'
import TotalRequestValue from 'components/RequestSummary/components/TotalRequestValue'
import { formatToReal } from 'utils/formatToReal/formateToReal'
import DrawerBottomNavigation from 'components/drawerBottomNavigation'
import Button from 'components/button'
import { SMyTravel } from 'shared/interfaces/SMyTravel'

export type ViewFullReservationProps = {
  onClose?: any
  travelData: SMyTravel
}

const ViewFullReservation: React.FC<ViewFullReservationProps> = ({
  onClose,
  travelData,
}) => {
  const { t } = useTranslation()

  const flights = travelData.flights

  const totalFlightValue = flights.reduce(
    (total, flight) => total + flight.amount,
    0,
  )

  const total = totalFlightValue

  return (
    <>
      <RequestSummaryStyles data-testid="smiles-view-full-reservation">
        <div className="title">{t('requestStatus.resume')}</div>
        <BookingDetails travelData={travelData} />
        <div className="title_container">
          <SmilesIcon type="travel" />
          <div className="title">{t('requestStatus.complete')}</div>
        </div>
        {flights && (
          <FlightRequestSummary
            flights={flights}
            totalFlightValue={totalFlightValue}
          />
        )}
        <TotalRequestValue currency={'R$'} total={formatToReal(total)} />
      </RequestSummaryStyles>

      <DrawerBottomNavigation>
        <Button width="250px" onClick={onClose}>
          {t('footerButton.return')}
        </Button>
      </DrawerBottomNavigation>
    </>
  )
}

export default ViewFullReservation
