import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCostCenter } from 'services/debtAllocation'
import { SCostCenter } from 'shared/interfaces/SCostCenter'
import { debounce } from 'utils/debounce'
import { notifyError } from 'utils/handleRequestNotify'

export const useCostCenters = () => {
  const { t } = useTranslation()
  const [costCentersList, setCostCentersList] = useState<SCostCenter[]>([])
  const [loadingCostCenters, setLoadingCostCenters] = useState(false)

  const searchCostCenters = useCallback(
    debounce(async (searchValue: string) => {
      if (searchValue.length < 3) return
      setLoadingCostCenters(true)
      try {
        const response = await getCostCenter({ search: searchValue })
        setCostCentersList(response)
      } catch (error) {
        notifyError(t('debit_allocation.error.const_center'))
      } finally {
        setLoadingCostCenters(false)
      }
    }, 800),
    [t],
  )

  const memoizedSetCostCentersList = useCallback(setCostCentersList, [])

  return {
    costCentersList,
    loadingCostCenters,
    handleSearch: searchCostCenters,
    setCostCentersList: memoizedSetCostCentersList,
  }
}
