import styled from 'styled-components'

export const GroupScreenContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .page-title {
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px;
  }

  .search-and-table {
    border-radius: 8px;
    padding: 0px 16px 32px 16px;
    background: var(--Neutral-Neutral-100, #fff);
    display: flex;
    flex-direction: column;
    box-shadow:
      0px 0px 24px 0px rgba(0, 0, 0, 0.08),
      0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    gap: 24px;
  }

  .trash-icon {
    cursor: pointer;
    > svg {
      &:hover {
        fill: #ff1f1f;
      }
    }
  }
`

export const SearchBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
