import React, { useEffect, useState } from 'react'
import { SelectLanguageContainer } from './styles'
import { SmilesIcon } from 'components/icons'
import i18n from 'configs/i18n'

export type SelectLanguageTypes = {
  onClick?: () => void
}

const SelectLanguage: React.FC<SelectLanguageTypes> = ({ onClick }) => {
  const [languageFlag, setLanguageFlag] = useState<'brazil' | 'usa' | 'spain'>(
    'brazil',
  )

  useEffect(() => {
    switch (i18n.language) {
      case 'pt':
        setLanguageFlag('brazil')
        break
      case 'en':
        setLanguageFlag('usa')
        break
      case 'es':
        setLanguageFlag('spain')
        break
    }
  }, [i18n.language])

  return (
    <SelectLanguageContainer
      onClick={onClick}
      data-testid="smiles-select-language"
    >
      <div>
        <SmilesIcon type={languageFlag} />
      </div>
      <div>
        <SmilesIcon type="dropdown-arrow" size={10} />
      </div>
    </SelectLanguageContainer>
  )
}

export default SelectLanguage
