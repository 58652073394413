import styled from 'styled-components'

export const BottomNavigationStyled = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  padding: 16px 156px;
  justify-content: space-between;
  align-items: center;
  background: var(--Neutral-Neutral-100, #fff);
  box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.16);
  z-index: 99;
  > p {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
  }

  @media screen and (max-width: 1270px) {
    padding: 16px 24px;

    p {
      font-size: 18px;
    }
  }
`
