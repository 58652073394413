import { useCallback, useState } from 'react'
import { getCompanies } from 'services/outsourced'
import { debounce } from 'utils/debounce'
import { notifyError } from 'utils/handleRequestNotify'

export const useCompaniesList = () => {
  const [isCompaniesLoading, setIsCompaniesLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [paginationCompanies, setPaginationCompanies] = useState({
    page: 1,
    limit: 10,
    total: 0,
  })

  const onCompaniesPageChange = (page: number) => {
    setPaginationCompanies((prevState: any) => ({ ...prevState, page }))
  }

  const fetchCompanies = useCallback(
    debounce(
      async (search: string | undefined, page: number, limit: number) => {
        if (search && search?.length < 3) {
          setCompanies([])
          return
        }
        setIsCompaniesLoading(true)
        try {
          const response = await getCompanies(search, page, limit)
          setPaginationCompanies((prevState: any) => ({
            ...prevState,
            total: response.pagination.totalItems,
          }))
          setCompanies(response.data)
        } catch (error) {
          notifyError()
        } finally {
          setIsCompaniesLoading(false)
        }
      },
      500,
    ),
    [],
  )

  return {
    companies,
    setCompanies,
    fetchCompanies,
    paginationCompanies,
    onCompaniesPageChange,
    isCompaniesLoading,
  }
}
