export const ACTION = {
  permissions: {
    listAll: '/permissions/v1/permission#GET',
    updateGroupPermissions: '/permissions/v1/group/permission/{id}#PATCH',
    listGroupPermissions: '/permissions/v1/group/permission/list#GET',
    listUserGroups: '/permissions/v1/group/user#GET',
    listGroupUsers: '/permissions/v1/group/user/{id}#GET',
    removeUserFromGroup: '/permissions/v1/group/user/{id}#DELETE',
    listUserPermissions: '/permissions/v1/permission/user#GET',
    updateUserPermissions: '/permissions/v1/permission/user#PATCH',
    listGroups: '/permissions/v1/group#GET',
    getGroupById: '/permissions/v1/group/{id}#GET',
    createGroup: '/permissions/v1/group#POST',
    updateGroup: '/permissions/v1/group/{id}#PUT',
    deleteGroup: '/permissions/v1/group/{id}#DELETE',
  },
  users: {
    listAllUsers: '/users/v1/user#GET',
    getUserById: '/users/v1/user/{id}#GET',
  },
  travelRequest: {
    create: '/travel-request/v1/travel-request#POST',
    update: '/travel-request/v1/travel-request#PUT',
    getById: '/travel-request/v1/travel-request/{id}#GET',
    listAll: '/travel-request/v1/travel-request#GET',
    listUserRequests: '/travel-request/v1/travel-request/user#GET',
    listLocations: '/travel-request/v1/location#GET',
    listFlights: '/travel-request/v1/flight#GET',
    addFlight: '/travel-request/v1/flight#POST',
    removeFlight: '/travel-request/v1/flight/{id}#DELETE',
    listHotels: '/travel-request/v1/hotel#GET',
    addHotel: '/travel-request/v1/hotel#POST',
    getHotelDetails: '/travel-request/v1/hotel/detail/{keyDetail}#GET',
    removeHotel: '/travel-request/v1/hotel/{id}#DELETE',
    listStatus: '/travel-request/v1/status#GET',
    closeRequest: '/close-travel-request/v1/close#POST',
  },
  debitAllocation: {
    addAllocation: '/debit-allocation/v1/debit-allocation#POST',
    listCostCenters: '/debit-allocation/v1/cost-center#GET',
    listTravelReasons: '/debit-allocation/v1/travel-reason#GET',
    listEarlyJustifications: '/debit-allocation/v1/early-justification#GET',
  },
  approval: {
    getRequestDetails: '/approval/v1/travel-request/{id}#GET',
    denyRequest: '/approval/v1/travel-request/deny#POST',
    approveRequest: '/approval/v1/travel-request/approve#POST',
    cancelRequest: '/approval/v1/travel-request/cancel#PUT',
  },
}
