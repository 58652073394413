import React from 'react'
import { SVSummaryStyles } from './styles'
import { Col, Row } from 'antd'
import SVSummaryItem from '../svSummaryItem'
import { TravelRequest } from 'shared/interfaces/STravelRequestDetails'
import dayjs from 'dayjs'
import { formatToReal } from 'utils/formatToReal/formateToReal'
import { useTranslation } from 'react-i18next'

type SVSummaryProps = {
  travelRequest: TravelRequest
}

const SVSummary: React.FC<SVSummaryProps> = ({ travelRequest }) => {
  const { t } = useTranslation()

  return (
    <SVSummaryStyles data-testid="sv-summary">
      <Row>
        <Col span={6}>
          <SVSummaryItem
            label={t('travel_request.summary.requester')}
            value={travelRequest.consultant.name}
            icon="person"
          />
        </Col>
        <Col span={6}>
          <SVSummaryItem
            label={t('travel_request.summary.traveler')}
            value={travelRequest.requester.name}
            icon="person"
          />
        </Col>
        <Col span={6}>
          <SVSummaryItem
            label={t('travel_request.summary.origin')}
            value={travelRequest.origin}
            icon="airplaneUp"
          />
        </Col>
        <Col span={6}>
          <SVSummaryItem
            label={t('travel_request.summary.destination')}
            value={travelRequest.destination}
            icon="airplaneUp"
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <SVSummaryItem
            label={t('travel_request.summary.request_date')}
            value={dayjs(travelRequest.created_at).format('DD/MM/YYYY')}
            icon="calendar"
          />
        </Col>
        <Col span={6}>
          <SVSummaryItem
            label={t('travel_request.summary.travel_date')}
            value={dayjs(travelRequest.date).utc().format('DD/MM/YYYY')}
            icon="calendar"
          />
        </Col>
        <Col span={6}>
          <SVSummaryItem
            label={t('travel_request.summary.consultant')}
            value={travelRequest.consultant.name}
            icon="person"
          />
        </Col>
        <Col span={6}>
          <SVSummaryItem
            label={t('travel_request.summary.travel_cost')}
            value={formatToReal(travelRequest.amount)}
            icon="cancel"
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <SVSummaryItem
            label={t('travel_request.summary.advance')}
            value={t('travel_request.summary.within_politics')}
            icon="calendar"
          />
        </Col>
      </Row>
    </SVSummaryStyles>
  )
}

export default SVSummary
