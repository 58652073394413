import React from 'react'
import { useTranslation } from 'react-i18next'
import { SmilesIcon } from 'components/icons'
import { ViewBookingStyles, ViewBookingLinkStyles } from './styles'

export type ViewBookingProps = {
  onClick?: any
}

const ViewBooking: React.FC<ViewBookingProps> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <ViewBookingLinkStyles onClick={onClick} data-testid="smiles-view-booking">
      <ViewBookingStyles>
        <div className="content">
          <SmilesIcon type="travel" size={22} />
          {t('viewReservation.title')}
        </div>
      </ViewBookingStyles>
    </ViewBookingLinkStyles>
  )
}

export default ViewBooking
