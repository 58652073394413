import React, { ReactNode } from 'react'
import { HeaderStyled } from './styles'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export type SectionHeaderTypes = {
  title: string
  children?: ReactNode
  redirectPath?: string
}

const SectionHeader: React.FC<SectionHeaderTypes> = ({
  title,
  children,
  redirectPath = '',
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <HeaderStyled data-testid="smiles-section-header">
      <p>{title}</p>
      {children}
      <button
        className="global-button-reset"
        onClick={() => navigate(redirectPath)}
      >
        {t('component.header.see_all')}
      </button>
    </HeaderStyled>
  )
}

export default SectionHeader
