import React from 'react'

import { useTranslation } from 'react-i18next'
import { FlightRequestSummaryStyles } from './styles'
import { SmilesIcon } from 'components/icons'
import RequestCost from 'components/RequestSummary/components/RequestCost'
import { formatToReal } from 'utils/formatToReal/formateToReal'
import { BoxLabel } from 'components/summaryModalContent/styles'
import { SMyTravelFlight } from 'shared/interfaces/SMyTravel'
import RenderFlightsDefault from 'components/renderFlightsDefault'

type FlightRequestSummaryProps = {
  flights: SMyTravelFlight[]
  totalFlightValue: number
}

const FlightRequestSummary: React.FC<FlightRequestSummaryProps> = ({
  flights,
  totalFlightValue,
}) => {
  const { t } = useTranslation()

  return (
    <FlightRequestSummaryStyles data-testid="smiles-flight-request-summary">
      <BoxLabel>
        <span>
          <SmilesIcon type="airplanes" size={24} />
          <p>{t('component.request_summary.flight.air')}</p>
        </span>
      </BoxLabel>
      <RenderFlightsDefault flightData={flights} />

      <RequestCost
        unitaryText={t('component.request_summary.flight.fare_per_segment')}
        unitaryValue={formatToReal(totalFlightValue / flights.length)}
        totalText={t('component.request_summary.total')}
        totalValue={formatToReal(totalFlightValue)}
      />
    </FlightRequestSummaryStyles>
  )
}

export default FlightRequestSummary
