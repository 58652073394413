import styled from 'styled-components'

export const HotelFiltersSectionContainer = styled.section`
  width: 475px;
  border-radius: 16px;
  border: 2px solid #d9d9d9;
  padding: 24px;
  height: fit-content;

  > form {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .filters-container-header {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
  }
  .container-with-dividers {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`

export const HotelFiltersSectionDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 12px 0;
  background-color: #999;
`

export const HotelFiltersCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  > p {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.08px;
  }
`

export const HotelFiltersButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  > p {
    padding: 24px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.08px;
  }
`

export const HotelFiltersRangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  > p {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.08px;
  }
  > div {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
`

export const SwitchSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: column;
    span {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      font-size: 11px;
      color: #595959;
    }
  }
`
