export default `
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.2539 14.7549H15.4639L15.1839 14.4849C16.1639 13.3449 16.7539 11.8649 16.7539 10.2549C16.7539 
6.66488 13.8439 3.75488 10.2539 3.75488C6.66391 3.75488 3.75391 6.66488 3.75391 10.2549C3.75391 13.8449 
6.66391 16.7549 10.2539 16.7549C11.8639 16.7549 13.3439 16.1649 14.4839 15.1849L14.7539 15.4649V16.2549L19.7539 
21.2449L21.2439 19.7549L16.2539 14.7549ZM10.2539 14.7549C7.76391 14.7549 5.75391 12.7449 5.75391 10.2549C5.75391 
7.76488 7.76391 5.75488 10.2539 5.75488C12.7439 5.75488 14.7539 7.76488 14.7539 10.2549C14.7539 12.7449 12.7439 
14.7549 10.2539 14.7549Z" fill="#7C7C7C"/>
</svg>
`
