export const STATUS = {
  OPENED: 'Em aberto',
  WAITING_FOR_APPROVAL: 'Aguardando aprovação',
  CONFIRMED: 'Confirmado',
  APPROVED: 'Aprovado',
  CANCELED: 'Cancelado',
  REJECTED: 'Rejeitado',
}

export enum StatusEnum {
  OPENED = 'OPENED',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  CONFIRMED = 'CONFIRMED',
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
}
