import { createGlobalStyle } from 'styled-components'
import 'typeface-nunito'

export const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: #ff7020;
    --primary-hover: #DD621D;
    --secondary-color: #2ecc71;
    --text-color: #333;
  }
  * {
    font-family: 'Nunito', sans-serif;
  }
`
export enum Color {
  PRIMARY = '#ff7020',
  GRAY_1 = '#999999',
  INACTIVE_INPUT = '#bebebe',
  ACTIVE_INPUT = '#4c4c4c',
  LIGHT_BORDER = '#dadada',
  NORMAL_BORDER = '#afafaf',
  LIGHT_YELLOW = '#fff6e0',
  NORMAL_YELLOW = '#956a05',
  LIGHT_GREEN = '#e6f1eb',
  NORMAL_GREEN = '#5a7f4e',
  LIGHT_RED = '#fff2f5',
  NORMAL_RED = '#a9082f',
}

export enum Size {
  INPUT_HEIGHT = '50px',
  BORDER_RADIUS = '15px',
  LIGHT_BORDER = '1px',
  NORMAL_BORDER = '2px',
}

export enum ScreenSize {
  LARGE = 'min-width: 1000px',
  MEDIUM = 1000,
  SMALL = '',
}

export enum MediaQuery {
  LARGE = 'min-width: 1000px',
  MEDIUM = `max-width: 1000px`,
  SMALL = '',
}
