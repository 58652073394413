import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
`

export const ContainerSearch = styled.div`
  background: white;
  width: 100%;
  padding: 40px;
  box-shadow:
    0px 0px 24px 0px rgba(0, 0, 0, 0.08),
    0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 16px 16px;
`
