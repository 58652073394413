export const setStorage = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const getFromStorage = (key: string) => {
  const storedData = localStorage.getItem(key)
  return storedData ? JSON.parse(storedData) : null
}

export const removeFromStorage = (key: string) => {
  localStorage.removeItem(key)
}
