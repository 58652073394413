export const colors = {
  'orange-500': '#FF7020',
  gray: '#F6F6F6',
  'gray-20': '#D0D1D2',
  'gray-500': '#737373',
  'gray-600': '#333333',
  grafite: '#1E1E1E',
  white: '#FFFFFF',
  'white-ice': '#f7f5ed',
  black: '#000000',
  body: '#d5d8de',
}

export const themeAntd = {
  components: {
    Layout: {
      headerColor: colors.black,
      headerBg: colors.white,
      headerHeight: 80,
      footerBg: colors.white,
      bodyBg: colors.body,
    },
    Collapse: {
      colorBorder: 'none',
      contentBg: colors['white-ice'],
    },
  },
  token: {
    colorPrimary: colors['orange-500'],
    fontFamily: 'sans-serif, Roboto',
    colorSplit: colors['orange-500'],
    fontSize: 13,
    borderRadius: 5,
    colorBgContainer: colors.white,
  },
}
