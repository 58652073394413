import { useState, useEffect, useCallback } from 'react'
import { FormInstance } from 'antd'
import {
  getAllSystemPermissions,
  getUserPermissions,
  patchUserPermissions,
} from 'services/permissions'
import { formatPermissionsForTreeSelect } from 'pages/shared/formatPermissionsForTreeSelect'
import { notifyError } from 'utils/handleRequestNotify'
import { filterGroupsAndPerms } from 'utils/filterGroupsAndPerms'
import { createPermissionsToPatch } from 'pages/shared/createIdArray'
import { SPermission } from 'shared/interfaces/SPermission'
import { SUserPermission } from 'shared/interfaces/SUserPermission'
import { TreeData } from 'components/permissionTreeSelect'
import { notify } from 'components/notify'
import { usePermissionsContext } from 'contexts/permissionsContext/usePermissionsContext'
import { ACTION } from 'shared/userActionPermissions'

export const useUserPermissions = (
  email: string,
  form: FormInstance,
  t: any,
  language?: any,
) => {
  const { can } = usePermissionsContext()

  const [allSystemPerms, setAllSystemPerms] = useState<SPermission[]>()
  const [checkedKeys, setCheckedKeys] = useState<string[]>([])
  const [isLoadingPerms, setIsLoadingPerms] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [treeData, setTreeData] = useState<TreeData>()

  const fetchSelectedUserPermissions = async () => {
    const userPerms = await getUserPermissions(email)
    if (userPerms.data) {
      const checkedPermissions = userPerms.data.map(
        (permission: SUserPermission) => permission.permissionId,
      )
      setCheckedKeys(checkedPermissions)
      form.setFieldValue('permissions', checkedPermissions)
    }
  }

  const fetchAllSystemPermissions = useCallback(async () => {
    if (!can(ACTION.permissions.listUserPermissions)) {
      return
    }
    setIsLoadingPerms(true)
    try {
      const res = await getAllSystemPermissions()
      const treeData = formatPermissionsForTreeSelect(res.data, t, language)
      setTreeData(treeData)
      setAllSystemPerms(res.data)
      await fetchSelectedUserPermissions()
    } catch (error) {
      notifyError()
    } finally {
      setIsLoadingPerms(false)
    }
  }, [t, form, email, language])

  const handleFinish = async () => {
    setIsLoading(true)
    const selectedPermissions = await form.getFieldValue('permissions')
    if (selectedPermissions && allSystemPerms) {
      const filteredPerms = filterGroupsAndPerms(selectedPermissions, treeData)
      const permsReadyToPatch = createPermissionsToPatch(
        filteredPerms,
        allSystemPerms,
      )
      await patchUserPermissions(email, permsReadyToPatch)
      notify({
        msg: t('user.notify.msg.success'),
        desc: t('user.notify.desc.success'),
        type: 'success',
      })
      await fetchAllSystemPermissions()
    } else {
      notify({
        msg: t('user.notify.msg.error'),
        desc: t('user.notify.desc.error'),
        type: 'error',
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchAllSystemPermissions()
  }, [can])

  return {
    form,
    treeData,
    isLoadingPerms,
    isLoading,
    checkedKeys,
    handleFinish,
  }
}
