import { API } from 'services/config'
import { SFlightCart } from 'shared/interfaces/SFlightCart'
import { setCustomCookie } from 'utils/cookies/cookiesUtils'

const point = '/open-travel-request/v1'

export type FLightRequestParams = {
  start: string
  end?: string
  origin: string
  destination: string
  occupancy: number
  stops?: number
}

const getFlights = async ({
  start,
  end,
  origin,
  destination,
  occupancy,
}: FLightRequestParams) => {
  try {
    let endpoint = `${point}/flight?start=${start}&occupancy=${occupancy}&origin=${origin}&destination=${destination}`
    if (end) {
      endpoint += `&end=${end}`
    }
    const response = await API.get(endpoint)

    return response.data
  } catch (error) {
    return { error: 'Error on get flights' }
  }
}

const postCartFlight = async (flightCartData: SFlightCart) => {
  try {
    const response = await API.post(`${point}/flight`, flightCartData)

    if (response.data[0].travel_request_id) {
      setCustomCookie({
        data: response.data[0].travel_request_id,
        expirationInMinutes: 5,
        cookieName: 'travel_request_id',
      })
      return response.data
    }
  } catch (error) {
    console.error('Error details:', error)
    throw new Error('Error on add/create flight cart')
  }
}

export { getFlights, postCartFlight }
