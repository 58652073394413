import styled from 'styled-components'

export const UserPermissionsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .action-bar {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
  }
`
