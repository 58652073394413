import { useCallback, useState } from 'react'
import { cancelSV } from 'services/approval'
import { notifyError } from 'utils/handleRequestNotify'

interface State {
  showForm: boolean
  selectedReason: string
  anotherMotive: string
  isDrawerOpen: boolean
  loading: boolean
}

const useSvCancel = (fetchDetails: any) => {
  const [state, setState] = useState<State>({
    showForm: false,
    selectedReason: '',
    anotherMotive: '',
    isDrawerOpen: false,
    loading: false,
  })

  const setIsDrawerOpen = (isDrawerOpen: boolean) => {
    setState((prevState) => ({ ...prevState, isDrawerOpen }))
  }

  const setShowForm = (showForm: boolean) => {
    setState((prevState) => ({ ...prevState, showForm }))
  }

  const setSelectedReason = (selectedReason: string) => {
    setState((prevState) => ({ ...prevState, selectedReason }))
  }

  const setReasonDesc = (anotherMotive: string) => {
    setState((prevState) => ({ ...prevState, anotherMotive }))
  }

  const setLoading = (loading: boolean) => {
    setState((prevState) => ({ ...prevState, loading }))
  }

  const handleFinish = useCallback(
    async (travelRequestId: string, reason: string, anotherMotive: string) => {
      setLoading(true)

      try {
        await cancelSV(travelRequestId, reason, anotherMotive)
        setIsDrawerOpen(false)
        setShowForm(false)
        await fetchDetails(travelRequestId)
      } catch {
        notifyError()
      } finally {
        setLoading(false)
      }
    },
    [],
  )

  return {
    state,
    setSelectedReason,
    setIsDrawerOpen,
    setReasonDesc,
    handleFinish,
    setShowForm,
    setLoading,
  }
}

export default useSvCancel
