import styled from 'styled-components'

export const EmployeeDetailsContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;

  .header {
    width: 100%;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.08px;
    background-color: #fff;
    padding-left: 16px;
  }
`
export const SkeletonContainer = styled.div`
  padding: 16px;
`

export const FieldsContainer = styled.div`
  padding: 16px;
`

export const RowContainer = styled.div`
  padding: 8px 0 8px 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Label = styled.p`
  font-size: 18px;
  font-weight: 700;
`

export const Value = styled.div`
  font-size: 16px;
  font-weight: 400;
`
