import { Empty } from 'antd'
import React from 'react'
import { SMyTravel } from 'shared/interfaces/SMyTravel'
import { SectionContainer } from './styles'
import { useTranslation } from 'react-i18next'

export const TravelSection: React.FC<{
  title: string
  travels?: SMyTravel[]
  emptyTestId: string
  seeAllCallback?: () => void
  cardComponent: React.FC<{ travelData: SMyTravel; isCanceled?: boolean }>
  containerClass?: string
  limit?: number
  isCanceled?: boolean
}> = ({
  title,
  travels,
  emptyTestId,
  containerClass = 'cards-container',
  seeAllCallback,
  cardComponent: CardComponent,
  limit,
  isCanceled,
}) => {
  const { t } = useTranslation()

  return (
    <SectionContainer>
      <p className="header">{title}</p>
      <div className={`${containerClass}`}>
        {travels
          ?.slice(0, limit ?? travels.length)
          .map((travel) => (
            <CardComponent
              key={travel.id}
              travelData={travel}
              isCanceled={isCanceled}
            />
          ))}
      </div>
      {!travels?.length && (
        <Empty data-testid={emptyTestId} className="empty" />
      )}
      {seeAllCallback && (
        <button
          className="global-button-reset see-all-button"
          onClick={seeAllCallback}
          data-testid={`see-all-${emptyTestId}-button`}
        >
          {t('my_travels.travels.see_all')}
        </button>
      )}
    </SectionContainer>
  )
}
