import styled from 'styled-components'

export const RoomsModalContentStyles = styled.div`
  .data {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 24px;
    margin-bottom: 150px;

    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.0015em;
    }
  }

  .bottom-navigation-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .price {
      display: flex;
      flex-direction: column;
      .price-label {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
      }
      .price-value {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
      }
    }
  }
`
