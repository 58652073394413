import React from 'react'
import {
  ViewUserDataContainer,
  UserDataItem,
  ViewUserDataEmptyContainer,
} from './styles'
import { Col, Empty, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { SGroupUser } from 'shared/interfaces/SGroupUser'

export type ViewUserDataProps = {
  userData?: SGroupUser
}

const ViewUserData: React.FC<ViewUserDataProps> = ({ userData }) => {
  const { t } = useTranslation()

  if (!userData) {
    return (
      <ViewUserDataEmptyContainer>
        <Empty />
      </ViewUserDataEmptyContainer>
    )
  }

  return (
    <div data-testid="smiles-view-user-data">
      <ViewUserDataContainer>
        <Row gutter={[32, 32]}>
          <Col>
            <UserDataItem>
              <p>{t('group.table.columns.userName')}</p>
              <span>{userData.name}</span>
            </UserDataItem>
          </Col>
          <Col>
            <UserDataItem>
              <p>E-mail</p>
              <span>{userData.email}</span>
            </UserDataItem>
          </Col>
        </Row>
      </ViewUserDataContainer>
    </div>
  )
}

export default ViewUserData
