import styled from 'styled-components'

export const InfoBarContainer = styled.section`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #999999;
`

export const FlowTabsContainer = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
`
