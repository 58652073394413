import React from 'react'
import { ClassAndFareStyles } from './styles'
import { Flight } from 'services/flights/flightModel'
import { useTranslation } from 'react-i18next'
import { formatToReal } from 'utils/formatToReal/formateToReal'

export type ClassAndFareProps = {
  flightData: Flight
}

const b = 'flights.flightCardRadio.classAndFare.'

const ClassAndFare: React.FC<ClassAndFareProps> = ({ flightData }) => {
  const { t } = useTranslation()
  const fareBySegment =
    flightData.fares.find((fare) => fare.type === 'FARE')?.price.amount ?? 0

  return (
    <ClassAndFareStyles data-testid="smiles-class-and-fare">
      <div className="flight-class">
        <p>{t(b + 'class')}</p>
        <p>{<b>{flightData.segments[0].class}</b>}</p>
      </div>
      <div className="fare-per-segment">
        <p>
          <b>{t(b + 'farePerSegment')}</b>
        </p>
        {<p>{formatToReal(fareBySegment)}</p>}
      </div>
    </ClassAndFareStyles>
  )
}

export default ClassAndFare
