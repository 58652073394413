export default `
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20 18">
      <path d="M8.83398 8.75C11.044 8.75 12.834 6.96 12.834 4.75C12.834 2.54 11.044 0.75 8.83398 
      0.75C6.62398 0.75 4.83398 2.54 4.83398 4.75C4.83398 6.96 6.62398 8.75 8.83398 8.75ZM8.83398 
      2.75C9.93398 2.75 10.834 3.65 10.834 4.75C10.834 5.85 9.93398 6.75 8.83398 6.75C7.73398 6.75 
      6.83398 5.85 6.83398 4.75C6.83398 3.65 7.73398 2.75 8.83398 2.75ZM2.83398 14.75C3.03398 14.12 
      5.40398 13.07 7.79398 12.81L9.83398 10.81C9.44398 10.77 9.15398 10.75 8.83398 10.75C6.16398 10.75 
      0.833984 12.09 0.833984 14.75V16.75H9.83398L7.83398 14.75H2.83398ZM18.434 9.25L13.304 14.42L11.234 12.34L9.83398 
      13.75L13.304 17.25L19.834 10.66L18.434 9.25Z" />
    </svg>
`
