import styled from 'styled-components'

export const FlightDDetailsStyles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  .airline-icon {
    width: 40px;
    height: 40px;
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }

  .info-container {
    .airline-name {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.12px;
      margin-bottom: 16px;
    }
    .travel-info-data {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .airport-and-hour {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.08px;
          b {
            margin-left: 4px;
          }
        }
        .travel-city {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.12px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .airline-name {
      font-size: 10px;
      margin-bottom: 16px;
    }
    .travel-info-data {
      > div {
        .airport-and-hour {
          font-size: 12px;
        }
        .travel-city {
          font-size: 8px;
        }
      }
    }
  }
`
