import styled from 'styled-components'

export const MyProfilePageHeader = styled.header`
  margin-bottom: 2rem;

  .header-title {
    font-size: 3rem;
    line-height: 3.75rem;
    font-weight: 400;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
`

export const MyProfilePageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`

export const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`
