export default `
    <svg width="100%" height="100%" viewBox="0 0 60 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.7398 24.4731C33.9864 24.4731 33.2462 24.401 32.5256 24.2698C26.9704 23.2338 22.7646 18.7749 22.7646 
    12.9128C22.7646 8.7424 26.1449 4.93266 30.3179 4.93266C31.0844 4.93266 31.8312 5.05069 32.5321 5.26052C29.4401 6.20476 
    27.1931 9.08337 27.1931 12.4866C27.1931 15.8897 29.4401 18.7684 32.5321 19.7126C33.2331 19.929 33.9733 20.0404 34.7463 
    20.0404C38.9127 20.0404 42.293 16.2373 42.293 12.0603C42.293 6.19821 38.0873 1.73931 32.5321 0.703274C33.2462 0.565573 
    33.9864 0.5 34.7463 0.5C39.8757 0.5 44.2452 3.72614 45.955 8.26372V16.7094C44.2387 21.247 39.8626 24.4731 34.7398 24.4731Z" 
    fill="#BABBBB"/>
    <path d="M32.5255 0.710598C31.8114 0.579454 31.0711 0.507324 30.3112 0.507324C24.5661 0.507324 19.7642 4.55967 18.6047 
    9.9628C18.5654 10.1464 18.4016 10.2775 18.2182 10.2775H12.3878C12.1651 10.2775 11.9882 10.4546 11.9882 10.6775V14.3037C11.9882 
    14.5266 12.1651 14.7037 12.3878 14.7037H17.5041C17.7269 14.7037 17.9037 14.8807 17.9037 15.1036V16.9003C17.9037 17.0905 17.8317 
    17.2806 17.7072 17.4249C16.325 19.038 14.2745 20.0543 11.9817 20.0543C7.81529 20.0543 4.435 16.6708 4.435 12.5004C4.435 8.33006 
    7.81529 4.94655 11.9817 4.94655C13.1543 4.94655 14.2614 5.21539 15.2506 5.69407C15.4406 5.78587 15.6633 5.7203 15.7681 5.54325L17.6221 
    2.41547C17.74 2.21876 17.6679 1.96302 17.4648 1.85811C15.8205 1.00567 13.9535 0.526998 11.9751 0.526998C5.36523 0.526998 0 5.89078 0 
    12.5136C0 19.1363 5.35868 24.5001 11.9751 24.5001C15.4864 24.5001 18.6506 22.9854 20.8386 20.5723C20.9958 20.3953 21.2709 20.3953 
    21.4347 20.5723C23.6227 22.9854 26.7868 24.5001 30.2981 24.5001C31.0515 24.5001 31.7918 24.428 32.5124 24.2968C26.9572 23.2542 
    22.7515 18.8019 22.7515 12.9398C22.7515 8.76284 26.1317 4.95966 30.3047 4.95966C31.0712 4.95966 31.818 5.07769 32.5189 5.28752C35.611 
    6.23176 37.8579 9.11037 37.8579 12.5136C37.8579 15.9167 35.611 18.7953 32.5189 19.7396C33.2199 19.956 33.9601 20.0674 34.7331 
    20.0674C38.8995 20.0674 42.2864 16.2643 42.2864 12.0873C42.2864 6.20553 38.0807 1.74664 32.5255 0.710598ZM58.6179 
    19.7068H50.7633C50.5406 19.7068 50.3637 19.5298 50.3637 19.3068V1.24829C50.3637 1.02534 50.1868 0.848299 49.9641 
    0.848299H46.3414C46.1187 0.848299 45.9418 1.02534 45.9418 1.24829V23.3329C45.9418 23.7723 46.3021 24.1329 46.741 
    24.1329H58.6179C58.8406 24.1329 59.0175 23.9559 59.0175 23.7329V20.1068C59.0175 19.8838 58.8406 19.7068 58.6179 19.7068Z" fill="#FF7021"/>
    </svg>
`
