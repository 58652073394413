import axios from 'axios'
import {
  getFromStorage,
  removeFromStorage,
} from 'utils/localStorage/storageUtils'
import { config } from 'configs/vars'

const { REACT_APP_API_URL } = config()

const API = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

API.interceptors.request.use(async (config) => {
  const { accessToken, accessTokenExpiration } =
    await loadStorageAsyncWithDelay()

  const today = new Date().getTime()

  if (accessToken && accessTokenExpiration && accessTokenExpiration > today) {
    config.headers.Authorization = `Bearer ${accessToken}`
  } else {
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
    })

    removeFromStorage('accessTokenExpiration')
    removeFromStorage('accessToken')

    if (window.location.pathname !== '/login') {
      window.location.pathname = '/login'
    }
  }

  return config
})

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const loadStorageAsyncWithDelay = async () => {
  await delay(300)
  const accessToken = await getFromStorage('accessToken')
  const accessTokenExpiration = await getFromStorage('accessTokenExpiration')
  return {
    accessToken,
    accessTokenExpiration: Number(accessTokenExpiration) || 0,
  }
}

export { API }
