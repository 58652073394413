import React from 'react'
import DOMPurify from 'dompurify'
import { iconsPaths, iconType } from './svgs/icons'

import styled from 'styled-components'

export type SvgModule = typeof import('./svgs/icons')
export type IconPaths = keyof typeof iconsPaths

export interface SmilesIconProps {
  type: iconType
  size?: number
  color?: string
  viewBox?: string
  onClick?: () => void
  className?: string
}

function pathPurify(icon: iconType): string {
  const iconPath = iconsPaths[icon]
  const iconPathPurify = DOMPurify.sanitize(`<svg> ${iconPath} </svg>`)
  return iconPathPurify.substring(5, iconPathPurify.length - 6)
}

const defaultSize = 24
const defaultColor = '#232323'
const defaultViewBox = '0 0 24 24'

const Icon = styled.svg``

export const SmilesIcon = ({
  type: iconTypeProp,
  color = defaultColor,
  size = defaultSize,
  viewBox = defaultViewBox,
  onClick,
  className,
}: SmilesIconProps) => {
  const iconPath = pathPurify(iconTypeProp)
  return (
    <Icon
      data-testid={`smiles-icon-${iconTypeProp}`}
      viewBox={viewBox}
      height={size}
      width={size}
      fill={color}
      dangerouslySetInnerHTML={{
        __html: iconPath,
      }}
      onClick={onClick}
      className={className}
    />
  )
}
