import React from 'react'
import { SelectProps } from 'antd'
import { InputContainer, InputLabel } from 'components/Input/styles'
import { StyledAntdSelect } from './styles'

export type SelectCustomProps = SelectProps & {
  label?: string
  width?: string
}

const Select: React.FC<SelectCustomProps> = ({
  label,
  width,
  placeholder,
  ...rest
}) => (
  <InputContainer width={width} data-testid="smiles-select">
    <InputLabel>{label}</InputLabel>
    <StyledAntdSelect
      allowClear
      placeholder={placeholder}
      data-testid="select"
      filterOption={() => true}
      {...rest}
    />
  </InputContainer>
)

export default Select
