import React from 'react'
import { SVApproveAndDisapproveStyles } from './styles'
import Button from 'components/button'
import DoubleCheck from 'components/doubleCheck'
import SVDisapproveForm from './svDisapproveForm'
import useSVApproval from './hooks/useApproval'
import { TravelRequest } from 'shared/interfaces/STravelRequestDetails'
import { StatusEnum } from 'constants/statusEnum'
import { useTranslation } from 'react-i18next'

type SVApproveAndDisapproveProps = {
  travelRequest: TravelRequest
  fetchDetails: any
}

const SVApproveAndDisapprove: React.FC<SVApproveAndDisapproveProps> = ({
  travelRequest,
  fetchDetails,
}) => {
  const { t } = useTranslation()
  const {
    state: {
      openApproveDoubleCheck,
      openDisapproveDoubleCheck,
      showDisapproveForm,
      disapproveJustification,
      loading,
    },
    handleApprove,
    handleDisapprove,
    setOpenApproveDoubleCheck,
    setOpenDisapproveDoubleCheck,
    setShowDisapproveForm,
    setDisapproveJustification,
  } = useSVApproval(fetchDetails)

  const isWaitingForApproval =
    travelRequest.status === StatusEnum.WAITING_FOR_APPROVAL
  const disableApproveButton = !isWaitingForApproval || showDisapproveForm

  return (
    <SVApproveAndDisapproveStyles data-testid="sv-approve-and-disapprove">
      <div className="buttons">
        <Button
          buttonType="secondary"
          size="sm"
          width="165px"
          onClick={() => setShowDisapproveForm(true)}
          disabled={!isWaitingForApproval}
        >
          {t('travel_request.reprove')}
        </Button>
        <Button
          size="sm"
          width="165px"
          onClick={() => setOpenApproveDoubleCheck(true)}
          disabled={disableApproveButton}
        >
          {t('travel_request.approve')}
        </Button>
      </div>

      {showDisapproveForm && (
        <SVDisapproveForm
          onCancel={() => setShowDisapproveForm(false)}
          onConfirm={() => setOpenDisapproveDoubleCheck(true)}
          justification={disapproveJustification}
          onJustificationChange={setDisapproveJustification}
        />
      )}

      <DoubleCheck
        buttonClick={() =>
          handleApprove(travelRequest.id, Number(travelRequest.check_rate_id))
        }
        buttonLabel={t('travel_request.yes')}
        close={() => setOpenApproveDoubleCheck(false)}
        mainLabel={t('travel_request.approve_request')}
        subLabel={t('travel_request.do_you_want_to_approve')}
        desc={t('travel_request.approval_description')}
        isOpen={openApproveDoubleCheck}
        height="24.444vw"
        loading={loading}
      />

      <DoubleCheck
        buttonClick={() => handleDisapprove(travelRequest.id)}
        buttonLabel={t('travel_request.yes')}
        close={() => setOpenDisapproveDoubleCheck(false)}
        mainLabel={t('travel_request.reprove_request')}
        subLabel={t('travel_request.do_you_want_to_reprove')}
        desc={t('travel_request.reproval_description')}
        isOpen={openDisapproveDoubleCheck}
        height="24.444vw"
        loading={loading}
      />
    </SVApproveAndDisapproveStyles>
  )
}

export default SVApproveAndDisapprove
