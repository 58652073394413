export default `
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
  <g clip-path="url(#clip0_2935_34055)">
    <path d="M11.333 3.99566H9.99969V1.99354C9.99969 1.62649 9.69969 1.32617 9.33302 1.32617H6.66635C6.29968 
    1.32617 5.99968 1.62649 5.99968 1.99354V3.99566H4.66634C3.93301 3.99566 3.33301 4.5963 3.33301 
    5.33041V12.6715C3.33301 13.4056 3.93301 14.0063 4.66634 14.0063C4.66634 14.3733 4.96635 14.6736 5.33301 
    14.6736C5.69968 14.6736 5.99968 14.3733 5.99968 14.0063H9.99969C9.99969 14.3733 10.2997 14.6736 10.6664 
    14.6736C11.033 14.6736 11.333 14.3733 11.333 14.0063C12.0664 14.0063 12.6664 13.4056 12.6664 12.6715V5.33041C12.6664 
    4.5963 12.0664 3.99566 11.333 3.99566ZM5.83301 12.0041C5.55968 12.0041 5.33301 11.7772 5.33301 
    11.5036V6.49831C5.33301 6.22469 5.55968 5.99778 5.83301 5.99778C6.10635 5.99778 6.33302 6.22469 6.33302 
    6.49831V11.5036C6.33302 11.7772 6.10635 12.0041 5.83301 12.0041ZM7.99969 12.0041C7.72635 12.0041 7.49969 
    11.7772 7.49969 11.5036V6.49831C7.49969 6.22469 7.72635 5.99778 7.99969 5.99778C8.27302 5.99778 8.49969 
    6.22469 8.49969 6.49831V11.5036C8.49969 11.7772 8.27302 12.0041 7.99969 12.0041ZM8.99969 
    3.99566H6.99968V2.32723H8.99969V3.99566ZM10.1664 12.0041C9.89302 12.0041 9.66636 11.7772 9.66636 
    11.5036V6.49831C9.66636 6.22469 9.89302 5.99778 10.1664 5.99778C10.4397 5.99778 10.6664 6.22469 
    10.6664 6.49831V11.5036C10.6664 11.7772 10.4397 12.0041 10.1664 12.0041Z"/>
  </g>
  <defs>
    <clipPath id="clip0_2935_34055">
      <rect width="16" height="16.0169" fill="white" transform="translate(0 -0.00854492)"/>
    </clipPath>
  </defs>
</svg>
`
