import React, { useState, memo, useMemo } from 'react'
import MainContainer from 'components/mainContainer'
import { MyTravelsPageContent, MyTravelsPageHeader } from './styles'
import SimpleTabs from 'components/simpleTabs'
import ListAllTravels from './components/listAllTravels'
import ListFilteredTravels from './components/listFilteredTravels'
import Spinner from 'components/spinner'
import { useFetchTravels } from './hooks/useFetchTravels'
import { useTranslation } from 'react-i18next'

enum TabIndex {
  ALL = 0,
  NEXT = 1,
  CANCELED = 2,
  PREVIOUS = 3,
}

const MyTravels: React.FC = () => {
  const { t } = useTranslation()

  const [currentTab, setCurrentTab] = useState<TabIndex>(TabIndex.ALL)
  const { travels, loading } = useFetchTravels()

  const tabs = [
    { label: t('my_travels.travels.all') },
    { label: t('my_travels.travels.next') },
    { label: t('my_travels.travels.cancelled_travels') },
    { label: t('my_travels.travels.previous_travels') },
  ]

  const showTravelsComponent = useMemo(
    () => ({
      [TabIndex.ALL]: (
        <ListAllTravels
          next={travels.next}
          canceled={[]}
          previous={travels.previous}
          seeAllCallback={setCurrentTab}
        />
      ),
      [TabIndex.NEXT]: <ListFilteredTravels tab="next" next={travels.next} />,
      [TabIndex.CANCELED]: <ListFilteredTravels tab="canceled" canceled={[]} />,
      [TabIndex.PREVIOUS]: (
        <ListFilteredTravels tab="previous" previous={travels.previous} />
      ),
    }),
    [travels],
  )

  return (
    <div data-testid="smiles-my-travels-screen">
      <MainContainer>
        <MyTravelsPageContent>
          <MyTravelsPageHeader>
            <p className="header-title">{t('my_travels.travels.title')}</p>
            <SimpleTabs
              tabs={tabs}
              onClick={(idx) => setCurrentTab(idx as TabIndex)}
              currentTab={currentTab}
            />
          </MyTravelsPageHeader>

          <div className="content">
            {loading ? <Spinner /> : showTravelsComponent[currentTab]}
          </div>
        </MyTravelsPageContent>
      </MainContainer>
    </div>
  )
}

export default memo(MyTravels)
