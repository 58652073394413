import { AxiosResponse } from 'axios'
import { API } from 'services/config'
import { SGroupUser } from 'shared/interfaces/SGroupUser'

const point = '/users/v1/user'

interface UserApiResponse<T> {
  data?: T
  error?: string
}

const getAllUsers = async (limit = 100) => {
  try {
    const response = await API.get(`${point}?limit=${limit}`)
    return response.data
  } catch (error) {
    return { error: 'Error on get users' }
  }
}

const getUser = async () => {
  try {
    const response = await API.get(`${point}/my-profile`)
    return response.data
  } catch (error) {
    return { error: 'Error on get users' }
  }
}

const getUserById = async (id: string) => {
  try {
    const response = await API.get(`${point}/${id}`)
    return response.data
  } catch (error) {
    return { error: 'Error on get user' }
  }
}

const getUserByEmail = async (
  email: string,
): Promise<UserApiResponse<SGroupUser[]>> => {
  try {
    const response: AxiosResponse<SGroupUser[]> = await API.get(
      `${point}?limit=1&email=${email}`,
    )
    return { data: response.data }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || error.message || 'Unexpected error'

    return { error: errorMessage }
  }
}

export { getAllUsers, getUser, getUserById, getUserByEmail }
