import React from 'react'

import { useTranslation } from 'react-i18next'

import { BookingDetailsStyles } from './styles'

import { SmilesIcon } from 'components/icons'

import BookingDetailsCell from 'components/RequestSummary/components/BookingDetails/components/BookingDetailsCell'
import { SMyTravel } from 'shared/interfaces/SMyTravel'
import dayjs from 'dayjs'
import { STATUS, StatusEnum } from 'constants/statusEnum'

export type BookingDetailsProps = {
  travelData: SMyTravel
}

const BookingDetails: React.FC<BookingDetailsProps> = ({ travelData }) => {
  const { t } = useTranslation()

  return (
    <BookingDetailsStyles data-testid="smiles-booking-details">
      <BookingDetailsCell
        icon={<SmilesIcon type="person" />}
        title={t('reservationDetails.traveler')}
        text={travelData.users[0].user.name}
      />
      <div className="container">
        <div className="content">
          <BookingDetailsCell
            icon={<SmilesIcon type="calendar-month" />}
            title={t('reservationDetails.reservationDate')}
            text={dayjs(travelData.created_at).format('DD/MM/YYYY - HH:mm')}
          />
          <BookingDetailsCell
            icon={<SmilesIcon type="pin-drop" />}
            title={t('reservationDetails.costCenter')}
            text={
              travelData.debitAllocations[0]?.costCenters[0]?.costCenter.name
            }
          />
          <BookingDetailsCell
            icon={<SmilesIcon type="tour" />}
            title={t('reservationDetails.status')}
            text={STATUS[travelData.status as StatusEnum]}
          />
        </div>

        <div className="content">
          <BookingDetailsCell
            icon={<SmilesIcon type="confirmation-number" />}
            title={t('reservationDetails.reservationNumber')}
            text={travelData.reservation_number}
          />
          <BookingDetailsCell
            icon={<SmilesIcon type="label" />}
            title={t('reservationDetails.locator')}
            text={travelData.locator ?? 'N/A'}
          />
        </div>
      </div>
    </BookingDetailsStyles>
  )
}

export default BookingDetails
