const ptComponent = {
  component: {
    bottom_nav: {
      continue: 'Continuar',
    },
    complete_request: {
      notifications: {
        verify_cost_center_and_try_again:
          'Verifique os Centros de Custos e tente novamente.',
        failed_to_create_allocation: 'Erro ao criar alocação',
      },
    },
    mobile_nav: {
      make_a_request: 'Faça uma solicitação',
      request_flight: 'Solicitar aéreo',
      request_hotel: 'Solicitar hospedagem',
      request_car: 'Solicitar carro',
      request_bus: 'Solicitar rodoviário',
      request_services: 'Solicitar outros serviços',
      my_request: 'Minhas solicitações',
      see_my_requests: 'Ver minhas solicitações',
      your_requests: 'Suas solicitações',
      changed_trips: 'Viagens alteradas',
      next_trips: 'Próximas viagens',
      previous_trips: 'Viagens anteriores',
      your_favorites: 'Seus favoritos',
      favorites: 'Favoritos',
      favorite_flights: 'Voos favoritos',
      favorite_hotels: 'Hotéis favoritos',
      favorite_trips: 'Viagens favoritas',
      your_management: 'Sua gestão',
      reports: 'Relatórios',
      thrid_part_register: 'Cadastro de terceiros',
      advance_and_accountability: 'Adiantamento e prestação de contas',
      my_travels: 'Minhas viagens',
      see_my_travels: 'Ver minhas viagens',
    },
    request_summary: {
      request_summary: 'Resumo da solicitação',
      total: 'Total:',
      total_value: 'Valor total',
      requester: 'Solicitante',
      traveler: 'Viajante',
      add_more_services_to_your_reserve:
        'Adicionar mais serviços à sua reserva',
      come_back: 'Voltar',
      go_to_costs_center: 'Ir para centro de custos',
      flight: {
        air: 'Aéreo',
        fare_per_segment: 'Tarifa por trecho:',
      },
      hotel: {
        checkin: 'Check-in',
        daily_fare: 'Diária hotel:',
      },
    },
    cost_center: {
      cost_center: 'Centro de custo',
      description: 'Descrição',
      approver: 'Aprovador',
      regional: 'Regional',
      notifications: {
        failed_to_get_cost_center: 'Erro ao buscar o centro de custo.',
      },
      search: 'Pesquisar',
    },
    search_form: {
      add_third_part: 'Adicionar pessoa colaboradora ou terceirizada',
      requester: 'Solicitante',
      required_field: 'Campo obrigatório',
      request_travel_to: 'Solicitar viagem para',
      search: 'Pesquisar...',
      employee_added: 'Colaborador adicionado',
      cancel: 'Cancelar',
      add: 'Adicionar',
      notifications: {
        failed_to_get_airports: 'Erro ao buscar aeroportos.',
        failed_to_get_locations: 'Erro ao buscar locais.',
      },
      select_departure_airport: 'Selecione o aeroporto de origem',
      select_destination_airport: 'Selecione o aeroporto de destino',
      select_departure_date: 'Selecione a data de saída',
      select_return_date: 'Selecione a data de retorno',
    },
    header: {
      see_all: 'Ver todas',
    },
    stops_and_baggage: {
      direct: 'Direto',
      stop: 'parada',
      stops: 'paradas',
    },
    summary_modal: {
      ticket_segment: 'Passagem trecho',
    },
    hotel_info: {
      see_on_map: 'Ver no mapa',
      check_in: 'Check-in',
      check_out: 'Check-out',
      daily_rate: 'Diária hotel',
      total: 'Total',
    },
    modify_reserve: {
      modify: 'Modificar reserva',
      remove: 'Remover',
    },
  },
}
export default ptComponent
