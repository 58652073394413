import React from 'react'
import { StyledCollapse } from './styles'
import { CollapseProps } from 'antd'

export type CollapseTypes = {
  items: CollapseProps['items']
}

const Collapse: React.FC<CollapseTypes> = ({ items }) => {
  return <StyledCollapse data-testid="smiles-collapse" items={items} />
}

export default Collapse
