import { useCallback } from 'react'
import { getUserInformations } from 'services/debtAllocation'
import { SCostCenter } from 'shared/interfaces/SCostCenter'
import { SSystemUser } from 'shared/interfaces/SSystemUser'
import { SMyProfile } from 'shared/interfaces/SMyProfile'
import { getFromStorage } from 'utils/localStorage/storageUtils'
import { FormInstance } from 'antd'

export const useUserCostCenter = (
  form: FormInstance,
  setSelectedCostCenters: React.Dispatch<
    React.SetStateAction<SCostCenter[] | undefined>
  >,
  setSelectCostCenterName: React.Dispatch<React.SetStateAction<string>>,
) => {
  const loadUserCostCenter = useCallback(async () => {
    const myProfile: SMyProfile = getFromStorage('my_profile_data')

    if (!myProfile.id) return

    try {
      const fullUserData: SSystemUser = await getUserInformations(myProfile.id)
      if (fullUserData?.id && fullUserData.costCenter?.id) {
        setSelectCostCenterName(fullUserData.costCenter.name)

        form.setFieldsValue({
          costCenters: {
            value: fullUserData.costCenter.id,
            label: fullUserData.costCenter.name,
          },
          approver: fullUserData.costCenter.approvals[0]?.user.name,
        })
        setSelectedCostCenters([fullUserData.costCenter as SCostCenter])
      }
    } catch (error) {
      console.error('Erro na validação:', error)
    }
  }, [form, setSelectedCostCenters])

  return {
    loadUserCostCenter,
  }
}
