import React from 'react'
import { TreeData } from 'components/permissionTreeSelect'
import { DownOutlined } from '@ant-design/icons'
import { Alert, Tree } from 'antd'
import { usePermissionsContext } from 'contexts/permissionsContext/usePermissionsContext'
import { ACTION } from 'shared/userActionPermissions'
import { useTranslation } from 'react-i18next'

type ListGroupPermissionsProps = {
  treeData: TreeData
  checkedKeys: string[]
}

const ListGroupPermissions: React.FC<ListGroupPermissionsProps> = ({
  checkedKeys,
  treeData,
}) => {
  const { t } = useTranslation()
  const { can } = usePermissionsContext()
  return (
    <>
      {can(ACTION.permissions.listGroupPermissions) ? (
        <Tree
          switcherIcon={<DownOutlined />}
          checkable
          checkedKeys={checkedKeys}
          treeData={treeData}
          data-testid="smiles-list-group-permissions"
        />
      ) : (
        <Alert
          showIcon
          type="warning"
          description={t('listGroupPermissions.alert.notHavePermToView')}
        />
      )}
    </>
  )
}

export default ListGroupPermissions
