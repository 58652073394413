import styled from 'styled-components'

export const AccessDeniedContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  padding: 64px 0;

  p {
    font-size: 24px;
  }
`
