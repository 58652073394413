import { useEffect, useState } from 'react'
import { getCompany } from 'services/outsourced'
import { notifyError } from 'utils/handleRequestNotify'

type Company = {
  company_name: string
  document: string
  employees: any[]
  is_active?: boolean
}

type DrawerState = {
  isOpen: boolean
  currentDrawer: string | undefined
}

export const useCompanyDetails = (companyId: string) => {
  const [isEditMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [company, setCompany] = useState<Company | undefined>(undefined)
  const [drawerState, setDrawerState] = useState<DrawerState>({
    isOpen: false,
    currentDrawer: undefined,
  })
  const [selectedItemListId, setSelectedItemListId] = useState<
    string | undefined
  >(undefined)
  const [enabled, setEnabled] = useState<boolean>(true)

  useEffect(() => {
    fetchEmployeeDetails(companyId)
  }, [companyId])

  const fetchEmployeeDetails = async (companyId: string) => {
    try {
      if (!companyId) return
      setLoading(true)
      const response = await getCompany(companyId)
      setCompany(response.result)
    } catch {
      notifyError()
    } finally {
      setLoading(false)
    }
  }

  const setDrawerData = (
    isOpen: boolean,
    currentDrawer: string | undefined,
  ) => {
    setDrawerState((prevState: any) => ({
      ...prevState,
      isOpen,
      currentDrawer,
    }))
  }

  const handleCloseDrawer = () => {
    setDrawerData(false, undefined)
  }

  return {
    loading,
    company,
    drawerState,
    setDrawerData,
    fetchEmployeeDetails,
    isEditMode,
    setEditMode,
    selectedItemListId,
    handleCloseDrawer,
    setSelectedItemListId,
    enabled,
  }
}
