import React from 'react'
import { DropdownNavContentStyles } from './styles'
import NavCardContent from '../navCardItem'
import { SmilesIcon } from 'components/icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export type DropdownNavContentTypes = {
  currentLink: number | null
}

export const DropdownNavContent: React.FC<DropdownNavContentTypes> = ({
  currentLink,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  switch (currentLink) {
    // descomentar os serviços quando possível
    case 1:
      return (
        <DropdownNavContentStyles>
          <p>{t('navbar.linksDropdown.makeRequest.title')}</p>
          <div>
            <NavCardContent
              icon={<SmilesIcon type="plane-ticket" size={20} />}
              label={t('navbar.linksDropdown.makeRequest.requestAir')}
              onClick={() => navigate('/flow/flights')}
            />
            {/* <NavCardContent
              icon={<SmilesIcon type="sleep" size={20} />}
              label={t('navbar.linksDropdown.makeRequest.requestAccommodation')}
              onClick={() => navigate('/flow/hotel')}
            /> */}
            {/* <NavCardContent
              icon={<SmilesIcon type="car" size={22} />}
              label={t('navbar.linksDropdown.makeRequest.requestCar')}
            /> */}
            {/* <NavCardContent
              icon={<SmilesIcon type="bus" size={18} />}
              label={t('navbar.linksDropdown.makeRequest.requestRoad')}
            /> */}
            {/* <NavCardContent
              icon={<SmilesIcon type="ticket" size={20} />}
              label={t('navbar.linksDropdown.makeRequest.requestOther')}
            /> */}
          </div>
        </DropdownNavContentStyles>
      )
    case 3:
      return (
        <DropdownNavContentStyles data-testid="smiles-nav-dropdown-content">
          <p>{t('navbar.linksDropdown.myTravels.title')}</p>
          <div>
            <NavCardContent
              icon={<SmilesIcon type="exclamation" size={20} />}
              label={t('navbar.linksDropdown.myTravels.changedTrips')}
            />
            <NavCardContent
              icon={<SmilesIcon type="plane-right" size={20} />}
              label={t('navbar.linksDropdown.myTravels.upcomingTrips')}
            />
            <NavCardContent
              icon={<SmilesIcon type="dot-flag" size={20} />}
              label={t('navbar.linksDropdown.myTravels.previousTrips')}
            />
          </div>
        </DropdownNavContentStyles>
      )
    case 4:
      return (
        <DropdownNavContentStyles>
          <p>{t('navbar.linksDropdown.favorites.title')}</p>
          <div>
            <NavCardContent
              icon={<SmilesIcon type="star-outline" size={20} />}
              label={t('navbar.linksDropdown.favorites.favorites')}
            />
            <NavCardContent
              icon={<SmilesIcon type="plane-ticket" size={20} />}
              label={t('navbar.linksDropdown.favorites.favoriteFlights')}
            />
            <NavCardContent
              icon={<SmilesIcon type="sleep" size={20} />}
              label={t('navbar.linksDropdown.favorites.favoriteHotels')}
            />
            <NavCardContent
              icon={<SmilesIcon type="ticket" size={20} />}
              label={t('navbar.linksDropdown.favorites.favoriteTravels')}
            />
          </div>
        </DropdownNavContentStyles>
      )
    case 5:
      return (
        <DropdownNavContentStyles>
          <p>{t('navbar.linksDropdown.management.title')}</p>
          <div>
            <NavCardContent
              icon={<SmilesIcon type="report" size={20} />}
              label={t('navbar.linksDropdown.management.report')}
            />
            <NavCardContent
              icon={<SmilesIcon type="people-check" size={20} />}
              label={t(
                'navbar.linksDropdown.management.thirdPartyRegistration',
              )}
            />

            <NavCardContent
              icon={<SmilesIcon type="check-list" size={20} />}
              label={t(
                'navbar.linksDropdown.management.advanceAndAccountability',
              )}
            />
          </div>
        </DropdownNavContentStyles>
      )
    default:
      return null
  }
}

export default DropdownNavContent
