import React, { useState } from 'react'
import { NextTravelCardStyles } from './styles'
import { SmilesIcon } from 'components/icons'
import { Color } from 'styles/global'
import Button from 'components/button'
import dayjs from 'dayjs'
import { notifyError } from 'utils/handleRequestNotify'
import Drawer from 'components/drawer'
import CancelTravelModal from '../cancelTravelModal'
import { SMyTravel } from 'shared/interfaces/SMyTravel'
import ViewFullReservation from 'components/ViewFullReservation'
import StatusBadge from 'components/statusBadge'
import { STATUS, StatusEnum } from 'constants/statusEnum'
import { useTranslation } from 'react-i18next'
import { downloadVoucher } from 'services/booking'

export type NextTravelCardProps = {
  travelData: SMyTravel
}

const NextTravelCard: React.FC<NextTravelCardProps> = ({ travelData }) => {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)
  const [openCancelDrawer, setOpenCancelDrawer] = useState(false)
  const [openFullRequestDrawer, setOpenFullRequestDrawer] = useState(false)
  const [isVoucherLoading, setIsVoucherLoading] = useState(false)

  const copyTextToClipboard = async () => {
    if (!travelData.locator) {
      return
    }
    try {
      await navigator.clipboard.writeText(travelData.locator)
      setCopied(true)
      setTimeout(() => setCopied(false), 15000)
    } catch (err) {
      notifyError('Falha ao copiar')
    }
  }

  return (
    <NextTravelCardStyles data-testid="smiles-next-travel-card">
      <div className="data">
        <div className="cities-and-date">
          <div className="cities">
            <p className="city-name">{travelData.origin}</p>
            <SmilesIcon type="plane-right" size={16} color={Color.PRIMARY} />
            <p className="city-name">{travelData.destination}</p>
          </div>
          <div className="date">
            <SmilesIcon type="calendar" size={22} />
            <p className="date-text">
              {dayjs(travelData.flights[0].departure_time).format(
                'ddd, D MMM YYYY',
              )}
              {travelData.flights[1] ? (
                <>
                  {' - '}
                  {dayjs(travelData.flights[1].departure_time).format(
                    'ddd, D MMM YYYY',
                  )}
                </>
              ) : (
                <>
                  {' - '}
                  {dayjs(travelData.flights[0].return_date).format(
                    'ddd, D MMM YYYY',
                  )}
                </>
              )}
            </p>
          </div>
        </div>
        <div className="travel-info">
          <div className="travel-info-item">
            <p>{t('my_travels.travels.traveller')}:</p>
            <span>{travelData.consultant.name}</span>
          </div>
          <div className="travel-info-item">
            <p>{t('my_travels.travels.flight_operator')}:</p>
            <span>{travelData.flights[0].flightRoutes[0].airline_name}</span>
          </div>
          <div className="travel-info-item">
            <p>{t('my_travels.travels.status')}:</p>
            <StatusBadge
              status={travelData.status as StatusEnum}
              text={STATUS[travelData.status as StatusEnum]}
            />
          </div>
        </div>
        <div className="locator">
          <SmilesIcon type="square-arrow" size={18} />
          <p>{t('my_travels.travels.locator')}:</p>
          <span>{travelData.locator ?? 'N/A'}</span>
          <button
            className="global-button-reset"
            data-testid="copy-locator"
            onClick={copyTextToClipboard}
          >
            {copied ? (
              <SmilesIcon type="check-circle" size={18} color="#2ecc71" />
            ) : (
              <SmilesIcon
                type="copy"
                size={18}
                color={travelData.locator ? Color.PRIMARY : '#959595'}
              />
            )}
          </button>
        </div>
      </div>
      <div className="buttons">
        <button
          className="global-button-reset see-full-request"
          onClick={() => setOpenFullRequestDrawer(true)}
        >
          {t('my_travels.travels.see_all_details')}
        </button>
        <div className="bottom-buttons">
          {/* <Button
            size="sm"
            buttonType="secondary"
            onClick={() => setOpenDrawer(true)}
          >
            {t('my_travels.travels.cancel_travel')}
            <SmilesIcon type="arrow-right" />
          </Button> */}
          <Button
            size="sm"
            width="150px"
            isLoading={isVoucherLoading}
            onClick={() => {
              setIsVoucherLoading(true)
              downloadVoucher(travelData.check_rate_id, setIsVoucherLoading, t)
            }}
          >
            {t('my_travels.travels.download_voucher')}
          </Button>
        </div>
      </div>

      <Drawer
        onClose={() => setOpenCancelDrawer(false)}
        open={openCancelDrawer}
        width={700}
      >
        <CancelTravelModal close={() => setOpenCancelDrawer(false)} />
      </Drawer>

      <Drawer
        onClose={() => setOpenFullRequestDrawer(false)}
        open={openFullRequestDrawer}
        width={600}
      >
        <ViewFullReservation
          travelData={travelData}
          onClose={() => setOpenFullRequestDrawer(false)}
        />
      </Drawer>
    </NextTravelCardStyles>
  )
}

export default NextTravelCard
