import React, { useEffect, useState } from 'react'
import DrawerBottomNavigation from 'components/drawerBottomNavigation'
import Button from 'components/button'
import { HotelDetailsImage, HotelDetailsModalContentStyles } from './styles'
import { formatToReal } from 'utils/formatToReal/formateToReal'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'
import { countTotalDays } from 'utils/countTotalDays'
import { useTranslation } from 'react-i18next'

export type HotelDetailsModalContentProps = {
  addHotelCallback: () => void
}

const HotelDetailsModalContent: React.FC<HotelDetailsModalContentProps> = ({
  addHotelCallback,
}) => {
  const { t } = useTranslation()
  const { selectedHotel, selectedRoom } = useFlowContext()
  const [hotelImage, setHotelImage] = useState('')

  const formattedAddress = `${selectedHotel?.hotel.address?.address}, ${selectedHotel?.hotel.address.city.name} - ${selectedHotel?.hotel.address.city.country.code}`
  const isRefundable = selectedRoom?.cancellationPolicies.refundable
  const totalFare =
    selectedRoom?.fares.find((fare) => fare.type === 'FARE')?.price.amount ?? 0

  const dailyFare =
    (selectedRoom &&
      totalFare /
        countTotalDays(selectedRoom.checkIn, selectedRoom.checkOut)) ??
    0

  useEffect(() => {
    if (selectedHotel?.hotel?.images?.length) {
      setHotelImage(selectedHotel?.hotel?.images[0].medium)
    }
  }, [selectedHotel])

  return (
    <HotelDetailsModalContentStyles>
      <div className="content" data-testid="smiles-rooms-modal-content">
        <p className="title">{t('hotel.hotel_details')}</p>

        <HotelDetailsImage $urlimage={hotelImage} />

        <div className="data">
          <div className="data-content">
            <p className="name">{selectedHotel?.hotel.name}</p>
            <p className="address">{formattedAddress}</p>
            <div className="refundable">
              {isRefundable ? (
                <p>{t('hotel.refundable_fare')}</p>
              ) : (
                <p>{t('hotel.non_refundable_fare')}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <DrawerBottomNavigation>
        <div className="bottom-navigation-container">
          <div className="price">
            <p className="price-label">{t('hotel.daily_rate')}:</p>
            <p className="price-value">{formatToReal(dailyFare)}</p>
          </div>
          <Button width="264px" onClick={() => addHotelCallback()}>
            {t('hotel.add')}
          </Button>
        </div>
      </DrawerBottomNavigation>
    </HotelDetailsModalContentStyles>
  )
}

export default HotelDetailsModalContent
