import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CompleteRequestStyles } from './styles'
import iconStyles from '../RequestSummary/components/styles.module.css'
import Button from 'components/button'
import { SmilesIcon } from 'components/icons'
import {
  createDebitAllocation,
  createDebitAllocationParams,
} from 'services/debtAllocation'
import { notifyError } from 'utils/handleRequestNotify'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'
import { closeTravelRequest } from 'services/travelRequest'
import { destroyCustomCookie } from 'utils/cookies/cookiesUtils'
import { SMyTravel } from 'shared/interfaces/SMyTravel'

export type CompleteRequestProps = {
  handleOpenRequestStatusDrawer?: any
  closeButton?: any
  formProps?: any
  setCloseTravelResponseCallback: (response: SMyTravel) => void
}

const CompleteRequest: React.FC<CompleteRequestProps> = ({
  handleOpenRequestStatusDrawer,
  closeButton,
  formProps,
  setCloseTravelResponseCallback,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { setTravelRequestId, travelRequestId, selectedCostCenters } =
    useFlowContext()

  const createDebtAllocation = async () => {
    if (!selectedCostCenters) {
      return notifyError(
        t(
          'component.complete_request.notifications.verify_cost_center_and_try_again',
        ),
      )
    }

    setLoading(true)

    try {
      const requestObject: createDebitAllocationParams = {
        travelRequestId,
        reasonId: formProps.reason,
        costCentersIds: selectedCostCenters.map((item) => item.id),
        anotherMotive: formProps.anotherMotive,
      }
      if (formProps.additionalInformation) {
        requestObject.additionalInformation = formProps.additionalInformation
      }

      await createDebitAllocation(requestObject)

      const closeTravelResponse: SMyTravel =
        await closeTravelRequest(travelRequestId)

      setCloseTravelResponseCallback(closeTravelResponse)
      setTravelRequestId(undefined)
      destroyCustomCookie('travel_request_id')

      return handleOpenRequestStatusDrawer()
    } catch (error) {
      return notifyError(
        t(
          'component.complete_request.notifications.failed_to_create_allocation',
        ),
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <CompleteRequestStyles data-testid="smiles-complete-request">
      <div className="header">
        <span>{t('completeRequest.title')}</span>
        <a className="header_button" onClick={closeButton}>
          <SmilesIcon
            type="circle-close"
            className={iconStyles.icon_40}
            viewBox="0 0 41 41"
          ></SmilesIcon>
        </a>
      </div>
      <div className="text_container">
        <div className="content">
          <span className="text_container_title">
            {t('completeRequest.question')}
          </span>
          <span className="text_container_text">
            {t('completeRequest.text')}
          </span>
        </div>
        <Button
          onClick={createDebtAllocation}
          isLoading={loading}
          disabled={loading}
          size="md-responsive"
          width="18.333vw"
        >
          {t('completeRequest.yes')}
        </Button>
      </div>
    </CompleteRequestStyles>
  )
}

export default CompleteRequest
