import { parseISO, format, isValid } from 'date-fns'

export const formatCPF = (cpf: string): string => {
  if (!cpf) {
    return ''
  }
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const formatRG = (rg: string): string => {
  if (!rg) {
    return ''
  }
  return rg.replace(/(\d{2})(\d{3})(\d{3})(\d{1,2})/, '$1.$2.$3-$4')
}

export const formatDate = (dateString: string): string => {
  if (!dateString) {
    return ''
  }

  try {
    const date = parseISO(dateString)
    if (!isValid(date)) {
      return ''
    }
    return format(date, 'dd/MM/yyyy')
  } catch (error) {
    console.error('Error parsing date:', error)
    return ''
  }
}

export const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return ''
  }

  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  if (cleaned.length === 11) {
    const match = RegExp(/^(\d{2})(\d{5})(\d{4})$/).exec(cleaned)
    if (match) {
      return `(${match[1]}) ${match[2]} ${match[3]}`
    }
  } else if (cleaned.length === 10) {
    const match = RegExp(/^(\d{2})(\d{4})(\d{4})$/).exec(cleaned)
    if (match) {
      return `(${match[1]}) ${match[2]} ${match[3]}`
    }
  }
  return phoneNumber
}

export const formatCNPJ = (cnpj: string): string => {
  if (!cnpj) {
    return ''
  }
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}
