import { Collapse } from 'antd'
import styled from 'styled-components'

export const StyledCollapse = styled(Collapse)`
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0px !important;

  .ant-collapse-header {
    height: 72px;
    display: flex !important;
    align-items: center !important;
    background: white;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.08px;
    &:hover {
      font-family: Nunito;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.08px;
    }
  }
  .ant-collapse-item {
  }

  .ant-collapse-content {
    background: #fafafa;
  }
`
