import styled from 'styled-components'

export const RoomCardRadioContainer = styled.div<{ $checked: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  min-height: 170px;

  .check-container {
    display: flex;
    padding: 0px 24px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px 0px 0px 16px;
    border: 2px solid #999;
    > div {
      display: flex;
      width: 24px;
      height: 24px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      border: 3px solid #999;
    }
  }

  .room-data {
    display: flex;
    align-items: center;
    flex: 1 0 0;
    border-radius: 0px 16px 16px 0px;
    border-top: 2px solid #999;
    border-right: 2px solid #999;
    border-bottom: 2px solid #999;

    .room-data-img {
      min-height: 100%;
      min-width: 158px;
      background-color: #feeadf;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .room-data-info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px;

      .info-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.0015em;
      }
      .info-desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.005em;
        color: #595959;
      }
      .info-accomodations-and-price {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .info-price {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;

          .info-price-label {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.01em;
          }
          .info-price-value {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.005em;
          }
        }
      }
    }
  }

  ${({ $checked }) =>
    $checked &&
    ` 
    .check-container {
      border: 2px solid var(--primary-color);
      border-right: 2px solid #999;

      > div {
        background: var(--primary-color);
        border: none;
      }
    }

    .room-data { 
      border-top: 2px solid var(--primary-color);
      border-right: 2px solid var(--primary-color);
      border-bottom: 2px solid var(--primary-color);
    }
  `}
`
