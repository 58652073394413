const esUser = {
  user: {
    informations: 'Información',
    permissions: 'Permisos',
    permissionsTab: {
      treeSelect: {
        label: 'Seleccionar permisos de usuario',
        placeholder: 'Permisos',
      },
      updatePermissions: 'Actualizar permisos',
      updateConfirm: {
        title: 'Actualizar permisos',
        description: '¿Desea actualizar los permisos de este usuario?',
      },
      noViewPermissions: 'No tienes permiso para ver los permisos de usuario.',
      noUpdatePermissions:
        'Nota: No podrás cambiar los permisos del usuario, ya que no tienes permiso para realizar esta acción.',
    },
    notify: {
      msg: {
        success: '¡Éxito!',
        error: '¡Error!',
      },
      desc: {
        success: '¡Permisos actualizados exitosamente!',
        error:
          'Hay algún problema con los permisos, verifique los datos o vuelva a intentarlo más tarde.',
      },
    },
    popConfirm: {
      yes: 'Sí',
      cancel: 'Cancelar',
    },
    noData: 'No hay datos',
  },
}

export default esUser
