export default `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" >
<path d="M15.7539 14.2539H14.9639L14.6839 13.9839C15.6639 12.8439 16.2539 11.3639 16.2539 9.75391C16.2539 
6.16391 13.3439 3.25391 9.75391 3.25391C6.16391 3.25391 3.25391 6.16391 3.25391 9.75391C3.25391 13.3439 
6.16391 16.2539 9.75391 16.2539C11.3639 16.2539 12.8439 15.6639 13.9839 14.6839L14.2539 14.9639V15.7539L19.2539 
20.7439L20.7439 19.2539L15.7539 14.2539ZM9.75391 14.2539C7.26391 14.2539 5.25391 12.2439 5.25391 9.75391C5.25391 
7.26391 7.26391 5.25391 9.75391 5.25391C12.2439 5.25391 14.2539 7.26391 14.2539 9.75391C14.2539 12.2439 12.2439 
14.2539 9.75391 14.2539Z" />
</svg>
`
