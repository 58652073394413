import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getEmployee } from 'services/outsourced'
import { formatCPF, formatPhoneNumber, formatRG } from 'utils/formatters'
import { notifyError } from 'utils/handleRequestNotify'

export interface Nationality {
  id: string
  name: string
}

export interface Company {
  id: string
  company_name: string
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
}

export interface OutsourcedEmployee {
  id: string
  company_id: string
  nationality_id?: string
  is_active: boolean
  is_concession: boolean
  full_name: string
  cpf?: string
  rg?: string
  passport?: string
  job?: string
  gender: Gender
  date_of_birth: string
  phone: string
  email?: string
  nationality?: Nationality
  company?: Company
}

interface Fields {
  [key: string]: {
    label: string
    value: string | undefined
  }
}

const useEmployeeDetails = (employeeId: string | undefined) => {
  const { t } = useTranslation()
  const [employee, setEmployee] = useState<OutsourcedEmployee | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [fields, setFields] = useState<Fields>({})

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        if (!employeeId) return
        setLoading(true)
        const response = await getEmployee(employeeId)
        const fields = getFields(response)
        setFields(fields)
      } catch {
        notifyError()
      } finally {
        setLoading(false)
      }
    }
    fetchEmployeeDetails()
  }, [employeeId])

  const getFields = (employee: OutsourcedEmployee): Fields => ({
    full_name: {
      label: 'employee_details.fields.name.label',
      value: employee.full_name,
    },
    cpf: {
      label: 'employee_details.fields.cpf.label',
      value: employee.cpf ? formatCPF(employee.cpf) : '',
    },
    rg: {
      label: 'employee_details.fields.rg.label',
      value: employee.rg ? formatRG(employee.rg) : '',
    },
    passport: {
      label: 'employee_details.fields.passport.label',
      value: employee.passport,
    },
    job: {
      label: 'employee_details.fields.position.label',
      value: employee.job,
    },
    gender: {
      label: 'employee_details.fields.gender.label',
      value: employee.gender,
    },
    date_of_birth: {
      label: 'employee_details.fields.birthdate.label',
      value: employee.date_of_birth,
    },
    phone: {
      label: 'employee_details.fields.phone.label',
      value: employee.phone ? formatPhoneNumber(employee.phone) : '',
    },
    email: {
      label: 'employee_details.fields.email.label',
      value: employee.email,
    },
    nationality: {
      label: 'employee_details.fields.nationality.label',
      value: employee.nationality?.name,
    },
    company: {
      label: 'employee_details.fields.company.label',
      value: employee.company?.company_name,
    },
    is_concession: {
      label: 'employee_details.fields.concession.label',
      value: employee.is_concession
        ? t('employee_details.fields.yes.label')
        : t('employee_details.fields.no.label'),
    },
  })

  return { fields, loading }
}

export default useEmployeeDetails
