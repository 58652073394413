const enAccessDenied = {
  access_denied: {
    oops: 'Oops!',
    you_do_not_have_permission:
      'You do not have permission to access this area.',
    back_to_beginning: 'Come back to beginning',
  },
}

export default enAccessDenied
