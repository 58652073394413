import { useState, useEffect } from 'react'
import { useForm } from 'antd/es/form/Form'
import { useAuth0 } from '@auth0/auth0-react'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'
import { getCustomCookie } from 'utils/cookies/cookiesUtils'
import { SMyTravel } from 'shared/interfaces/SMyTravel'
import { SCostCenter } from 'shared/interfaces/SCostCenter'
import { DefaultOptionType } from 'antd/es/select'
import { useReasons } from '../useReasons'
import { useCostCenters } from '../useCostCenters'
import { useUserCostCenter } from '../useUserCostCenter'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'

export const useDebtAllocation = () => {
  const [form] = useForm()
  const values = Form.useWatch([], form)

  const { user } = useAuth0()
  const { selectedCostCenters, setSelectedCostCenters, setTravelRequestId } =
    useFlowContext()

  const [selectCostCenterName, setSelectCostCenterName] = useState('')
  const [others, setOthers] = useState(false)
  const [formValues, setFormValues] = useState([])
  const [canSubmit, setCanSubmit] = useState<boolean>(false)
  const [closeTravelResponse, setCloseTravelResponse] = useState<SMyTravel>()
  const [secondCostCenter, setSecondCostCenter] = useState<SCostCenter>()
  const [openDrawers, setOpenDrawers] = useState({
    searchCostCenter: false,
    requestSummary: false,
    completeRequest: false,
    requestStatus: false,
    viewFullReservation: false,
  })

  const {
    costCentersList,
    loadingCostCenters,
    handleSearch,
    setCostCentersList,
  } = useCostCenters()
  const { reasonsList, fetchReasons, loadingReasonsList } = useReasons()
  const { loadUserCostCenter } = useUserCostCenter(
    form,
    setSelectedCostCenters,
    setSelectCostCenterName,
  )

  const handleOpenDrawer = (drawer: string) => {
    setOpenDrawers({
      searchCostCenter: false,
      requestSummary: false,
      completeRequest: false,
      requestStatus: false,
      viewFullReservation: false,
      [drawer]: true,
    })
  }

  const handleCloseDrawer = (drawer: string) =>
    setOpenDrawers({ ...openDrawers, [drawer]: false })

  const handleCheckboxChange = (event: any) => {
    if (event.target.checked) {
      handleOpenDrawer('searchCostCenter')
    } else if (selectedCostCenters?.length) {
      setSelectedCostCenters([selectedCostCenters[0]])
    }
  }

  const handleFormSubmit = async () => {
    try {
      const values = await form.validateFields()
      setFormValues(values)
      handleOpenDrawer('completeRequest')
    } catch (error) {
      console.error('Erro na validação:', error)
    }
  }

  const getApproverNameById = (
    selectedCostCenterId: string,
    costCenters: SCostCenter[],
  ) => {
    const approverName: string[] = costCenters
      .filter((costCenter) => selectedCostCenterId === costCenter.id)
      .map((costCenter) => costCenter.approvals[0]?.user.name)

    form.setFieldValue('approver', approverName)
  }

  const handleSelectCostCenter = (selectedItem: DefaultOptionType) => {
    const findSelectedCostCenter = costCentersList.find(
      (costCenter) => costCenter.id === selectedItem.value,
    )

    if (findSelectedCostCenter) {
      setSelectedCostCenters([findSelectedCostCenter])
      getApproverNameById(findSelectedCostCenter?.id, costCentersList)
      setSelectCostCenterName(selectedItem.label as string)
    }
  }

  useEffect(() => {
    fetchReasons()
  }, [fetchReasons])

  useEffect(() => {
    const travelRequestIdCookie = getCustomCookie('travel_request_id')
    setTravelRequestId(travelRequestIdCookie)
    loadUserCostCenter()
  }, [setTravelRequestId])

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setCanSubmit(true))
      .catch(() => setCanSubmit(false))
  }, [form, values])

  useEffect(() => {
    if (selectedCostCenters && selectedCostCenters.length > 0) {
      form.setFieldValue('costCenters', {
        label: selectedCostCenters[0].name,
        value: selectedCostCenters[0].id,
      })
    }
    if (selectedCostCenters && selectedCostCenters.length > 1) {
      setSecondCostCenter(selectedCostCenters[1])
    } else {
      setSecondCostCenter(undefined)
    }
  }, [selectedCostCenters])

  useEffect(() => {
    return () => {
      setSelectedCostCenters([])
      setTravelRequestId(undefined)
    }
  }, [])

  return {
    form,
    user,
    openDrawers,
    selectCostCenterName,
    others,
    formValues,
    canSubmit,
    closeTravelResponse,
    secondCostCenter,
    costCentersList,
    loadingCostCenters,
    reasonsList,
    loadingReasonsList,
    setOthers,
    handleSearch,
    setCostCentersList,
    handleOpenDrawer,
    handleCloseDrawer,
    handleCheckboxChange,
    handleFormSubmit,
    handleSelectCostCenter,
    setCloseTravelResponse,
    setSecondCostCenter,
  }
}
