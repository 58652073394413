import { Skeleton as AntSkeleton } from 'antd'
import React from 'react'

export type SkeletonProps = {
  isLoading?: boolean
}

const Skeleton: React.FC<SkeletonProps> = ({ isLoading }) => {
  if (isLoading) {
    return (
      <div data-testid="smiles-skeleton">
        <AntSkeleton active />
      </div>
    )
  }
  return null
}

export default Skeleton
