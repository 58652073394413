import React from 'react'
import MainContainer from 'components/mainContainer'
import { FullRequestContainer, SVHeader } from './styles'
import { SmilesIcon } from 'components/icons'
import StatusBadge from 'components/statusBadge'
import SVSummary from './components/svSummary'
import SVFlight from './components/svFlight'
import SVApproveAndDisapprove from './components/svApproveAndDisapprove'
import SVCancel from './components/svCancel'
import { Empty } from 'antd'
import { useParams, useNavigate } from 'react-router-dom'
import useFullRequest from './hooks/useFullRequest'
import { StatusEnum } from 'constants/statusEnum'
import Skeleton from 'components/skeleton'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import Alert from 'components/alert'
import { formatToReal } from 'utils/formatToReal/formateToReal'

const FullRequest: React.FC = () => {
  const { t } = useTranslation()
  const { travelRequestId } = useParams()
  const navigate = useNavigate()

  const {
    state: { details, loading, isCostCenterApproverAndHasPermission },
    fetchDetails,
  } = useFullRequest(travelRequestId as string)

  const onBack = () => navigate(-1)

  const options = [
    {
      label: t('travel_request.cancel_reasons.date_change'),
      value: 'DATE_CHANGE',
    },
    {
      label: t('travel_request.cancel_reasons.flight_change'),
      value: 'FLIGHT_CHANGE',
    },
    {
      label: t('travel_request.cancel_reasons.employee_terminated'),
      value: 'EMPLOYEE_TERMINATED',
    },
    {
      label: t('travel_request.cancel_reasons.incorrect_entries'),
      value: 'INCORRECT_ENTRIES',
    },
    {
      label: t('travel_request.cancel_reasons.duplicate_entries'),
      value: 'DUPLICATE_ENTRIES',
    },
    {
      label: t('travel_request.cancel_reasons.expired_reservations'),
      value: 'EXPIRED_RESERVATIONS',
    },
    {
      label: t('travel_request.cancel_reasons.trip_canceled'),
      value: 'TRIP_CANCELED',
    },
    { label: t('travel_request.cancel_reasons.other'), value: 'OTHER' },
  ]

  const getReasonLabel = (reason: string) => {
    const option = options.find((option) => option.value === reason)
    return option ? option.label : reason
  }

  const getAnotherMotive = (anotherMotive: string) => {
    return anotherMotive ? `- ${anotherMotive}` : ''
  }

  const showPriceChangeAlert = (
    amount: number,
    oldAmount: number,
    status: StatusEnum,
  ) => {
    return (
      oldAmount &&
      oldAmount !== amount &&
      status === StatusEnum.WAITING_FOR_APPROVAL
    )
  }

  return (
    <MainContainer>
      <FullRequestContainer data-testid="smiles-full-request-screen">
        <span className="go-back-container">
          <Button width="84px" buttonType="back-button" onClick={onBack}>
            <SmilesIcon type="arrow-right" size={32} />
            <p className="go-back-label">Voltar</p>
          </Button>
        </span>

        <Skeleton isLoading={loading} />

        {!loading && !details && <Empty />}

        {!loading && details && (
          <>
            {showPriceChangeAlert(
              details.travelRequest.amount,
              details?.travelRequest?.old_amount,
              details.travelRequest.status as StatusEnum,
            ) && (
              <StatusBadge
                status={StatusEnum.WAITING_FOR_APPROVAL}
                text={t('travel_request.price_change.text', {
                  old_amount: formatToReal(details?.travelRequest?.old_amount),
                  amount: formatToReal(details.travelRequest.amount),
                })}
              />
            )}
            <SVHeader>
              <div className="left">
                <p className="sv-number">
                  [ {details.travelRequest?.reservation_number} ]
                </p>
                <div className="services">
                  {details.travelRequest.flights.length > 0 && (
                    <SmilesIcon type="plane-right" color="#999999" />
                  )}
                </div>
              </div>
              <div className="status">
                <StatusBadge
                  status={details.travelRequest.status as unknown as StatusEnum}
                  text={t(
                    `myRequests.status.${(
                      details.travelRequest.status as unknown as StatusEnum
                    )
                      .toString()
                      .toLowerCase()}`,
                  )}
                />
              </div>
            </SVHeader>

            <div className="summary-and-cancel">
              <SVSummary travelRequest={details.travelRequest} />
              {isCostCenterApproverAndHasPermission ? (
                <SVApproveAndDisapprove
                  travelRequest={details.travelRequest}
                  fetchDetails={fetchDetails}
                />
              ) : (
                <SVCancel
                  travelRequest={details.travelRequest}
                  fetchDetails={fetchDetails}
                />
              )}
              {details.travelRequest.status === 'CANCELED' && (
                <Alert
                  type="error"
                  title="Solicitação cancelada"
                  desc={`Motivo: ${getReasonLabel(
                    details.travelRequest.reasons[0].reason,
                  )} ${getAnotherMotive(
                    details.travelRequest.reasons[0].another_motive,
                  )}`}
                />
              )}
              {details.travelRequest.status === 'REJECTED' && (
                <Alert
                  type="error"
                  title="Solicitação reprovada"
                  desc={`Motivo: ${getReasonLabel(
                    details.travelRequest.reasons[0].reason,
                  )}
                  ${getAnotherMotive(
                    details.travelRequest.reasons[0].another_motive,
                  )}
                  `}
                />
              )}
              {details.travelRequest.status === 'APPROVED' && (
                <Alert type="success" title="Solicitação aprovada" />
              )}
            </div>

            {details.travelRequest.flights.length > 0 && (
              <SVFlight travelRequest={details.travelRequest} />
            )}
          </>
        )}
      </FullRequestContainer>
    </MainContainer>
  )
}

export default FullRequest
