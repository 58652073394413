import { Form } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ActionButton,
  Card,
  CardAction,
  Column,
  EmployeesList,
  HandleCompanyFormContent,
  RowContainer,
  StyledInputMask,
} from './styles'
import { useCompany } from './hooks/useCompany'
import Input from 'components/Input'
import { SmilesIcon } from 'components/icons'
import HandleOutsourcedForm from './handleOutsourcedForm'
import DrawerBottomNavigation from 'components/drawerBottomNavigation'
import Button from 'components/button'

type HandleCompany = {
  onFinishCallback: () => void
  isEditMode: boolean
  companyData?: any
}

const HandleCompany: React.FC<HandleCompany> = ({
  onFinishCallback,
  isEditMode,
  companyData,
}) => {
  const { t } = useTranslation()
  const {
    form,
    isLoading,
    onValuesChange,
    isFormVisible,
    setFormVisibility,
    employeesList,
    setEmployeesList,
    onRemoveEmployee,
    submittable,
    onCreateCompany,
    onUpdateCompany,
  } = useCompany(onFinishCallback, companyData)

  return (
    <div data-testid="smiles-handle-company">
      <Form
        form={form}
        onValuesChange={onValuesChange}
        data-testid="smiles-handle-company-form"
      >
        <HandleCompanyFormContent>
          <div className="header">
            {isEditMode ? (
              <p>{t('handle_company.edit_title')}</p>
            ) : (
              <p>{t('handle_company.title')}</p>
            )}
          </div>
          <div className="form-container">
            <div className="form-row">
              <Form.Item
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: t('handle_company.form.required'),
                  },
                ]}
              >
                <Input
                  label={t('handle_company.form.company_name.label')}
                  placeholder={t(
                    'handle_company.form.company_name.placeholder',
                  )}
                />
              </Form.Item>
            </div>
            <div className="form-row">
              <div>
                <p>{t('handle_company.form.cnpj.label')}</p>
                <Form.Item
                  name="document"
                  rules={[
                    {
                      required: true,
                      message: t('handle_company.form.required'),
                    },
                  ]}
                >
                  <StyledInputMask
                    mask="99.999.999/9999-99"
                    placeholder={t('handle_company.form.cnpj.placeholder')}
                  />
                </Form.Item>
              </div>
            </div>
            {isEditMode ? (
              <></>
            ) : (
              <>
                <p className="outsourced-title">
                  {t('handle_company.outsourced_title')}
                </p>
                <p className="outsourced-description">
                  {t('handle_company.outsourced_add_description')}
                </p>
                <EmployeesList>
                  {employeesList.map((item: any) => {
                    return (
                      <Card key={item.cpf}>
                        <RowContainer>
                          <Column>
                            <p>{item.fullName}</p>
                            <p>{item.cpf}</p>
                          </Column>
                          <Column>
                            <CardAction>
                              <SmilesIcon
                                onClick={() => {
                                  onRemoveEmployee(item.cpf as string)
                                }}
                                type="close"
                                size={28}
                              />
                            </CardAction>
                          </Column>
                        </RowContainer>
                      </Card>
                    )
                  })}
                </EmployeesList>
                <div>
                  <ActionButton
                    type="button"
                    className="global-button-reset"
                    onClick={() => {
                      setFormVisibility(true)
                    }}
                  >
                    <SmilesIcon size={18} type="plus-rounded" />
                    {t('handle_company.outsourced_add_button')}
                  </ActionButton>
                </div>
              </>
            )}
            <div>
              {isFormVisible && (
                <div>
                  <HandleOutsourcedForm
                    onFinishCallback={() => {
                      setFormVisibility(false)
                    }}
                    setEmployeesList={setEmployeesList}
                  />
                </div>
              )}
            </div>
          </div>
        </HandleCompanyFormContent>
        <div className="drawer-bottom">
          <DrawerBottomNavigation>
            <Button
              disabled={!submittable || isLoading}
              onClick={isEditMode ? onUpdateCompany : onCreateCompany}
              width="340px"
              isLoading={isLoading}
            >
              {isEditMode
                ? t('handle_company.edit_button')
                : t('new_outsourced.confirmButton')}
            </Button>
          </DrawerBottomNavigation>
        </div>
      </Form>
    </div>
  )
}

export default HandleCompany
