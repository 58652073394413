import React, { useEffect, useState } from 'react'
import DrawerBottomNavigation from 'components/drawerBottomNavigation'
import Button from 'components/button'
import { Form } from 'antd'
import Select from 'components/select'
import { getAllUsers } from 'services/users'
import { formatSelectOptions } from 'utils/formatSelectOptions'
import { useTranslation } from 'react-i18next'
import { SUser } from 'shared/interfaces/SUser'
import { RemoveTravelerButton, AddTravelerContent } from './styles'
import { SmilesIcon } from 'components/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'

type STraveler = {
  label: string
  value: string
}

export const difference = (arr1: any, arr2: any) => {
  if (!arr1 || !arr2) return []
  const set1 = new Set(arr1.map((item: any) => item.value))
  const set2 = new Set(arr2.map((item: any) => item.value))
  const arrDiff1 = arr1.filter((item: any) => !set2.has(item.value))
  const arrDiff2 = arr2.filter((item: any) => !set1.has(item.value))
  return [...arrDiff1, ...arrDiff2]
}

const AddTravelers: React.FC<{
  onClose: Function
}> = ({ onClose }) => {
  const { t } = useTranslation()
  const { user } = useAuth0()
  const [users, setUsers] = useState<SUser[]>()
  const [options, setOptions] = useState<
    | {
        label: string
        value: string
      }[]
    | undefined
  >([])
  const [traveler, setTraveler] = useState<STraveler | null>()
  const [isLoading, setIsLoading] = useState(true)
  const [form] = Form.useForm()
  const { addTravelers, travelers } = useFlowContext()

  const handleFinish = async () => {
    if (traveler) {
      addTravelers([{ ...traveler } as { label: string; value: string }])
      onClose()
      form.resetFields()
      setTraveler(null)
      return
    }
    form.validateFields()
  }

  const fetchAllUsers = async () => {
    try {
      const res = await getAllUsers()
      setUsers(res)
      setIsLoading(false)
      setOptions(
        formatSelectOptions({
          data: users,
          label: 'name',
          value: 'id',
        }),
      )
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onCancel = () => {
    setTraveler(null)
    form.resetFields()
    onClose()
  }

  const getSelectOptions = () => {
    const formattedUsers = users?.map((x) => ({ value: x.id, label: x.name }))
    return formatSelectOptions({
      data: difference(formattedUsers, travelers),
      label: 'label',
      value: 'value',
    })
  }

  useEffect(() => {
    fetchAllUsers()
  }, [travelers])

  return (
    <div data-testid="smiles-add-travelers-modal-content">
      <Form form={form} data-testid="smiles-new-group-form">
        <AddTravelerContent>
          <p className="header">{t('component.search_form.add_third_part')}</p>

          <div>
            <p className="text_bold">{t('component.search_form.requester')}</p>
            <p className="traveler_info">{user?.name}</p>
          </div>

          <div>
            <Form.Item
              name="userName"
              rules={[
                {
                  required: true,
                  message: t('component.search_form.required_field'),
                },
              ]}
            >
              <Select
                label={t('component.search_form.request_travel_to')}
                placeholder={t('component.search_form.search')}
                showSearch
                options={getSelectOptions()}
                loading={isLoading}
                onChange={(_, value) => {
                  setTraveler(value as STraveler)
                }}
                filterOption={(input, option) => {
                  return ((option?.label ?? '') as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }}
              />
            </Form.Item>
            {traveler && (
              <div className="added_traveler_info">
                <p className="text_bold">
                  {t('component.search_form.employee_added')}
                </p>
                <RemoveTravelerButton
                  type="button"
                  className="global-button-reset"
                  onClick={() => {
                    form.resetFields()
                    setTraveler(null)
                  }}
                >
                  {traveler?.label}
                  <SmilesIcon size={26} color="#ffffff" type="close" />
                </RemoveTravelerButton>
              </div>
            )}
          </div>
        </AddTravelerContent>

        <DrawerBottomNavigation>
          <Button buttonType="secondary" onClick={onCancel}>
            {t('component.search_form.cancel')}
          </Button>
          <Button
            onClick={handleFinish}
            disabled={isLoading}
            isLoading={isLoading}
          >
            {t('component.search_form.add')}
          </Button>
        </DrawerBottomNavigation>
      </Form>
    </div>
  )
}

export default AddTravelers
