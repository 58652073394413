import React from 'react'
import { CustomAntdCheckbox } from './styles'

export type CheckboxProps = {
  label?: string | React.ReactNode
  disabled?: boolean
  name?: string
  value?: any
  onChange?: any
  checked?: boolean
}

export const Checkbox = ({
  label,
  disabled = false,
  name,
  value,
  onChange,
  ...rest
}: CheckboxProps) => {
  return (
    <CustomAntdCheckbox
      data-testid="smiles-checkbox"
      disabled={disabled}
      name={name}
      value={value}
      onChange={onChange}
      {...rest}
    >
      {label}
    </CustomAntdCheckbox>
  )
}
