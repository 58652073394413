import React from 'react'
import { TextAreaContainer, TextAreaLabel } from './styles'
import { Input } from 'antd'

export type TextAreaTypes = {
  placeholder?: string
  label?: string
  value?: string | number
  width?: string
  height?: string
  name?: string
  disabled?: boolean
  suffix?: JSX.Element
  onChange?: (e?: any) => void
  maxLength?: number
  minLength?: number
  rows?: number
}

const { TextArea: AntdTextArea } = Input

const TextArea: React.FC<TextAreaTypes> = ({
  placeholder,
  label,
  value,
  width,
  height,
  name,
  disabled = false,
  onChange,
  maxLength,
  minLength,
  rows,
  ...rest
}) => (
  <TextAreaContainer
    width={width}
    height={height}
    data-testid="smiles-text-area-container"
  >
    {label && <TextAreaLabel>{label}</TextAreaLabel>}
    <AntdTextArea
      className="custom-antd-text-area"
      placeholder={placeholder}
      name={name}
      value={value}
      data-testid="smiles-text-area"
      onChange={onChange}
      disabled={disabled}
      maxLength={maxLength}
      minLength={minLength}
      rows={rows}
      {...rest}
    />
  </TextAreaContainer>
)

export default TextArea
