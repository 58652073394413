import styled from 'styled-components'

export const CanceledTravelModalContent = styled.div`
  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;

    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
    }

    .reason {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 24px;

      .reason-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.0015em;
      }
      .reason-text {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.01em;
      }
    }
  }
`
