import styled from 'styled-components'

export const HotelDetailsModalContentStyles = styled.div`
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 150px;

    .title {
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0.0025em;
      padding: 24px 48px;
    }

    .data {
      .data-content {
        padding: 24px 48px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .name {
          font-size: 32px;
          font-weight: 500;
          line-height: 48px;
          letter-spacing: 0.0025em;
        }
        .address {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.005em;
        }
        .refundable {
          background: #f2f2f2;
          padding: 4px 8px;
          gap: 0px;
          border-radius: 16px;
          opacity: 0px;
          width: fit-content;

          p {
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0.01em;
          }
        }
      }
    }
  }

  .bottom-navigation-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .price {
      display: flex;
      flex-direction: column;
      .price-label {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
      }
      .price-value {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
      }
    }
  }
`

export const HotelDetailsImage = styled.div<{ $urlimage?: string }>`
  height: 300px;
  min-width: 100%;
  background: url(${(props) => props.$urlimage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`
