const ptNavbar = {
  navbar: {
    links: {
      home: 'Início',
      makeRequest: 'Fazer solicitação',
      myRequests: 'Minhas solicitações',
      myTravels: 'Minhas viagens',
      favorites: 'Favoritos',
      management: 'Gestão',
    },
    linksDropdown: {
      makeRequest: {
        title: 'Selecione o serviço desejado',
        requestAir: 'Aéreo',
        requestAccommodation: 'Hospedagem',
        requestCar: 'Carro',
        requestRoad: 'Rodoviário',
        requestOther: 'Outros serviços',
      },
      myTravels: {
        title: 'Todas as suas viagens',
        changedTrips: 'Viagens alteradas',
        upcomingTrips: 'Próximas viagens',
        previousTrips: 'Viagens anteriores',
      },
      favorites: {
        title: 'Acesse rapidamente seus itens favoritados',
        favorites: 'Favoritos',
        favoriteFlights: 'Voos favoritos',
        favoriteHotels: 'Hotéis favoritos',
        favoriteTravels: 'Viagens favoritas',
      },
      management: {
        title: 'Acesse dados de gestão',
        report: 'Relatório',
        thirdPartyRegistration: 'Cadastro de pessoas terceirizadas',
        advanceAndAccountability: 'Adiantamento e prestação de contas',
      },
    },
    languageDropdown: {
      en: 'Inglês',
      es: 'Espanhol',
      pt: 'Português',
    },
    profileDropdown: {
      hello: 'Olá,',
      myProfile: 'Meu perfil',
      changeToAdmin: 'Mudar para admin',
      userGroups: 'Grupos de usuários',
      logout: 'Sair',
    },
  },
}

export default ptNavbar
