import React, { useState, useEffect } from 'react'
import {
  FlightFiltersCheckboxContainer,
  FlightFiltersRangeContainer,
  FlightFiltersSectionContainer,
  FlightFiltersSectionDivider,
} from './styles'
import { Checkbox as AntdCheckbox, Form } from 'antd'
import { Checkbox } from 'components/checkbox'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'
import { useTranslation } from 'react-i18next'
import RangeWithInputs from 'components/rangeWithInputs'

export type FlightFilters = {
  destinationAiport?: string[]
  flightClass?: string[]
  flightDepartureTime?: string[]
  flightReturnTime?: string[]
  flightStops?: number[]
  maxValue: number
  minValue: number
  originAiport?: string[]
}

const b = 'flights.flightFilterSection.'

const FlightFilterSection: React.FC = () => {
  const { t } = useTranslation()
  const { setFlightFilters, flightFilters } = useFlowContext()
  const [form] = Form.useForm<FlightFilters>()
  const [rangeValues, setRangeValues] = useState([0, 5000])

  useEffect(() => {
    form.setFieldsValue({
      minValue: 0,
      maxValue: 5000,
    })
    setFlightFilters((prevFilters) => ({
      ...prevFilters,
      minValue: 0,
      maxValue: 5000,
    }))
  }, [form, setFlightFilters])

  const handleCheckboxChange = (
    filterKey: keyof FlightFilters,
    e: React.ChangeEvent<HTMLInputElement>,
    value: string | number,
  ): void => {
    const isChecked = e.target.checked
    setFlightFilters((prevFilters: FlightFilters) => {
      const currentValues: any = Array.isArray(prevFilters[filterKey])
        ? prevFilters[filterKey]
        : []

      const updatedValues = isChecked
        ? [...currentValues, value]
        : currentValues.filter((v: string | number) => v !== value)

      return {
        ...prevFilters,
        [filterKey]: updatedValues,
      }
    })
  }

  const handleSliderChange = (values: number[]) => {
    setRangeValues(values)
    form.setFieldsValue({
      minValue: values[0],
      maxValue: values[1],
    })

    setFlightFilters((prevFilters) => ({
      ...prevFilters,
      minValue: values[0],
      maxValue: values[1],
    }))
  }

  const flightStops = [
    { label: t(b + 'flightTypes.direct'), value: 0 },
    { label: t(b + 'flightTypes.oneStop'), value: 1 },
    { label: t(b + 'flightTypes.twoStops'), value: 2 },
  ]

  const flightClasses = [
    {
      label: t(b + 'flightClasses.economic'),
      value: 'ECONOMIC',
    },
    {
      label: t(b + 'flightClasses.premiumEconomy'),
      value: 'ECONOMIC_PREMIUM',
    },
    {
      label: t(b + 'flightClasses.executive'),
      value: 'EXECUTIVE',
    },
    {
      label: t(b + 'flightClasses.firstClass'),
      value: 'FIRST_CLASS',
    },
  ]

  const flightTime = [
    {
      label: t(b + 'flightTime.dawn'),
      value: 'dawn',
    },
    {
      label: t(b + 'flightTime.morning'),
      value: 'morning',
    },
    {
      label: t(b + 'flightTime.afternoon'),
      value: 'afternoon',
    },
    {
      label: t(b + 'flightTime.night'),
      value: 'night',
    },
  ]

  return (
    <FlightFiltersSectionContainer data-testid="smiles-flight-filters-section">
      <Form form={form} initialValues={flightFilters}>
        <p className="filters-container-header">{t(b + 'header')}</p>

        <Form.Item name="flightType">
          <AntdCheckbox.Group>
            <FlightFiltersCheckboxContainer>
              <p>{t(b + 'flightTypeGroupboxHeader')}</p>
              {flightStops.map((data) => (
                <Checkbox
                  key={data.value}
                  label={data.label}
                  name={`flightType-${data.value}`}
                  value={data.value}
                  data-testid="check-box-flight-stops"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleCheckboxChange('flightStops', e, data.value)
                  }
                />
              ))}
            </FlightFiltersCheckboxContainer>
          </AntdCheckbox.Group>
        </Form.Item>

        <FlightFiltersRangeContainer>
          <p>{t(b + 'flightPriceGroupboxHeader')}</p>
          <RangeWithInputs
            rangeValues={rangeValues}
            updateFilter={handleSliderChange}
            maxValueLabel={t(b + 'form.maxValue.label')}
            minValueLabel={t(b + 'form.minValue.label')}
            min={0}
            max={10000}
            step={10}
          />
        </FlightFiltersRangeContainer>

        <div className="container-with-dividers">
          <Form.Item name="flightClass">
            <AntdCheckbox.Group>
              <FlightFiltersCheckboxContainer>
                <p>{t(b + 'flightClassGroupboxHeader')}</p>
                {flightClasses.map((flightClass) => {
                  return (
                    <Checkbox
                      key={flightClass.value}
                      label={flightClass.label}
                      name={`flightClass-${flightClass.value}`}
                      value={flightClass.value}
                      data-testid="check-box-flight-class"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleCheckboxChange(
                          'flightClass',
                          e,
                          flightClass.value,
                        )
                      }
                    />
                  )
                })}
              </FlightFiltersCheckboxContainer>
            </AntdCheckbox.Group>
          </Form.Item>
          <FlightFiltersSectionDivider />

          <Form.Item name="flightDepartureTime">
            <AntdCheckbox.Group>
              <FlightFiltersCheckboxContainer>
                <p>{t(b + 'flightDepartureTimeGroupboxHeader')}</p>
                {flightTime.map((time) => (
                  <Checkbox
                    key={time.value}
                    name={`flightDepartureTime-${time.value}`}
                    value={time.value}
                    data-testid="check-box-flight-departure"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleCheckboxChange('flightDepartureTime', e, time.value)
                    }
                    label={
                      <div className="flight-time-label">
                        <p>
                          <b>{time.label.split(' ')[0]}</b>
                        </p>
                        <p>
                          {time.label.split(' ')[1]} -{' '}
                          {time.label.split(' ')[3]}
                        </p>
                      </div>
                    }
                  />
                ))}
              </FlightFiltersCheckboxContainer>
            </AntdCheckbox.Group>
          </Form.Item>
          <FlightFiltersSectionDivider />

          <Form.Item name="flightReturnTime">
            <AntdCheckbox.Group>
              <FlightFiltersCheckboxContainer>
                <p>{t(b + 'flightReturnTimeGroupboxHeader')}</p>
                {flightTime.map((time) => (
                  <Checkbox
                    key={time.value}
                    name={`flightReturnTime-${time.value}`}
                    value={time.value}
                    data-testid="check-box-flight-return"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleCheckboxChange('flightReturnTime', e, time.value)
                    }
                    label={
                      <div className="flight-time-label">
                        <p>
                          <b>{time.label.split(' ')[0]}</b>
                        </p>
                        <p>
                          {time.label.split(' ')[1]} -{' '}
                          {time.label.split(' ')[3]}
                        </p>
                      </div>
                    }
                  />
                ))}
              </FlightFiltersCheckboxContainer>
            </AntdCheckbox.Group>
          </Form.Item>
          <FlightFiltersSectionDivider />

          {/* uncomment when we start implement the feature
          <FlightFilterCollapse label={t(b + 'originAirport')}>
            <Form.Item name="originAiport">
              <AntdCheckbox.Group>
                <FlightFiltersCheckboxContainer>
                  {originAirport.map((origin) => (
                    <Checkbox
                      key={origin.value}
                      name={`originAirport-${origin.value}`}
                      value={origin.value}
                      label={
                        <div className="flight-airport-label">
                          <p>{origin.label}</p>
                          <SmilesIcon
                            type="airplaneUp"
                            color={Color.PRIMARY}
                            size={20}
                          />
                        </div>
                      }
                    />
                  ))}
                </FlightFiltersCheckboxContainer>
              </AntdCheckbox.Group>
            </Form.Item>
          </FlightFilterCollapse>
          <FlightFiltersSectionDivider />

          <FlightFilterCollapse label={t(b + 'destinationAirport')}>
            <Form.Item name="destinationAiport">
              <AntdCheckbox.Group>
                <FlightFiltersCheckboxContainer>
                  {destinationAirport.map((origin) => (
                    <Checkbox
                      key={origin.value}
                      name={`destinationAirport-${origin.value}`}
                      value={origin.value}
                      label={
                        <div className="flight-airport-label">
                          <p>{origin.label}</p>
                          <SmilesIcon
                            type="airplaneUp"
                            color={Color.PRIMARY}
                            size={20}
                          />
                        </div>
                      }
                    />
                  ))}
                </FlightFiltersCheckboxContainer>
              </AntdCheckbox.Group>
            </Form.Item>
          </FlightFilterCollapse>
          <FlightFiltersSectionDivider /> */}

          {/* <FlightFiltersButtonsContainer>
            <p>{t(b + 'lookingQuote')}</p>
            <Button size="sm">{t(b + 'getQuote')}</Button>
          </FlightFiltersButtonsContainer>
          <FlightFiltersButtonsContainer>
            <p>{t(b + 'flightWantedNotFound')}</p>
            <Button size="sm">{t(b + 'askTheAgency')}</Button>
          </FlightFiltersButtonsContainer> */}
        </div>
      </Form>
    </FlightFiltersSectionContainer>
  )
}

export default FlightFilterSection
