const enMyTravels = {
  my_travels: {
    cancelled: {
      title: 'Travel cancellation',
      canceled_by_policy: 'Canceled by cancellation policy',
      cancelation_reason: 'Cancellation reason',
    },
    cancel: {
      required_field: 'Required field',
      cancel_reason: 'Cancellation reason',
      detail_cancel_reason: 'Clarify the reasons for cancellation',
      field_constraints: 'Minimum of 10 and maximum of 300 characters.',
      cancel: 'Cancel',
      proceed: 'Proceed',
    },
    dropdown: {
      download_in_en: 'Download in English',
      download_in_es: 'Download in Spanish',
      download_in_pt: 'Download in Portuguese',
    },
    travels: {
      all: 'All',
      next: 'Next travels',
      cancelled_travels: 'Cancelled travels',
      previous_travels: 'Previous travels',
      your_cancelled_travels: 'Your cancelled travels',
      your_previous_travels: 'Your previous travels',
      see_all: 'See all',
      traveller: 'Traveller',
      flight_operator: 'Flight operator',
      status: 'Status',
      locator: 'Locator',
      see_all_details: 'See full request',
      cancel_travel: 'Cancel travel',
      download_voucher: 'Download voucher',
      cancellation_requested_by_employee: 'Cancelled at employee request',
      title: 'My travels',
    },
    voucher_download_error: 'Failed to download voucher.',
  },
}

export default enMyTravels
