import styled from 'styled-components'

export const FooterStyles = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  background-color: #ffffff;
  box-shadow: 0px 0px 48px 0px #00000029;
  z-index: 50;
  padding: 16px 156px;
  justify-content: end;

  @media screen and (max-width: 1270px) {
    padding: 16px 24px;
  }
`
