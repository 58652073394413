import React from 'react'
import {
  TravelInfo,
  FlightCardRadioContainer,
  FlightCardRadioDetails,
  FlightCardRadioLuggage,
} from './styles'
import { SmilesIcon } from 'components/icons'
import ClassAndFare from './classAndFare'
import { Flight } from 'services/flights/flightModel'
import AirlineIcon from 'components/flightDetails/airlineIcon'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Color } from 'styles/global'

export type FlightCardRadioProps = {
  checked: boolean
  flightData: Flight
  onClick: () => void
}

const FlightCardRadio: React.FC<FlightCardRadioProps> = ({
  checked,
  flightData,
  onClick,
}) => {
  const { t } = useTranslation()

  const brTimezoneInMinutes = 180

  const stopsText = () => {
    const stopsCount = flightData.stopsCount
    switch (stopsCount) {
      case 0:
        return t('component.stops_and_baggage.direct')
      case 1:
        return `1 ${t('component.stops_and_baggage.stop')}`
      default:
        return `${stopsCount} ${t('component.stops_and_baggage.stops')}`
    }
  }

  return (
    <FlightCardRadioContainer
      $checked={checked}
      data-testid="smiles-flight-card-radio"
      onClick={onClick}
    >
      <div className="check-container">
        <div>{checked && <SmilesIcon type="check" />}</div>
      </div>
      <div className="flight-data">
        <TravelInfo>
          <FlightCardRadioDetails>
            <AirlineIcon />

            <div className="info-container">
              <p className="airline-name">{`${flightData.airline.code} ${flightData.airline.name}`}</p>
              <div className="travel-info-data">
                <div>
                  <p className="airport-and-hour">
                    {flightData.origin.code}
                    <b>
                      {dayjs(flightData.departure)
                        .utcOffset(-brTimezoneInMinutes)
                        .format('H[h]mm')}
                    </b>
                  </p>
                  <p className="travel-city"> {flightData.origin.city.name} </p>
                </div>
                <SmilesIcon type="plane-right" size={18} color="#ff7020" />
                <div>
                  <p className="airport-and-hour">
                    {flightData.destination.code}
                    <b>
                      {dayjs(flightData.arrival)
                        .utcOffset(-brTimezoneInMinutes)
                        .format('H[h]mm')}
                    </b>
                  </p>
                  <p className="travel-city">
                    {flightData.destination.city.name}
                  </p>
                </div>
              </div>
            </div>
          </FlightCardRadioDetails>
          <FlightCardRadioLuggage>
            <p>
              <b>{stopsText()}</b>
            </p>
            <div className="amount-luggage">
              <div>
                <SmilesIcon type="backpack" color={Color.PRIMARY} size={18} />
                <p>
                  <b>{flightData.segments[0].baggage.quantity}</b>
                </p>
              </div>
              <div>
                <SmilesIcon type="work" color={Color.PRIMARY} size={18} />
                <p>
                  <b>{flightData.segments[0].baggage.quantity}</b>
                </p>
              </div>
              <div>
                <SmilesIcon type="luggage" color={Color.PRIMARY} size={18} />
                <p>
                  <b>{flightData.segments[0].baggage.quantity}</b>
                </p>
              </div>
            </div>
          </FlightCardRadioLuggage>
          <ClassAndFare flightData={flightData} />
        </TravelInfo>
      </div>
    </FlightCardRadioContainer>
  )
}

export default FlightCardRadio
