import React from 'react'
import { DropdownLangContentStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { SmilesIcon } from 'components/icons'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'

interface LocaleMap {
  [key: string]: string
}

const DropdownLangContent: React.FC = () => {
  const { t, i18n } = useTranslation()

  const changeLanguage = (newLanguage: string) => {
    i18n.changeLanguage(newLanguage)
    localStorage.setItem('userLanguage', newLanguage)

    const localeMap: LocaleMap = {
      es: 'es',
      en: 'en',
      pt: 'pt-BR',
    }

    const selectedLocale = localeMap[newLanguage] || 'pt-BR'
    localStorage.setItem('userLocale', selectedLocale)
    dayjs.locale(selectedLocale)
  }

  return (
    <DropdownLangContentStyles data-testid="smiles-dropdown-lang-content">
      <button onClick={() => changeLanguage('es')} data-testid="spn-button">
        <div className="country-flag">
          <SmilesIcon type="spain" />
        </div>

        <p>{t('navbar.languageDropdown.es')}</p>
      </button>
      <button onClick={() => changeLanguage('en')} data-testid="en-button">
        <div className="country-flag">
          <SmilesIcon type="usa" />
        </div>

        <p>{t('navbar.languageDropdown.en')}</p>
      </button>
      <button onClick={() => changeLanguage('pt')} data-testid="pt-button">
        <div className="country-flag">
          <SmilesIcon type="brazil" />
        </div>
        <p>{t('navbar.languageDropdown.pt')}</p>
      </button>
    </DropdownLangContentStyles>
  )
}

export default DropdownLangContent
