import { Flight } from 'services/flights/flightModel'
import { SFlightItemCart } from 'shared/interfaces/SFlightCart'

export function getPriceFromCart(flight: Flight): number {
  const fare = flight.fares.find(
    (flightFare: any) => flightFare.type === 'FARE',
  )
  return fare ? fare.price.amount : 0
}

const defaultLogo =
  'https://smileshml.infotravel.com.br/infotravel/resources/images/ciaaerea/G3.png'

export function mountFlightCart(flight: Flight): SFlightItemCart {
  return {
    departureTime: flight.departure,
    returnDate: flight.arrival,
    stopsCount: flight.stopsCount,
    amount: getPriceFromCart(flight),
    key: flight.key,
    number: flight.number,
    duration: flight.duration,
    origin: flight.origin.code,
    destination: flight.destination.code,
    baggage: String(flight.segments[0].baggage.quantity),
    routes: flight.segments.map((segment: any) => ({
      departure: segment.departure,
      arrival: segment.arrival,
      typeOfFlight: segment.flightType,
      airlineName: segment.airline.name,
      airlineLogo: flight.airline.logo ?? defaultLogo,
    })),
  }
}
