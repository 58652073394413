import { Slider } from 'antd'
import styled from 'styled-components'
import { Color } from 'styles/global'

export const StyledSlider = styled(Slider)`
  .antd-slider:hover,
  .ant-slider-track {
    background-color: ${Color.PRIMARY} !important;
    height: 8px !important;
    margin-top: -1px !important;
  }

  .ant-slider-handle {
    &::after {
      background: ${Color.PRIMARY} !important;
      box-shadow: 0 0 0 2px ${Color.PRIMARY} !important;
      height: 20px !important;
      width: 20px !important;
      margin-top: -5px !important;
    }
  }

  .ant-slider-handle:hover::after,
  .ant-slider-handle:active::after,
  .ant-slider-handle:focus::after {
    box-shadow: 0 0 0 4px #ff5c00 !important;
  }
`
