import React from 'react'
import {
  CardList,
  Column,
  Card,
  Label,
  RowContainer,
  PaginationContainer,
} from './styles'
import { useTranslation } from 'react-i18next'
import { SmilesIcon } from 'components/icons'
import { formatCNPJ } from 'utils/formatters'
import { Pagination } from 'antd'

type CompaniesListData = {
  data: any[]
  onItemClick: any
  pagination: {
    page: number
    limit: number
    total: number
    onCompaniesPageChange: (page: number) => void
  }
}

const CompaniesList: React.FC<CompaniesListData> = ({
  data,
  onItemClick,
  pagination,
}) => {
  const { t } = useTranslation()
  return (
    <CardList>
      {data.map((company: any) => {
        return (
          <Card onClick={() => onItemClick(company.id)} key={company.id}>
            <RowContainer>
              <Column>
                <Label>{t('companies_list.card.company_name.label')}</Label>
                <p>{company.company_name}</p>
              </Column>
              <Column>
                <Label>{t('companies_list.card.cnpj.label')}</Label>
                <p>{formatCNPJ(company.document)}</p>
              </Column>
              <Column>
                <SmilesIcon type="arrow-right-alt" size={32} />
              </Column>
            </RowContainer>
          </Card>
        )
      })}
      <PaginationContainer>
        <Pagination
          showTotal={(total) => `Total ${total} items`}
          defaultCurrent={1}
          current={pagination.page}
          pageSize={pagination.limit}
          total={pagination.total}
          onChange={pagination.onCompaniesPageChange}
        />
      </PaginationContainer>
    </CardList>
  )
}
export default CompaniesList
