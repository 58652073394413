import styled from 'styled-components'

export const HotelSearchContainer = styled.div`
  > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .search-dropdowns-container {
    display: flex;
    flex-direction: row;
    gap: 18px;
    @media screen and (max-width: 650px) {
      flex-direction: column;
    }
  }
  .search-inputs-container {
    width: 100%;
  }
  .search-buttom-container {
    width: 100%;
    display: flex;
    justify-content: end;
    > {
      button {
        width: 276px;
      }
    }
    @media screen and (max-width: 840px) {
      > button {
        width: 100%;
      }
    }
  }
`
