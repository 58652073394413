const enUser = {
  user: {
    informations: 'Information',
    permissions: 'Permissions',
    permissionsTab: {
      treeSelect: {
        label: 'Select user permissions',
        placeholder: 'Permissions',
      },
      updatePermissions: 'Update permissions',
      updateConfirm: {
        title: 'Update permissions',
        description: 'Do you want to update this user’s permissions?',
      },
      noViewPermissions: 'You do not have permission to view user permissions.',
      noUpdatePermissions:
        'Note: You will not be able to change the user’s permissions because you do not have permission to perform this action.',
    },
    notify: {
      msg: {
        success: 'Success!',
        error: 'Error!',
      },
      desc: {
        success: 'Permissions updated successfully!',
        error:
          'There is something wrong with the permissions, please check the data or try again later.',
      },
    },
    popConfirm: {
      yes: 'Yes',
      cancel: 'Cancel',
    },
    noData: 'No data',
  },
}

export default enUser
