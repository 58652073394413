export default `
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.999 7C15.789 7 19.169 9.13 20.819 12.5C19.169 15.87 15.789 18 11.999 18C8.20902 18 
4.82902 15.87 3.17902 12.5C4.82902 9.13 8.20902 7 11.999 7ZM11.999 5C6.99902 5 2.72902 8.11 0.999023 
12.5C2.72902 16.89 6.99902 20 11.999 20C16.999 20 21.269 16.89 22.999 12.5C21.269 8.11 16.999 5 11.999 
5ZM11.999 10C13.379 10 14.499 11.12 14.499 12.5C14.499 13.88 13.379 15 11.999 15C10.619 15 9.49902 13.88 
9.49902 12.5C9.49902 11.12 10.619 10 11.999 10ZM11.999 8C9.51902 8 7.49902 10.02 7.49902 12.5C7.49902 14.98 
9.51902 17 11.999 17C14.479 17 16.499 14.98 16.499 12.5C16.499 10.02 14.479 8 11.999 8Z" fill="#1A1A1A"/>
</svg>
`
