import { mountFlightCart } from 'components/summaryModalContent/helpers/mountFlightCart'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'
import { postCartFlight } from 'services/flights'
import { Flight } from 'services/flights/flightModel'
import { SFlightCart } from 'shared/interfaces/SFlightCart'
import { SMyProfile } from 'shared/interfaces/SMyProfile'
import { SMyTravelFlight } from 'shared/interfaces/SMyTravel'
import { getCustomCookie } from 'utils/cookies/cookiesUtils'
import { notifyError } from 'utils/handleRequestNotify'
import { getFromStorage } from 'utils/localStorage/storageUtils'

type CartFlights = {
  departure: Flight
  return?: Flight
}

type UseFlightCartProps = {
  selectedFlights?: CartFlights
}

export const useFlightCart = ({ selectedFlights }: UseFlightCartProps) => {
  const { setFlightsCart } = useFlowContext()

  return async () => {
    if (!selectedFlights?.departure) {
      return
    }

    try {
      const flights = [mountFlightCart(selectedFlights.departure)]
      if (selectedFlights.return?.key) {
        flights.push(mountFlightCart(selectedFlights.return))
      }

      let cart: SFlightCart

      const travelRequestId = await getCustomCookie('travel_request_id')
      const user: SMyProfile = await getFromStorage('my_profile_data')

      if (travelRequestId) {
        cart = {
          flights,
          travel_request_id: travelRequestId,
        }
      } else {
        const travel_request = {
          consultant_id: user.id,
          requester_id: user.id,
          origin: selectedFlights.departure.origin.city.name ?? 'n/a',
          destination: selectedFlights.departure.destination.city.name ?? 'n/a',
          travelers: [user.id],
        }

        cart = {
          flights,
          travel_request,
        }
      }
      const cartResponse: SMyTravelFlight[] = await postCartFlight(cart)
      setFlightsCart(cartResponse)
    } catch (error: any) {
      notifyError(error.message)
      throw error
    }
  }
}
