import React, { useState } from 'react'
import Tab, { TabContentTypes } from 'components/tab'
import { Container, ContainerSearch } from './styles'
import { useTranslation } from 'react-i18next'
import TabGroup from 'components/TabGroup'
import FlightFormSearch from 'components/searchForms/flights'
import HotelFormSearch from 'components/searchForms/hotel'

const b = 'home.searchSection.'

const SearchSection: React.FC = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState<number>(0)

  const handleTabClick = (index: number) => {
    setActiveTab(index)
  }

  const TabsData: TabContentTypes[] = [
    {
      label: t(b + 'tabs.air'),
      icon: 'plane-right',
    },
    // {
    //   label: t(b + 'tabs.hotel'),
    //   icon: 'bed',
    // },
    // descomentar quando tivermos outros serviços.
    // {
    //   label: t(b + 'tabs.other'),
    //   icon: 'ticket',
    // },
  ]

  return (
    <Container data-testid="smiles-search-section">
      <TabGroup>
        {TabsData.map((data, idx: number) => {
          return (
            <Tab
              key={data.label}
              $active={idx === activeTab}
              onClick={() => handleTabClick(idx)}
              label={data.label}
              icon={data.icon}
            />
          )
        })}
      </TabGroup>
      <ContainerSearch>
        {activeTab === 0 && <FlightFormSearch />}
        {activeTab === 1 && <HotelFormSearch />}
      </ContainerSearch>
    </Container>
  )
}

export default SearchSection
