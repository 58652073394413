const enPermissions = {
  permissions: {
    home: 'Home',
    flights: 'Flights',
    user_groups: 'User groups',
    choose_permissions: 'Choose permissions',
  },
}

export default enPermissions
