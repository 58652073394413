import styled from 'styled-components'

export const PermissionTreeSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  p {
    font-size: 18px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 21px;
    letter-spacing: 0.035px;
  }
`
