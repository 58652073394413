import { FlightAvailability } from 'services/flights/flightModel'

export const filterFlightsByRoute = (
  data: FlightAvailability[],
  numberRoute: number,
) => {
  return data
    .filter((flightAvailability) =>
      flightAvailability.routes.some(
        (route) => route.numberRoute === numberRoute,
      ),
    )
    .map((flightAvailability) =>
      flightAvailability.routes.find(
        (route) => route.numberRoute === numberRoute,
      ),
    )
    .filter(Boolean)
}
