export const config = () => {
  const ENV: any = window['env' as any]
  return {
    REACT_APP_AUTH0_DOMAIN: ENV?.REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID: ENV?.REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_AUTH0_AUDIENCE: ENV?.REACT_APP_AUTH0_AUDIENCE,
    REACT_APP_AUTH0_TOKEN_VALIDITY_IN_SECONDS:
      ENV?.REACT_APP_AUTH0_TOKEN_VALIDITY_IN_SECONDS,
    REACT_APP_API_URL: ENV?.REACT_APP_API_URL,
    REACT_APP_SMILES_VIAGENS_API_URL: ENV?.REACT_APP_SMILES_VIAGENS_API_URL,
    REACT_APP_VAPID_PUBLIC_KEY: ENV?.REACT_APP_VAPID_PUBLIC_KEY,
  }
}
