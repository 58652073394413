const createDownloadAnchorForBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = filename

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      removeEventListener('click', clickHandler)
    }, 10)
  }

  anchor.addEventListener('click', clickHandler, false)

  return anchor
}

export { createDownloadAnchorForBlob }
