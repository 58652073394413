import styled from 'styled-components'

export const FlightCardRadioContainer = styled.div<{ $checked: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  cursor: pointer;

  .check-container {
    display: flex;
    padding: 0px 24px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px 0px 0px 16px;
    border-top: 2px solid #999;
    border-left: 2px solid #999;
    border-bottom: 2px solid #999;
    > div {
      display: flex;
      width: 24px;
      height: 24px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      border: 3px solid #999;

      ${({ $checked }) =>
        $checked &&
        `
        background: var(--primary-color);
        border: none;
      `}
    }
  }

  .flight-data {
    display: flex;
    padding: 32px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 0px 16px 16px 0px;
    border: 2px solid #999;
  }
`

export const TravelInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  gap: 12px;

  > div {
    align-items: center;
  }
`

export const FlightCardRadioDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  .airline-icon {
    width: 40px;
    height: 40px;
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }

  .info-container {
    .airline-name {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.12px;
      margin-bottom: 16px;
    }
    .travel-info-data {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .airport-and-hour {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.08px;
          b {
            margin-left: 4px;
          }
        }
        .travel-city {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.12px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .airline-name {
      font-size: 10px;
      margin-bottom: 16px;
    }
    .travel-info-data {
      > div {
        .airport-and-hour {
          font-size: 12px;
        }
        .travel-city {
          font-size: 8px;
        }
      }
    }
  }
`

export const FlightCardRadioLuggage = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
  justify-content: space-between;
  align-items: end;

  .amount-luggage {
    display: flex;
    flex-direction: row;
    gap: 8px;

    > div {
      display: flex;
      flex-direction: row;
      gap: 4px;

      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.035px;
    }
  }

  @media screen and (max-width: 745px) {
    font-size: 14px;

    .amount-luggage {
      > div {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.035px;
      }
    }
  }
`
