import styled from 'styled-components'

export const BookingDetailsCellStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 8px;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.001em;
    color: #1a1a1a;
    gap: 8px;
  }

  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
  }
`
