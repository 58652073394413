import MainContainer from 'components/mainContainer'
import React, { useEffect, useState } from 'react'
import SearchSection from './sections/searchSection'
import RquestsTableSection, {
  SRequestsTableItemProps,
} from './sections/requestsTableSection'
import { HomeContent } from './styles'
import MyTravelsSection from './sections/myTravelsSection'
import { createUserSubscription } from 'services/notification'
import {
  registerServiceWorker,
  subscribeToPushNotifications,
} from 'services/serviceWorker'
import { bufferToBase64 } from 'utils/parseBase64'
import {
  adaptMyTravelsToMyTravelsTable,
  adaptTravelReqsToReqsTable,
  fetchTravelData,
  StatusEnum,
} from 'services/travelRequest'
import { SMyTravelsTable } from 'shared/interfaces/SMyTravelsTable'
import { getUser } from 'services/users'
import { setStorage } from 'utils/localStorage/storageUtils'
import { TypeEnum } from 'constants/typeEnum'

export const initializeServiceWorker = async () => {
  try {
    const permission = await Notification.requestPermission()
    const swReg = await registerServiceWorker()

    if (permission === 'granted' && swReg) {
      const subscription = await subscribeToPushNotifications(swReg)
      const p256dhKey = subscription.getKey('p256dh')
      const authKey = subscription.getKey('auth')
      const subscriptionEndpoint = subscription.endpoint

      let p256dhString = ''
      let authString = ''

      if (p256dhKey && authKey) {
        p256dhString = bufferToBase64(p256dhKey)
        authString = bufferToBase64(authKey)
      }

      createUserSubscription(authString, p256dhString, subscriptionEndpoint)
    } else {
      console.warn('Notifications permission not granted')
    }
  } catch (error) {
    console.error('Service Worker registration failed:', error)
  }
}

const Home: React.FC = () => {
  const [travelRequestsLoading, setTravelRequestsLoading] = useState(true)
  const [travelRequests, setTravelRequests] = useState<
    SRequestsTableItemProps[]
  >([])
  const [myTravelsLoading, setMyTravelsLoading] = useState(true)
  const [myTravels, setMyTravels] = useState<SMyTravelsTable[]>([])

  useEffect(() => {
    const initialize = async () => {
      const serviceWorkerPromise = initializeServiceWorker()
      const myProfilePromise = getUser()

      const travelRequestsPromise = fetchTravelData({
        status: [
          StatusEnum.WAITING_FOR_APPROVAL,
          StatusEnum.APPROVED,
          StatusEnum.CANCELED,
          StatusEnum.REJECTED,
        ],
        order: 'NEW',
        maxItems: 5,
        adapterDataFn: adaptTravelReqsToReqsTable,
        setLoading: setTravelRequestsLoading,
        setDataFn: setTravelRequests,
      })

      const travelsPromise = fetchTravelData({
        status: [StatusEnum.CONFIRMED],
        order: 'NEW',
        type: [TypeEnum.ONLY_MY_TRAVELS],
        maxItems: 5,
        adapterDataFn: adaptMyTravelsToMyTravelsTable,
        setLoading: setMyTravelsLoading,
        setDataFn: setMyTravels,
      })

      await serviceWorkerPromise
      await travelRequestsPromise
      await travelsPromise

      setStorage('my_profile_data', await myProfilePromise)
    }

    initialize()
  }, [])

  return (
    <MainContainer>
      <HomeContent data-testid="smiles-home-screen">
        <SearchSection />
        <RquestsTableSection
          items={travelRequests}
          loading={travelRequestsLoading}
        />
        <MyTravelsSection items={myTravels} loading={myTravelsLoading} />
      </HomeContent>
    </MainContainer>
  )
}

export default Home
