import { Drawer } from 'antd'
import styled from 'styled-components'

export const DrawerStyles = styled(Drawer)`
  .ant-drawer-wrapper-body {
    .ant-drawer-header {
      padding: 25px 20px;
      border: none;

      .ant-drawer-header-title {
        justify-content: end;
      }
    }
    .ant-drawer-body {
      padding: 0;
    }
  }
`
