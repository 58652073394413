export default `
<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" width="40" height="40" rx="20" fill="#FF7021"/>
<path d="M22.2163 10.7012C21.6302 10.7012 21.0569 10.7557 20.501 10.859C24.809 11.6645 28.0703 
15.116 28.0703 19.6585C28.0703 22.8919 25.4497 25.8398 22.2163 25.8398C21.6198 25.8398 21.0441 
25.7504 20.501 25.5845C18.1044 24.851 16.3623 22.6227 16.3623 19.9858C16.3623 17.349 18.1055 
15.1207 20.501 14.3872C19.959 14.2212 19.3822 14.1319 18.7856 14.1319C15.5522 14.1319 12.9316 
17.0809 12.9316 20.3131C12.9316 24.8545 16.1929 28.3072 20.501 29.1115C21.0569 29.2148 21.6302 
29.2693 22.2163 29.2693C27.3438 29.2693 31.501 25.1121 31.501 19.9847C31.501 14.8572 27.3438 
10.7012 22.2163 10.7012Z" fill="#F3A677"/>
<path d="M18.7847 10.7148C19.3708 10.7148 19.9441 10.7694 20.5 10.8727C24.8081 11.6781 28.0693 
15.1297 28.0693 19.6722C28.0693 22.9056 25.4487 25.8535 22.2153 25.8535C21.6188 25.8535 21.042 
25.7641 20.5 25.5982C22.8966 24.8647 24.6386 22.6352 24.6386 19.9995C24.6386 17.3627 22.8954 
15.1343 20.5 14.4009C19.9568 14.2349 19.3812 14.1455 18.7847 14.1455C15.5513 14.1455 12.9307 
17.0946 12.9307 20.3268C12.9307 24.8681 16.1919 28.3209 20.5 29.1252C19.9441 29.2296 19.3708 
29.2842 18.7847 29.2842C13.6572 29.2842 9.5 25.127 9.5 19.9995C9.5 14.8721 13.6572 10.7148 
18.7847 10.7148Z" fill="white"/>
</svg>
`
