import {
  airplane,
  airplanes,
  airplaneDown,
  airplaneUp,
  arrowRight,
  attention,
  bed,
  backpack,
  burgerMenu,
  bus,
  cancel,
  car,
  check,
  checkCircle,
  checkList,
  close,
  dollarCircle,
  dotFlag,
  dropdownArrow,
  exclamation,
  gol,
  luggage,
  peopleCheck,
  planeRight,
  planeTicket,
  report,
  sleep,
  starCircle,
  starOutline,
  search,
  ticket,
  user,
  warning,
  smilesCorp,
  brazil,
  spain,
  usa,
  work,
  plusRounded,
  trash,
  pen,
  profile,
  travel,
  searchGray,
  checkCircleOutline,
  person,
  tour,
  pinDrop,
  label,
  lens,
  confirmationNumber,
  calendarMonth,
  hotel,
  starRate,
  room,
  arrowCircleLeft,
  arrowCircleRight,
  logo,
  simpleStar,
  calendar,
  squareArrow,
  copy,
  circleClose,
  circleCloseBlack,
  golFull,
  arrowRightAlt,
  arrowLeftAlt,
  business,
  visibility,
  block,
  enable,
} from './iconsText'

// To add an icon, use kebab-case on key name and insert only SVG paths on value
export const iconsPaths: Record<string, string> = {
  airplane,
  airplanes,
  'airplane-down': airplaneDown,
  airplaneUp,
  'arrow-right': arrowRight,
  attention,
  bed,
  'burger-menu': burgerMenu,
  bus,
  backpack,
  cancel,
  car,
  check,
  'check-circle': checkCircle,
  'check-list': checkList,
  close,
  'dollar-circle': dollarCircle,
  'dot-flag': dotFlag,
  'dropdown-arrow': dropdownArrow,
  exclamation,
  gol,
  luggage,
  'people-check': peopleCheck,
  'plane-right': planeRight,
  'plane-ticket': planeTicket,
  report,
  sleep,
  'star-circle': starCircle,
  search,
  'star-outline': starOutline,
  ticket,
  user,
  warning,
  'smiles-corp': smilesCorp,
  brazil,
  spain,
  usa,
  work,
  'plus-rounded': plusRounded,
  trash,
  pen,
  profile,
  travel,
  'search-gray': searchGray,
  'check-circle-outline': checkCircleOutline,
  person,
  tour,
  'pin-drop': pinDrop,
  label,
  lens,
  'confirmation-number': confirmationNumber,
  'calendar-month': calendarMonth,
  hotel,
  'star-rate': starRate,
  room,
  'arrow-circle-left': arrowCircleLeft,
  'arrow-circle-right': arrowCircleRight,
  logo,
  'simple-star': simpleStar,
  calendar,
  'square-arrow': squareArrow,
  copy,
  'circle-close': circleClose,
  'circle-close-black': circleCloseBlack,
  'gol-full': golFull,
  'arrow-right-alt': arrowRightAlt,
  'arrow-left-alt': arrowLeftAlt,
  business,
  visibility,
  block,
  enable,
}

export type iconType =
  | 'airplane'
  | 'airplanes'
  | 'airplane-down'
  | 'airplaneUp'
  | 'arrow-right'
  | 'attention'
  | 'bed'
  | 'burger-menu'
  | 'bus'
  | 'backpack'
  | 'cancel'
  | 'car'
  | 'check'
  | 'check-circle'
  | 'check-list'
  | 'close'
  | 'dollar-circle'
  | 'dot-flag'
  | 'dropdown-arrow'
  | 'exclamation'
  | 'gol'
  | 'luggage'
  | 'people-check'
  | 'plane-right'
  | 'plane-ticket'
  | 'report'
  | 'sleep'
  | 'star-circle'
  | 'star-outline'
  | 'search'
  | 'ticket'
  | 'user'
  | 'warning'
  | 'smiles-corp'
  | 'brazil'
  | 'spain'
  | 'usa'
  | 'work'
  | 'plus-rounded'
  | 'trash'
  | 'pen'
  | 'profile'
  | 'travel'
  | 'search-gray'
  | 'check-circle-outline'
  | 'person'
  | 'tour'
  | 'pin-drop'
  | 'label'
  | 'lens'
  | 'confirmation-number'
  | 'calendar-month'
  | 'hotel'
  | 'star-rate'
  | 'room'
  | 'arrow-circle-left'
  | 'arrow-circle-right'
  | 'logo'
  | 'simple-star'
  | 'calendar'
  | 'square-arrow'
  | 'copy'
  | 'circle-close'
  | 'circle-close-black'
  | 'gol-full'
  | 'arrow-right-alt'
  | 'arrow-left-alt'
  | 'business'
  | 'visibility'
  | 'block'
  | 'enable'
