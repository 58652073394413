const ptAccessDenied = {
  access_denied: {
    oops: 'Oops!',
    you_do_not_have_permission:
      'Você não possui permissão para acessar esta área.',
    back_to_beginning: 'Voltar ao inicio',
  },
}

export default ptAccessDenied
