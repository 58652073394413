import styled from 'styled-components'

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  align-items: center;
  .profile-icon {
    background: #f2f2f2;
    min-width: 28px;
    height: 28px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profile-arrow {
    padding: 8px;
  }
  > p {
    white-space: nowrap;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.12px;
  }

  svg {
    display: flex !important;
    border-radius: 20px;
  }
`
