import { useCallback, useState } from 'react'
import { getEmployees } from 'services/outsourced'
import { debounce } from 'utils/debounce'
import { notifyError } from 'utils/handleRequestNotify'

export const useEmployeesList = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [employees, setEmployees] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
  })

  const onPageChange = (page: number) => {
    setPagination((prevState: any) => ({ ...prevState, page }))
  }

  const fetchEmployees = useCallback(
    debounce(async (search: string | null, page: number, limit: number) => {
      if (search && search?.length < 3) {
        setEmployees([])
        return
      }
      setIsLoading(true)
      try {
        const response = await getEmployees(search, page, limit)
        setPagination((prevState: any) => ({
          ...prevState,
          total: response.pagination.totalItems,
        }))
        setEmployees(response.data)
      } catch (error) {
        notifyError()
      } finally {
        setIsLoading(false)
      }
    }, 500),
    [],
  )

  return {
    employees,
    setEmployees,
    fetchEmployees,
    pagination,
    onPageChange,
    isLoading,
  }
}
