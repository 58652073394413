import styled from 'styled-components'

export const BookingCompletedCardStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 32px;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  background-color: #e6f1eb;

  .content {
    width: 100%;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.0015em;
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
  }
`
