import styled from 'styled-components'

export const SVApproveAndDisapproveStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: end;

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: end;
  }

  .buttons {
    display: flex;
    justify-content: end;
    gap: 16px;

    button {
      border-radius: 16px;
    }
  }
`
