import React, { ReactNode } from 'react'
import { AlertStyles } from './styles'
import { SmilesIcon } from 'components/icons'

export type AlertTypes = 'success' | 'error'

export type AlertProps = {
  type: AlertTypes
  title: string
  desc?: string | ReactNode
}

const Alert: React.FC<AlertProps> = ({ type, title, desc }) => {
  const icon = {
    success: <SmilesIcon type="check-circle" size={32} color="#478331" />,
    error: <SmilesIcon type="cancel" size={32} color="#FC2C5E" />,
  }

  return (
    <AlertStyles type={type} data-testid="smiles-alert">
      <div className="icon-div">{icon[type]}</div>
      <div className="text">
        <p className="title">{title}</p>
        <div className="message">{desc}</div>
      </div>
    </AlertStyles>
  )
}

export default Alert
