import React from 'react'
import { SmilesIcon } from 'components/icons'
import { HotelAvail } from 'shared/interfaces/SHotelAvailability'
import dayjs from 'dayjs'
import { SRoom } from 'shared/interfaces/SRoom'
import { formatToReal } from 'utils/formatToReal/formateToReal'
import { countTotalDays } from 'utils/countTotalDays'
import { useTranslation } from 'react-i18next'

export type RenderHotelInfoProps = {
  selectedHotel: HotelAvail | undefined
  selectedRoom: SRoom | undefined
}

const RenderHotelInfo: React.FC<RenderHotelInfoProps> = ({
  selectedHotel,
  selectedRoom,
}) => {
  const { t } = useTranslation()

  const totalHotelFare =
    selectedRoom?.fares.find((fare) => fare.type === 'FARE')?.price.amount ?? 0

  const dailyFare =
    (selectedRoom &&
      totalHotelFare /
        countTotalDays(selectedRoom.checkIn, selectedRoom.checkOut)) ??
    0

  return (
    <div className="hotel-info" data-testid="render-cart-hotel">
      <div className="stars">
        <SmilesIcon type="simple-star" size={12} color="#E5A200" />
      </div>
      <div className="address">
        <p className="address-text">{`${selectedHotel?.hotel.address?.address}, ${selectedHotel?.hotel.address.city.name}-${selectedHotel?.hotel.address.city.country.code}`}</p>
        <button className="global-button-reset address-map-button">
          <p>a</p>
          <p>{t('component.hotel_info.see_on_map')}</p>
        </button>
      </div>
      <div className="check">
        <div className="check-in-and-out">
          <SmilesIcon type="check-circle" />
          <p className="check-in-out-label">
            {t('component.hotel_info.check_in')}
          </p>
          <p className="check-in-out-value">
            {dayjs(selectedHotel?.checkIn).format('ddd, D MMM, YYYY')}
          </p>
        </div>
        <div className="check-in-and-out">
          <SmilesIcon type="check-circle" />
          <p className="check-in-out-label">
            {t('component.hotel_info.check_out')}
          </p>
          <p className="check-in-out-value">
            {dayjs(selectedHotel?.checkOut).format('ddd, D MMM, YYYY')}
          </p>
        </div>
      </div>
      <div className="room">
        <div className="room-bed-icon">
          <SmilesIcon type="bed" />
        </div>
        <div className="room-info">
          <p className="room-name">{selectedRoom?.roomType.name}</p>
          <p className="room-description">
            {selectedRoom?.roomType.description}
          </p>
        </div>
      </div>
      <div className="price">
        <div className="price-daily">
          <p className="price-daily-label">
            {t('component.hotel_info.daily_rate')}
          </p>
          <span className="price-daily-value">{formatToReal(dailyFare)}</span>
        </div>
        <div className="price-total">
          <p className="price-total-label">{t('component.hotel_info.total')}</p>
          <span className="price-total-value">
            {formatToReal(totalHotelFare)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default RenderHotelInfo
