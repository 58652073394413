import styled from 'styled-components'
import InputMask from 'react-input-mask'
import { Form } from 'antd'

export const NewOutsourcedFormContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;

  .header {
    width: 100%;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.08px;
    position: absolute;
    z-index: 1;
    background-color: #fff;
  }

  .two-columns {
    > * {
      flex-grow: 2;
    }
    display: flex;
    width: 100%;
    gap: 16px;

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  .form-container {
    margin-top: 72px;
    margin-bottom: 96px;
    z-index: 0;
    display: flex;
    flex-direction: column;

    .description {
      font-size: 16px;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 24px;
    }
  }

  .form-row {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`

export const StyledFormItem = styled(Form.Item)`
  display: flex;
  align-items: center;
  margin: 0;
`

export const PhoneInputWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 1rem;
  }
`

export const StyledInputMask = styled(InputMask)`
  width: 100%;
  height: 4rem;
  border: 0.125rem solid #999999;
  border-radius: 0.5rem;
  padding: 1.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: #595959;
  outline: none;

  @media (max-width: 820px) {
    width: 100%;
    height: 3rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`
