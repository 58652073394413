import React, { useState } from 'react'
import {
  HotelFiltersButtonsContainer,
  HotelFiltersCheckboxContainer,
  HotelFiltersRangeContainer,
  HotelFiltersSectionContainer,
  HotelFiltersSectionDivider,
  SwitchSection,
} from './styles'
import Button from 'components/button'
import { Checkbox as AntdCheckbox, Form, Switch } from 'antd'
import { Checkbox } from 'components/checkbox'
import { useTranslation } from 'react-i18next'
import RangeWithInputs from 'components/rangeWithInputs'

export type FlightFilters = {
  destinationAiport?: string[]
  flightClass?: string[]
  flightDepartureTime?: string[]
  flightReturnTime?: string[]
  flightType?: string[]
  maxValue: number
  minValue: number
  originAiport?: string[]
}

const b = 'hotel.filterSection.'

const HotelFilterSection: React.FC = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm<FlightFilters>()
  const [rangeValues, setRangeValues] = useState([0, 1299])

  const handleSliderChange = (values: number[]) => {
    setRangeValues(values)
    form.setFieldsValue({
      minValue: values[0],
      maxValue: values[1],
    })
  }

  const hotelTypes = [
    {
      label: t(b + 'hotelTypes.peopleWd'),
      value: 'peopleWd',
    },
    {
      label: t(b + 'hotelTypes.pets'),
      value: 'pets',
    },
    {
      label: t(b + 'hotelTypes.kids'),
      value: 'kids',
    },
    {
      label: t(b + 'hotelTypes.babies'),
      value: 'babies',
    },
  ]

  const hotelAmenities = [
    {
      label: t(b + 'hotelAmenities.doubleBed'),
      value: 'doubleBed',
    },
    {
      label: t(b + 'hotelAmenities.singleBed'),
      value: 'singleBed',
    },
    {
      label: t(b + 'hotelAmenities.noPreference'),
      value: 'noPreference',
    },
  ]

  const initialValues = {
    hotelTypes: ['pwd', 'pets'],
    priceRange: [0, 20],
    minValue: rangeValues[0],
    maxValue: rangeValues[1],
  }

  return (
    <HotelFiltersSectionContainer data-testid="smiles-hotel-filters-section">
      <Form form={form} initialValues={initialValues}>
        <p className="filters-container-header">{t(b + 'header')}</p>

        <SwitchSection>
          <div>
            <span>{t('hotel.breakfast_alimentation')}</span>
            <p>{t('hotel.only_exib_acomodations_with_food')}</p>
          </div>
          <Switch />
        </SwitchSection>

        <HotelFiltersRangeContainer>
          <p>{t(b + 'hotelPriceGroupboxHeader')}</p>
          <RangeWithInputs
            rangeValues={rangeValues}
            updateFilter={handleSliderChange}
            maxValueLabel={t(b + 'rangeWithInputs.form.maxValue.label')}
            minValueLabel={t(b + 'rangeWithInputs.form.minValue.label')}
          />
        </HotelFiltersRangeContainer>

        <div className="container-with-dividers">
          <Form.Item name="hotelTypes">
            <AntdCheckbox.Group>
              <HotelFiltersCheckboxContainer>
                <p>{t(b + 'hotelTypesGroupboxHeader')}</p>
                {hotelTypes.map((type) => (
                  <Checkbox
                    key={type.value}
                    label={type.label}
                    name={`hotelType-${type.value}`}
                    value={type.value}
                  />
                ))}
              </HotelFiltersCheckboxContainer>
            </AntdCheckbox.Group>
          </Form.Item>

          <HotelFiltersSectionDivider />

          <Form.Item name="hotelAmenities">
            <AntdCheckbox.Group>
              <HotelFiltersCheckboxContainer>
                <p>{t(b + 'hotelAmenitiesGroupboxHeader')}</p>
                {hotelAmenities.map((amenitie) => (
                  <Checkbox
                    key={amenitie.value}
                    label={amenitie.label}
                    name={`hotelAmenitie-${amenitie.value}`}
                    value={amenitie.value}
                  />
                ))}
              </HotelFiltersCheckboxContainer>
            </AntdCheckbox.Group>
          </Form.Item>

          <HotelFiltersButtonsContainer>
            <p>{t(b + 'lookingQuote')}</p>
            <Button size="sm">{t(b + 'getQuote')}</Button>
          </HotelFiltersButtonsContainer>
          <HotelFiltersButtonsContainer>
            <p>{t(b + 'flightWantedNotFound')}</p>
            <Button size="sm">{t(b + 'askTheAgency')}</Button>
          </HotelFiltersButtonsContainer>
        </div>
      </Form>
    </HotelFiltersSectionContainer>
  )
}

export default HotelFilterSection
