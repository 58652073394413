import styled from 'styled-components'

export const MoreOptionsButtonStyles = styled.div`
  display: flex;
  padding: 8px 8px 8px 16px;
  border-radius: 16px;
  border: 2px solid var(--Neutral-Neutral-400, #7c7c7c);
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  align-self: center;

  > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
`
