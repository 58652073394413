const ptHome = {
  home: {
    searchSection: {
      tabs: {
        air: 'Passagens aéreas',
        hotel: 'Reserva de hotel',
        car: 'Aluguel de carro',
        other: 'Outros serviços',
      },
      flight: {
        filters: {
          flightOptions: {
            depAndReturn: 'Ida e volta',
            depOrReturn: 'Apenas ida',
          },
          classes: {
            economic: 'Econômica',
            executive: 'Executiva',
            allClasses: 'Todas as classes',
          },
        },
        form: {
          departureInputLabel: 'De onde está saindo?',
          destinationInputLabel: 'Para onde está indo?',
          start: 'Data de ida',
          end: 'Data de volta',
          inputPlaceholder: 'Cidade, aeroporto ou local',
          inputDatePlaceholder: 'dd/mm/aaaa',
          searchButton: 'Buscar passagens',
        },
      },
      hotel: {
        filters: {
          roomType: {
            single: 'Single',
            couple: 'Casal',
          },
        },
        form: {
          destinationInputLabel: 'Onde vai se hospedar?',
          destinationInputPlaceholder: 'Destino',
          checkInInputLabel: 'Check-in',
          checkOutInputLabel: 'Check-out',
          checkInInputPlaceholder: 'Ida',
          checkOutInputPlaceholder: 'Volta',
          roomTypeInputLabel: 'Tipo de quarto',
          searchButton: 'Buscar hotel',
          requiredField: 'Campo obrigatório',
        },
      },
    },
    requestsTableSection: {
      title: 'Solicitações',
      tHeader: {
        svNumber: 'Número SV',
        requester: 'Solicitante',
        requestDate: 'Data pedido',
        destinationCity: 'Cidade destino',
        status: 'Status',
      },
    },
    myTravelsSection: {
      title: 'Minhas viagens',
      tHeader: {
        departureCity: 'Cidade destino',
        travelDate: 'Data viagem',
        locator: 'Localizador',
      },
    },
  },
}

export default ptHome
