export default `
	<svg xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 125"
		xml:space="preserve" height="100%" width="100%">
		<g>
			<circle cx="50" cy="63.5" r="3.9"/>
			<g>
				<path d="M88.6,50c0-21.3-17.3-38.6-38.6-38.6c-21.3,0-38.6,17.3-38.6,38.6S28.7,88.6,50,88.6C71.3,88.6,88.6,71.3,88.6,50z
					M19.9,50c0-16.6,13.5-30.1,30.1-30.1c16.6,0,30.1,13.5,30.1,30.1S66.6,80.1,50,80.1C33.4,80.1,19.9,66.6,19.9,50z"/>
				<path d="M50,55.3c2.3,0,4.2-1.9,4.2-4.2V34.3c0-2.3-1.9-4.2-4.2-4.2c-2.3,0-4.2,1.9-4.2,4.2V51C45.8,53.4,47.7,55.3,50,55.3z"/>
			</g>
		</g>
	</svg>
`
