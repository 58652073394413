import styled from 'styled-components'

export const FullRequestContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 34px;

  .go-back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .go-back-label {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.01em;
    }

    svg {
      transform: rotate(180deg);
    }
  }

  .summary-and-cancel {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 32px;
    border-bottom: 1px solid #d9d9d9;
  }
`

export const SVHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .left {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 34px;

    .sv-number {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
    }

    .services {
      display: flex;
      gap: 8px;
    }
  }

  .status {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
`
