import esLogin from './esLogin'
import esHome from './esHome'
import esNavbar from './esNavbar'
import esUserGroups from './esUserGroups'
import esGroup from './esGroup'
import esFlights from './esFlights'
import esFlow from './esFlow'
import esReservation from './esReservation'
import esHotel from './esHotel'
import esMyProfile from './esMyProfile'
import esMyRequests from './esMyRequests'
import esAccessDenied from './esAccessDenied'
import esTravelRequest from './esTravelRequest'
import esMyTravels from './esMyTravels'
import esPermissions from './esPermissions'
import esUser from './esUser'
import esComponent from './esComponent'
import esOutsourced from './esOutsourced'

const esTranslations = {
  translations: {
    ...esAccessDenied,
    ...esLogin,
    ...esHome,
    ...esNavbar,
    ...esUserGroups,
    ...esGroup,
    ...esFlights,
    ...esFlow,
    ...esReservation,
    ...esHotel,
    ...esMyProfile,
    ...esMyRequests,
    ...esTravelRequest,
    ...esMyTravels,
    ...esPermissions,
    ...esUser,
    ...esComponent,
    ...esOutsourced,
  },
}

export default esTranslations
