import { TFunction } from 'i18next'
import { API } from 'services/config'
import { createDownloadAnchorForBlob } from 'utils/download'
import { notifyError } from 'utils/handleRequestNotify'
import { base64toBlob } from 'utils/parseBase64'

const bookingEndpoint = '/booking/v1/booking'

const downloadVoucher = async (
  reserveId: string,
  setIsVoucherLoading: any,
  t: TFunction<'translation', undefined>,
): Promise<any> => {
  try {
    setIsVoucherLoading(true)
    const response = await API.get(`${bookingEndpoint}/download/${reserveId}`)

    const fileBlob = base64toBlob(response.data, 'application/zip')
    const downloadAnchor = createDownloadAnchorForBlob(fileBlob, 'vouchers.zip')

    document.body.appendChild(downloadAnchor)

    downloadAnchor.click()
  } catch (error) {
    notifyError(t('my_travels.voucher_download_error'))
  } finally {
    setIsVoucherLoading(false)
  }
}

export { downloadVoucher }
