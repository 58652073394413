import { notification } from 'antd'
import React, { ReactNode } from 'react'
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons'

type IconTypes = 'info' | 'success' | 'error' | 'warning'

export type NotifyTypes = {
  msg?: string
  desc?: string
  duration?: number
  type: IconTypes
}

const getIcon = (type: IconTypes): ReactNode => {
  switch (type) {
    case 'info':
      return <InfoCircleOutlined style={{ color: '#1890ff' }} />
    case 'success':
      return <CheckCircleOutlined style={{ color: '#52c41a' }} />
    case 'error':
      return <CloseCircleOutlined style={{ color: '#f5222d' }} />
    case 'warning':
      return <WarningOutlined style={{ color: '#faad14' }} />
    default:
      return null
  }
}

export const notify = ({
  msg = 'Erro!',
  desc = 'Houve um erro, tente novamente mais tarde.',
  duration = 5,
  type,
}: NotifyTypes) => {
  notification.open({
    message: msg,
    description: desc,
    duration,
    icon: getIcon(type),
  })
}
