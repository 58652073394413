const enHome = {
  home: {
    searchSection: {
      tabs: {
        air: 'Air tickets',
        hotel: 'Hotel reservation',
        car: 'Car rental',
        other: 'Other services',
      },
      flight: {
        filters: {
          flightOptions: {
            depAndReturn: 'Round trip',
            depOrReturn: 'One way',
          },
          classes: {
            economic: 'Economy',
            executive: 'Executive',
            allClasses: 'All classes',
          },
        },
        form: {
          departureInputLabel: 'Departure from',
          destinationInputLabel: 'Going to',
          start: 'Departure date',
          end: 'Return date',
          inputPlaceholder: 'City, airport, or place',
          inputDatePlaceholder: 'yyyy-mm-dd',
          searchButton: 'Search flights',
        },
      },
      hotel: {
        filters: {
          roomType: {
            single: 'Single',
            couple: 'Couple',
          },
        },
        form: {
          destinationInputLabel: 'Where to stay?',
          destinationInputPlaceholder: 'Destination',
          checkInInputLabel: 'Check-in',
          checkOutInputLabel: 'Check-out',
          checkInInputPlaceholder: 'Check-in',
          checkOutInputPlaceholder: 'Check-out',
          roomTypeInputLabel: 'Room type',
          searchButton: 'Search hotel',
          requiredField: 'Required field',
        },
      },
    },
    requestsTableSection: {
      title: 'Requests',
      tHeader: {
        svNumber: 'SV number',
        requester: 'Requester',
        requestDate: 'Request date',
        destinationCity: 'Destination city',
        status: 'Status',
      },
    },
    myTravelsSection: {
      title: 'My travels',
      tHeader: {
        departureCity: 'Destination city',
        travelDate: 'Travel date',
        locator: 'Locator',
      },
    },
  },
}

export default enHome
