import { mountHotelCart } from 'components/summaryModalContent/helpers/mountHotelCart'
import { postCartHotel } from 'services/hotel'
import { HotelAvail } from 'shared/interfaces/SHotelAvailability'
import { SRoom } from 'shared/interfaces/SRoom'
import { getCustomCookie } from 'utils/cookies/cookiesUtils'
import { notifyError } from 'utils/handleRequestNotify'

type UseHotelCartProps = {
  selectedHotel: HotelAvail | undefined
  selectedRoom: SRoom | undefined
}

export const useHotelCart = ({
  selectedHotel,
  selectedRoom,
}: UseHotelCartProps) => {
  return async () => {
    if (selectedHotel && selectedRoom) {
      try {
        const travelRequestId = await getCustomCookie('travel_request_id')
        const hotelCart = mountHotelCart(
          selectedHotel,
          [selectedRoom],
          travelRequestId,
        )
        await postCartHotel(hotelCart)
      } catch (error: any) {
        notifyError(error.message)
        throw error
      }
    }
  }
}
