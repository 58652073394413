import { TreeData } from 'components/permissionTreeSelect'

export const filterGroupsAndPerms = (
  values: string[],
  treeData?: TreeData,
): string[] => {
  let finalValues = values

  if (treeData) {
    finalValues = values.flatMap((val: string) => {
      const group = treeData?.find((item) => item.value === val && item.group)
      return group ? group.children.map((child) => child.value) : val
    })
  }

  return finalValues
}
