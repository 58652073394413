import React from 'react'
import { MainContainerStyles } from './styles'

export type MainContainerTypes = {
  children: React.ReactNode
  clearPaddingX?: boolean
}
const MainContainer = ({ children, clearPaddingX }: MainContainerTypes) => (
  <MainContainerStyles
    data-testid="smiles-main-container"
    $clearpaddingx={clearPaddingX}
  >
    <div>{children}</div>
  </MainContainerStyles>
)

export default MainContainer
