const esFlights = {
  flights: {
    moreOptionsButton: 'Ver más opciones',
    tabs: {
      outboundFlight: 'Seleccione su vuelo de ida',
      returnFlight: 'Seleccione su vuelo de vuelta',
    },
    flightCardRadio: {
      classAndFare: {
        class: 'Clase del vuelo:',
        farePerSegment: 'Tarifa por segmento',
      },
    },
    flightFilterSection: {
      header: 'Elige tus preferencias',
      flightTypeGroupboxHeader: 'Tipo de vuelo',
      flightPriceGroupboxHeader: 'Precio de los billetes',
      flightClassGroupboxHeader: 'Clase del vuelo',
      flightDepartureTimeGroupboxHeader: 'Hora de salida del vuelo',
      flightReturnTimeGroupboxHeader: 'Hora de regreso del vuelo',
      form: {
        minValue: {
          label: 'Valor mínimo',
        },
        maxValue: {
          label: 'Valor máximo',
        },
      },
      flightTypes: {
        direct: 'Directo',
        oneStop: '1 parada',
        twoStops: '2 paradas',
      },
      flightClasses: {
        economic: 'Económica',
        premiumEconomy: 'Premium economic',
        executive: 'Ejecutiva',
        firstClass: 'Primera clase',
      },
      flightTime: {
        dawn: 'Madrugada 00h00 - 04h59',
        morning: 'Mañana 05h00 - 11h59',
        afternoon: 'Tarde 12h00 - 17h59',
        night: 'Noche 18h00 - 23h59',
      },
      originAirport: 'Aeropuerto de origen',
      destinationAirport: 'Aeropuerto de destino',
      lookingQuote: '¿Buscando un presupuesto de vuelo?',
      getQuote: '¡Obtenga un presupuesto!',
      flightWantedNotFound: '¿No encontraste el vuelo que querías?',
      askTheAgency: 'Preguntar a la agencia',
      confirm: 'Confirmar',
    },
    renderFlights: {
      emptyFlightsDescription: 'Sin opciones para la búsqueda',
    },
    flightInfoBar: {
      changeSearch: 'Cambiar búsqueda',
    },
  },
}

export default esFlights
