import React, { useEffect, useState } from 'react'
import {
  ChangeSearchButton,
  FlightInfoBarContainer,
  FlightInfoBarStyles,
} from './styles'
import { Color } from 'styles/global'
import { SmilesIcon } from 'components/icons'
import Drawer from 'components/drawer'
import FlightFormSearch from 'components/searchForms/flights'
import MainContainer from 'components/mainContainer'
import { useWindowSize } from 'react-use'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'

const FlightInfoBar: React.FC = () => {
  const { t } = useTranslation()
  const [openDrawer, setOpenDrawer] = useState(false)
  const { searchFlights } = useFlowContext()

  const { width } = useWindowSize()
  const isSmallScreen = width < 840

  const handleClose = () => {
    setOpenDrawer(false)
  }

  const renderFlightInfo = () => {
    if (searchFlights) {
      return (
        <>
          <FlightInfoBarStyles>
            <div>
              <p>{searchFlights?.origin.value}</p>
              <SmilesIcon type="plane-right" color={Color.PRIMARY} />
              <p>{searchFlights?.destination.value}</p>
            </div>
            <p>{dayjs(searchFlights?.start).format('ddd, D MMM')}</p>
          </FlightInfoBarStyles>
          {searchFlights.end && (
            <FlightInfoBarStyles>
              <div>
                <p>{searchFlights?.destination.value}</p>
                <SmilesIcon type="plane-right" color={Color.PRIMARY} />
                <p>{searchFlights?.origin.value}</p>
              </div>
              <p>{dayjs(searchFlights?.end).format('ddd, D MMM')}</p>
            </FlightInfoBarStyles>
          )}
        </>
      )
    }

    return null
  }

  useEffect(() => {
    handleClose()
  }, [searchFlights])

  return (
    <FlightInfoBarContainer data-testid="smiles-flight-infobar">
      {renderFlightInfo()}

      <ChangeSearchButton
        data-testid="change-search-button"
        onClick={() => setOpenDrawer(true)}
      >
        {t('flights.flightInfoBar.changeSearch')}
      </ChangeSearchButton>

      <Drawer
        onClose={handleClose}
        open={openDrawer}
        placement="top"
        closable={isSmallScreen}
        height={isSmallScreen ? '100%' : 420}
      >
        <MainContainer>
          <FlightFormSearch />
        </MainContainer>
      </Drawer>
    </FlightInfoBarContainer>
  )
}

export default FlightInfoBar
