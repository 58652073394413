import styled from 'styled-components'

export const TextAreaContainer = styled.div<{
  width?: string
  height?: string
}>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  gap: 8px;

  .custom-antd-text-area {
    width: 100%;
    height: 200px;
    border: 2px solid #999;
    padding: 18px 24px;
    z-index: 1;
    resize: none;

    svg {
      fill: #999;
    }

    &::placeholder {
      color: #666666;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.08px;
    }
    &:hover,
    &:focus-within,
    &:focus {
      border: 2px solid #999;
    }
  }

  .custom-antd-input:focus,
  .custom-antd-input:focus-within {
    box-shadow: 0 0 0 2px rgba(255, 112, 32, 0.1);
  }
`
export const TextAreaLabel = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 21px;
  letter-spacing: 0.035px;
`
