import styled from 'styled-components'

export const ViewUserDataContainer = styled.div`
  width: 100%;
`

export const ViewUserDataEmptyContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UserDataItem = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.16px;
  }
  span {
    color: #737373;
  }
`
