const ptMyTravels = {
  my_travels: {
    cancelled: {
      title: 'Cancelamento de viagem',
      canceled_by_policy: 'Cancelado pela política de cancelamento',
      cancelation_reason: 'Motivo do cancelamento',
    },
    cancel: {
      required_field: 'Campo obrigatório',
      cancel_reason: 'Motivo do cancelamento',
      detail_cancel_reason: 'Esclareça os motivos do cancelamento',
      field_constraints:
        'Preenchimento mínimo de 10 e máximo de 300 caracteres.',
      cancel: 'Cancelar',
      proceed: 'Prosseguir',
    },
    dropdown: {
      download_in_en: 'Baixar em inglês',
      download_in_es: 'Baixar em espanhol',
      download_in_pt: 'Baixar em português',
    },
    travels: {
      all: 'Todas',
      next: 'Próximas viagens',
      cancelled_travels: 'Viagens canceladas',
      previous_travels: 'Viagens anteriores',
      your_cancelled_travels: 'Suas viagens canceladas',
      your_previous_travels: 'Suas viagens anteriores',
      see_all: 'Ver todas',
      traveller: 'Viajante',
      flight_operator: 'Operadora do voo',
      status: 'Status',
      locator: 'Localizador',
      see_all_details: 'Ver solicitação completa',
      cancel_travel: 'Cancelar viagem',
      download_voucher: 'Baixar voucher',
      cancellation_requested_by_employee: 'Cancelado a pedido do colaborador',
      title: 'Minhas viagens',
    },
    voucher_download_error: 'Não foi possível baixar o voucher.',
  },
}

export default ptMyTravels
