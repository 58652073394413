const esGroup = {
  group: {
    searchUserPlaceholder: 'Buscar usuario por nombre',
    addUserButton: 'Agregar usuario',
    breadcrumb: {
      home: 'Inicio',
      userGroups: 'Grupos de usuarios',
      group: 'Grupo',
    },
    notify: {
      userRemoved: 'Usuario eliminado del grupo.',
      userAdded: 'El usuario ha sido agregado al grupo.',
      userAlreadyInGroup: 'El usuario ya ha sido añadido a este grupo',
      errorOnRemoveUser:
        'Se produjo un error al eliminar el usuario, por favor inténtelo de nuevo más tarde.',
    },
    tabs: {
      users: 'Usuarios',
      permissions: 'Permisos',
    },
    table: {
      columns: {
        userName: 'Nombre',
        action: 'Acción',
      },
      removeUserPopConfirm: {
        title: 'Eliminar usuario del grupo',
        description: 'Esta acción es irreversible, ¿desea continuar?',
        okText: 'Sí',
        cancelText: 'Cancelar',
      },
    },
    alert: {
      noPermToViewGroupUsers:
        'No tienes permiso para ver los usuarios de este grupo.',
    },
  },
  addUserToGroup: {
    title: 'Agregar usuario al grupo',
    form: {
      userName: {
        label: 'Buscar usuario',
        placeholder: 'Ingrese el nombre del usuario',
        required: 'Campo obligatorio',
      },
      addButton: 'Agregar',
    },
    alert: {
      notHavePermToAdd: 'No tienes permiso para listar usuarios.',
    },
  },
  listGroupPermissions: {
    alert: {
      notHavePermToView:
        'No tienes permiso para ver los permisos de este grupo.',
    },
  },
}

export default esGroup
