import styled from 'styled-components'

type PropTypes = {
  $urlimage?: string
}

export const LoginContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
`

export const LoginFormContainer = styled.section`
  height: calc(100vh - 72px);
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 360px;
    height: 400px;
    background: white;
    display: flex;
    flex-direction: column;
    gap: 32px;
    > p {
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: 0.08px;
    }
    > form {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 16px 24px;
  }
`
export const LoginBackgorundImage = styled.div<PropTypes>`
  height: calc(100vh - 72px);
  width: 50vw;
  background: url(${(props: PropTypes) => props.$urlimage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`
