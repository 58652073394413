const enFlow = {
  flow: {
    tabs: {
      air: 'Flights',
      hotel: 'Hotel reservation',
      car: 'Car rental',
      other: 'Other services',
    },
  },
}

export default enFlow
