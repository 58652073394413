import { AxiosError } from 'axios'
import { API } from 'services/config'

const endpoint = '/outsourced/v1'

interface Company {
  companyId?: string
  companyName?: string
  document?: string
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export interface CreateEmployeeDto {
  id?: string
  company?: Company
  nationalityId?: string
  isForeigner: boolean
  isConcession: boolean
  fullName: string
  cpf?: string
  rg?: string
  passport?: string
  job?: string
  gender?: Gender
  dateOfBirth: Date
  phone: string
  email?: string
}

interface CreateCompany {
  companyName?: string
  document?: string
  employees: Omit<CreateEmployeeDto, 'company'>[]
}

interface ChangeEmployeeStatus {
  id: string
  status: boolean
}

interface ChangeCompanyStatus {
  id: string
  status: boolean
}

const createEmployee = async (data: CreateEmployeeDto) => {
  try {
    const response = await API.post(`${endpoint}/employee`, data)
    return { status: 'success', result: response.data }
  } catch (error) {
    if (error instanceof AxiosError) {
      return { status: 'error', error: error.response?.data.details }
    }
    return { status: 'error', error }
  }
}

const getEmployees = async (
  search: string | null,
  page: number,
  limit: number,
) => {
  let params = {
    page,
    limit,
  } as { page: number; limit: number; search?: string }

  if (search) {
    params = { ...params, search }
  }
  try {
    const response = await API.get(`${endpoint}/employees`, { params })
    return response.data
  } catch (error) {
    return { error: 'Error' }
  }
}

const getEmployee = async (id: string) => {
  try {
    const response = await API.get(`${endpoint}/employee/${id}`)
    return response.data
  } catch (error) {
    return { error: 'Error' }
  }
}

const getNationalities = async (search: string = 'brasil') => {
  try {
    const response = await API.get(`${endpoint}/nationalities?search=${search}`)
    return response.data
  } catch (error) {
    return { error: 'Error' }
  }
}

const getCompanies = async (
  search: string | undefined,
  page: number,
  limit: number,
) => {
  let params = {
    page,
    limit,
  } as { page: number; limit: number; search?: string }

  if (search) {
    params = { ...params, search }
  }
  try {
    const response = await API.get(`${endpoint}/companies`, { params })
    return response.data
  } catch (error) {
    return { error: 'Error' }
  }
}

const getCompany = async (id: string) => {
  try {
    const response = await API.get(`${endpoint}/company/${id}`)
    return { status: 'success', result: response.data }
  } catch (error) {
    if (error instanceof AxiosError) {
      return { status: 'error', error: error.response?.data.details }
    }
    return { status: 'error', error }
  }
}

const updateEmployee = async (data: Partial<CreateEmployeeDto>) => {
  try {
    const response = await API.put(`${endpoint}/employee`, data)
    return { status: 'success', result: response.data }
  } catch (error) {
    if (error instanceof AxiosError) {
      return { status: 'error', error: error.response?.data.details }
    }
    return { status: 'error', error }
  }
}

const createCompany = async (data: CreateCompany) => {
  try {
    const response = await API.post(`${endpoint}/company`, data)
    return { status: 'success', result: response.data }
  } catch (error) {
    if (error instanceof AxiosError) {
      return { status: 'error', error: error.response?.data.details }
    }
    return { status: 'error', error }
  }
}

const changeEmployeeStatus = async (data: ChangeEmployeeStatus) => {
  try {
    const response = await API.post(`${endpoint}/employee/change-status`, data)
    return { status: 'success', result: response.data }
  } catch (error) {
    if (error instanceof AxiosError) {
      return { status: 'error', error: error.response?.data.details }
    }
    return { status: 'error', error }
  }
}

const changeCompanyStatus = async (data: ChangeCompanyStatus) => {
  try {
    const response = await API.post(`${endpoint}/company/change-status`, data)
    return { status: 'success', result: response.data }
  } catch (error) {
    if (error instanceof AxiosError) {
      return { status: 'error', error: error.response?.data.details }
    }
    return { status: 'error', error }
  }
}

const updateCompany = async (data: Partial<CreateCompany>) => {
  try {
    const response = await API.put(`${endpoint}/company`, data)
    return { status: 'success', result: response.data }
  } catch (error) {
    if (error instanceof AxiosError) {
      return { status: 'error', error: error.response?.data.details }
    }
    return { status: 'error', error }
  }
}

export {
  getNationalities,
  createEmployee,
  getEmployees,
  getEmployee,
  getCompanies,
  getCompany,
  updateEmployee,
  createCompany,
  changeEmployeeStatus,
  changeCompanyStatus,
  updateCompany,
}
