import styled from 'styled-components'

export const SectionContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.0025em;
  }
  .see-all-button {
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: end;
    width: fit-content;
    align-self: flex-end;
  }
  .cards-container {
    width: 100%;
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .next-travels-cards-container {
    width: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;
  }
`
