import i18n from 'i18next'
import enTranslations from 'public/locales/en'
import esTranslations from 'public/locales/es'
import ptTranslations from 'public/locales/pt'
import { initReactI18next } from 'react-i18next'

const savedLanguage = localStorage.getItem('userLanguage') ?? 'pt-BR'

i18n.use(initReactI18next).init({
  resources: {
    pt: {
      ...ptTranslations,
    },
    en: {
      ...enTranslations,
    },
    es: {
      ...esTranslations,
    },
  },
  lng: savedLanguage,
  fallbackLng: savedLanguage,
  defaultNS: 'translations',
})

export default i18n
