import Button from 'components/button'
import { SmilesIcon } from 'components/icons'
import MainContainer from 'components/mainContainer'
import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Subheader,
  Divider,
  Details,
  Data,
  Action,
  ActionButton,
  Info,
  Label,
  Value,
  CardList,
  RowContainer,
  Column,
  Card,
  CardAction,
} from './styles'
import Breadcrumb from 'components/breadcrumb'
import { useTranslation } from 'react-i18next'
import { useCompanyDetails } from './hooks/useCompanyDetails'
import { formatCNPJ } from 'utils/formatters'
import Skeleton from 'components/skeleton'
import Drawer from 'components/drawer'
import HandleCompany from '../handleCompany'
import HandleEmployee from '../handleOutsourced'
import ChangeEmployeeStatus from '../changeEmployeeStatus'
import ChangeCompanyStatus from '../changeCompanyStatus'

const CompanyDetails: React.FC = () => {
  const { t } = useTranslation()
  const onBack = () => navigate(-1)
  const navigate = useNavigate()
  const { companyId } = useParams()
  const {
    loading,
    company,
    drawerState,
    setDrawerData,
    fetchEmployeeDetails,
    isEditMode,
    setEditMode,
    selectedItemListId,
    handleCloseDrawer,
    setSelectedItemListId,
  } = useCompanyDetails(companyId as string)
  if (loading)
    return (
      <MainContainer>
        <Skeleton isLoading={loading} />{' '}
      </MainContainer>
    )
  return (
    <MainContainer>
      <Subheader>
        <Button width="84px" buttonType="back-button" onClick={onBack}>
          <SmilesIcon type="arrow-left-alt" size={32} />
          <p className="go-back-label">Voltar</p>
        </Button>
        <Breadcrumb
          items={[
            {
              title: (
                <Link to="/management">
                  {t('company_details.breadcrumb.outsourced')}
                </Link>
              ),
            },
            {
              title: company?.company_name ?? '',
            },
          ]}
        />
      </Subheader>
      <Details>
        <Data>
          <Info>
            <Label>
              <SmilesIcon size={20} type="business" />
              Empresa
            </Label>
            <Value>{company?.company_name ?? ''}</Value>
          </Info>
          <Info>
            <Label>
              <SmilesIcon size={20} type="label" />
              CNPJ
            </Label>
            <Value>{formatCNPJ(company?.document ?? '')}</Value>
          </Info>
        </Data>
        <Action>
          <ActionButton
            type="button"
            className="global-button-reset"
            onClick={() => {
              setEditMode(false)
              setDrawerData(true, 'change_company_status')
            }}
          >
            {(company?.is_active as boolean) ? (
              <SmilesIcon size={18} type="block" />
            ) : (
              <SmilesIcon size={18} type="enable" />
            )}
            {(company?.is_active as boolean)
              ? t('company_details.action.disable_company')
              : t('company_details.action.enable_company')}
          </ActionButton>
          <ActionButton
            type="button"
            className="global-button-reset"
            onClick={() => {
              setEditMode(false)
              setDrawerData(true, 'employee')
            }}
          >
            <SmilesIcon size={18} type="plus-rounded" />
            {t('company_details.action.add_outsourced')}
          </ActionButton>
          <ActionButton
            type="button"
            className="global-button-reset"
            onClick={() => {
              setEditMode(true)
              setDrawerData(true, 'company')
            }}
          >
            <SmilesIcon size={18} type="pen" />
            {t('company_details.action.edit_company')}
          </ActionButton>
        </Action>
      </Details>
      <Divider />
      <CardList>
        <Label>
          <SmilesIcon size={20} type="person" />
          Pessoa terceirizada
        </Label>
        {!loading &&
          company &&
          company.employees.map((item: any) => {
            return (
              <Card onClick={() => {}} key={item.id}>
                <RowContainer>
                  <Column active={item.is_active}>
                    <p>{item.full_name}</p>
                  </Column>
                  <Column active={item.is_active}>
                    <CardAction>
                      {(item.is_active as boolean) ? (
                        <SmilesIcon
                          type="block"
                          onClick={() => {
                            setSelectedItemListId(item.id)
                            setDrawerData(true, 'change_employee_status')
                          }}
                          size={20}
                        />
                      ) : (
                        <SmilesIcon size={18} type="enable" />
                      )}
                      <SmilesIcon
                        onClick={() => {
                          setSelectedItemListId(item.id)
                          setDrawerData(true, 'employee_details')
                        }}
                        type="visibility"
                        size={20}
                      />
                      <SmilesIcon
                        onClick={() => {
                          setEditMode(true)
                          setSelectedItemListId(item.id)
                          setDrawerData(true, 'employee')
                        }}
                        type="pen"
                        size={20}
                      />
                    </CardAction>
                  </Column>
                </RowContainer>
              </Card>
            )
          })}
      </CardList>
      <Drawer
        onClose={handleCloseDrawer}
        open={drawerState.isOpen}
        width={700}
        data-testid="drawer-component-id"
        destroyOnClose
      >
        {drawerState.currentDrawer === 'employee' && (
          <HandleEmployee
            onFinishCallback={() => {
              setDrawerData(false, undefined)
              fetchEmployeeDetails(companyId as string)
            }}
            isEditMode={isEditMode}
            employeeId={selectedItemListId}
          />
        )}
        {drawerState.currentDrawer === 'company' && (
          <HandleCompany
            onFinishCallback={() => {
              setDrawerData(false, undefined)
            }}
            isEditMode={isEditMode}
            companyData={company}
          />
        )}
        {drawerState.currentDrawer === 'change_employee_status' && (
          <ChangeEmployeeStatus
            onFinishCallback={() => {
              setDrawerData(false, undefined)
              fetchEmployeeDetails(companyId as string)
            }}
            employeeId={selectedItemListId as string}
            enabled={
              company?.employees.filter(
                (item) => item.id === selectedItemListId,
              )[0].is_active
            }
          />
        )}
        {drawerState.currentDrawer === 'change_company_status' && (
          <ChangeCompanyStatus
            onFinishCallback={() => {
              setDrawerData(false, undefined)
            }}
            companyId={companyId as string}
            enabled={company?.is_active as boolean}
          />
        )}
      </Drawer>
    </MainContainer>
  )
}

export default CompanyDetails
