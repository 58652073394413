const esComponent = {
  component: {
    bottom_nav: {
      continue: 'Continuar',
    },
    complete_request: {
      notifications: {
        verify_cost_center_and_try_again:
          'Verifique los Centros de Costos e intente nuevamente.',
        failed_to_create_allocation: 'Error al crear la asignación',
      },
    },
    mobile_nav: {
      make_a_request: 'Hacer una solicitud',
      request_flight: 'Solicitar aéreo',
      request_hotel: 'Solicitar hospedaje',
      request_car: 'Solicitar coche',
      request_bus: 'Solicitar bus',
      request_services: 'Solicitar otros servicios',
      my_request: 'Mis solicitudes',
      see_my_requests: 'Ver mis solicitudes',
      your_requests: 'Sus solicitudes',
      changed_trips: 'Viajes modificados',
      next_trips: 'Próximos viajes',
      previous_trips: 'Viajes anteriores',
      your_favorites: 'Sus favoritos',
      favorites: 'Favoritos',
      favorite_flights: 'Vuelos favoritos',
      favorite_hotels: 'Hoteles favoritos',
      favorite_trips: 'Viajes favoritos',
      your_management: 'Su gestión',
      reports: 'Informes',
      third_part_register: 'Registro de terceros',
      advance_and_accountability: 'Anticipo y rendición de cuentas',
      my_travels: 'Mis viajes',
      see_my_travels: 'Ver mis viajes',
    },
    request_summary: {
      request_summary: 'Resumen de la solicitud',
      total: 'Total:',
      total_value: 'Valor total',
      requester: 'Solicitante',
      traveler: 'Viajero',
      add_more_services_to_your_reserve: 'Agregar más servicios a su reserva',
      come_back: 'Volver',
      go_to_costs_center: 'Ir al centro de costos',
      flight: {
        air: 'Aéreo',
        fare_per_segment: 'Tarifa por segmento:',
      },
      hotel: {
        checkin: 'Check-in',
        daily_fare: 'Tarifa diaria del hotel:',
      },
    },
    cost_center: {
      cost_center: 'Centro de costos',
      description: 'Descripción',
      approver: 'Aprobador',
      regional: 'Regional',
      notifications: {
        failed_to_get_cost_center: 'Error al obtener el centro de costos.',
      },
      search: 'Buscar',
    },
    search_form: {
      add_third_part: 'Agregar colaborador o persona externa',
      requester: 'Solicitante',
      required_field: 'Campo obligatorio',
      request_travel_to: 'Solicitar viaje para',
      search: 'Buscar...',
      employee_added: 'Empleado agregado',
      cancel: 'Cancelar',
      add: 'Agregar',
      notifications: {
        failed_to_get_airports: 'Error al obtener aeropuertos.',
        failed_to_get_locations: 'Error al obtener ubicaciones.',
      },
      select_departure_airport: 'Seleccione el aeropuerto de salida',
      select_destination_airport: 'Seleccione el aeropuerto de destino',
      select_departure_date: 'Seleccione la fecha de salida',
      select_return_date: 'Seleccione la fecha de regreso',
    },
    header: {
      see_all: 'Ver todas',
    },
    stops_and_baggage: {
      direct: 'Directo',
      stop: 'parada',
      stops: 'paradas',
    },
    summary_modal: {
      ticket_segment: 'Segmento del boleto',
    },
    hotel_info: {
      see_on_map: 'Ver en el mapa',
      check_in: 'Check-in',
      check_out: 'Check-out',
      daily_rate: 'Tarifa diaria del hotel',
      total: 'Total',
    },
    modify_reserve: {
      modify: 'Modificar reserva',
      remove: 'Eliminar',
    },
  },
}

export default esComponent
