import styled from 'styled-components'

export const DropdownNavContentStyles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  > p {
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.035px;
  }
  > div {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
`
