import styled from 'styled-components'

export const FlightFiltersSectionContainer = styled.section`
  min-width: 335px;
  border-radius: 16px;
  border: 2px solid #d9d9d9;
  padding: 24px;
  height: fit-content;

  > form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .filters-container-header {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
  }
  .flight-type {
    display: flex;
    flex-direction: column;
    gap: 18px;
    > p {
      padding: 18px 0;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.08px;
    }
  }
  .container-with-dividers {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .flight-time-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.035px;
  }
  .flight-airport-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const FlightFiltersSectionDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 12px 0;
  background-color: #999;
`

export const FlightFiltersCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  > p {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.08px;
  }
`

export const FlightFiltersButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  > p {
    padding: 24px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.08px;
  }
`

export const FlightFiltersRangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  > p {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.08px;
  }
  > div {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
`
