import React from 'react'
import { StyledTable } from './styles'
import type { TableProps } from 'antd'

export type SmilesTableProps = {
  columns: TableProps<any>['columns']
  data: any[]
  loading?: boolean
}

const Table: React.FC<SmilesTableProps> = ({ columns, data, loading }) => {
  return (
    <StyledTable
      dataSource={data}
      columns={columns}
      loading={loading}
      pagination={false}
      data-testid="smiles-table"
    />
  )
}

export default Table
