import React, { useEffect, useState } from 'react'
import { Empty } from 'antd'
import FlightCardRadio from '../flightCardRadio'
import MoreOptionsButton from 'components/moreOptionsButton'
import { Flight, FlightRoute } from 'services/flights/flightModel'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'
import { RenderFlightsStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { filterFlights } from 'pages/flights/helpers/filterFlights'

export type RenderFlightsProps = {
  flightData: (FlightRoute | undefined)[]
  isLoading: boolean
  flightType: 'departure' | 'return'
}

const DEFAULT_VISIBLE_FLIGHTS = 5

const RenderFlights: React.FC<RenderFlightsProps> = ({
  flightData,
  isLoading,
  flightType,
}) => {
  const { setSelectedFlights, selectedFlights, flightFilters } =
    useFlowContext()
  const { t } = useTranslation()

  const [selectedFlightItem, setSelectedFlightItem] = useState<Flight>()
  const [visibleFlights, setVisibleFlights] = useState<number>(
    DEFAULT_VISIBLE_FLIGHTS,
  )
  const [filteredFlights, setFilteredFlights] = useState<Flight[]>([])

  const handleSelectFlight = (flight: Flight) => {
    setSelectedFlightItem(flight)
  }

  const loadMoreFlights = () => {
    setVisibleFlights((prevVisibleFlights) => prevVisibleFlights + 5)
  }

  const renderFlightCard = (flight: Flight) =>
    flight && (
      <FlightCardRadio
        key={flight.key}
        checked={flight.key === selectedFlightItem?.key}
        flightData={flight}
        onClick={() => handleSelectFlight(flight)}
      />
    )

  useEffect(() => {
    if (selectedFlightItem && flightType === 'departure') {
      setSelectedFlights({ ...selectedFlights, departure: selectedFlightItem })
    } else if (
      selectedFlights &&
      selectedFlightItem &&
      flightType === 'return'
    ) {
      setSelectedFlights({ ...selectedFlights, return: selectedFlightItem })
    }
  }, [selectedFlightItem])

  useEffect(() => {
    const defaultFlight = flightData[0]?.flights[0]
    if (!defaultFlight) {
      return
    }

    const selectedFlight = selectedFlights?.[flightType]
    setSelectedFlightItem(selectedFlight || defaultFlight)
  }, [flightData])

  useEffect(() => {
    const newFilteredFlights = filterFlights(
      flightData as FlightRoute[],
      flightFilters,
    )
    setFilteredFlights(newFilteredFlights)
  }, [flightData, flightFilters])

  return (
    <RenderFlightsStyles data-testid={`smiles-render-${flightType}-flights`}>
      {filteredFlights
        .slice(0, visibleFlights)
        .map((flight) => renderFlightCard(flight))}
      {!isLoading && !filteredFlights.length && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('flights.renderFlights.emptyFlightsDescription')}
          data-testid="smiles-empty-img"
        />
      )}
      {filteredFlights.length > 0 && (
        <MoreOptionsButton
          label={t('flights.moreOptionsButton')}
          onClick={loadMoreFlights}
        />
      )}
    </RenderFlightsStyles>
  )
}

export default RenderFlights
