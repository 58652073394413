export default `
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" >
    <path d="M14.7773 7.70711C15.1678 7.31658 15.801 7.31658 16.1915 7.70711C16.582 8.09763 
    16.582 8.7308 16.1915 9.12132L9.12041 16.1924C8.72989 16.5829 8.09672 16.5829 7.7062 
    16.1924C7.31568 15.8019 7.31568 15.1687 7.7062 14.7782L14.7773 7.70711Z" />
    <path d="M16.1913 14.7782C16.5819 15.1688 16.5819 15.8019 16.1913 16.1925C15.8008 16.583 
    15.1676 16.583 14.7771 16.1925L7.70605 9.12139C7.31552 8.73087 7.31553 8.0977 7.70605 7.70718C8.09657 
    7.31665 8.72974 7.31665 9.12026 7.70718L16.1913 14.7782Z" />
  </svg>
`
