import React, { useEffect, useState } from 'react'
import {
  BoxLabel,
  // MoreServicesBox,
  SummaryBox,
  SummaryContentStyles,
  SummaryFlightBox,
  SummaryHotelBox,
} from './styles'
import { SmilesIcon } from 'components/icons'
// import ServiceCard from 'components/serviceCard'
import Button from 'components/button'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'
import { Flight } from 'services/flights/flightModel'
import DrawerBottomNavigation from 'components/drawerBottomNavigation'
import RenderTravelerAndRequester from './components/renderTravelerAndRequester'
import RenderTotal from './components/renderTotal'
import RenderFlightInfo from './components/renderFlightInfo'
import RenderHotelInfo from './components/renderHotelInfo'
import { useHotelCart } from './hooks/useHotelCart'
import { useFlightCart } from './hooks/useFlightCart'
import { useNavigate } from 'react-router-dom'
import RenderModifyButtons from './components/renderModifyButtons'
import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'

export type SummaryModalContentProps = {
  goBack: () => void
}

const SummaryModalContent: React.FC<SummaryModalContentProps> = ({
  goBack,
}) => {
  const { t } = useTranslation()
  const path = window.location.pathname

  const {
    selectedFlights,
    selectedHotel,
    selectedRoom,
    totalValue,
    loading,
    setSelectedFlights,
    setSelectedHotel,
    setSelectedRoom,
  } = useFlowContext()
  const navigate = useNavigate()

  const { user } = useAuth0()

  const sendHotelCart = useHotelCart({ selectedHotel, selectedRoom })
  const sendFlightCart = useFlightCart({ selectedFlights })

  const [flightData, setFlightData] = useState<Flight[]>()
  const [canNavigateToCostCenter, setCanNavigateToCostCenter] = useState(false)

  const finish = async () => {
    setCanNavigateToCostCenter(false)
    try {
      await sendFlightCart()
      await sendHotelCart()
      navigate('/debt-allocation')
    } catch (e) {
      console.log(e)
    } finally {
      setCanNavigateToCostCenter(true)
    }
  }

  const removeHotel = () => {
    setSelectedHotel(undefined)
    setSelectedRoom(undefined)
  }

  const removeFlights = () => {
    setSelectedFlights(undefined)
  }

  // const saveHotelAndChangeToFlights = async () => {
  //   try {
  //     await sendHotelCart()
  //     navigate('/flow/flights')
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  // const saveFlightsAndChangeToHotel = async () => {
  //   try {
  //     await sendFlightCart()
  //     navigate('/flow/hotel')
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  useEffect(() => {
    if (!loading && (selectedFlights || (selectedHotel && selectedRoom))) {
      setCanNavigateToCostCenter(true)
    } else {
      setCanNavigateToCostCenter(false)
    }
  }, [selectedFlights, selectedHotel, selectedRoom, loading])

  useEffect(() => {
    const flights = []
    if (selectedFlights?.departure) {
      flights.push(selectedFlights.departure)
    }
    if (selectedFlights?.return?.key) {
      flights.push(selectedFlights.return)
    }
    setFlightData(flights)
  }, [selectedFlights])

  return (
    <SummaryContentStyles data-testid="smiles-summary-modal-content">
      <div className="data">
        <p className="title">
          {t('component.request_summary.request_summary')}
        </p>

        <div className="travelers-and-fares">
          <SummaryBox>
            <RenderTravelerAndRequester
              name={user?.name}
              title={t('component.request_summary.requester')}
            />
            <RenderTravelerAndRequester
              name={user?.name}
              title={t('component.request_summary.traveler')}
            />
          </SummaryBox>

          <SummaryBox>
            <p className="total-label">
              {t('component.request_summary.total_value')}
            </p>
            <RenderTotal total={totalValue} />
          </SummaryBox>
        </div>

        {flightData && flightData.length > 0 && (
          <SummaryFlightBox>
            <BoxLabel>
              <span>
                <SmilesIcon type="airplanes" size={24} />{' '}
                <p>{t('component.request_summary.flight.air')}</p>
              </span>
              {path !== '/flow/flights' && (
                <RenderModifyButtons
                  navigate={navigate}
                  path="/flow/flights"
                  removeFunc={removeFlights}
                />
              )}
            </BoxLabel>
            <RenderFlightInfo flightData={flightData} />
          </SummaryFlightBox>
        )}

        {selectedHotel && selectedRoom && (
          <SummaryHotelBox>
            <BoxLabel>
              <span>
                <SmilesIcon type="sleep" size={24} />
                <p>{selectedHotel.hotel.name}</p>
              </span>
              {path !== '/flow/hotel' && (
                <RenderModifyButtons
                  navigate={navigate}
                  path="/flow/hotel"
                  removeFunc={removeHotel}
                />
              )}
            </BoxLabel>
            <RenderHotelInfo
              selectedHotel={selectedHotel}
              selectedRoom={selectedRoom}
            />
          </SummaryHotelBox>
        )}

        {/* <MoreServicesBox>
          <p>{t('component.request_summary.add_more_services_to_your_reserve')}</p>
          <div>
            {path !== '/flow/flights' && (
              <ServiceCard
                icon={<SmilesIcon type="airplane" size={20} />}
                label={'Aéreo'}
                onClick={() => saveHotelAndChangeToFlights()}
              />
            )}
            {path !== '/flow/hotel' && (
              <ServiceCard
                icon={<SmilesIcon type="sleep" size={20} />}
                label={'Hotel'}
                onClick={() => saveFlightsAndChangeToHotel()}
              />
            )}
          </div>
        </MoreServicesBox> */}
      </div>

      <DrawerBottomNavigation>
        <Button buttonType="secondary" onClick={goBack}>
          {t('component.request_summary.come_back')}
        </Button>
        <Button onClick={finish} disabled={!canNavigateToCostCenter}>
          {t('component.request_summary.go_to_costs_center')}
        </Button>
      </DrawerBottomNavigation>
    </SummaryContentStyles>
  )
}

export default SummaryModalContent
