export const isValidCPF = (cpf: string) => {
  // Remove caracteres não numéricos
  cpf = cpf.replace(/\D/g, '')

  // Verifica se o CPF tem 11 dígitos
  if (cpf.length !== 11) {
    return false
  }

  // Verifica se todos os dígitos são iguais, o que é inválido
  if (/^(\d)\1{10}$/.test(cpf)) {
    return false
  }

  // Calcula os dígitos verificadores
  let sum = 0
  let remainder

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i)
  }

  remainder = (sum * 10) % 11

  if (remainder === 10 || remainder === 11) {
    remainder = 0
  }

  if (remainder !== parseInt(cpf.substring(9, 10))) {
    return false
  }

  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i)
  }

  remainder = (sum * 10) % 11

  if (remainder === 10 || remainder === 11) {
    remainder = 0
  }

  if (remainder !== parseInt(cpf.substring(10, 11))) {
    return false
  }

  return true
}

export const isValidPassport = (passportNumber: string): boolean => {
  if (passportNumber.length < 6) {
    return false
  }

  if (!/^[A-Z0-9]+$/i.test(passportNumber)) {
    return false
  }

  return true
}

export const isValidRG = (rg: string) => {
  rg = rg.replace(/\D/g, '')

  if (rg.length < 6 || rg.length > 14) {
    return false
  }

  if (/^(\d)\1+$/.test(rg)) {
    return false
  }
  return true
}
