import React, { ReactElement } from 'react'
import { NavCardItemStyles } from './styles'

export type NavCardItemTypes = {
  icon: ReactElement
  label: string
  onClick?: () => void
}

const NavCardContent: React.FC<NavCardItemTypes> = ({
  icon,
  label,
  onClick,
}) => (
  <NavCardItemStyles data-testid="smiles-nav-card-item" onClick={onClick}>
    <div>{icon}</div>
    <div>{label}</div>
  </NavCardItemStyles>
)

export default NavCardContent
