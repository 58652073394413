const esOutsourced = {
  management: {
    title: 'Registro de empleados terceirizados',
    tabs: {
      employees: 'Empleado externalizado',
      companies: 'Empresas',
    },
    search: {
      placeholder: 'Busca por CPF, nombre o correo electrónico.',
      company_placeholder: 'Busca por CNPJ o nombre de la empresa.',
      add_outsourced: 'Externalizado',
      add_company: 'Empresa',
    },
  },
  employees_list: {
    card: {
      name: {
        label: 'Nombre',
      },
      cpf: {
        label: 'CPF',
      },
      phone: {
        label: 'Teléfono',
      },
    },
  },
  company_details: {
    breadcrumb: {
      outsourced: 'Terceirizados',
    },
    action: {
      enable_company: 'Activar empresa',
      disable_company: 'Desactivar empresa',
      add_outsourced: 'Empleado tercerizado',
      edit_company: 'Editar',
    },
  },
  companies_list: {
    card: {
      company_name: {
        label: 'Empresa',
      },
      cnpj: {
        label: 'CNPJ',
      },
    },
  },
  handle_company: {
    title: 'Registrar empresa',
    edit_title: 'Editar empresa',
    outsourced_title: 'Personas tercerizadas',
    outsourced_add_description:
      'Adicione ou cadastre ao menos uma Pessoa terceirizada. Você poderá cadastrar mais Pessoas terceirizadas depois.',
    outsourced_add_button: 'Adicionar colaborador',
    form: {
      required: 'Campo obligatorio',
      company_name: {
        label: 'Nombre comercial',
        placeholder: 'Ingrese el nombre comercial',
      },
      cnpj: {
        label: 'CNPJ',
        placeholder: 'Ingrese el CNPJ',
        errors: {
          not_valid: 'El CNPJ proporcionado no es válido',
        },
      },
    },
    edit_button: 'Guardar',
  },
  edit_employee: {
    title: 'Editar empleado tercerizado',
    save_button: 'Guardar',
  },
  employee_details: {
    title: 'Persona tercerizada',
    fields: {
      name: {
        label: 'Nombre completo',
      },
      cpf: {
        label: 'CPF',
      },
      rg: {
        label: 'RG',
      },
      nationality: {
        label: 'Nationality',
      },
      passport: {
        label: 'Pasaporte',
      },
      company: {
        label: 'Empresa',
      },
      position: {
        label: 'Cargo',
      },
      gender: {
        label: 'Género',
        male: {
          label: 'Masculino',
        },
        female: {
          label: 'Femenino',
        },
      },
      birthdate: {
        label: 'Fecha de nacimiento',
      },
      phone: {
        label: 'Teléfono',
      },
      email: {
        label: 'Correo electrónico',
      },
      concession: {
        label: 'Concesión',
      },
      foreign: {
        label: 'Persona extranjera',
      },
    },
    bottom_drawer: {
      edit: 'Editar',
    },
  },
  new_outsourced: {
    registerOutsourcedEmployee: 'Registrar empleado externalizado',
    confirmButton: 'Registrar',
    bad_request:
      'Error al ejecutar la acción. Verifique los datos del formulario.',
    form: {
      required: 'Campo obligatorio',
      name: {
        label: 'Nombre completo',
        placeholder: 'Ingrese el nombre completo',
      },
      cpf: {
        label: 'CPF',
        placeholder: 'Ingrese el CPF',
        errors: {
          not_valid: 'El CPF proporcionado no es válido',
        },
      },
      rg: {
        label: 'RG (Opcional)',
        placeholder: 'Ingrese el RG',
        errors: {
          not_valid: 'El RG proporcionado no es válido',
        },
      },
      nationality: {
        label: 'Nationality (Optional)',
        placeholder: 'Select',
      },
      passport: {
        label: 'Pasaporte (opcional)',
        placeholder: 'Ingrese el número de pasaporte',
      },
      company: {
        label: 'Empresa',
        placeholder: 'Ingrese el empresa',
      },
      position: {
        label: 'Cargo',
        placeholder: 'Ingrese el cargo',
      },
      gender: {
        label: 'Género',
        placeholder: 'Seleccione',
        male: {
          label: 'Masculino',
        },
        female: {
          label: 'Femenino',
        },
      },
      birthdate: {
        label: 'Fecha de nacimiento',
      },
      phone: {
        label: 'Teléfono',
        placeholder: 'DDD + teléfono',
      },
      email: {
        label: 'Correo electrónico',
        placeholder: 'Ingrese el correo electrónico',
      },
      concession: {
        label: 'Concesión',
      },
      foreign: {
        label: 'Persona extranjera',
      },
      optional: '(Opcional)',
    },
  },
  change_employee_status: {
    title: 'Desactivar tercero',
    disable_description_1:
      'Esta acción desactivará a la persona tercero, impidiendo que se utilice en cualquier proceso dentro del sistema.',
    disable_description_2:
      'Puedes reactivar a la persona tercero en el futuro.',
    enable_description_1:
      'Esta acción activará a la persona tercero, permitiendo que se utilice en cualquier proceso dentro del sistema.',
    enable_description_2: 'Puedes desactivar la persona tercero en el futuro.',
    confirm_checkbox: 'Comprendido',
    disable_button: 'Desactivar',
    enable_button: 'Activar',
    success_message: 'Estado cambiado con éxito',
    error_message: 'Error al cambiar el estado del tercero',
  },
  change_company_status: {
    title: 'Desactivar empresa',
    disable_description_1:
      'Esta acción desactivará la empresa seleccionada, impidiendo que se utilice en cualquier proceso dentro del sistema, ' +
      'así como desactivando a todas las personas tercerizadas vinculadas a ella.',
    disable_description_2: 'Puedes reactivar la empresa en el futuro.',
    enable_description_1:
      'Esta acción activará la empresa seleccionada, permitiendo que se utilice en cualquier proceso dentro del sistema, ' +
      'así como activando a todas las personas tercerizadas vinculadas a ella.',
    enable_description_2: 'Puedes desactivar la empresa en el futuro.',
    confirm_checkbox: 'Comprendido',
    disable_button: 'Desactivar',
    success_message: 'Estado cambiado con éxito',
    error_message: 'Error al cambiar el estado de la empresa',
  },
}

export default esOutsourced
