import React from 'react'
import MainContainer from 'components/mainContainer'
import UserInfo from './components/UserInfo'
import ContactInfo from './components/ContactInfo'
import Spinner from 'components/spinner'
import useProfileData from './hooks/useProfileData'
import {
  MyProfilePageHeader,
  MyProfilePageContent,
  LoadingSpinner,
} from './styles'
import { useTranslation } from 'react-i18next'

const MyProfile: React.FC = () => {
  const {
    userData,
    isEditing,
    newPhoneNumber,
    newSecondaryPhoneNumber,
    error,
    isLoading,
    setIsEditing,
    setNewPhoneNumber,
    setNewSecondaryPhoneNumber,
    isSaveButtonDisabled,
  } = useProfileData()
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <MainContainer data-testid="my-profile-loading">
        <MyProfilePageHeader>
          <h1 className="header-title">{t('myProfile.header')}</h1>
        </MyProfilePageHeader>
        <MyProfilePageContent>
          <LoadingSpinner>
            <Spinner />
          </LoadingSpinner>
        </MyProfilePageContent>
      </MainContainer>
    )
  }

  if (error) {
    return (
      <MainContainer data-testid="my-profile-error">
        <MyProfilePageHeader>
          <h1 className="header-title">{t('myProfile.header')}</h1>
        </MyProfilePageHeader>
        <MyProfilePageContent>
          <p className="error-message">{error}</p>
        </MyProfilePageContent>
      </MainContainer>
    )
  }

  return (
    <MainContainer data-testid="my-profile">
      <MyProfilePageHeader>
        <h1 className="header-title">{t('myProfile.header')}</h1>
      </MyProfilePageHeader>
      <MyProfilePageContent>
        <UserInfo userData={userData} />
        <ContactInfo
          userData={userData}
          isEditing={isEditing}
          newPhoneNumber={newPhoneNumber}
          newSecondaryPhoneNumber={newSecondaryPhoneNumber}
          setNewPhoneNumber={setNewPhoneNumber}
          setNewSecondaryPhoneNumber={setNewSecondaryPhoneNumber}
          handleEditClick={() => setIsEditing(true)}
          handleCancelClick={() => setIsEditing(false)}
          handleSaveClick={() => setIsEditing(false)}
          isSaveButtonDisabled={isSaveButtonDisabled}
        />
      </MyProfilePageContent>
    </MainContainer>
  )
}

export default MyProfile
