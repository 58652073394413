import styled from 'styled-components'

export const SelectLanguageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  > div {
    svg {
      display: flex !important;
      border-radius: 20px;
    }
  }
`
