import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react'
import { getAllUserPermissions } from 'services/permissions'
import { SPermission } from 'shared/interfaces/SPermission'
import { notifyError } from 'utils/handleRequestNotify'

export type PermissionsContextState = {
  userPermissions?: SPermission[]
  setUserPermissions: Dispatch<SetStateAction<SPermission[] | undefined>>
  isPermissionsLoading: boolean
  setIsPermissionsLoading: Dispatch<SetStateAction<boolean>>
  can: (action: string) => boolean
  loadAllUserPermissions: () => void
}

const PermissionsContext = createContext<PermissionsContextState | undefined>(
  undefined,
)

type PermissionsContextProviderProps = {
  children: ReactNode
}

export const PermissionsContextProvider: React.FC<
  PermissionsContextProviderProps
> = ({ children }) => {
  const [userPermissions, setUserPermissions] = useState<SPermission[]>()
  const [isPermissionsLoading, setIsPermissionsLoading] =
    useState<boolean>(false)

  const loadAllUserPermissions = async () => {
    setIsPermissionsLoading(true)
    try {
      const response = await getAllUserPermissions()
      if (response.error) {
        notifyError(response.error)
        return
      }

      setUserPermissions(response)
    } catch (error: any) {
      notifyError(error.message)
    } finally {
      setIsPermissionsLoading(false)
    }
  }

  const can = (action: string): boolean => {
    if (isPermissionsLoading || !userPermissions) {
      return false
    }

    return userPermissions.some(
      (perm) => `${perm.path}#${perm.method}` === action,
    )
  }

  const contextValue: PermissionsContextState = useMemo(() => {
    return {
      userPermissions,
      setUserPermissions,
      isPermissionsLoading,
      setIsPermissionsLoading,
      can,
      loadAllUserPermissions,
    }
  }, [
    userPermissions,
    setUserPermissions,
    isPermissionsLoading,
    setIsPermissionsLoading,
    loadAllUserPermissions,
    can,
  ])

  return (
    <PermissionsContext.Provider value={contextValue}>
      {children}
    </PermissionsContext.Provider>
  )
}

export const usePermissionsContext = () => {
  const context = useContext(PermissionsContext)
  if (!context) {
    throw new Error(
      'usePermissionsContext must be used inside a PermissionsContextProvider',
    )
  }
  return context
}
