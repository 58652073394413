import styled from 'styled-components'

export const NewGroupContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;

  .header {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.08px;
  }

  .permissions {
    > p {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px;
      letter-spacing: 0.08px;
    }

    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 150px;
  }
`
