import styled from 'styled-components'

export const SimpleTabsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding: 8px 0;
`

export const SimpleTab = styled.button<{ $active: boolean }>`
  margin: 0;
  border: none;
  background: none;
  font: inherit;
  color: #737373;
  text-align: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.16px;
  padding: 18px 0;
  cursor: pointer;

  > div {
    width: 100%;
    height: 3px;
    margin-top: 8px;
  }

  ${({ $active }) =>
    $active &&
    `
    color: #1A1A1A;
    > div {
      background-color: var(--primary-color);
    }
  `}
`
