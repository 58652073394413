import styled from 'styled-components'

export const SearchCostCenterStyles = styled.div`
  padding: 0vw 3.888vw 7.777vw 3.888vw;
  .header {
    width: 51.666vw;
    height: 2.291vw;
    margin-bottom: 1.944vw;
    font-family: Nunito;
    font-size: 1.666vw;
    font-weight: 600;
    line-height: 2.5vw;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 51.736vw;
    height: 19.166vw;
    gap: 1.666vw;
  }

  .search_bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 51.736vw;
    padding: 1.388vw 1.666vw;
    border-radius: 0.555vw;
    border: 0.139vw solid #999999;
    gap: 0.555vw;
  }

  .search_bar input {
    border: none;
    width: 46.18vw;
    font-family: Nunito;
    font-size: 0.972vw;
    font-weight: 400;
    line-height: 1.388vw;
    letter-spacing: 0.0025em;
    color: #1a1a1a;
  }

  input:focus {
    outline: none;
  }

  .input_field {
    width: 51.736vw;
    height: 6.388vw;
    gap: 0.555vw;
  }

  .input_field_label {
    height: 1.388vw;
    font-family: Nunito;
    font-size: 0.972vw;
    font-weight: 700;
    line-height: 1.388vw;
    letter-spacing: 0.0025em;
  }

  tr {
    padding: 0.555vw 0vw;
    width: 51.666vw;
    height: 2.5vw;
    padding: 0.555vw;
    border-radius: 0.555vw;
    border-bottom: 0.139vw solid #d9d9d9;
  }

  th {
    width: 51.666vw;
    height: 2.5vw;
    padding: 0.555vw;
    font-family: Nunito;
    font-size: 1.111vw;
    font-weight: 700;
    line-height: 1.666vw;
    letter-spacing: 0.005em;
  }

  td {
    font-family: Nunito;
    font-size: 0.972vw;
    font-weight: 400;
    line-height: 1.389vw;
    letter-spacing: 0.0025em;
    color: #1a1a1a;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
`
export const FooterStyles = styled.div`
  position: fixed;
  bottom: 0;
  width: 59.444vw;
  height: 7.777vw;
  padding: 1.666vw 3.888vw 1.666vw 3.888vw;
  gap: 1.111vw;
  background-color: #ffffff;
  box-shadow: 0vw 0vw 1.666vw 0vw #00000014;

  button {
    padding: 0;
    margin-left: auto;
    font-size: 1.111vw;
    height: 4.444vw;
  }
`
