import styled from 'styled-components'

export const CompleteRequestStyles = styled.div`
  width: 100%;
  height: 24.444vw;
  padding: 4.583vw;
  background-color: #333333;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90.833vw;
    height: 2.777vw;
    margin-bottom: 2.222vw;
  }

  .header_button {
    cursor: pointer;
  }

  .header span {
    height: 2.222vw;
    font-family: Nunito;
    font-size: 1.666vw;
    font-weight: 600;
    line-height: 2.222vw;
    letter-spacing: -0.034vw;
    color: #ffffff;
  }

  .text_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90.833vw;
    height: 10.277vw;
    border-radius: 1.111vw;
    padding: 2.916vw 2.777vw;
    gap: 4.444vw;
    background-color: #ffffff;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.555vw;
  }

  .text_container_title {
    width: 62.5vw;
    height: 2.222vw;
    font-family: Nunito;
    font-size: 1.388vw;
    font-weight: 600;
    line-height: 2.222vw;
    letter-spacing: 0.0015em;
  }

  .text_container_text {
    width: 62.5vw;
    height: 1.666vw;
    font-family: Nunito;
    font-size: 1.111vw;
    font-weight: 400;
    line-height: 1.666vw;
    letter-spacing: 0.005em;
    color: #595959;
  }
`
