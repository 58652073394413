import React from 'react'
import { InputContainer, InputLabel } from './styles'
import { Input as AntdInput } from 'antd'

export type InputTypes = {
  placeholder?: string
  label?: string
  value?: string | number
  width?: string
  name?: string
  inputType?: 'text' | 'password'
  type?: string
  disabled?: boolean
  suffix?: JSX.Element
  prefix?: JSX.Element
  onChange?: (e?: any) => void
}

const Input: React.FC<InputTypes> = ({
  placeholder,
  label,
  value,
  width,
  name,
  inputType = 'text',
  type,
  disabled = false,
  prefix,
  onChange,
  ...rest
}) => (
  <InputContainer width={width} data-testid="smiles-input">
    {label && <InputLabel>{label}</InputLabel>}
    {inputType === 'text' && (
      <AntdInput
        className="custom-antd-input"
        placeholder={placeholder}
        name={name}
        value={value}
        data-testid="smiles-input-text"
        type={type}
        onChange={onChange}
        disabled={disabled}
        prefix={prefix}
        {...rest}
      />
    )}
    {inputType === 'password' && (
      <AntdInput.Password
        className="custom-antd-input"
        placeholder={placeholder}
        name={name}
        data-testid="smiles-input-password"
        {...rest}
      />
    )}
  </InputContainer>
)

export default Input
