import React, { useEffect, useState } from 'react'
import {
  ChangeSearchButton,
  HotelInfoBarContainer,
  HotelInfoBarStyles,
} from './styles'
import Drawer from 'components/drawer'
import MainContainer from 'components/mainContainer'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'
import HotelFormSearch from 'components/searchForms/hotel'
import dayjs from 'dayjs'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'

const HotelInfoBar: React.FC = () => {
  const { t } = useTranslation()
  const { searchHotels } = useFlowContext()

  const [openDrawer, setOpenDrawer] = useState(false)

  const { width } = useWindowSize()
  const isSmallScreen = width < 840

  const handleClose = () => {
    setOpenDrawer(false)
  }

  const renderHotelInfo = () => {
    if (searchHotels) {
      return (
        <>
          <HotelInfoBarStyles>
            <div>
              <span>{searchHotels.destination}</span>
              <p>{dayjs(searchHotels.start).format('ddd, D MMM, YYYY')}</p>
              <p>a</p>
              <p>{dayjs(searchHotels.end).format('ddd, D MMM, YYYY')}</p>
            </div>
          </HotelInfoBarStyles>
          <HotelInfoBarStyles>
            <div>
              <p>1 {t('hotel.room')}</p>
            </div>
          </HotelInfoBarStyles>
          <HotelInfoBarStyles>
            <div>
              <p>Single</p>
            </div>
          </HotelInfoBarStyles>
        </>
      )
    }

    return null
  }

  useEffect(() => {
    handleClose()
  }, [searchHotels])

  return (
    <HotelInfoBarContainer data-testid="smiles-hotel-infobar">
      {renderHotelInfo()}
      <ChangeSearchButton
        data-testid="change-search-button"
        onClick={() => setOpenDrawer(true)}
      >
        {t('hotel.hotelInfoBar.changeSearch')}
      </ChangeSearchButton>

      <Drawer
        onClose={handleClose}
        open={openDrawer}
        placement="top"
        closable={isSmallScreen}
        height={isSmallScreen ? '100%' : 320}
      >
        <MainContainer>
          <HotelFormSearch />
        </MainContainer>
      </Drawer>
    </HotelInfoBarContainer>
  )
}

export default HotelInfoBar
