const enOutsourced = {
  management: {
    title: 'Outsourced employee registration',
    tabs: {
      employees: 'Outsourced employees',
      companies: 'Companies',
    },
    search: {
      placeholder: 'Search by CPF, name, or email.',
      company_placeholder: 'Search by CNPJ or company name',
      add_outsourced: 'Outsourced',
      add_company: 'Company',
    },
  },
  employees_list: {
    card: {
      name: {
        label: 'Name',
      },
      cpf: {
        label: 'CPF',
      },
      phone: {
        label: 'Phone',
      },
    },
  },
  company_details: {
    breadcrumb: {
      outsourced: 'Terceirizados',
    },
    action: {
      enable_company: 'Enable company',
      disable_company: 'Disable company',
      add_outsourced: 'Outsourced employee',
      edit_company: 'Edit',
    },
  },
  companies_list: {
    card: {
      company_name: {
        label: 'Company',
      },
      cnpj: {
        label: 'CNPJ',
      },
    },
  },
  handle_company: {
    title: 'Register company',
    edit_title: 'Edit company',
    outsourced_title: 'Outsource employees',
    outsourced_add_description:
      'Adicione ou cadastre ao menos uma Pessoa terceirizada. Você poderá cadastrar mais Pessoas terceirizadas depois.',
    outsourced_add_button: 'Adicionar colaborador',
    form: {
      required: 'Required field',
      company_name: {
        label: 'Company name',
        placeholder: 'Enter the company name',
      },
      cnpj: {
        label: 'CNPJ',
        placeholder: 'CNPJ',
        errors: {
          not_valid: 'The provided CNPJ is not valid',
        },
      },
    },
    edit_button: 'Save',
  },
  edit_employee: {
    title: 'Edit outsourced employee',
    save_button: 'Save',
  },
  employee_details: {
    title: 'Outsource employee',
    fields: {
      name: {
        label: 'Full name',
      },
      cpf: {
        label: 'CPF',
      },
      rg: {
        label: 'RG',
      },
      nationality: {
        label: 'Nationality',
      },
      passport: {
        label: 'Passport',
      },
      company: {
        label: 'Company',
      },
      position: {
        label: 'Position',
      },
      gender: {
        label: 'Gender',
        male: {
          label: 'Male',
        },
        female: {
          label: 'Female',
        },
      },
      birthdate: {
        label: 'Date of birth',
      },
      phone: {
        label: 'Phone',
      },
      email: {
        label: 'E-mail',
      },
      concession: {
        label: 'Concession',
      },
      foreign: {
        label: 'Foreign person',
      },
    },
    bottom_drawer: {
      edit: 'Edit',
    },
  },
  new_outsourced: {
    registerOutsourcedEmployee: 'Register outsourced employee',
    confirmButton: 'Register',
    bad_request: 'Error executing the action. Check the form data.',
    form: {
      required: 'Required field',
      name: {
        label: 'Full name',
        placeholder: 'Enter the full name',
      },
      cpf: {
        label: 'CPF',
        placeholder: 'CPF',
        errors: {
          not_valid: 'The provided CPF is not valid',
        },
      },
      rg: {
        label: 'RG (Optional)',
        placeholder: 'Enter the RG',
        errors: {
          not_valid: 'The provided RG is not valid',
        },
      },
      nationality: {
        label: 'Nationality (Optional)',
        placeholder: 'Select',
      },
      passport: {
        label: 'Passport (Optional)',
        placeholder: 'Enter the passport number',
      },
      company: {
        label: 'Company',
        placeholder: 'Enter the company name or CNPJ',
      },
      position: {
        label: 'Position',
        placeholder: 'Enter the position',
      },
      gender: {
        label: 'Gender',
        placeholder: 'Select',
        male: {
          label: 'Male',
        },
        female: {
          label: 'Female',
        },
      },
      birthdate: {
        label: 'Date of birth',
      },
      phone: {
        label: 'Phone',
        placeholder: 'DDD + phone',
      },
      email: {
        label: 'E-mail',
        placeholder: 'Enter the e-mail',
      },
      concession: {
        label: 'Concession',
      },
      foreign: {
        label: 'Foreign person',
      },
      optional: '(Optional)',
    },
  },
  change_employee_status: {
    title: 'Deactivate third party',
    disable_description_1:
      'This action will deactivate the third party, preventing them from being used in any processes within the system.',
    disable_description_2: 'You can reactivate the third party in the future.',
    enable_description_1:
      'This action will activate the third party, allowing it to be used in any processes within the system.',
    enable_description_2: 'You can deactivate the third party in the future.',
    confirm_checkbox: 'I understand',
    disable_button: 'Disable',
    enable_button: 'Enable',
    success_message: 'Status changed successfully',
    error_message: 'Error changing third party status',
  },
  change_company_status: {
    title: 'Deactivate company',
    disable_description_1:
      'This action will deactivate the selected company, preventing it from being used in any processes within the system, ' +
      'as well as deactivating all third parties linked to it.',
    disable_description_2: 'You can reactivate the company in the future.',
    enable_description_1:
      'This action will activate the selected company, allowing it to be used in any processes within the system, ' +
      'as well as activating all third parties linked to it.',
    enable_description_2: 'You can deactivate the company in the future.',
    confirm_checkbox: 'I understand',
    disable_button: 'Deactivate',
    enable_button: 'Enable',
    success_message: 'Status changed successfully',
    error_message: 'Error changing company status',
  },
}

export default enOutsourced
