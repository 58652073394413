const ptTravelRequest = {
  travel_request: {
    confirm_answer: 'Confirmar resposta',
    justify_reproval: 'Justifique a reprovação',
    justify_reproval_reason: 'Descreva o motivo (mínimo 10 e máximo 300)',
    justify_cancellation: 'Justifique o cancelamento',
    approve: 'Aprovar SV',
    reprove: 'Reprovar SV',
    approve_request: 'Aprovar solicitação',
    reprove_request: 'Reprovar solicitação',
    do_you_want_to_approve: 'Deseja aprovar a solicitação?',
    do_you_want_to_reprove: 'Deseja reprovar a solicitação?',
    do_you_want_to_cancel: 'Deseja cancelar a solicitação?',
    approval_description:
      'Ao clicar em “Sim” a solicitação será emitida. Atenção: essa ação só poderá ser cancelada pelo time de gestão ou backoffice.',
    reproval_description:
      'Ao clicar em “Sim” a solicitação completa será reprovada. Atenção: essa ação não poderá ser desfeita.',
    cancel_description:
      'Ao clicar em “Sim” a solicitação será cancelada. Atenção: essa ação não poderá ser desfeita.',
    cancellation_description: 'Descrição do motivo de cancelamento',
    type_here: 'Descreva aqui (Mínimo de 10 e máximo de 300 caracteres)',
    choose_reason: 'Selecione o motivo',
    yes: 'Sim',
    cancel: 'Cancelar',
    cancel_request: 'Cancelar Solicitação',
    cancel_reasons: {
      date_change: 'Alteração de datas',
      flight_change: 'Alteração de voo',
      employee_terminated: 'Colaborador desligado',
      incorrect_entries: 'Lançamentos incorretos',
      duplicate_entries: 'Lançamentos em duplicidade',
      expired_reservations: 'Reservas vencidas',
      trip_canceled: 'Viagem cancelada',
      other: 'Outros',
    },
    notification: {
      successfully_approved: 'SV aprovada com sucesso!',
      successfully_reproved: 'SV reprovada com sucesso!',
      failed_to_approve: 'Falha ao aprovar a SV',
      failed_to_reprove: 'Falha ao reprovar a SV',
    },
    flight: {
      direct: 'Direto',
      stop: 'parada',
      stops: 'paradas',
      hours: 'hrs',
      air: 'Aéreo',
      departure_ticket: 'Passagem de ida',
      arrival_ticket: 'Passagem de volta',
      total: 'Total',
      fare_per_segment: 'Tarifa por trecho',
    },
    summary: {
      requester: 'Solicitante',
      traveler: 'Viajante',
      origin: 'Origem',
      destination: 'Destino',
      request_date: 'Data da solicitação',
      travel_date: 'Data da viagem',
      consultant: 'Consultor',
      travel_cost: 'Valor da viagem',
      advance: 'Antecedência',
      within_politics: 'Dentro da política',
    },
    price_change: {
      text: 'O preço da reserva mudou de R$ {{old_amount}} para R$ {{amount}}.',
    },
  },
}

export default ptTravelRequest
