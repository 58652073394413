import React from 'react'

import { useTranslation } from 'react-i18next'

import { FooterStyles } from './styles'

import Button from 'components/button'

export type FooterProps = {
  buttonDisabled?: boolean
  onClickReturn?: any
  onClickContinue?: any
}

const Footer: React.FC<FooterProps> = ({
  buttonDisabled,
  onClickReturn,
  onClickContinue,
}) => {
  const { t } = useTranslation()

  return (
    <FooterStyles data-testid="debt-allocation-footer">
      <Button width="100px" buttonType="outline-black" onClick={onClickReturn}>
        {t('footerButton.return')}
      </Button>
      <Button onClick={onClickContinue} disabled={buttonDisabled} width="260px">
        {t('footerButton.conclude')}
      </Button>
    </FooterStyles>
  )
}

export default Footer
