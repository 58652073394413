import React, { useEffect, useMemo, memo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { ThemeProvider } from 'styled-components'
import { ConfigProvider } from 'antd'
import { GlobalStyle } from 'styles/global'
import { themeAntd } from 'styles/theme'
import Routers from 'routes/Routers'
import ptBR from 'antd/lib/locale/pt_BR'
import esES from 'antd/lib/locale/es_ES'
import enUS from 'antd/lib/locale/en_US'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { setStorage } from 'utils/localStorage/storageUtils'
import { config } from 'configs/vars'
import { Locale } from 'antd/es/locale'

interface LocaleMap {
  [key: string]: Locale
}

const App: React.FC = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const { REACT_APP_AUTH0_TOKEN_VALIDITY_IN_SECONDS } = config()
  const { i18n } = useTranslation()
  const language = i18n.language

  const antdLocaleMap: LocaleMap = {
    pt: ptBR,
    es: esES,
    en: enUS,
  }

  const antdLocale: Locale = antdLocaleMap[language]
  dayjs.locale('pt-BR')

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then((token) => {
          const expirationDate = new Date()
          expirationDate.setTime(
            expirationDate.getTime() +
              Number(REACT_APP_AUTH0_TOKEN_VALIDITY_IN_SECONDS ?? 0),
          )

          setStorage('accessTokenExpiration', expirationDate.getTime())
          setStorage('accessToken', token)
        })
        .catch((error) => {
          console.error('Failed to fetch access token:', error)
        })
    }
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    REACT_APP_AUTH0_TOKEN_VALIDITY_IN_SECONDS,
    language,
  ])

  return (
    <div data-testid="smiles-app">
      <I18nextProvider i18n={i18n}>
        <ConfigProvider theme={themeAntd} locale={antdLocale}>
          <ThemeProvider theme={{}}>
            <GlobalStyle />
            <Routers />
          </ThemeProvider>
        </ConfigProvider>
      </I18nextProvider>
    </div>
  )
}

export default memo(App)
