import React from 'react'
import {
  CardList,
  Column,
  Card,
  Label,
  RowContainer,
  PaginationContainer,
} from './styles'
import { useTranslation } from 'react-i18next'
import { SmilesIcon } from 'components/icons'
import { Pagination } from 'antd'
import { formatCPF } from 'utils/formatters'

type EmployeesListData = {
  data: any[]
  onItemClick: any
  pagination: {
    page: number
    limit: number
    total: number
    onPageChange: (page: number) => void
  }
}

const EmployeesList: React.FC<EmployeesListData> = ({
  data,
  onItemClick,
  pagination,
}) => {
  const { t } = useTranslation()
  return (
    <CardList>
      {data.map((employee: any) => {
        return (
          <Card onClick={() => onItemClick(employee.id)} key={employee.id}>
            <RowContainer>
              <Column>
                <Label>{t('employees_list.card.name.label')}</Label>
                <p>{employee.full_name}</p>
              </Column>
              <Column>
                <Label>{t('employees_list.card.cpf.label')}</Label>
                <p>{formatCPF(employee.cpf)}</p>
              </Column>
              <Column>
                <Label>{t('employees_list.card.phone.label')}</Label>
                <p>{employee.phone}</p>
              </Column>
              <Column>
                <SmilesIcon type="arrow-right-alt" size={32} />
              </Column>
            </RowContainer>
          </Card>
        )
      })}
      <PaginationContainer>
        <Pagination
          showTotal={(total) => `Total ${total} items`}
          defaultCurrent={1}
          current={pagination.page}
          pageSize={pagination.limit}
          total={pagination.total}
          onChange={pagination.onPageChange}
        />
      </PaginationContainer>
    </CardList>
  )
}
export default EmployeesList
