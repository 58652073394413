import styled from 'styled-components'

export const CancelTravelModalContent = styled.div`
  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;

    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
    }

    .area-limit {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.0025em;
      margin-top: 8px;
    }
  }
`
