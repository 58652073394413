import React from 'react'
import { SVCancelStyles } from './styles'
import Button from 'components/button'
import DoubleCheck from 'components/doubleCheck'
import SVCancelForm from './svCancelForm'
import useSvCancel from './hooks/useSvCancel'
import { TravelRequest } from 'shared/interfaces/STravelRequestDetails'
import { StatusEnum } from 'constants/statusEnum'
import { useTranslation } from 'react-i18next'

type SVCancelProps = {
  travelRequest: TravelRequest
  fetchDetails: any
}

const SVCancel: React.FC<SVCancelProps> = ({ travelRequest, fetchDetails }) => {
  const { t } = useTranslation()
  const {
    state: { isDrawerOpen, showForm, anotherMotive, selectedReason, loading },
    handleFinish,
    setIsDrawerOpen,
    setReasonDesc,
    setSelectedReason,
    setShowForm,
  } = useSvCancel(fetchDetails)

  const isCancelDisabled = () => {
    return !(
      travelRequest.status === StatusEnum.OPENED ||
      travelRequest.status === StatusEnum.WAITING_FOR_APPROVAL
    )
  }

  return (
    <SVCancelStyles>
      {
        <Button
          buttonType="secondary"
          size="sm"
          width="165px"
          onClick={() => setShowForm(true)}
          disabled={isCancelDisabled()}
        >
          {t('travel_request.cancel_request')}
        </Button>
      }

      {showForm && (
        <SVCancelForm
          onCancel={() => setShowForm(false)}
          onConfirm={() => setIsDrawerOpen(true)}
          selectedReason={selectedReason}
          onReasonChange={setSelectedReason}
          anotherMotive={anotherMotive}
          onDescChange={setReasonDesc}
        />
      )}

      <DoubleCheck
        buttonClick={() =>
          handleFinish(travelRequest.id, selectedReason, anotherMotive)
        }
        buttonLabel={t('travel_request.yes')}
        close={() => setIsDrawerOpen(false)}
        mainLabel={t('travel_request.cancel_request')}
        subLabel={t('travel_request.do_you_want_to_cancel')}
        desc={t('travel_request.cancel_description')}
        isOpen={isDrawerOpen}
        height="24.444vw"
        loading={loading}
      />
    </SVCancelStyles>
  )
}

export default SVCancel
