import styled from 'styled-components'

export const SVFlightItemStyles = styled.div<{
  width?: string
}>`
  width: ${(props) => (props.width ? props.width : '100%')};
  padding: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    display: flex;
    justify-content: space-between;

    .route {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.01em;
    }
    .date {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.0025em;
    }
    .bagage {
      display: flex;
      gap: 8px;

      .bagage-item {
        display: flex;
        gap: 4px;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.0025em;
      }
    }
  }

  .flight {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    .route {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 32px;

      .iata-and-hour {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.005em;
      }
      .city {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.01em;
      }
    }

    .stops-and-hour {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .stops {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.005em;
      }
      .hour {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.005em;
      }
    }
  }
`
