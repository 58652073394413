const ptUserGroups = {
  userGroups: {
    pageTitle: 'Grupos de usuários',
    searchGroupPlaceholder: 'Pesquisar nome do grupo',
    newGroupButton: 'Novo grupo',
    breadcrumb: {
      home: 'Início',
      userGroups: 'Grupos de usuários',
    },
    notify: {
      groupRemoved: 'O grupo foi removido.',
    },
    table: {
      columns: {
        groupName: 'Nome do grupo',
        description: 'Descrição',
        action: 'Ação',
      },
      deleteGroupPopConfirm: {
        title: 'Deletar grupo',
        description: 'Essa açao é irreversível, deseja continuar?',
        okText: 'Sim',
        cancelText: 'Cancelar',
      },
    },
    alert: {
      noPermToViewGroupUsers:
        'Você não tem permissão para visualizar grupos de usuários.',
    },
  },
  handleGroup: {
    newUserGroups: 'Novo grupo de usuários',
    editUserGroups: 'Editar grupo de usuários',
    groupPermissions: 'Permissões do grupo',
    form: {
      groupName: {
        label: 'Nome do grupo',
        placeholder: 'Digite o nome do grupo',
        required: 'Campo obrigatório',
      },
      groupDescription: {
        label: 'Descrição',
        placeholder: 'Breve descrição do grupo',
        required: 'Campo obrigatório',
      },
      permissions: {
        label: 'Permissões',
        placeholder: 'Selecione as permissões',
      },
      confirmButton: 'Confirmar',
    },
    permissions: {
      permissions: 'Permissões',
      users: 'Usuários',
      'travel-request': 'Solicitação de viagem',
      'debit-allocation': 'Alocação de débito',
      'close-travel-request': 'Encerramento de viagem',
      approval: 'Aprovação',
    },
    alert: {
      noPermToViewGroupPermissions:
        'Você não tem permissão para visualizar permissões de grupo.',
      noPermToUpdateGroupPermissions:
        'Você não tem permissão para atualizar permissões de grupo.',
    },
  },
}

export default ptUserGroups
