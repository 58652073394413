import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react'
import { SPermission } from 'shared/interfaces/SPermission'

export type GlobalContextState = {
  userPermissions: SPermission[] | undefined
  setUserPermissions: Dispatch<SetStateAction<SPermission[] | undefined>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
}

const GlobalContext = createContext<GlobalContextState | undefined>(undefined)

type GlobalContextProviderProps = {
  children: ReactNode
}

export const GlobalContextProvider: React.FC<GlobalContextProviderProps> = ({
  children,
}) => {
  const [userPermissions, setUserPermissions] = useState<SPermission[]>()
  const [loading, setLoading] = useState(false)

  const contextValue: GlobalContextState = useMemo(() => {
    return {
      userPermissions,
      loading,
      setUserPermissions,
      setLoading,
    }
  }, [userPermissions, loading, setLoading, setUserPermissions])

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => {
  const context = useContext(GlobalContext)
  if (!context) {
    throw new Error(
      'useGlobalContext must be used inside a GlobalContextProvider',
    )
  }
  return context
}
