import styled from 'styled-components'

export const SnallTravelCardStyles = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  border: 1px solid #999999;
  border-radius: 16px;
  justify-content: space-between;
  width: 100%;

  .data {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .city-and-date {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .city {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.005em;
      }
      .date {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.01em;
      }
    }
    .sv-number {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.01em;
    }
  }

  .button-container {
    display: flex;
    height: 100%;
    .rounded-button {
      padding: 0px, 16px, 0px, 16px;
      background: var(--primary-color);
      height: 40px;
      width: 40px;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
`
