import React from 'react'
import { TabsWrapper } from './styles'

export type TabGroupProps = {
  children: React.ReactNode
  isFlowTab?: boolean
}

const TabGroup: React.FC<TabGroupProps> = ({ children, isFlowTab }) => (
  <TabsWrapper $isflowtab={isFlowTab} data-testid="smiles-tab-group">
    {children}
  </TabsWrapper>
)

export default TabGroup
