import React, { useEffect, useState } from 'react'
import MainContainer from 'components/mainContainer'
import { GroupScreenContent, SearchBar } from './styles'
import Table from 'components/table'
import { Alert, Popconfirm, Skeleton, Space, TableProps } from 'antd'
import { Link, useParams } from 'react-router-dom'
import SimpleTabs from 'components/simpleTabs'
import { SmilesIcon } from 'components/icons'
import {
  getGroupById,
  getGroupUsers,
  removeUserFromGroup,
} from 'services/group'
import Input from 'components/Input'
import Button from 'components/button'
import Drawer from 'components/drawer'
import AddUser from './addUser'
import Breadcrumb from 'components/breadcrumb'
import { notifyError, notifySuccess } from 'utils/handleRequestNotify'
import { useTranslation } from 'react-i18next'
import { SGroup } from 'shared/interfaces/SGroup'
import { SUser } from 'shared/interfaces/SUser'
import ListGroupPermissions from './listGroupPermissions'
import {
  getAllSystemPermissions,
  getPermissionsByGroupId,
} from 'services/permissions'
import { formatPermissionsForTreeSelect } from 'pages/shared/formatPermissionsForTreeSelect'
import { TreeData } from 'components/permissionTreeSelect'
import { usePermissionsContext } from 'contexts/permissionsContext/usePermissionsContext'
import { ACTION } from 'shared/userActionPermissions'

const GroupScreen: React.FC = () => {
  const { groupId } = useParams()
  const { t, i18n } = useTranslation()
  const { can } = usePermissionsContext()

  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [searchValue, setSearchValue] = useState<string>('')
  const [openDrawer, setOpenDrawer] = useState(false)
  const [users, setUsers] = useState<SUser[]>([])
  const [groupData, setGroupData] = useState<SGroup>()
  const [treeData, setTreeData] = useState<TreeData>([])
  const [checkedKeys, setCheckedKeys] = useState<string[]>([])

  const getCheckedPermissions = (permissions: []): string[] =>
    permissions.map((currentValue: any) => currentValue.id)

  const columns: TableProps<SUser>['columns'] = [
    {
      title: t('group.table.columns.userName'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <Link to={`/user/${record.email}`}>
          <p>{record.name}</p>
        </Link>
      ),
    },
    {
      title: t('group.table.columns.action'),
      key: 'action',
      width: 100,
      render: (_, record) => (
        <Space size="middle">
          {can(ACTION.permissions.removeUserFromGroup) && (
            <Popconfirm
              title={t('group.table.removeUserPopConfirm.title')}
              description={t('group.table.removeUserPopConfirm.description')}
              okText={t('group.table.removeUserPopConfirm.okText')}
              cancelText={t('group.table.removeUserPopConfirm.cancelText')}
              onConfirm={() => handleDeleteUser(record)}
              placement="left"
            >
              <div className="trash-icon">
                <SmilesIcon type="trash" size={20} />
              </div>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ]

  const tabs = [
    { label: t('group.tabs.users') },
    { label: t('group.tabs.permissions') },
  ]

  const handleTab = (idx: number) => {
    setCurrentTabIndex(idx)
  }

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
  }

  const handleOpenDrawer = () => {
    setOpenDrawer(true)
  }

  const handleCloseDrawer = () => {
    setOpenDrawer(false)
  }

  const handleDeleteUser = async (user: SUser) => {
    if (!groupId) {
      return
    }
    try {
      await removeUserFromGroup(user, groupId)
      fetchUsers(groupId)
      notifySuccess(t('group.notify.userRemoved'))
    } catch (error) {
      notifyError(t('group.notify.errorOnRemoveUser'))
    }
  }

  const onAddUserCallback = () => {
    setOpenDrawer(false)
    fetchUsers(groupId as string)
    notifySuccess(t('group.notify.userAdded'))
  }

  const fetchUsers = async (id: string) => {
    try {
      setIsLoading(true)
      const response = await getGroupUsers(id)
      setUsers(response.data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      notifyError()
    }
  }

  const fetchGroupInfo = async (id: string) => {
    try {
      const response = await getGroupById(id)
      setGroupData(response)
      fetchUsers(groupId as string)
    } catch (error) {
      setIsLoading(false)
      notifyError()
    }
  }

  const filteredUsers = users
    ?.filter((item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase()),
    )
    .map((item) => ({ ...item, key: item.id }))

  useEffect(() => {
    if (groupId) {
      fetchGroupInfo(groupId)
    }
  }, [groupId])

  const fetchPermissions = async () => {
    try {
      setIsLoading(true)
      const response = await getAllSystemPermissions()
      interface LocaleMap {
        [key: string]: string
      }
      const localeMap: LocaleMap = {
        es: 'es',
        en: 'en',
        'pt-BR': 'pt',
      }
      const language = localeMap[i18n.language] ?? 'pt'
      const treeData = formatPermissionsForTreeSelect(
        response.data,
        t,
        language,
      )
      setTreeData(treeData)
      const groupPermissions = await getPermissionsByGroupId(groupId)
      const checkedPermissions = getCheckedPermissions(groupPermissions.data)
      setCheckedKeys(checkedPermissions)
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchPermissions()
  }, [])

  return (
    <div data-testid="smiles-group-screen">
      <MainContainer>
        <GroupScreenContent>
          <Breadcrumb
            items={[
              {
                title: <Link to="/">{t('group.breadcrumb.home')}</Link>,
              },
              {
                title: (
                  <Link to="/user-groups">
                    {t('group.breadcrumb.userGroups')}
                  </Link>
                ),
              },
              {
                title: t('group.breadcrumb.group'),
              },
            ]}
          />
          <p className="page-title">{groupData?.name}</p>
          <div className="search-and-table">
            <SimpleTabs tabs={tabs} onClick={handleTab} />

            {currentTabIndex === 0 && (
              <>
                {can(ACTION.permissions.listGroupUsers) ? (
                  <>
                    <SearchBar data-testid="container-bar">
                      <Input
                        width="450px"
                        placeholder={t('group.searchUserPlaceholder')}
                        suffix={<SmilesIcon type="search" />}
                        onChange={(e) => handleSearchChange(e.target.value)}
                      />
                      <Button
                        onClick={handleOpenDrawer}
                        buttonType="outline"
                        width="250px"
                      >
                        <p>{t('group.addUserButton')}</p>
                      </Button>
                    </SearchBar>
                    <Table
                      columns={columns}
                      data={filteredUsers}
                      loading={isLoading}
                    />
                  </>
                ) : (
                  <Alert
                    showIcon
                    type="warning"
                    description={t('group.alert.noPermToViewGroupUsers')}
                  />
                )}
              </>
            )}
            {currentTabIndex === 1 &&
              (isLoading ? (
                <Skeleton active />
              ) : (
                <ListGroupPermissions
                  treeData={treeData}
                  checkedKeys={checkedKeys}
                />
              ))}
          </div>
        </GroupScreenContent>
      </MainContainer>

      <Drawer
        onClose={handleCloseDrawer}
        destroyOnClose
        open={openDrawer}
        width={700}
      >
        <AddUser onAddUserCallback={onAddUserCallback} groupId={groupId} />
      </Drawer>
    </div>
  )
}

export default GroupScreen
