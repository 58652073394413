const ptGroup = {
  group: {
    searchUserPlaceholder: 'Pesquisar nome de usuário',
    addUserButton: 'Adicionar usuário',
    breadcrumb: {
      home: 'Início',
      userGroups: 'Grupos de usuários',
      group: 'Grupo',
    },
    notify: {
      userRemoved: 'Usuário removido do grupo.',
      userAdded: 'O usuário foi adicionado ao grupo',
      userAlreadyInGroup: 'Usuário já foi adicionado a este grupo',
      errorOnRemoveUser:
        'Ocorreu um erro ao remover o usuário, tente novamente mais tarde.',
    },
    tabs: {
      users: 'Usuários',
      permissions: 'Permissões',
    },
    table: {
      columns: {
        userName: 'Nome',
        action: 'Ação',
      },
      removeUserPopConfirm: {
        title: 'Remover usuário do grupo',
        description: 'Essa ação é irreversível, deseja continuar?',
        okText: 'Sim',
        cancelText: 'Cancelar',
      },
    },
    alert: {
      noPermToViewGroupUsers:
        'Você não tem permissão para visualizar os usuários deste grupo.',
    },
  },
  addUserToGroup: {
    title: 'Adicionar usuário ao grupo',
    form: {
      userName: {
        label: 'Pesquisar usuário',
        placeholder: 'Digite o nome do usuário',
        required: 'Campo obrigatório',
      },
      addButton: 'Adicionar',
    },
    alert: {
      notHavePermToAdd: 'Você não tem permissão para listar usuário.',
    },
  },
  listGroupPermissions: {
    alert: {
      notHavePermToView:
        'Você não tem permissão para visualizar as permissões deste grupo.',
    },
  },
}

export default ptGroup
