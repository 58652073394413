import React from 'react'
import { StatusBadgeStyled } from './styles'
import { SmilesIcon } from 'components/icons'
import { StatusEnum } from 'constants/statusEnum'

export type BadgeProps = {
  status: StatusEnum
  text: string
}

const StatusBadge: React.FC<BadgeProps> = ({ status, text }) => {
  const statusIcon: any = {
    [StatusEnum.APPROVED]: <SmilesIcon type="check-circle" size={18} />,
    [StatusEnum.CONFIRMED]: <SmilesIcon type="check-circle" size={18} />,
    [StatusEnum.REJECTED]: <SmilesIcon type="cancel" size={18} />,
    [StatusEnum.CANCELED]: <SmilesIcon type="cancel" size={18} />,
    [StatusEnum.OPENED]: <SmilesIcon type="warning" size={16} />,
    [StatusEnum.WAITING_FOR_APPROVAL]: <SmilesIcon type="warning" size={16} />,
  }

  return (
    <StatusBadgeStyled $status={status} data-testid="smiles-status-badge">
      {statusIcon[status]}
      {text}
    </StatusBadgeStyled>
  )
}

export default StatusBadge
