import styled from 'styled-components'

export const HotelPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const HotelPageContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-bottom: 150px;
`

export const HotelSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const HotelHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.0015em;
  }

  .options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 2px solid #999999;
    > p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.005em;
    }
  }
`
