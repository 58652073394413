import React from 'react'
import { CustomAntdRadio } from './styles'

export type RadioProps = {
  value: string | number | boolean
  label: string
}

export const CustomRadio = ({ value, label, ...rest }: RadioProps) => {
  return (
    <CustomAntdRadio value={value} {...rest}>
      {label}
    </CustomAntdRadio>
  )
}
