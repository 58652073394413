import { useState } from 'react'
import { changeEmployeeStatus } from 'services/outsourced'
import { notifyError, notifySuccess } from 'utils/handleRequestNotify'
import { useTranslation } from 'react-i18next'

export const useChangeEmployeeStatus = (
  onFinishCallback: Function,
  employeeId: string,
  enabled: boolean,
) => {
  const { t } = useTranslation()
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onChangeStatus = async () => {
    setIsLoading(true)
    const dto = {
      id: employeeId,
      status: !enabled,
    }
    const response = await changeEmployeeStatus(dto)
    if (response.status === 'error') {
      const errorDesc = response.error ?? null
      setIsLoading(false)
      notifyError(errorDesc)
      return
    }
    setIsLoading(false)
    notifySuccess()
    onFinishCallback()
  }

  return {
    isConfirmed,
    setIsConfirmed,
    isLoading,
    onChangeStatus,
  }
}
