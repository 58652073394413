import { StatusEnum } from 'constants/statusEnum'
import { useEffect, useState } from 'react'
import { getMyTravels } from 'services/travelRequest'
import { SMyTravel } from 'shared/interfaces/SMyTravel'
import { notifyError } from 'utils/handleRequestNotify'

export const useFetchTravels = () => {
  const [travels, setTravels] = useState({
    next: [] as SMyTravel[],
    canceled: [] as SMyTravel[],
    previous: [] as SMyTravel[],
  })
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const [next, canceled, previous] = await Promise.all([
          getMyTravels({ status: [StatusEnum.CONFIRMED] }),
          getMyTravels({ status: [StatusEnum.CANCELED, StatusEnum.REJECTED] }),
          getMyTravels({ status: [StatusEnum.CONFIRMED], previous: true }),
        ])

        setTravels({ next, canceled, previous })
      } catch (error) {
        console.error('Failed to fetch travels:', error)
        notifyError()
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return { travels, loading }
}
