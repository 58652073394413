import { useState, useEffect, useCallback } from 'react'
import { FormInstance, Form } from 'antd'
import { createGroup, editGroup, patchGroupPermissions } from 'services/group'
import {
  getAllSystemPermissions,
  getPermissionsByGroupId,
} from 'services/permissions'
import { notifyError } from 'utils/handleRequestNotify'
import { SGroup } from 'shared/interfaces/SGroup'
import { SPermission } from 'shared/interfaces/SPermission'
import { createPermissionsToPatch } from 'pages/shared/createIdArray'
import { formatPermissionsForTreeSelect } from 'pages/shared/formatPermissionsForTreeSelect'
import { TreeData } from 'components/permissionTreeSelect'
import { filterGroupsAndPerms } from 'utils/filterGroupsAndPerms'
import { usePermissionsContext } from 'contexts/permissionsContext/usePermissionsContext'
import { ACTION } from 'shared/userActionPermissions'

type HandleGroupState = {
  form: FormInstance
  treeData?: TreeData
  permissions?: SPermission[]
  isLoading: boolean
  checkedKeys: string[]
}

export const useHandleGroup = (
  selectedGroupToEdit?: SGroup,
  onFinishCallback?: () => void,
  t?: any,
  language?: any,
) => {
  const { can } = usePermissionsContext()
  const [form] = Form.useForm()
  const [state, setState] = useState<HandleGroupState>({
    form,
    treeData: undefined,
    permissions: undefined,
    isLoading: false,
    checkedKeys: [],
  })

  const setTreeData = (treeData: TreeData) => {
    setState((prevState) => ({ ...prevState, treeData }))
  }

  const setPermissions = (permissions: SPermission[]) => {
    setState((prevState) => ({ ...prevState, permissions }))
  }

  const setIsLoading = (isLoading: boolean) => {
    setState((prevState) => ({ ...prevState, isLoading }))
  }

  const setCheckedKeys = (checkedKeys: string[]) => {
    setState((prevState) => ({ ...prevState, checkedKeys }))
  }

  const fetchPermissions = useCallback(async () => {
    setIsLoading(true)
    try {
      const res = await getAllSystemPermissions()
      const treeData = formatPermissionsForTreeSelect(res.data, t, language)
      setPermissions(res.data)
      setTreeData(treeData)

      if (selectedGroupToEdit?.id) {
        await loadDefaultPermissions(selectedGroupToEdit.id)
      }
    } catch (error) {
      notifyError()
    } finally {
      setIsLoading(false)
    }
  }, [selectedGroupToEdit, t])

  const loadDefaultPermissions = async (groupId: string) => {
    setIsLoading(true)
    try {
      const groupPermissions = await getPermissionsByGroupId(groupId)
      const checkedPermissions = groupPermissions.data.map(
        (permission: any) => permission.id,
      )
      setCheckedKeys(checkedPermissions)
    } catch (error) {
      notifyError()
    } finally {
      setIsLoading(false)
    }
  }

  const handleFinish = async () => {
    try {
      setIsLoading(true)
      const formValues = await form.validateFields()
      if (formValues) {
        if (selectedGroupToEdit) {
          await handleEditGroup(formValues)
        } else {
          await handleCreateGroup(formValues)
        }
      }
    } catch (error) {
      setIsLoading(false)
      notifyError()
    } finally {
      setIsLoading(false)
    }
  }

  const handleCreateGroup = async (formValues: any) => {
    setIsLoading(true)
    try {
      const groupData = await createGroup({
        name: formValues.groupName,
        description: formValues.groupDescription,
      })

      if (formValues.permissions && state.permissions) {
        const perms = filterGroupsAndPerms(
          formValues.permissions,
          state.treeData,
        )

        const newSelectedPermissions = createPermissionsToPatch(
          perms,
          state.permissions,
        )
        await patchGroupPermissions({
          groupId: groupData.id,
          permissions: newSelectedPermissions,
        })
      }

      onFinishCallback?.()
    } catch (error) {
      notifyError()
    } finally {
      setIsLoading(false)
    }
  }

  const handleEditGroup = async (formValues: any) => {
    setIsLoading(true)
    try {
      const groupId = selectedGroupToEdit?.id as string

      await editGroup({
        name: formValues.groupName,
        description: formValues.groupDescription,
        id: groupId,
      })

      if (formValues.permissions && state.permissions) {
        const perms = filterGroupsAndPerms(
          formValues.permissions,
          state.treeData,
        )

        const newSelectedPermissions = createPermissionsToPatch(
          perms,
          state.permissions,
        )
        const isPermissionsChanged =
          newSelectedPermissions.length !== state.checkedKeys.length ||
          newSelectedPermissions.some(
            (permission) => !state.checkedKeys.includes(permission.id),
          )

        if (isPermissionsChanged) {
          await patchGroupPermissions({
            groupId,
            permissions: newSelectedPermissions,
          })
        }
      }

      onFinishCallback?.()
    } catch (error) {
      notifyError()
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (can(ACTION.permissions.listAll)) {
      fetchPermissions()
    }
  }, [can])

  useEffect(() => {
    if (selectedGroupToEdit) {
      form.setFieldsValue({
        groupName: selectedGroupToEdit?.name,
        groupDescription: selectedGroupToEdit?.description,
        permissions: state.checkedKeys,
      })
    }
  }, [selectedGroupToEdit, state.checkedKeys, form])

  return {
    ...state,
    handleFinish,
    setTreeData,
    setPermissions,
    setIsLoading,
    setCheckedKeys,
  }
}
