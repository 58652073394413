import styled from 'styled-components'

export const ViewBookingLinkStyles = styled.a`
  text-decoration: none;
  color: black;

  :hover {
    color: #ff7020;
  }
`

export const ViewBookingStyles = styled.div`
  width: 100%;
  padding: 24px 32px;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  gap: 16px;

  .content {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.005em;
    gap: 16px;
  }
`
