import React, { ReactElement } from 'react'

import { BookingDetailsCellStyles } from './styles'

export type BookingDetailsProps = {
  icon?: ReactElement
  title: string
  text: string
}

const BookingDetailsCell: React.FC<BookingDetailsProps> = ({
  icon,
  title,
  text,
}) => {
  return (
    <BookingDetailsCellStyles data-testid="booking-details-cell">
      <div className="title">
        {icon}
        {title}
      </div>
      <div className="text">{text}</div>
    </BookingDetailsCellStyles>
  )
}

export default BookingDetailsCell
