import React, { useState } from 'react'
import { StyledSearchInput } from './styles'
import { Input } from 'antd'
import { SmilesIcon } from 'components/icons'
import { Color } from 'styles/global'

export type SearchProps = {
  placeholder?: string
  onChange?: (e?: any) => void
  value?: string
  width?: string
}

const SearchInput: React.FC<SearchProps> = ({
  placeholder,
  onChange,
  value,
  width,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const focusHandler = () => {
    setIsFocused(true)
  }

  const blurHandler = () => {
    setIsFocused(false)
  }

  return (
    <StyledSearchInput $width={width}>
      <Input
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onFocus={focusHandler}
        onBlur={blurHandler}
        type="search"
      />
      <SmilesIcon
        type="lens"
        color={isFocused ? Color.ACTIVE_INPUT : Color.INACTIVE_INPUT}
        size={20}
      />
    </StyledSearchInput>
  )
}

export default SearchInput
