import styled from 'styled-components'
import { Color, MediaQuery, Size } from 'styles/global'

export const PageTitle = styled.h2`
  margin-bottom: 30px !important;
`

export const MyRequestsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  gap: 24px;
`

export const SpinnerContainer = styled.div`
  width: fit-content;
  margin: 10vh auto;
`

export const NotFoundTitle = styled.h3`
  color: ${Color.GRAY_1};
  width: fit-content;
  margin: 20vh auto;
`

export const RequestsFilter = styled.aside`
  width: 20vw;
  min-width: 315px;
  border: 1px solid ${Color.LIGHT_BORDER};
  border-radius: ${Size.BORDER_RADIUS};
  padding: 20px;
  transition: all ease 0.5s;

  @media screen and (${MediaQuery.MEDIUM}) {
    width: 100%;
    height: 65px;
    overflow: hidden;

    &.active {
      height: 620px;
    }
  }

  h3 {
    color: #676767;
    margin-bottom: 10px;

    @media screen and (${MediaQuery.MEDIUM}) {
      &:first-of-type::after {
        display: block;
        width: 10px;
        height: 10px;
        content: ' ';
        border: 1px solid ${Color.NORMAL_BORDER};
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg);
        position: relative;
        top: -22px;
        right: -95%;
      }
    }
  }

  h3:not(:first-of-type) {
    margin-top: 20px;
  }

  div > div {
    display: inline-grid;
  }

  label {
    margin-bottom: 10px;
  }
`

export const TravelRequestMainContent = styled.section`
  width: 64%;
  @media screen and (${MediaQuery.MEDIUM}) {
    width: 100%;
  }
`

export const TravelRequestCard = styled.div`
  width: 100%;
  margin-top: 25px;
  border: 1px solid ${Color.LIGHT_BORDER};
  border-radius: ${Size.BORDER_RADIUS};
  padding: 25px 20px;

  .card-header {
    display: flex;
    justify-content: space-between;

    .card-header-title {
      display: flex;
      width: 37%;

      @media screen and (${MediaQuery.MEDIUM}) {
        flex-direction: column;
      }

      .travel-request-number {
        font-weight: bold;
        font-size: 1.5rem;
        margin-right: 40px;
      }

      svg {
        margin-right: 5px;
      }
    }
  }

  .travel-request-details {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    flex-flow: row wrap;
    gap: 25px;

    @media screen and (${MediaQuery.MEDIUM}) {
      justify-content: flex-start;
    }

    span {
      display: flex;
      align-items: center;
    }

    span:first-of-type {
      font-weight: bold;
      padding-left: 5px;
    }
  }

  .more-information {
    border-bottom: ${Size.NORMAL_BORDER} solid ${Color.NORMAL_BORDER};
    transition: all ease 0.5s;
    &:hover {
      cursor: pointer;
      text-shadow: 0px 0px 3px rgba(10, 10, 0, 0.2);
    }

    .link {
      color: #000000;
    }
  }
`
