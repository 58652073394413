import styled from 'styled-components'
import { AlertTypes } from '.'

export const AlertStyles = styled.button<{ type: AlertTypes }>`
  width: 100%;
  padding: 16px 32px 16px 32px;
  border: 1px solid #d9d9d9;
  display: flex;
  gap: 24px;
  text-align: left;
  border-radius: 16px;

  .text {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.0015em;
    }
    .message {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.005em;
    }
  }

  ${({ type }) =>
    type == 'success' &&
    ` 
    background: #E6F1EB;
  `};

  ${({ type }) =>
    type == 'error' &&
    ` 
    background: #fff2f5;
  `};
`
