const esReservation = {
  reservationDetails: {
    traveler: 'Viajero',
    reservationDate: 'Fecha de solicitud',
    approvalDate: 'Fecha de aprobación',
    status: 'Estado',
    costCenter: 'Centro de costos',
    reservationNumber: 'Número de reserva',
    locator: 'Localizador',
  },
  reservationCompleted: {
    title: 'Procesamiento de reservas completado',
    text: 'Ahora solo tienes que esperar. Su solicitud ha sido enviada a su gerente y recibirá una confirmación de su pedido por correo electrónico.',
  },
  viewReservation: {
    title: 'Ver reserva completa',
    resumeTitle: 'Ver resumen de reserva',
  },
  completeRequest: {
    title: 'Completar solicitud',
    question: '¿Deseas confirmar tu solicitud?',
    text: 'Al hacer clic en "Sí", tu solicitud se enviará a tu supervisor inmediato.',
    yes: 'Sí',
  },
  flightRequest: {
    title: 'Aire',
    oneWayTicket: 'Billete de ida',
    returnTicket: 'Billete de vuelta',
    stopover: 'Detener',
  },
  hotelRequest: {
    seeOnMap: 'Ver en el mapa',
  },
  totalRequestValue: {
    totalValue: 'Valor total',
  },
  footerButton: {
    return: 'Volver',
    returnToHome: 'Regresar a la página principal',
    conclude: 'Concluir',
  },
  searchCostCenter: {
    search: 'Buscar centro de costos',
    find: 'Encuentra tu centro de costes',
    select: 'Seleccionar centro de costos',
    selectTwo: 'Seleccione dos centros de costos para continuar',
  },
  requestStatus: {
    resume: 'Resumen de la Solicitud',
    status: 'Estado de la solicitud',
    complete: 'Reserva Completa',
  },
  debit_allocation: {
    others: 'Otros',
    required_field: 'Campo requerido',
    cost_center: 'Centro de costos',
    regional_or_cost_center: 'Regional o centro de costos',
    approver: 'Aprobador',
    trip_reason: 'Motivo del viaje',
    reason: 'Motivo',
    reason_justification: 'Justificación del motivo',
    antecedence_justification: '¡Justificación de la antelación del viaje!',
    split_cost_center: 'Centro de costos dividido',
    complementary_info: 'Informaciones complementarias',
    do_you_need_to_provide_more_info: '¿Necesita proporcionar más información?',
    error: {
      reasons: 'No se pudo obtener los motivos.',
      cost_center: 'No se pudieron obtener los centros de costos.',
      min_characters: 'El campo debe tener al menos 10 caracteres.',
    },
    shared_with: 'Compartido con:',
    gol_employee: 'Empleado de Gol',
  },
}

export default esReservation
