import styled from 'styled-components'
import InputMask from 'react-input-mask'
import { Form } from 'antd'

export const NewOutsourcedFormContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0px 24px;

  .switch-container {
    display: flex;
    font-size: 16px;
    font-weight: 700;
    gap: 16px;
    align-items: center;

    span {
      padding-top: 4px;
    }
  }

  .two-columns {
    > * {
      flex-grow: 2;
    }
    display: flex;
    width: 100%;
    gap: 16px;

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  .form-container {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
  }

  .form-row {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`

export const StyledFormItem = styled(Form.Item)`
  display: flex;
  align-items: center;
  margin: 0;
`

export const PhoneInputWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 1rem;
  }
`

export const StyledInputMask = styled(InputMask)`
  width: 100%;
  height: 4rem;
  border: 0.125rem solid #999999;
  border-radius: 0.5rem;
  padding: 1.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: #595959;
  outline: none;

  @media (max-width: 820px) {
    width: 100%;
    height: 3rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`

export const ActionButton = styled.button`
  display: flex;
  height: 44px;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #737373;
  border-radius: 16px;
  padding: 8px 16px 8px 18px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  svg {
    margin-top: 2px;
  }
`
