import React, { useEffect, useState } from 'react'
import {
  HotelHeaderContainer,
  HotelPage,
  HotelPageContent,
  HotelSection,
} from './styles'
import HotelFilterSection from './components/hotelFilterSection'
import Dropdown from 'components/dropdown'
import RenderHotels from './components/renderHotels'
import { getHotels } from 'services/hotel'
import {
  HotelAvail,
  SHotelAvailability,
} from 'shared/interfaces/SHotelAvailability'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'
import { useTranslation } from 'react-i18next'

const Hotel: React.FC = () => {
  const { t } = useTranslation()
  const { setLoading, loading, searchHotels } = useFlowContext()

  const [hotels, setHotels] = useState<HotelAvail[]>([])
  const [cityName, setCityName] = useState('')

  const fetchHotels = async () => {
    try {
      setLoading(true)

      if (!searchHotels) {
        return
      }

      const hotelsResponse: SHotelAvailability = await getHotels(searchHotels)

      if (hotelsResponse?.hotelAvail.length) {
        setHotels(hotelsResponse.hotelAvail)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchHotels()
  }, [searchHotels])

  useEffect(() => {
    if (searchHotels?.city) {
      const regex = /[^,]*/
      const matchResult = regex.exec(searchHotels.city)
      const filteredCityName = matchResult ? matchResult[0] : searchHotels.city

      setCityName(filteredCityName)
    }
  }, [searchHotels])

  return (
    <HotelPage data-testid="smiles-hotel-screen">
      <HotelPageContent>
        <HotelFilterSection />

        <HotelSection>
          <HotelHeaderContainer>
            {!loading && hotels.length > 0 && (
              <>
                <p className="title">
                  {t('hotel.acomodations_in')} {cityName}
                </p>
                <div className="options">
                  <p>{`+${t('hotel.of')} ${hotels.length - 1} ${t(
                    'hotel.options',
                  )}`}</p>
                  <Dropdown
                    defaultValue={t('recommended')}
                    options={[
                      { label: t('recommended'), value: 'recommended' },
                    ]}
                  />
                </div>
              </>
            )}
          </HotelHeaderContainer>

          <RenderHotels hotelAvail={hotels} loading={loading} />
        </HotelSection>
      </HotelPageContent>
    </HotelPage>
  )
}

export default Hotel
