const enGroup = {
  group: {
    searchUserPlaceholder: 'Search user by name',
    addUserButton: 'Add user',
    breadcrumb: {
      home: 'Home',
      userGroups: 'User Groups',
      group: 'Group',
    },
    notify: {
      userRemoved: 'User removed from group.',
      userAdded: 'The user has been added to the group.',
      userAlreadyInGroup: 'User has already been added to this group',
      errorOnRemoveUser:
        'An error occurred while removing the user, please try again later.',
    },
    tabs: {
      users: 'Users',
      permissions: 'Permissions',
    },
    table: {
      columns: {
        userName: 'Name',
        action: 'Action',
      },
      removeUserPopConfirm: {
        title: 'Remove user from group',
        description: 'This action is irreversible, do you want to continue?',
        okText: 'Yes',
        cancelText: 'Cancel',
      },
    },
    alert: {
      noPermToViewGroupUsers:
        'You do not have permission to view the users of this group.',
    },
  },
  addUserToGroup: {
    title: 'Add user to group',
    form: {
      userName: {
        label: 'Search user',
        placeholder: 'Enter user name',
        required: 'Required field',
      },
      addButton: 'Add',
    },
    alert: {
      notHavePermToAdd: 'You do not have permission to list users.',
    },
  },
  listGroupPermissions: {
    alert: {
      notHavePermToView:
        'You do not have permission to view the permissions of this group.',
    },
  },
}

export default enGroup
