const ptLogin = {
  login: {
    accessYourAccount: 'Acesse sua conta',
    form: {
      tenantInput: {
        label: 'Organização',
        placeholder: 'Digite o nome da sua organização',
        required: '*Campo obrigatório',
      },
      loginButton: 'Acessar',
    },
    errors: {
      login: {
        msg: 'Ops!',
        desc: 'Há algo errado, tente novamente mais tarde.',
      },
      tenant: {
        msg: 'Organização inválida!',
        desc: 'Verifique as informações e tente novamente.',
      },
    },
  },
}

export default ptLogin
