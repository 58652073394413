const ptPermissions = {
  permissions: {
    home: 'Início',
    flights: 'Voos',
    user_groups: 'Grupos de usuários',
    choose_permissions: 'Selecione as permissões',
  },
}

export default ptPermissions
