import React, { useEffect, useState } from 'react'
import { DatePickerStyles } from './styles'
import { InputContainer, InputLabel } from 'components/Input/styles'
import { DatePickerProps as AntDatePickerProps } from 'antd'
import es_ES from 'antd/es/date-picker/locale/es_ES'
import pt_BR from 'antd/es/date-picker/locale/pt_BR'
import en_US from 'antd/es/date-picker/locale/en_US'
import { PickerLocale } from 'antd/es/date-picker/generatePicker'
import { useTranslation } from 'react-i18next'

export type DatePickerProps = AntDatePickerProps & {
  width?: string
  label: string
}

interface LocaleMap {
  [key: string]: PickerLocale
}

const DatePicker: React.FC<DatePickerProps> = ({
  width,
  label,
  onChange,
  ...rest
}) => {
  const { i18n } = useTranslation()
  const language = i18n.language
  const [format, setFormat] = useState('DD/MM/YYYY')
  const datePickerLocaleMap: LocaleMap = {
    pt: pt_BR,
    es: es_ES,
    en: en_US,
  }
  const locale = datePickerLocaleMap[language]

  useEffect(() => {
    if (language === 'en') {
      setFormat('YYYY-MM-DD')
    } else {
      setFormat('DD/MM/YYYY')
    }
  }, [language])

  return (
    <InputContainer width={width} data-testid="smiles-date-picker">
      <InputLabel>{label}</InputLabel>
      <DatePickerStyles
        locale={locale}
        format={format}
        onChange={onChange}
        {...rest}
      />
    </InputContainer>
  )
}

export default DatePicker
