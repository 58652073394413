export default `
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 22 20" fill="none">
      <path d="M12.7153 0.700195C12.1292 0.700195 11.5559 0.754743 11 0.858035C15.3081 1.66348 18.5693 5.11505 18.5693 
      9.65757C18.5693 12.891 15.9487 15.8388 12.7153 15.8388C12.1188 15.8388 11.5431 15.7495 11 15.5835C8.60338 14.85 6.86135 
      12.6217 6.86135 9.98485C6.86135 7.34801 8.60454 5.11969 11 4.38621C10.458 4.22024 9.88118 4.13088 9.28464 4.13088C6.05126 
      4.13088 3.43066 7.07992 3.43066 10.3121C3.43066 14.8535 6.6919 18.3062 11 19.1105C11.5559 19.2138 12.1292 19.2684 12.7153 
      19.2684C17.8428 19.2684 22 15.1111 22 9.98369C22 4.85624 17.8428 0.700195 12.7153 0.700195Z" fill="#F3A677"/>
      <path d="M9.28466 0.714844C9.87075 0.714844 10.4441 0.769391 11 0.872683C15.3081 1.67813 18.5693 5.1297 18.5693 
      9.67222C18.5693 12.9056 15.9487 15.8535 12.7153 15.8535C12.1188 15.8535 11.542 15.7641 11 15.5982C13.3966 14.8647 
      15.1386 12.6352 15.1386 9.9995C15.1386 7.36266 13.3954 5.13434 11 4.40085C10.4568 4.23489 9.8812 4.14553 9.28466 
      4.14553C6.05128 4.14553 3.43068 7.09457 3.43068 10.3268C3.43068 14.8681 6.69192 18.3209 11 19.1252C10.4441 19.2296 
      9.87075 19.2842 9.28466 19.2842C4.15721 19.2842 0 15.127 0 9.9995C0 4.87205 4.15721 0.714844 9.28466 0.714844Z" fill="white"/>
    </svg>
`
