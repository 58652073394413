import styled from 'styled-components'
import { Radio } from 'antd'
import { Color, Size } from 'styles/global'

export const OutsourcedPageHeader = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .header-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 60px;
  }
`

export const SearchCard = styled.div`
  width: 100%;
  border: 1px solid ${Color.LIGHT_BORDER};
  border-radius: ${Size.BORDER_RADIUS};
  padding: 36px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CustomRadioButton = styled(Radio.Button)`
  &.ant-radio-group {
    border-radius: 48px;
  }

  &.ant-radio-button-wrapper-checked {
    background-color: black !important;
    border-color: black !important;
    color: white !important;

    &:hover {
      background-color: black !important;
      border-color: black !important;
    }
  }

  &:hover {
    color: black;
  }
`

export const AddOutsourcedButton = styled.button`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #737373;
  border-radius: 16px;
  padding: 8px 12px 8px 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-left: 12px;
  svg {
    margin-top: 2px;
  }
`

export const ButtonsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
`
