import { API } from 'services/config'

type GetLocationsParams = {
  search: string
  type: 'FLIGHT' | 'HOTEL'
  limit?: number
}

const point = '/open-travel-request/v1/localities'

const getLocations = async ({ search, type }: GetLocationsParams) => {
  try {
    const response = await API.get(`${point}?locality=${search}&type=${type}`)
    return response.data
  } catch (error) {
    throw new Error('Error on get locations')
  }
}

export { getLocations }
