import React from 'react'
import { HeaderCloseButtonStyles } from './styles'
import iconStyles from '../../components/styles.module.css'
import { SmilesIcon } from 'components/icons'

export type HeaderCloseButtonProps = {
  closeButton?: any
}

const HeaderCloseButton: React.FC<HeaderCloseButtonProps> = ({
  closeButton,
}) => {
  return (
    <HeaderCloseButtonStyles data-testid="smiles-header-close-button">
      <a onClick={closeButton}>
        <SmilesIcon
          type="circle-close-black"
          className={iconStyles.icon_40}
          viewBox="0 0 41 41"
          color="#333333"
        ></SmilesIcon>
      </a>
    </HeaderCloseButtonStyles>
  )
}

export default HeaderCloseButton
