import styled from 'styled-components'

export const DrawerBottomNavigationStyled = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 24px;
  gap: 24px;
  background: var(--Neutral-Neutral-100, #fff);
  box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.16);
  justify-content: end;
`
