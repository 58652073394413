import { SPermission } from 'shared/interfaces/SPermission'

export function createPermissionsToPatch(
  selectedPermissionIds: string[],
  allPermissions: SPermission[],
) {
  return allPermissions
    .filter((permission) => selectedPermissionIds.includes(permission.id))
    .map((permission) => ({
      id: permission.id,
      type: permission.SK.split('#')[0],
    }))
}
