import styled from 'styled-components'

export const InputContainer = styled.span<{ width?: string }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : '100%')};
  gap: 8px;

  .custom-antd-input {
    width: 100%;
    border: 2px solid #999;
    padding: 20px 12px;
    z-index: 1;

    svg {
      fill: #999;
    }

    .ant-input-prefix {
      color: #595959;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5%;
    }

    input {
      color: #595959;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5%;
    }

    &::placeholder {
      color: #666666;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.08px;
    }
    &:hover,
    &:focus-within,
    &:focus {
      border: 2px solid #999;
    }

    .ant-input-password-icon {
      font-size: 22px;
    }
  }

  .custom-antd-input:focus,
  .custom-antd-input:focus-within {
    box-shadow: 0 0 0 2px rgba(255, 112, 32, 0.1);
  }
`

export const InputLabel = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 21px;
  letter-spacing: 0.035px;
`
