export default `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <rect x="-2" width="33.6" height="24" rx="2" fill="white"/>
  <mask id="mask0_2794_20842" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="-2" y="0" width="34" height="24">
    <rect x="-2" width="33.6" height="24" rx="2" fill="white"/>
  </mask>
  <g mask="url(#mask0_2794_20842)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.6 0H-2V1.6H31.6V0ZM31.6 3.2H-2V4.8H31.6V3.2ZM-2 6.4H31.6V8H-2V6.4ZM31.6 
    9.6H-2V11.2H31.6V9.6ZM-2 12.8H31.6V14.4H-2V12.8ZM31.6 16H-2V17.6H31.6V16ZM-2 19.2H31.6V20.8H-2V19.2ZM31.6 22.4H-2V24H31.6V22.4Z" fill="#D02F44"/>
    <rect x="-2" width="14.4" height="11.2" fill="#46467F"/>
    <g filter="url(#filter0_d_2794_20842)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.19961 2.39998C1.19961 2.8418 0.841437 3.19998 0.399609 3.19998C-0.0422184 
      3.19998 -0.400391 2.8418 -0.400391 2.39998C-0.400391 1.95815 -0.0422184 1.59998 0.399609 1.59998C0.841437 1.59998 1.19961 1.95815 
      1.19961 2.39998ZM4.39961 2.39998C4.39961 2.8418 4.04144 3.19998 3.59961 3.19998C3.15778 3.19998 2.79961 2.8418 2.79961 2.39998C2.79961 
      1.95815 3.15778 1.59998 3.59961 1.59998C4.04144 1.59998 4.39961 1.95815 4.39961 2.39998ZM6.79961 3.19998C7.24144 3.19998 
      7.59961 2.8418 7.59961 2.39998C7.59961 1.95815 7.24144 1.59998 6.79961 1.59998C6.35778 1.59998 5.99961 1.95815 5.99961 2.39998C5.99961 
      2.8418 6.35778 3.19998 6.79961 3.19998ZM10.7996 2.39998C10.7996 2.8418 10.4414 3.19998 9.99961 3.19998C9.55778 3.19998 9.19961 2.8418 
      9.19961 2.39998C9.19961 1.95815 9.55778 1.59998 9.99961 1.59998C10.4414 1.59998 10.7996 1.95815 10.7996 2.39998ZM1.99961 4.79998C2.44144 
      4.79998 2.79961 4.4418 2.79961 3.99998C2.79961 3.55815 2.44144 3.19998 1.99961 3.19998C1.55778 3.19998 1.19961 3.55815 1.19961 
      3.99998C1.19961 4.4418 1.55778 4.79998 1.99961 4.79998ZM5.99961 3.99998C5.99961 4.4418 5.64144 4.79998 5.19961 4.79998C4.75778 
      4.79998 4.39961 4.4418 4.39961 3.99998C4.39961 3.55815 4.75778 3.19998 5.19961 3.19998C5.64144 3.19998 5.99961 3.55815 5.99961 
      3.99998ZM8.39961 4.79998C8.84144 4.79998 9.19961 4.4418 9.19961 3.99998C9.19961 3.55815 8.84144 3.19998 8.39961 3.19998C7.95778 
      3.19998 7.59961 3.55815 7.59961 3.99998C7.59961 4.4418 7.95778 4.79998 8.39961 4.79998ZM10.7996 5.59998C10.7996 6.0418 10.4414 
      6.39998 9.99961 6.39998C9.55778 6.39998 9.19961 6.0418 9.19961 5.59998C9.19961 5.15815 9.55778 4.79998 9.99961 4.79998C10.4414 
      4.79998 10.7996 5.15815 10.7996 5.59998ZM6.79961 6.39998C7.24144 6.39998 7.59961 6.0418 7.59961 5.59998C7.59961 5.15815 7.24144 
      4.79998 6.79961 4.79998C6.35778 4.79998 5.99961 5.15815 5.99961 5.59998C5.99961 6.0418 6.35778 6.39998 6.79961 6.39998ZM4.39961 
      5.59998C4.39961 6.0418 4.04144 6.39998 3.59961 6.39998C3.15778 6.39998 2.79961 6.0418 2.79961 5.59998C2.79961 5.15815 3.15778 4.79998 
      3.59961 4.79998C4.04144 4.79998 4.39961 5.15815 4.39961 5.59998ZM0.399609 6.39998C0.841437 6.39998 1.19961 6.0418 1.19961 5.59998C1.19961 
      5.15815 0.841437 4.79998 0.399609 4.79998C-0.0422185 4.79998 -0.400391 5.15815 -0.400391 5.59998C-0.400391 6.0418 -0.0422185 6.39998 0.399609 
      6.39998ZM2.79961 7.19998C2.79961 7.6418 2.44144 7.99998 1.99961 7.99998C1.55778 7.99998 1.19961 7.6418 1.19961 7.19998C1.19961 6.75815 1.55778 
      6.39998 1.99961 6.39998C2.44144 6.39998 2.79961 6.75815 2.79961 7.19998ZM5.19961 7.99998C5.64144 7.99998 5.99961 7.6418 5.99961 
      7.19998C5.99961 6.75815 5.64144 6.39998 5.19961 6.39998C4.75778 6.39998 4.39961 6.75815 4.39961 7.19998C4.39961 7.6418 4.75778 
      7.99998 5.19961 7.99998ZM9.19961 7.19998C9.19961 7.6418 8.84144 7.99998 8.39961 7.99998C7.95778 7.99998 7.59961 7.6418 7.59961 
      7.19998C7.59961 6.75815 7.95778 6.39998 8.39961 6.39998C8.84144 6.39998 9.19961 6.75815 9.19961 7.19998ZM9.99961 9.59998C10.4414 
      9.59998 10.7996 9.2418 10.7996 8.79998C10.7996 8.35815 10.4414 7.99998 9.99961 7.99998C9.55778 7.99998 9.19961 8.35815 9.19961 
      8.79998C9.19961 9.2418 9.55778 9.59998 9.99961 9.59998ZM7.59961 8.79998C7.59961 9.2418 7.24144 9.59998 6.79961 9.59998C6.35778 
      9.59998 5.99961 9.2418 5.99961 8.79998C5.99961 8.35815 6.35778 7.99998 6.79961 7.99998C7.24144 7.99998 7.59961 8.35815 7.59961 
      8.79998ZM3.59961 9.59998C4.04144 9.59998 4.39961 9.2418 4.39961 8.79998C4.39961 8.35815 4.04144 7.99998 3.59961 7.99998C3.15778 
      7.99998 2.79961 8.35815 2.79961 8.79998C2.79961 9.2418 3.15778 9.59998 3.59961 9.59998ZM1.19961 8.79998C1.19961 9.2418 0.841437 
      9.59998 0.399609 9.59998C-0.0422185 9.59998 -0.400391 9.2418 -0.400391 8.79998C-0.400391 8.35815 -0.0422185 7.99998 0.399609 
      7.99998C0.841437 7.99998 1.19961 8.35815 1.19961 8.79998Z" fill="url(#paint0_linear_2794_20842)"/>
    </g>
  </g>
  <defs>
    <filter id="filter0_d_2794_20842" x="-0.400391" y="1.59998" width="11.1992" height="9" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2794_20842"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2794_20842" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_2794_20842" x1="-0.400391" y1="1.59998" x2="-0.400391" y2="9.59998" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="#F0F0F0"/>
    </linearGradient>
  </defs>
</svg>
`
