import styled from 'styled-components'

export const ClassAndFareStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .flight-class {
    display: flex;
    flex-direction: row;
    gap: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
  }

  .fare-per-segment {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    align-self: self-start;
  }
`
