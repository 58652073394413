import styled from 'styled-components'

export const NavCardItemStyles = styled.div`
  display: flex;
  height: 112px;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.035px;
  cursor: pointer;
  border-radius: 16px;
  border: 2px solid #f2f2f2;
  background: #fff;

  &:hover {
    svg {
      fill: var(--primary-color);
    }
  }

  @media screen and (max-width: 1148px) {
    height: auto;
    flex-direction: row-reverse;
    margin: 10px 0;
  }
`
