import MainContainer from 'components/mainContainer'
import React, { useEffect, useState } from 'react'
import {
  MyRequestsContainer,
  PageTitle,
  RequestsFilter,
  SpinnerContainer,
  TravelRequestCard,
  TravelRequestMainContent,
  NotFoundTitle,
} from './styles'
import { CustomRadio } from 'components/radio'
import type { RadioChangeEvent } from 'antd'
import { Flex, Radio } from 'antd'
import { Checkbox } from 'components/checkbox'
import { SmilesIcon } from 'components/icons'
import { Color, ScreenSize } from 'styles/global'
import SearchInput from 'components/inputSearch'
import { StatusEnum, getTravelRequests } from 'services/travelRequest'
import Spinner from 'components/spinner'
import { SUserTravelRequest } from 'shared/interfaces/SUserTravelRequest'
import { formatToReal } from 'utils/formatToReal/formateToReal'
import { useTranslation } from 'react-i18next'
import { notifyError } from 'utils/handleRequestNotify'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { Link } from 'react-router-dom'
import StatusBadge from 'components/statusBadge'
import { getFromStorage } from 'utils/localStorage/storageUtils'
import { TypeEnum } from 'constants/typeEnum'
import { usePermissionsContext } from 'contexts/permissionsContext/usePermissionsContext'

const MyRequests: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const { userPermissions, isPermissionsLoading } = usePermissionsContext()

  const [requestType, setRequestType] = useState([
    { name: TypeEnum.ONLY_MY_REQUESTS, isChecked: true },
    { name: TypeEnum.ONLY_MY_TRAVELS, isChecked: false },
    { name: TypeEnum.REQUESTS_FOR_OTHER_TRAVELERS, isChecked: false },
  ])

  const onRequestTypeChange = (e: RadioChangeEvent) => {
    const requestTypeName = e.target.value
    const newRequestType = [] as any

    requestType.forEach((type) => {
      type.isChecked = false

      if (type.name === requestTypeName) {
        type.isChecked = true
      }

      newRequestType.push(type)
    })

    setRequestType(newRequestType)
  }

  const [requestStatus, setRequestStatus] = useState([
    { name: 'ALL-STATUSES', isChecked: true },
    { name: StatusEnum.APPROVED, isChecked: true },
    { name: StatusEnum.CANCELED, isChecked: true },
    { name: StatusEnum.CONFIRMED, isChecked: true },
    { name: StatusEnum.REJECTED, isChecked: true },
    { name: StatusEnum.WAITING_FOR_APPROVAL, isChecked: true },
  ])

  const onRequestStatusChange = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked
    const statusName = e.target.value
    const newStatus = [] as any

    requestStatus.forEach((status) => {
      if (status.name === statusName) {
        status.isChecked = isChecked
      }
      newStatus.push(status)
    })

    if (statusName === requestStatus[0].name) {
      newStatus.forEach((status: any) => (status.isChecked = true))
    } else {
      const someUnflagged = requestStatus
        .slice(1)
        .some((status: any) => status.isChecked === false)

      if (someUnflagged) {
        newStatus[0].isChecked = false
      } else {
        newStatus[0].isChecked = true
      }
    }

    setRequestStatus(newStatus)
  }

  const toggleActiveClass = () => {
    const filterContainer = document.getElementById('filter-container')

    if (filterContainer?.classList?.contains('active')) {
      filterContainer.classList.remove('active')
    } else {
      filterContainer?.classList.add('active')
    }
  }

  const [requestsOrder, setRequestsOrder] = useState('NEW')

  const onRequestsOrderChange = (e: RadioChangeEvent) => {
    setRequestsOrder(e.target.value)
  }

  const [travels, setTravels] = useState<SUserTravelRequest[]>()

  const fetchTravels = async () => {
    setLoading(true)
    setTravels([])

    try {
      const statuses: StatusEnum[] = []
      requestStatus.slice(1).forEach((s) => {
        if (s.isChecked) {
          statuses.push(s.name as StatusEnum)
        }
      })

      const types: TypeEnum[] = []
      requestType.forEach((t) => {
        if (t.isChecked) {
          types.push(t.name)
        }
      })

      const travels = await getTravelRequests(
        statuses,
        requestsOrder,
        requestSearchQuery,
        types,
      )
      setTravels(travels.data)
    } catch (e) {
      console.error('Failed to fetch travel requests.', e)
      notifyError()
    } finally {
      setLoading(false)
    }
  }

  const [requestSearchField, setRequestSearchField] = useState<string>()
  const [requestSearchQuery, setRequestSearchQuery] = useState<string>()

  const requestSearchStringOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRequestSearchField(e.target.value)

    const MINIMUM_SEARCH_QUERY_LENGTH = 3

    if (e.target.value.length > MINIMUM_SEARCH_QUERY_LENGTH) {
      setRequestSearchQuery(e.target.value)
    }
  }

  useEffect(() => {
    fetchTravels()
  }, [requestStatus, requestsOrder, requestSearchQuery, requestType])

  const [isAnApproverUser, setIsAnApproverUser] = useState(false)

  useEffect(() => {
    if (!isPermissionsLoading) {
      const isApprover = userPermissions?.some((permission) => {
        return permission.path === '/approval/v1/travel-request/approve'
      })

      const hasCostCenterAttached =
        getFromStorage('my_profile_data')?.cost_center_id

      setIsAnApproverUser(!!isApprover && !!hasCostCenterAttached)
    }
  }, [isPermissionsLoading])

  return (
    <MainContainer>
      <PageTitle>{t('myRequests.requests')}</PageTitle>
      <MyRequestsContainer>
        <RequestsFilter id="filter-container">
          <h3 onClick={toggleActiveClass}>
            {t('myRequests.filter.filter-requests')}
          </h3>
          {isAnApproverUser && (
            <div>
              <Radio.Group
                onChange={onRequestTypeChange}
                value={requestType.find((type) => type.isChecked)?.name}
              >
                {requestType.map((type) => {
                  return (
                    <CustomRadio
                      key={type.name}
                      value={type.name}
                      label={t(`myRequests.filter.${type.name.toLowerCase()}`)}
                    />
                  )
                })}
              </Radio.Group>
            </div>
          )}

          <h3>Status</h3>
          <Flex vertical>
            {requestStatus.map((status) => {
              return (
                <Checkbox
                  key={status.name}
                  value={status.name}
                  checked={status.isChecked}
                  onChange={onRequestStatusChange}
                  label={t(`myRequests.status.${status.name.toLowerCase()}`)}
                />
              )
            })}
          </Flex>

          <h3>{t('myRequests.order.order-requests')}</h3>
          <Flex vertical>
            <Radio.Group onChange={onRequestsOrderChange} value={requestsOrder}>
              <CustomRadio
                value={'NEW'}
                label={t('myRequests.order.recent-requests')}
              />
              <CustomRadio
                value={'OLD'}
                label={t('myRequests.order.older-requests')}
              />
            </Radio.Group>
          </Flex>
        </RequestsFilter>

        <TravelRequestMainContent>
          <SearchInput
            placeholder={t('myRequests.search-travel-request')}
            value={requestSearchField}
            onChange={requestSearchStringOnChange}
            width={window.screen.width <= ScreenSize.MEDIUM ? '100%' : '40%'}
          />
          {loading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <div>
              {!travels?.length && (
                <div>
                  <NotFoundTitle>{t('myRequests.not-found')}</NotFoundTitle>
                </div>
              )}
              {travels?.map((travel) => {
                return (
                  <TravelRequestCard key={travel.id}>
                    <div className="card-header">
                      <div className="card-header-title">
                        <span className="travel-request-number">
                          {travel.reservation_number ?? 'n/a'}
                        </span>

                        <div className="travel-request-type">
                          {travel.flights && (
                            <SmilesIcon
                              type="airplane"
                              color={Color.GRAY_1}
                              size={27}
                            />
                          )}
                          {/* {travel.hotels && (
                            <SmilesIcon
                              type="bed"
                              color={Color.GRAY_1}
                              size={27}
                            />
                          )}
                          {travel.cars && (
                            <SmilesIcon
                              type="car"
                              color={Color.GRAY_1}
                              size={27}
                            />
                          )} */}
                        </div>
                      </div>

                      <div className="travel-request-status">
                        {/* <span className="greenish">
                          <SmilesIcon
                            type="check-circle"
                            color={Color.NORMAL_GREEN}
                            size={25}
                          />
                          Dentro da política
                        </span> */}
                        <StatusBadge
                          status={travel.status as unknown as StatusEnum}
                          text={t(
                            `myRequests.status.${(
                              travel.status as unknown as StatusEnum
                            )
                              .toString()
                              .toLowerCase()}`,
                          )}
                        />
                      </div>
                    </div>

                    <div className="travel-request-details">
                      <div>
                        <span>
                          <SmilesIcon
                            type="airplane-down"
                            color={Color.PRIMARY}
                            size={14}
                          />
                          <span>{t('myRequests.card.destination')}</span>
                        </span>
                        <span>{travel.destination}</span>
                      </div>
                      <div>
                        <span>
                          <SmilesIcon type="user" size={14} />
                          <span>{t('myRequests.card.requester')}</span>
                        </span>
                        <span>{travel.requester.name}</span>
                      </div>
                      <div>
                        <span>
                          <SmilesIcon type="user" size={14} />
                          <span>{t('myRequests.card.traveler')}</span>
                        </span>
                        {travel.users.map((user) => {
                          return (
                            <span key={user.user.name}>{user.user.name}</span>
                          )
                        })}
                      </div>
                      <div>
                        <span>
                          <SmilesIcon type="dollar-circle" size={19} />
                          <span>{t('myRequests.card.travel-price')}</span>
                        </span>
                        <span>{formatToReal(travel.amount)}</span>
                      </div>
                    </div>

                    <span className="more-information">
                      <Link
                        className="link"
                        to={`/travel-request/${travel.id}`}
                      >
                        {t('myRequests.card.more-information')}
                      </Link>
                    </span>
                  </TravelRequestCard>
                )
              })}
            </div>
          )}
        </TravelRequestMainContent>
      </MyRequestsContainer>
    </MainContainer>
  )
}

export default MyRequests
