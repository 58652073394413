export default `
<svg width="100%" height="100%" viewBox="0 0 55 42" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
<g clip-path="url(#clip0_2259_16658)">
<path d="M34.2174 16.7373H34.2227C34.7909 16.7373 35.2954 16.5943 35.6856 16.4112C36.0753 16.2249 36.3575 16.0089 36.4918 
15.8205C36.5803 15.6932 36.6361 15.545 36.6361 15.3806C36.6361 15.2601 36.6087 15.1354 36.5492 15.0148C36.4044 14.7315 36.1032 
14.599 35.8336 14.5885C35.7862 14.5885 35.734 14.5937 35.6866 14.6073C35.5402 14.6485 35.4396 14.697 35.337 14.7299C35.2327 
14.7623 35.1211 14.7899 34.9294 14.7936C34.6197 14.7868 34.4333 14.6725 34.3095 14.5468C34.2874 14.5207 34.0942 14.2859 34.021 
13.9895C33.9062 13.4995 33.8972 12.8076 33.8919 12.3192C33.9025 12.1449 33.9025 2.31164 33.9025 2.04552C33.9025 1.67974 33.7113 
1.39015 33.4533 1.21743C33.1847 1.0395 32.8556 0.958618 32.528 0.958618C32.2005 0.958618 31.8577 1.04367 31.5859 1.21743C31.3216 
1.38701 31.123 1.67296 31.123 2.02674V13.6707C31.123 14.3182 31.3142 15.0754 31.7987 15.6879C32.2811 16.2932 33.0699 16.7373 34.2174 16.7373Z" fill="#E65200"/>
<path d="M42.407 9.21489C41.5766 10.1275 40.4165 10.3915 39.1942 10.5157C39.1889 10.4088 39.1889 10.3023 39.1889 10.1933C39.1889 
9.12358 39.3406 7.91718 39.8825 7.15953L39.8878 7.15431C40.5839 6.16759 41.3122 6.0554 41.6535 6.04653H41.6587C42.2159 6.0554 
42.7857 6.41597 42.9489 7.28372C42.9753 7.42878 42.9863 7.56862 42.9863 7.7069C42.9863 8.30175 42.7578 8.81781 42.4081 
9.21489H42.407ZM45.3934 9.32343C45.6567 8.79015 45.7921 8.18121 45.7921 7.62758C45.7921 7.45174 45.78 7.28424 45.7521 
7.12353C45.4845 5.62231 44.0748 4.48166 41.902 4.49053C40.3854 4.49053 38.4422 5.08121 37.2526 6.90124C36.7966 7.61245 
36.1836 8.97174 36.1836 10.6885C36.1836 11.0991 36.2152 11.5275 36.2984 11.9742C36.5596 13.3006 37.1378 14.4918 38.0504 
15.3486C38.9578 16.2044 40.2027 16.7257 41.7593 16.7257H41.763C42.604 16.7257 43.3929 16.5806 43.9795 16.3427C44.2733 
16.22 44.5224 16.0729 44.7036 15.8991C44.8863 15.7249 45.0016 15.5161 45.0016 15.2792C45.0016 15.2448 45.0016 15.2067 
44.9911 15.1707C44.93 14.7668 44.6025 14.5012 44.2265 14.5033C44.1359 14.5033 44.0369 14.519 43.9479 14.5498C43.4845 14.6933 
43.0406 14.7741 42.4934 14.7741C42.3438 14.7741 42.1822 14.7668 42.01 14.7554C40.8625 14.6651 40.1848 13.9774 39.7756 13.2818C39.5755 
12.9296 39.4433 12.5774 39.3664 12.3102C39.3317 12.1923 39.3053 12.089 39.2879 12.0133C40.8588 11.946 42.2201 11.767 43.5024 11.0882C44.1606 
10.7239 44.9758 10.1374 45.3934 9.32291V9.32343Z" fill="#E65200"/>
<path d="M28.3327 3.87497C29.1458 3.87497 29.804 3.22116 29.804 2.41707C29.804 1.61298 29.1458 0.960205 28.3327 0.960205C27.5196 
0.960205 26.8629 1.61298 26.8613 2.41707C26.8634 3.22116 27.5196 3.87497 28.3327 3.87497Z" fill="#E65200"/>
<path d="M11.1148 16.2765C11.376 16.4545 11.7067 16.5374 12.0358 16.5374C12.365 16.5374 12.7083 16.4545 12.9727 16.2765C13.2412 
16.1075 13.4392 15.8242 13.4392 15.4672C13.4392 15.4672 13.4413 9.10026 13.4413 9.06738C13.4619 8.57898 13.5909 7.91682 13.8853 
7.40389C14.1865 6.88 14.6167 6.51579 15.2887 6.51057C15.5988 6.51057 15.8111 6.6071 15.9854 6.76103C16.2434 6.99375 16.3982 
7.38197 16.4783 7.77906C16.5567 8.1725 16.5604 8.56072 16.5604 8.78665C16.5604 8.83675 16.5641 15.2763 16.5641 15.449C16.5604 
15.82 16.7658 16.1101 17.0444 16.2823C17.323 16.455 17.6732 16.538 18.0197 16.538C18.3662 16.538 18.7316 16.455 19.0107 
16.2823C19.2983 16.1101 19.5089 15.8268 19.5068 15.4678V9.06269C19.5289 8.57272 19.6585 7.91682 19.9507 7.40441C20.2525 
6.88052 20.6806 6.51631 21.3541 6.51109C21.6622 6.51109 21.875 6.60606 22.0487 6.76155C22.3105 6.99428 22.46 7.38249 
22.5438 7.77958C22.6201 8.17458 22.6291 8.56124 22.6291 8.78718V15.4495C22.6291 15.8205 22.8308 16.1106 23.1077 
16.2828C23.3863 16.4555 23.7344 16.5385 24.0851 16.5385C24.4359 16.5385 24.7903 16.4555 25.0778 16.2828C25.3585 
16.1106 25.5723 15.8273 25.5723 15.4683V9.18583C25.5686 7.59539 25.2568 6.4083 24.667 5.60734C24.0804 4.80533 23.2041 
4.41137 22.1767 4.41868C21.1825 4.41868 20.4321 4.89143 19.897 5.43306C19.5294 5.80249 19.2683 6.20479 
19.0961 6.5257C18.8485 5.88545 18.4989 5.39235 18.0565 5.03858C17.5183 4.61748 16.8564 4.41868 16.1112 
4.41868C14.8384 4.42077 13.9695 5.18677 13.4366 5.89224V5.82701C13.4398 5.4628 13.2518 5.17476 12.9874 
4.9984C12.7257 4.82255 12.3987 4.74324 12.0658 4.74324C11.733 4.74324 11.3939 4.82412 11.129 4.9984C10.8615 
5.16798 10.6609 5.45445 10.6661 5.81449V15.4495C10.663 15.8153 10.851 16.1049 11.1153 16.2776L11.1148 16.2765Z" fill="#E65200"/>
<path d="M29.2732 5.00446C29.0136 4.82861 28.6824 4.74878 28.3501 4.74878C28.0178 4.74878 27.6829 4.83175 
27.4153 5.00446C27.1468 5.173 26.954 5.45999 26.9551 5.82055V15.455C26.9535 15.8203 27.1378 16.1125 27.4027 
16.2837C27.6639 16.4611 27.993 16.5456 28.3206 16.5456C28.6481 16.5456 28.9946 16.4611 29.2643 16.2837C29.5291 
16.1141 29.7277 15.8313 29.7256 15.4738C29.7256 15.4738 29.7224 6.09815 29.7224 5.83203C29.7256 5.46782 29.5344 5.18187 29.2732 5.00394V5.00446Z" fill="#E65200"/>
<path d="M54.1558 12.9155C54.1453 12.7339 54.1169 12.5628 54.0763 12.4036C53.911 11.7363 53.5187 11.2306 53.0347 
10.8262C52.308 10.2168 51.3775 9.81813 50.6134 9.3819C50.2321 9.16536 49.8903 8.93994 49.6407 8.68687C49.3911 8.43588 
49.2294 8.1609 49.1789 7.81964C49.1689 7.75807 49.1647 7.69284 49.1647 7.62762C49.1657 7.27645 49.3 6.91015 49.5896 
6.63307C49.7429 6.48436 50.2079 6.18537 50.8572 6.18537C51.6687 6.18537 52.3533 6.5381 52.7377 6.5381C53.1716 6.54228 
53.5471 6.29338 53.6603 5.87698C53.6819 5.79506 53.6935 5.71418 53.6935 5.62965C53.6935 5.15273 53.297 4.7165 52.7551 
4.58084C52.7551 4.58084 52.2659 4.41699 51.2305 4.41699C49.7908 4.41699 48.6117 4.76973 47.7823 5.38441C46.9534 5.99961 
46.4837 6.88719 46.4863 7.90417C46.4863 8.18646 46.519 8.47554 46.5906 8.7714C46.9008 10.0289 48.1515 10.7088 49.3058 
11.3219C49.8861 11.6241 50.4485 11.9095 50.874 12.2189C51.3016 12.5304 51.576 12.8555 51.6308 13.2323C51.6413 13.2954 51.6471 
13.3611 51.6471 13.4227C51.645 13.7687 51.506 14.089 51.2063 14.34C50.903 14.5868 50.4306 14.7622 49.7576 14.7752H49.695C48.9619 
14.7752 48.3353 14.567 47.9229 14.3891C47.7428 14.304 47.5585 14.2623 47.3874 14.2623C46.9455 14.256 46.5811 14.5529 46.5243 
14.9808C46.5174 15.0225 46.5153 15.0638 46.5153 15.1071C46.5153 15.4123 46.6812 15.6701 46.9092 15.8652C47.2504 16.1627 47.7365 
16.3604 48.1994 16.4956C48.1994 16.4956 50.6476 17.5621 52.7925 16.0103C54.595 14.7069 54.1564 12.9155 54.1564 12.9155H54.1558Z" fill="#E65200"/>
<path d="M4.54622 16.8385C3.16756 16.8385 2.12751 16.3939 2.11698 16.3897C2.11013 16.3866 2.10328 16.3845 2.09644 
16.3824C1.38657 16.1747 0.845745 15.9117 0.488705 15.6008C0.282275 15.4239 0.0374022 15.1327 0.0374022 14.7471C0.0374022 
14.6939 0.0405618 14.6506 0.0468811 14.6104C0.108494 14.1455 0.490811 13.8235 0.97687 13.8235H0.992142C1.19594 13.8235 1.41027 
13.8746 1.61407 13.9712C2.41188 14.315 3.20811 14.4883 3.98591 14.4883H4.06753C4.90431 14.4721 5.59048 14.2634 6.056 13.8851C6.46939 
13.5396 6.68793 13.0836 6.69109 12.5644C6.69109 12.4747 6.68319 12.3797 6.66739 12.2879C6.59367 11.7854 6.2598 11.3319 
5.61734 10.8639C5.09441 10.483 4.4272 10.1365 3.72102 9.76965L3.56199 9.68721C2.13646 8.92956 0.518721 8.06963 0.129558 
6.49119C0.0437214 6.13376 1.30227e-05 5.76902 1.30227e-05 5.40794C-0.00314662 4.16241 0.568749 3.05098 1.61038 2.27819C2.68782 
1.47984 4.1976 1.05823 5.97753 1.05823C7.2672 1.05823 7.89333 1.25964 7.89913 1.26121C7.90334 1.26277 7.90755 1.26382 7.91176 
1.26486C8.54106 1.42244 8.99763 1.92441 8.99763 2.45821C8.99763 2.54901 8.98552 2.6398 8.96024 2.73581C8.84017 3.1783 8.44153 
3.46476 7.94599 3.46476H7.93335C7.75273 3.46476 7.49206 3.39067 7.19031 3.30509C6.72268 3.17256 6.14026 3.00715 5.49306 
3.00715C4.61309 3.00715 3.96958 3.40267 3.72839 3.63643C3.34186 4.00587 3.12701 4.50784 3.12543 5.0479C3.12543 5.15069 3.13227 
5.24149 3.14597 5.3255C3.21285 5.77894 3.41875 6.16664 3.79211 6.54285C4.08649 6.8408 4.49882 7.13666 5.09073 7.4727C5.37141 
7.63289 5.66262 7.78578 5.97122 7.94753C6.74322 8.35245 7.54103 8.77093 8.20456 9.32769C8.89231 9.90271 9.3157 10.5424 9.49896 
11.2829C9.55215 11.4911 9.5848 11.7034 9.59691 11.9153C9.59743 11.9257 9.59902 11.9356 9.60165 11.9461C9.6027 11.9513 9.73383 
12.5122 9.58901 13.2615C9.39522 14.2613 8.82701 15.1087 7.89965 15.7792C6.92806 16.4821 5.79902 16.8385 4.54569 16.8385H4.54622Z" fill="#E65200"/>
<path d="M0.191406 19.7523H1.53636C2.07245 21.0547 3.30576 24.2309 3.98351 26.0447H4.00352C4.71128 24.2309 5.91458 21.0553 6.43013 
19.7523H7.77508L4.73129 27.5673H3.24468L0.191406 19.7523Z" fill="#595959"/>
<path d="M9.73828 19.7523H11.0121V27.5673H9.73828V19.7523Z" fill="#595959"/>
<path d="M16.0384 19.7523H17.5045L20.5583 27.5673H19.2033C18.91 26.8159 18.6067 26.0744 18.3134 25.323H15.179C14.8857 26.0744 
14.6024 26.8159 14.3096 27.5673H12.9746L16.0384 19.7523ZM17.8584 24.1109C17.535 23.2792 17.1606 22.3274 16.7662 21.2655H16.7462L15.634 24.1109H17.8584Z" fill="#595959"/>
<path d="M21.8203 23.6904C21.8203 21.0856 23.6908 19.5922 25.8041 19.5922C27.4624 19.5922 28.8679 20.5142 29.3834 
21.9966L28.2107 22.4673C27.7862 21.4555 26.9468 20.784 25.8041 20.784C24.338 20.784 23.1547 21.8761 23.1547 23.6898C23.1547 
25.5036 24.348 26.5451 25.9858 26.5451C27.2596 26.5451 28.1396 25.884 28.3218 24.6317H26.0263V23.5897H29.5757V23.9805C29.5757 
26.4554 28.0185 27.738 25.8547 27.738C23.4075 27.738 21.8203 26.0849 21.8203 23.6904Z" fill="#595959"/>
<path d="M31.8906 19.7523H36.9871V20.9144H33.165V22.9786H36.7849V24.1907H33.165V26.3948H37.0882V27.5673H31.8906V19.7523Z" fill="#595959"/>
<path d="M39.498 19.7523H40.833C42.1174 21.6057 43.3913 23.4294 44.6757 25.383H44.6957V19.7523H45.9695V27.5673H44.6346C43.3707 
25.7337 42.0763 23.9502 40.7919 22.0263H40.7719V27.5673H39.498V19.7523Z" fill="#595959"/>
<path d="M48.2051 25.4332L49.3884 25.0225C49.7017 26.1747 50.4805 26.5858 51.2689 26.5858C52.0572 26.5858 52.7455 26.2253 52.7455 
25.4634C52.7455 24.7016 52.0172 24.4814 51.3094 24.2711L50.6017 24.0509C49.8228 23.8104 48.4679 23.3095 48.4679 21.8364C48.4679 
20.3634 49.7017 19.5922 51.1472 19.5922C52.5928 19.5922 53.5638 20.4036 53.8472 21.4258L52.6844 21.8964C52.4316 21.1852 51.8955 
20.7344 51.1072 20.7344C50.3189 20.7344 49.8028 21.145 49.8028 21.7362C49.8028 22.4777 50.6722 22.7783 51.2589 22.9687L52.0778 
23.2291C52.8361 23.4696 54.1105 23.8401 54.1105 25.3032C54.1105 26.7664 52.9477 27.738 51.2794 27.738C49.8434 27.738 48.6 26.9866 
48.2056 25.4337L48.2051 25.4332Z" fill="#595959"/>
</g>
<path d="M3.46884 39.202C2.74616 39.202 2.12673 39.0506 1.61053 38.7477C1.09433 38.4449 0.695144 38.0147 0.412957 37.4572C0.137652 
36.8929 0 36.2287 0 35.4647C0 34.8935 0.07915 34.3807 0.23745 33.9265C0.39575 33.4653 0.622876 33.073 0.918828 32.7495C1.21478 
32.4192 1.57612 32.168 2.00284 31.9959C2.43644 31.8238 2.92511 31.7378 3.46884 31.7378C3.88179 31.7378 4.27754 31.7963 4.65609 
31.9133C5.04151 32.0303 5.37188 32.1955 5.64718 32.4088C5.7573 32.4846 5.82957 32.5706 5.86398 32.6669C5.8984 32.7633 5.90184 32.8562 
5.87431 32.9457C5.85366 33.0283 5.80892 33.1005 5.7401 33.1625C5.67815 33.2176 5.599 33.2485 5.50265 33.2554C5.40629 33.2554 5.30305 
33.2176 5.19293 33.1418C4.95204 32.956 4.68706 32.8218 4.39799 32.7392C4.1158 32.6497 3.81297 32.605 3.48948 32.605C2.95952 32.605 
2.50871 32.7186 2.13705 32.9457C1.77227 33.1659 1.49353 33.4894 1.30081 33.9161C1.11498 34.336 1.02207 34.8522 1.02207 35.4647C1.02207 
36.0704 1.11498 36.59 1.30081 37.0236C1.49353 37.4504 1.77227 37.7773 2.13705 38.0044C2.50871 38.2247 2.95952 38.3348 3.48948 
38.3348C3.81297 38.3348 4.11924 38.29 4.40831 38.2006C4.70426 38.1111 4.97613 37.9734 5.2239 37.7876C5.33402 37.7119 5.43382 37.6775 
5.52329 37.6844C5.61965 37.6913 5.6988 37.7222 5.76074 37.7773C5.82269 37.8255 5.86398 37.8943 5.88463 37.9838C5.91216 38.0664 
5.91216 38.1524 5.88463 38.2419C5.8571 38.3313 5.79516 38.4105 5.6988 38.4793C5.41661 38.7202 5.07937 38.9026 4.68706 39.0265C4.30163 
39.1435 3.89556 39.202 3.46884 39.202Z" fill="#595959"/>
<path d="M8.91107 39.202C8.41552 39.202 7.98536 39.0953 7.62058 38.8819C7.2558 38.6686 6.97362 38.3657 6.77402 37.9734C6.57442 37.5811 
6.47463 37.12 6.47463 36.59C6.47463 36.1908 6.52969 35.8329 6.63981 35.5163C6.75681 35.1929 6.922 34.9176 7.13536 34.6904C7.34872 
34.4633 7.60337 34.2912 7.89933 34.1742C8.20216 34.0504 8.53941 33.9884 8.91107 33.9884C9.40662 33.9884 9.83678 34.0951 10.2016 
34.3084C10.5663 34.5218 10.8485 34.8246 11.0481 35.217C11.2477 35.6024 11.3475 36.0601 11.3475 36.59C11.3475 36.9892 11.289 37.3506 
11.172 37.674C11.0619 37.9975 10.9001 38.2728 10.6868 38.5C10.4734 38.7271 10.2153 38.9026 9.91249 39.0265C9.61654 39.1435 9.28273 
39.202 8.91107 39.202ZM8.91107 38.4587C9.21391 38.4587 9.47544 38.3864 9.69569 38.2419C9.92281 38.0973 10.0949 37.8874 10.2119 37.6121C10.3358 
37.3368 10.3977 36.9961 10.3977 36.59C10.3977 35.9844 10.2635 35.5232 9.99508 35.2066C9.72666 34.89 9.36532 34.7317 8.91107 34.7317C8.61512 
34.7317 8.35358 34.804 8.12645 34.9485C7.90621 35.0862 7.73414 35.2927 7.61026 35.568C7.48637 35.8433 7.42443 36.184 7.42443 36.59C7.42443 
37.1957 7.55864 37.6603 7.82706 37.9838C8.10236 38.3004 8.4637 38.4587 8.91107 38.4587Z" fill="#595959"/>
<path d="M13.062 39.1813C12.9037 39.1813 12.7833 39.14 12.7007 39.0574C12.6181 38.9749 12.5768 38.8544 12.5768 38.6961V34.484C12.5768 
34.3257 12.6181 34.2087 12.7007 34.1329C12.7833 34.0504 12.8968 34.0091 13.0414 34.0091C13.1859 34.0091 13.296 34.0504 13.3717 34.1329C13.4543 
34.2087 13.4956 34.3257 13.4956 34.484V35.2789H13.3924C13.5025 34.8659 13.709 34.5528 14.0118 34.3394C14.3146 34.1261 14.6966 34.0056 15.1578 
33.9781C15.2679 33.9712 15.3574 33.9987 15.4262 34.0607C15.495 34.1157 15.5329 34.2121 15.5398 34.3497C15.5466 34.4805 15.5157 34.5872 
15.4468 34.6698C15.378 34.7455 15.2713 34.7902 15.1268 34.804L14.941 34.8143C14.4867 34.8625 14.1357 35.0105 13.8879 35.2582C13.647 
35.506 13.5266 35.8467 13.5266 36.2803V38.6961C13.5266 38.8544 13.4853 38.9749 13.4027 39.0574C13.327 39.14 13.2134 39.1813 13.062 39.1813Z" fill="#595959"/>
<path d="M16.8378 41.0396C16.6933 41.0396 16.5797 40.9983 16.4971 40.9158C16.4145 40.8332 16.3732 40.7162 16.3732 40.5647V34.484C16.3732 
34.3257 16.4145 34.2087 16.4971 34.1329C16.5797 34.0504 16.6933 34.0091 16.8378 34.0091C16.9892 34.0091 17.1028 34.0504 17.1785 34.1329C17.2611 
34.2087 17.3024 34.3257 17.3024 34.484V35.4234L17.1888 35.2376C17.299 34.8591 17.5158 34.5562 17.8392 34.3291C18.1627 34.102 18.5516 33.9884 
19.0058 33.9884C19.4532 33.9884 19.8421 34.0951 20.1724 34.3084C20.5028 34.5149 20.7609 34.8143 20.9467 35.2066C21.1326 35.5921 21.2255 
36.0532 21.2255 36.59C21.2255 37.12 21.1326 37.5846 20.9467 37.9838C20.7678 38.3761 20.5097 38.6789 20.1724 38.8923C19.8421 39.0987 
19.4532 39.202 19.0058 39.202C18.5585 39.202 18.173 39.0884 17.8496 38.8613C17.5261 38.6342 17.3093 38.3348 17.1992 37.9631H17.3127V40.5647C17.3127 
40.7162 17.2714 40.8332 17.1888 40.9158C17.1062 40.9983 16.9892 41.0396 16.8378 41.0396ZM18.789 38.4587C19.085 38.4587 19.3431 38.3864 19.5633 
38.2419C19.7905 38.0973 19.966 37.8874 20.0899 37.6121C20.2137 37.3368 20.2757 36.9961 20.2757 36.59C20.2757 35.9844 20.1415 35.5232 19.8731 
35.2066C19.6046 34.89 19.2433 34.7317 18.789 34.7317C18.4862 34.7317 18.2212 34.804 17.9941 34.9485C17.7739 35.0862 17.6018 35.2927 17.4779 
35.568C17.3609 35.8433 17.3024 36.184 17.3024 36.59C17.3024 37.1957 17.4366 37.6603 17.705 37.9838C17.9735 38.3004 18.3348 38.4587 18.789 38.4587Z" fill="#595959"/>
<path d="M24.5954 39.202C24.0998 39.202 23.6696 39.0953 23.3049 38.8819C22.9401 38.6686 22.6579 38.3657 22.4583 37.9734C22.2587 37.5811 
22.1589 37.12 22.1589 36.59C22.1589 36.1908 22.214 35.8329 22.3241 35.5163C22.4411 35.1929 22.6063 34.9176 22.8196 34.6904C23.033 34.4633 
23.2877 34.2912 23.5836 34.1742C23.8864 34.0504 24.2237 33.9884 24.5954 33.9884C25.0909 33.9884 25.5211 34.0951 25.8858 34.3084C26.2506 
34.5218 26.5328 34.8246 26.7324 35.217C26.932 35.6024 27.0318 36.0601 27.0318 36.59C27.0318 36.9892 26.9733 37.3506 26.8563 37.674C26.7462 
37.9975 26.5844 38.2728 26.3711 38.5C26.1577 38.7271 25.8996 38.9026 25.5968 39.0265C25.3008 39.1435 24.967 39.202 24.5954 39.202ZM24.5954 
38.4587C24.8982 38.4587 25.1597 38.3864 25.38 38.2419C25.6071 38.0973 25.7792 37.8874 25.8962 37.6121C26.0201 37.3368 26.082 36.9961 26.082 
36.59C26.082 35.9844 25.9478 35.5232 25.6794 35.2066C25.4109 34.89 25.0496 34.7317 24.5954 34.7317C24.2994 34.7317 24.0379 34.804 23.8107 
34.9485C23.5905 35.0862 23.4184 35.2927 23.2945 35.568C23.1707 35.8433 23.1087 36.184 23.1087 36.59C23.1087 37.1957 23.2429 37.6603 23.5113 
37.9838C23.7866 38.3004 24.148 38.4587 24.5954 38.4587Z" fill="#595959"/>
<path d="M28.7463 39.1813C28.588 39.1813 28.4676 39.14 28.385 39.0574C28.3024 38.9749 28.2611 38.8544 28.2611 38.6961V34.484C28.2611 
34.3257 28.3024 34.2087 28.385 34.1329C28.4676 34.0504 28.5811 34.0091 28.7256 34.0091C28.8702 34.0091 28.9803 34.0504 29.056 34.1329C29.1386 
34.2087 29.1799 34.3257 29.1799 34.484V35.2789H29.0767C29.1868 34.8659 29.3933 34.5528 29.6961 34.3394C29.9989 34.1261 30.3809 34.0056 30.842 
33.9781C30.9522 33.9712 31.0416 33.9987 31.1105 34.0607C31.1793 34.1157 31.2172 34.2121 31.224 34.3497C31.2309 34.4805 31.1999 34.5872 
31.1311 34.6698C31.0623 34.7455 30.9556 34.7902 30.8111 34.804L30.6252 34.8143C30.171 34.8625 29.82 35.0105 29.5722 35.2582C29.3313 
35.506 29.2109 35.8467 29.2109 36.2803V38.6961C29.2109 38.8544 29.1696 38.9749 29.087 39.0574C29.0113 39.14 28.8977 39.1813 28.7463 39.1813Z" fill="#595959"/>
<path d="M33.6073 39.202C33.2632 39.202 32.95 39.1332 32.6679 38.9955C32.3925 38.8579 32.1757 38.672 32.0174 38.438C31.866 38.204 
31.7903 37.9425 31.7903 37.6534C31.7903 37.2817 31.8832 36.9892 32.0691 36.7759C32.2549 36.5625 32.5681 36.4111 33.0085 36.3216C33.449 
36.2253 34.0409 36.1771 34.7843 36.1771H35.2695V36.7759H34.8049C34.2749 36.7759 33.8551 36.8 33.5454 36.8481C33.2425 36.8963 33.0292 
36.9823 32.9053 37.1062C32.7814 37.2232 32.7195 37.3884 32.7195 37.6018C32.7195 37.8702 32.8124 38.0904 32.9982 38.2625C33.1909 38.4346 
33.4525 38.5206 33.7828 38.5206C34.0444 38.5206 34.2749 38.4587 34.4745 38.3348C34.6741 38.204 34.8324 38.0319 34.9494 37.8186C35.0664 
37.5983 35.1249 37.3471 35.1249 37.0649V35.8777C35.1249 35.4716 35.0424 35.1791 34.8772 35.0002C34.712 34.8143 34.4332 34.7214 34.0409 
34.7214C33.8138 34.7214 33.5798 34.7524 33.3389 34.8143C33.098 34.8763 32.8468 34.9726 32.5853 35.1034C32.482 35.1516 32.3925 35.1688 
32.3168 35.155C32.2411 35.1344 32.1792 35.0931 32.131 35.0311C32.0897 34.9623 32.0656 34.89 32.0587 34.8143C32.0587 34.7317 32.0759 
34.6526 32.1104 34.5769C32.1517 34.5012 32.2205 34.4461 32.3168 34.4117C32.6197 34.2603 32.9191 34.1536 33.215 34.0916C33.5179 34.0228 
33.8 33.9884 34.0616 33.9884C34.5089 33.9884 34.8772 34.0607 35.1662 34.2052C35.4553 34.3429 35.6721 34.5597 35.8166 34.8556C35.9612 
35.1447 36.0334 35.5163 36.0334 35.9706V38.6961C36.0334 38.8544 35.9956 38.9749 35.9199 39.0574C35.8442 39.14 35.7341 39.1813 35.5895 
39.1813C35.4519 39.1813 35.3417 39.14 35.2592 39.0574C35.1834 38.9749 35.1456 38.8544 35.1456 38.6961V37.9631H35.2385C35.1834 38.2178 
35.0802 38.438 34.9288 38.6238C34.7774 38.8097 34.5881 38.9542 34.361 39.0574C34.1407 39.1538 33.8895 39.202 33.6073 39.202Z" fill="#595959"/>
<path d="M39.6231 39.202C39.217 39.202 38.8763 39.1297 38.601 38.9852C38.3326 38.8406 38.1296 38.6307 37.9919 38.3554C37.8612 38.0801 
37.7958 37.7429 37.7958 37.3437V34.804H37.135C37.0112 34.804 36.9148 34.773 36.846 34.7111C36.7771 34.6423 36.7427 34.5528 36.7427 
34.4427C36.7427 34.3257 36.7771 34.2362 36.846 34.1742C36.9148 34.1123 37.0112 34.0813 37.135 34.0813H37.7958V32.956C37.7958 32.7977 
37.8371 32.6807 37.9197 32.605C38.0023 32.5224 38.1193 32.4811 38.2707 32.4811C38.4221 32.4811 38.5357 32.5224 38.6114 32.605C38.694 
32.6807 38.7352 32.7977 38.7352 32.956V34.0813H39.9844C40.1152 34.0813 40.215 34.1123 40.2838 34.1742C40.3527 34.2362 40.3871 34.3257 
40.3871 34.4427C40.3871 34.5528 40.3527 34.6423 40.2838 34.7111C40.215 34.773 40.1152 34.804 39.9844 34.804H38.7352V37.2611C38.7352 
37.6396 38.8144 37.9253 38.9727 38.118C39.131 38.3107 39.3925 38.407 39.7573 38.407C39.8881 38.407 39.9982 38.3967 40.0877 38.3761C40.1772 
38.3485 40.2563 38.3348 40.3251 38.3348C40.3871 38.3279 40.4387 38.3485 40.48 38.3967C40.5282 38.4449 40.5523 38.5344 40.5523 38.6651C40.5523 
38.7615 40.535 38.851 40.5006 38.9336C40.4731 39.0093 40.4146 39.0609 40.3251 39.0884C40.2425 39.116 40.1324 39.14 39.9948 39.1607C39.8571 
39.1882 39.7332 39.202 39.6231 39.202Z" fill="#595959"/>
<path d="M41.8088 39.1607C41.6643 39.1607 41.5507 39.116 41.4681 39.0265C41.3855 38.937 41.3442 38.8131 41.3442 38.6548V34.5356C41.3442 
34.3704 41.3855 34.2465 41.4681 34.1639C41.5507 34.0744 41.6643 34.0297 41.8088 34.0297C41.9602 34.0297 42.0772 34.0744 42.1598 34.1639C42.2424 
34.2465 42.2837 34.3704 42.2837 34.5356V38.6548C42.2837 38.8131 42.2424 38.937 42.1598 39.0265C42.0841 39.116 41.9671 39.1607 
41.8088 39.1607ZM41.8088 32.9147C41.623 32.9147 41.475 32.8665 41.3649 32.7702C41.2616 32.6669 41.21 32.5327 41.21 32.3676C41.21 
32.1886 41.2616 32.0544 41.3649 31.9649C41.475 31.8686 41.623 31.8204 41.8088 31.8204C42.0084 31.8204 42.1564 31.8686 42.2527 
31.9649C42.356 32.0544 42.4076 32.1886 42.4076 32.3676C42.4076 32.5327 42.356 32.6669 42.2527 32.7702C42.1564 32.8665 42.0084 32.9147 41.8088 32.9147Z" fill="#595959"/>
<path d="M45.7299 39.1813C45.5991 39.1813 45.4855 39.1469 45.3892 39.0781C45.2928 39.0093 45.2102 38.8991 45.1414 38.7477L43.3863 
34.6595C43.3382 34.5425 43.3209 34.4358 43.3347 34.3394C43.3485 34.2431 43.3932 34.1639 43.4689 34.102C43.5515 34.04 43.6616 34.0091 
43.7993 34.0091C43.9163 34.0091 44.0092 34.0366 44.078 34.0916C44.1469 34.1467 44.2122 34.2431 44.2742 34.3807L45.895 
38.3761H45.606L47.2681 34.3807C47.3232 34.2431 47.3851 34.1467 47.454 34.0916C47.5297 34.0366 47.6329 34.0091 47.7637 
34.0091C47.8807 34.0091 47.9701 34.04 48.0321 34.102C48.1009 34.1639 48.1422 34.2431 48.156 34.3394C48.1697 34.4358 48.1491 
34.539 48.094 34.6491L46.3286 38.7477C46.2667 38.8991 46.1841 39.0093 46.0809 39.0781C45.9845 39.1469 45.8675 39.1813 45.7299 39.1813Z" fill="#595959"/>
<path d="M51.2456 39.202C50.75 39.202 50.3199 39.0953 49.9551 38.8819C49.5903 38.6686 49.3081 38.3657 49.1085 37.9734C48.9089 
37.5811 48.8091 37.12 48.8091 36.59C48.8091 36.1908 48.8642 35.8329 48.9743 35.5163C49.0913 35.1929 49.2565 34.9176 49.4699 
34.6904C49.6832 34.4633 49.9379 34.2912 50.2338 34.1742C50.5367 34.0504 50.8739 33.9884 51.2456 33.9884C51.7411 33.9884 52.1713 
34.0951 52.5361 34.3084C52.9008 34.5218 53.183 34.8246 53.3826 35.217C53.5822 35.6024 53.682 36.0601 53.682 36.59C53.682 36.9892 
53.6235 37.3506 53.5065 37.674C53.3964 37.9975 53.2346 38.2728 53.0213 38.5C52.8079 38.7271 52.5498 38.9026 52.247 
39.0265C51.951 39.1435 51.6172 39.202 51.2456 39.202ZM51.2456 38.4587C51.5484 38.4587 51.8099 38.3864 52.0302 38.2419C52.2573 
38.0973 52.4294 37.8874 52.5464 37.6121C52.6703 37.3368 52.7322 36.9961 52.7322 36.59C52.7322 35.9844 52.598 35.5232 52.3296 
35.2066C52.0612 34.89 51.6998 34.7317 51.2456 34.7317C50.9496 34.7317 50.6881 34.804 50.461 34.9485C50.2407 35.0862 50.0686 
35.2927 49.9448 35.568C49.8209 35.8433 49.7589 36.184 49.7589 36.59C49.7589 37.1957 49.8931 37.6603 50.1616 37.9838C50.4369 
38.3004 50.7982 38.4587 51.2456 38.4587Z" fill="#595959"/>
<defs>
<clipPath id="clip0_2259_16658">
<rect width="54.2006" height="26.7777" fill="white" transform="translate(0 0.960205)"/>
</clipPath>
</defs>
</svg>
`
