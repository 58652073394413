export default `
  <svg viewBox="0 0 32 32" width="100%" height="100%">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.66699 25.3337V17.3337C2.66699 16.7337
      2.78921 16.1892 3.03366 15.7003C3.2781 15.2114 3.60033 14.7781 4.00033 14.4003V10.667C4.00033
      9.55588 4.38921 8.61144 5.16699 7.83366C5.94477 7.05588 6.88921 6.66699 8.00033 6.66699H13.3337C
      13.8448 6.66699 14.3225 6.76166 14.767 6.95099C15.2114 7.13944 15.6225 7.40033 16.0003 7.73366C
      16.3781 7.40033 16.7892 7.13944 17.2337 6.95099C17.6781 6.76166 18.1559 6.66699 18.667 6.66699H24.0003C
      25.1114 6.66699 26.0559 7.05588 26.8337 7.83366C27.6114 8.61144 28.0003 9.55588 28.0003 10.667V
      14.4003C28.4003 14.7781 28.7225 15.2114 28.967 15.7003C29.2114 16.1892 29.3337 16.7337 29.3337 17.3337V
      25.3337H26.667V22.667H5.33366V25.3337H2.66699ZM17.3337 13.3337H25.3337V10.667C25.3337 10.2892 25.2057
      9.97233 24.9497 9.71633C24.6945 9.46121 24.3781 9.33366 24.0003 9.33366H18.667C18.2892 9.33366 17.9728
      9.46121 17.7177 9.71633C17.4617 9.97233 17.3337 10.2892 17.3337 10.667V13.3337ZM6.66699 13.3337H14.667V
      10.667C14.667 10.2892 14.5394 9.97233 14.2843 9.71633C14.0283 9.46121 13.7114 9.33366 13.3337 9.33366H
      8.00033C7.62255 9.33366 7.30566 9.46121 7.04966 9.71633C6.79455 9.97233 6.66699 10.2892 6.66699 10.667V
      13.3337ZM5.33366 20.0003H26.667V17.3337C26.667 16.9559 26.539 16.639 26.283 16.383C26.0279 16.1279
      25.7114 16.0003 25.3337 16.0003H6.66699C6.28921 16.0003 5.97233 16.1279 5.71633 16.383C5.46121 16.639
      5.33366 16.9559 5.33366 17.3337V20.0003Z" />
  </svg>
`
