import enLogin from './enLogin'
import enHome from './enHome'
import enNavbar from './enNavbar'
import enUserGroups from './enUserGroups'
import enGroup from './enGroup'
import enFlights from './enFlights'
import enFlow from './enFlow'
import enReservation from './enReservation'
import enHotel from './enHotel'
import enMyProfile from './enMyProfile'
import enMyRequests from './enMyRequests'
import enAccessDenied from './enAccessDenied'
import enTravelRequest from './enTravelRequest'
import enMyTravels from './enMyTravels'
import enPermissions from './enPermissions'
import enUser from './enUser'
import enComponent from './enComponent'
import enOutsourced from './enOutsourced'

const enTranslations = {
  translations: {
    ...enAccessDenied,
    ...enLogin,
    ...enHome,
    ...enNavbar,
    ...enUserGroups,
    ...enGroup,
    ...enFlights,
    ...enFlow,
    ...enReservation,
    ...enHotel,
    ...enMyProfile,
    ...enMyRequests,
    ...enTravelRequest,
    ...enMyTravels,
    ...enPermissions,
    ...enUser,
    ...enComponent,
    ...enOutsourced,
  },
}

export default enTranslations
