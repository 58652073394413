const enComponent = {
  component: {
    bottom_nav: {
      continue: 'Continue',
    },
    complete_request: {
      notifications: {
        verify_cost_center_and_try_again:
          'Verify the Cost Centers and try again.',
        failed_to_create_allocation: 'Failed to create allocation',
      },
    },
    mobile_nav: {
      make_a_request: 'Make a request',
      request_flight: 'Request flight',
      request_hotel: 'Request hotel',
      request_car: 'Request car',
      request_bus: 'Request bus',
      request_services: 'Request other services',
      my_request: 'My requests',
      see_my_requests: 'See my requests',
      your_requests: 'Your requests',
      changed_trips: 'Changed trips',
      next_trips: 'Next trips',
      previous_trips: 'Previous trips',
      your_favorites: 'Your favorites',
      favorites: 'Favorites',
      favorite_flights: 'Favorite flights',
      favorite_hotels: 'Favorite hotels',
      favorite_trips: 'Favorite trips',
      your_management: 'Your management',
      reports: 'Reports',
      third_part_register: 'Third party registration',
      advance_and_accountability: 'Advance and accountability',
      my_travels: 'My travels',
      see_my_travels: 'See my travels',
    },
    request_summary: {
      request_summary: 'Request summary',
      total: 'Total:',
      total_value: 'Total value',
      requester: 'Requester',
      traveler: 'Traveler',
      add_more_services_to_your_reserve:
        'Add more services to your reservation',
      come_back: 'Go back',
      go_to_costs_center: 'Go to cost center',
      flight: {
        air: 'Air',
        fare_per_segment: 'Fare per segment:',
      },
      hotel: {
        checkin: 'Check-in',
        daily_fare: 'Hotel daily rate:',
      },
    },
    cost_center: {
      cost_center: 'Cost center',
      description: 'Description',
      approver: 'Approver',
      regional: 'Regional',
      notifications: {
        failed_to_get_cost_center: 'Failed to get cost center.',
      },
      search: 'Search',
    },
    search_form: {
      add_third_part: 'Add collaborator or third-party person',
      requester: 'Requester',
      required_field: 'Required field',
      request_travel_to: 'Request travel for',
      search: 'Search...',
      employee_added: 'Employee added',
      cancel: 'Cancel',
      add: 'Add',
      notifications: {
        failed_to_get_airports: 'Failed to get airports.',
        failed_to_get_locations: 'Failed to get locations.',
      },
      select_departure_airport: 'Select departure airport',
      select_destination_airport: 'Select destination airport',
      select_departure_date: 'Select departure date',
      select_return_date: 'Select return date',
    },
    header: {
      see_all: 'See all',
    },
    stops_and_baggage: {
      direct: 'Direct',
      stop: 'stop',
      stops: 'stops',
    },
    summary_modal: {
      ticket_segment: 'Ticket segment',
    },
    hotel_info: {
      see_on_map: 'See on map',
      check_in: 'Check-in',
      check_out: 'Check-out',
      daily_rate: 'Hotel daily rate',
      total: 'Total',
    },
    modify_reserve: {
      modify: 'Modify reservation',
      remove: 'Remove',
    },
  },
}

export default enComponent
