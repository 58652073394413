import { StatusEnum } from 'constants/statusEnum'
import styled from 'styled-components'
import { Color, Size } from 'styles/global'

export const StatusBadgeStyled = styled.div<{ $status?: StatusEnum }>`
  width: fit-content !important;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  gap: 4px;
  border-radius: ${Size.BORDER_RADIUS};
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.12px;
  white-space: nowrap;
  content: ${(props) => (props.$status ? props.$status : '')};

  ${({ $status }) =>
    $status === StatusEnum.APPROVED &&
    `
      color: ${Color.NORMAL_GREEN};
      background: ${Color.LIGHT_GREEN};
      svg {
        fill: ${Color.NORMAL_GREEN};
      }
    `}
  ${({ $status }) =>
    $status === StatusEnum.CONFIRMED &&
    `
      color: ${Color.NORMAL_GREEN};
      background: ${Color.LIGHT_GREEN};
      svg {
        fill: ${Color.NORMAL_GREEN};
      }
    `}
  ${({ $status }) =>
    $status === StatusEnum.CANCELED &&
    `
      color: ${Color.NORMAL_RED};
      background: ${Color.LIGHT_RED};
      svg {
        fill: ${Color.NORMAL_RED};
      }
    `}
  ${({ $status }) =>
    $status === StatusEnum.REJECTED &&
    `
      color: ${Color.NORMAL_RED};
      background: ${Color.LIGHT_RED};
      svg {
        fill: ${Color.NORMAL_RED};
      }
    `}
  ${({ $status }) =>
    $status === StatusEnum.OPENED &&
    `
      color: ${Color.NORMAL_YELLOW};
      background: ${Color.LIGHT_YELLOW};
      svg {
        fill: ${Color.NORMAL_YELLOW};
      }
    `}
  ${({ $status }) =>
    $status === StatusEnum.WAITING_FOR_APPROVAL &&
    `
      color: ${Color.NORMAL_YELLOW};
      background: ${Color.LIGHT_YELLOW};
      svg {
        fill: ${Color.NORMAL_YELLOW};
      }
    `}
`
