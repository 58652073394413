import React from 'react'
import { NavBarHeader } from './styles'
import Collapse from '../collapse'
import { SmilesIcon } from 'components/icons'
import Drawer from 'components/drawer'
import { useTranslation } from 'react-i18next'
import { CollapseProps } from 'antd'
import NavCardContent from 'components/navBar/components/navCardItem'
import { useNavigate } from 'react-router-dom'

export type MobileNavBarTypes = {
  isOpen?: boolean
  close: () => void
}

const MobileNavbar: React.FC<MobileNavBarTypes> = ({
  isOpen = false,
  close,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const mobileNavbarItems: CollapseProps['items'] = [
    {
      key: '1',
      label: t('component.mobile_nav.make_a_request'),
      children: (
        <>
          <NavCardContent
            key={1}
            icon={<SmilesIcon type="plane-ticket" size={20} />}
            label={t('component.mobile_nav.request_flight')}
            onClick={() => navigate('/flow/flights')}
          />
          {/* <NavCardContent
            key={2}
            icon={<SmilesIcon type="sleep" size={20} />}
            label={t('component.mobile_nav.request_hotel')}
          />
          <NavCardContent
            key={3}
            icon={<SmilesIcon type="car" size={20} />}
            label={t('component.mobile_nav.request_car')}
          />
          <NavCardContent
            key={4}
            icon={<SmilesIcon type="bus" size={20} />}
            label={t('component.mobile_nav.request_bus')}
          />
          <NavCardContent
            key={5}
            icon={<SmilesIcon type="ticket" size={20} />}
            label={t('component.mobile_nav.request_services')}
          /> */}
        </>
      ),
    },
    {
      key: '2',
      label: t('component.mobile_nav.my_request'),
      children: (
        <NavCardContent
          key={1}
          icon={<SmilesIcon type="ticket" size={20} />}
          label={t('component.mobile_nav.see_my_requests')}
          onClick={() => navigate('/requests')}
        />
      ),
    },
    {
      key: '3',
      label: t('component.mobile_nav.my_travels'),
      children: (
        <NavCardContent
          key={1}
          icon={<SmilesIcon type="plane-right" size={20} />}
          label={t('component.mobile_nav.see_my_travels')}
          onClick={() => navigate('/my-travels')}
        />
      ),
    },
    // {
    //   key: '4',
    //   label: t('component.mobile_nav.your_requests'),
    //   children: (
    //     <>
    //       <NavCardContent
    //         key={6}
    //         icon={<SmilesIcon type="exclamation" size={20} />}
    //         label={t('component.mobile_nav.changed_trips')}
    //       />
    //       <NavCardContent
    //         key={7}
    //         icon={<SmilesIcon type="plane-right" size={20} />}
    //         label={t('component.mobile_nav.next_trips')}
    //       />
    //       <NavCardContent
    //         key={8}
    //         icon={<SmilesIcon type="dot-flag" size={20} />}
    //         label={t('component.mobile_nav.previous_trips')}
    //       />
    //     </>
    //   ),
    // },
    // {
    //   key: '5',
    //   label: t('component.mobile_nav.your_favorites'),
    //   children: (
    //     <>
    //       <NavCardContent
    //         key={9}
    //         icon={<SmilesIcon type="star-outline" size={20} />}
    //         label={t('component.mobile_nav.favorites')}
    //       />
    //       <NavCardContent
    //         key={10}
    //         icon={<SmilesIcon type="plane-ticket" size={20} />}
    //         label={t('component.mobile_nav.favorite_flights')}
    //       />
    //       <NavCardContent
    //         key={11}
    //         icon={<SmilesIcon type="sleep" size={20} />}
    //         label={t('component.mobile_nav.favorite_hotels')}
    //       />
    //       <NavCardContent
    //         key={12}
    //         icon={<SmilesIcon type="ticket" size={20} />}
    //         label={t('component.mobile_nav.favorite_trips')}
    //       />
    //     </>
    //   ),
    // },
    // {
    //   key: '6',
    //   label: t('component.mobile_nav.your_management'),
    //   children: (
    //     <>
    //       <NavCardContent
    //         key={13}
    //         icon={<SmilesIcon type="report" size={20} />}
    //         label={t('component.mobile_nav.reports')}
    //       />
    //       <NavCardContent
    //         key={14}
    //         icon={<SmilesIcon type="people-check" size={20} />}
    //         label={t('component.mobile_nav.thrid_part_register')}
    //       />
    //       <NavCardContent
    //         key={15}
    //         icon={<SmilesIcon type="check-list" size={20} />}
    //         label={t('component.mobile_nav.advance_and_accountability')}
    //       />
    //     </>
    //   ),
    // },
  ]

  return (
    <Drawer open={isOpen} onClose={close} closable={false} width={600}>
      <NavBarHeader data-testid="smiles-mobile-navbar">
        <SmilesIcon type="smiles-corp" size={48} />
        <SmilesIcon
          type="close"
          size={24}
          onClick={close}
          className="action-icon"
        />
      </NavBarHeader>
      <Collapse items={mobileNavbarItems} />
    </Drawer>
  )
}

export default MobileNavbar
