export default `
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16" >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66536 4C4.82442 4 3.33203 5.49239 3.33203 7.33334V13.3333C3.33203 
    14.0697 3.92898 14.6667 4.66536 14.6667H11.332C12.0684 14.6667 12.6654 14.0697 12.6654 13.3333V7.33333C12.6654 5.49239 
    11.173 4 9.33203 4H6.66536ZM11.332 8.66667H4.66536V13.3333H11.332V8.66667Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99967 10.6667H5.33301V10H10.6663V11.3333C10.6663 11.5174 10.5171 
    11.6667 10.333 11.6667C10.1489 11.6667 9.99967 11.5174 9.99967 11.3333V10.6667Z" />
    <rect x="4.66699" y="1.33325" width="2" height="5.33333" rx="0.666667" />
    <rect x="9.33301" y="1.33325" width="2" height="5.33333" rx="0.666667" />
</svg>
`
