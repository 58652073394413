const esNavbar = {
  navbar: {
    links: {
      home: 'Inicio',
      makeRequest: 'Hacer solicitud',
      myRequests: 'Mis solicitudes',
      myTravels: 'Mis viajes',
      favorites: 'Favoritos',
      management: 'Gestión',
    },
    linksDropdown: {
      makeRequest: {
        title: 'Seleccione el servicio deseado',
        requestAir: 'Aéreo',
        requestAccommodation: 'Alojamiento',
        requestCar: 'Coche',
        requestRoad: 'Carretera',
        requestOther: 'Otros servicios',
      },
      myTravels: {
        title: 'Todas tus viajes',
        changedTrips: 'Viajes modificados',
        upcomingTrips: 'Próximos viajes',
        previousTrips: 'Viajes anteriores',
      },
      favorites: {
        title: 'Accede rápidamente a tus elementos favoritos',
        favorites: 'Favoritos',
        favoriteFlights: 'Vuelos favoritos',
        favoriteHotels: 'Hoteles favoritos',
        favoriteTravels: 'Viajes favoritos',
      },
      management: {
        title: 'Accede a datos de gestión',
        report: 'Informe',
        thirdPartyRegistration: 'Registro de terceros',
        advanceAndAccountability: 'Anticipo y rendición de cuentas',
      },
    },
    languageDropdown: {
      en: 'Inglés',
      es: 'Español',
      pt: 'Portugués',
    },
    profileDropdown: {
      hello: 'Hola,',
      myProfile: 'Mi perfil',
      changeToAdmin: 'Cambiar a administrador',
      userGroups: 'Grupos de Usuarios',
      logout: 'Cerrar sesión',
    },
  },
}

export default esNavbar
