const ptTravelRequest = {
  travel_request: {
    confirm_answer: 'Confirmar respuesta',
    justify_reproval: 'Justificar la desaprobación',
    justify_reproval_reason: 'Describa el motivo (mínimo 10 y máximo 300)',
    justify_cancellation: 'Justificar la cancelación',
    approve: 'Aprobar SV',
    reprove: 'Desaprobar SV',
    approve_request: 'Aprobar solicitud',
    reprove_request: 'Desaprobar solicitud',
    do_you_want_to_approve: '¿Desea aprobar la solicitud?',
    do_you_want_to_reprove: '¿Desea desaprobar la solicitud?',
    do_you_want_to_cancel: '¿Desea cancelar la solicitud?',
    approval_description:
      'Al hacer clic en "Sí" la solicitud será emitida. Atención: esta acción solo puede ser cancelada por el equipo de gestión o backoffice.',
    reproval_description:
      'Al hacer clic en "Sí" la solicitud completa será desaprobada. Atención: esta acción no se puede deshacer.',
    cancel_description:
      'Al hacer clic en "Sí" la solicitud será cancelada. Atención: esta acción no se puede deshacer.',
    cancellation_description: 'Descripción del motivo de cancelación',
    type_here: 'Describa aquí (Mínimo de 10 y máximo de 300 caracteres)',
    choose_reason: 'Seleccione el motivo',
    yes: 'Sí',
    cancel: 'Cancelar',
    cancel_request: 'Cancelar Solicitud',
    cancel_reasons: {
      date_change: 'Cambio de fechas',
      flight_change: 'Cambio de vuelo',
      employee_terminated: 'Empleado despedido',
      incorrect_entries: 'Entradas incorrectas',
      duplicate_entries: 'Entradas duplicadas',
      expired_reservations: 'Reservas vencidas',
      trip_canceled: 'Viaje cancelado',
      other: 'Otros',
    },
    notification: {
      successfully_approved: '¡SV aprobada con éxito!',
      successfully_reproved: '¡SV desaprobada con éxito!',
      failed_to_approve: 'Error al aprobar la SV',
      failed_to_reprove: 'Error al desaprobar la SV',
    },
    flight: {
      direct: 'Directo',
      stop: 'parada',
      stops: 'paradas',
      hours: 'hrs',
      air: 'Aéreo',
      departure_ticket: 'Boleto de ida',
      arrival_ticket: 'Boleto de vuelta',
      total: 'Total',
      fare_per_segment: 'Tarifa por tramo',
    },
    summary: {
      requester: 'Solicitante',
      traveler: 'Viajero',
      origin: 'Origen',
      destination: 'Destino',
      request_date: 'Fecha de solicitud',
      travel_date: 'Fecha de viaje',
      consultant: 'Consultor',
      travel_cost: 'Costo del viaje',
      advance: 'Antelación',
      within_politics: 'Dentro de la política',
    },
    price_change: {
      text: 'El precio de la reserva ha cambiado de R$ {{old_amount}} a R$ {{amount}}.',
    },
  },
}

export default ptTravelRequest
