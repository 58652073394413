import styled from 'styled-components'

export const HeaderStyles = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .header_traveler {
  }

  .header_traveler_name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.0015em;
  }

  .header_traveler_role {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
  }

  .header_view_reservation {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  .header_view_reservation_text {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.01em;
  }

  .header_view_reservation_icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 42px;
    align-items: center;

    .header_traveler {
      align-items: center;
      text-align: center;
    }
  }
`
