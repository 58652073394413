import { API } from 'services/config'

const point = '/tenants/v1'

const getTenant = async (id: string) => {
  try {
    const response = await API.get(`${point}/tenant/${id}`)
    return response.data
  } catch (error) {
    return { error: 'Error on get tenant' }
  }
}

export { getTenant }
