import { config } from 'configs/vars'
import { urlBase64ToUint8Array } from 'utils/parseBase64'

const { REACT_APP_VAPID_PUBLIC_KEY } = config()

export async function registerServiceWorker() {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    try {
      const registration = await navigator.serviceWorker.register('/sw.js')
      return registration
    } catch (error) {
      console.error('Service Worker registration failed:', error)
      throw error
    }
  } else {
    return Promise.reject('Service Worker or Push Manager not supported')
  }
}
export async function subscribeToPushNotifications(
  swReg: ServiceWorkerRegistration,
) {
  return swReg.pushManager.getSubscription().then((subscription) => {
    if (subscription) {
      return subscription
    } else {
      const vapidPublicKey = REACT_APP_VAPID_PUBLIC_KEY as string
      const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey)

      return swReg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey,
      })
    }
  })
}
