export default `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 16" >
        <path d="M20.8008 6V2C20.8008 0.9 19.9008 0 18.8008 0H2.80078C1.70078 0 
        0.810781 0.9 0.810781 2V6C1.91078 6 2.80078 6.9 2.80078 8C2.80078 9.1 1.91078 
        10 0.800781 10V14C0.800781 15.1 1.70078 16 2.80078 16H18.8008C19.9008 16 20.8008 15.1 
        20.8008 14V10C19.7008 10 18.8008 9.1 18.8008 8C18.8008 6.9 19.7008 6 20.8008 6ZM18.8008 
        4.54C17.6108 5.23 16.8008 6.53 16.8008 8C16.8008 9.47 17.6108 10.77 18.8008 11.46V14H2.80078V11.46C3.99078 10.77 
        4.80078 9.47 4.80078 8C4.80078 6.52 4.00078 5.23 2.81078 4.54L2.80078 2H18.8008V4.54ZM7.87078 12L10.8008 10.12L13.7308 
        12L12.8408 8.64L15.5308 6.44L12.0608 6.23L10.8008 3L9.53078 6.22L6.06078 6.43L8.75078 8.63L7.87078 12Z" />
    </svg>
`
