const esMyTravels = {
  my_travels: {
    cancelled: {
      title: 'Cancelación de viaje',
      canceled_by_policy: 'Cancelado por la política de cancelación',
      cancelation_reason: 'Motivo de la cancelación',
    },
    cancel: {
      required_field: 'Campo obligatorio',
      cancel_reason: 'Motivo de la cancelación',
      detail_cancel_reason: 'Aclare los motivos de la cancelación',
      field_constraints: 'Mínimo de 10 y máximo de 300 caracteres.',
      cancel: 'Cancelar',
      proceed: 'Proceder',
    },
    dropdown: {
      download_in_en: 'Descargar en inglés',
      download_in_es: 'Descargar en español',
      download_in_pt: 'Descargar en portugués',
    },
    travels: {
      all: 'Todas',
      next: 'Próximos viajes',
      cancelled_travels: 'Viajes cancelados',
      previous_travels: 'Viajes anteriores',
      your_cancelled_travels: 'Sus viajes cancelados',
      your_previous_travels: 'Sus viajes anteriores',
      see_all: 'Ver todas',
      traveller: 'Viajero',
      flight_operator: 'Operadora del vuelo',
      status: 'Estado',
      locator: 'Localizador',
      see_all_details: 'Ver solicitud completa',
      cancel_travel: 'Cancelar viaje',
      download_voucher: 'Descargar voucher',
      cancellation_requested_by_employee: 'Cancelado a pedido del empleado',
      title: 'Mis viajes',
    },
    voucher_download_error: 'Não fue posible descargar el voucher.',
  },
}

export default esMyTravels
