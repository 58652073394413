const ptTravelRequest = {
  travel_request: {
    confirm_answer: 'Confirm answer',
    justify_reproval: 'Justify disapproval',
    justify_reproval_reason: 'Describe the reason (minimum 10 and maximum 300)',
    justify_cancellation: 'Justify cancellation',
    approve: 'Approve SV',
    reprove: 'Disapprove SV',
    approve_request: 'Approve request',
    reprove_request: 'Disapprove request',
    do_you_want_to_approve: 'Do you want to approve the request?',
    do_you_want_to_reprove: 'Do you want to disapprove the request?',
    do_you_want_to_cancel: 'Do you want to cancel the request?',
    approval_description:
      'By clicking "Yes" the request will be issued. Attention: this action can only be canceled by the management or backoffice team.',
    reproval_description:
      'By clicking "Yes" the entire request will be disapproved. Attention: this action cannot be undone.',
    cancel_description:
      'By clicking "Yes" the request will be canceled. Attention: this action cannot be undone.',
    cancellation_description: 'Cancellation reason description',
    type_here: 'Describe here (Minimum of 10 and maximum of 300 characters)',
    choose_reason: 'Select the reason',
    yes: 'Yes',
    cancel: 'Cancel',
    cancel_request: 'Cancel Request',
    cancel_reasons: {
      date_change: 'Date change',
      flight_change: 'Flight change',
      employee_terminated: 'Employee terminated',
      incorrect_entries: 'Incorrect entries',
      duplicate_entries: 'Duplicate entries',
      expired_reservations: 'Expired reservations',
      trip_canceled: 'Trip canceled',
      other: 'Other',
    },
    notification: {
      successfully_approved: 'SV successfully approved!',
      successfully_reproved: 'SV successfully disapproved!',
      failed_to_approve: 'Failed to approve the SV',
      failed_to_reprove: 'Failed to disapprove the SV',
    },
    flight: {
      direct: 'Direct',
      stop: 'stop',
      stops: 'stops',
      hours: 'hrs',
      air: 'Air',
      departure_ticket: 'Departure ticket',
      arrival_ticket: 'Return ticket',
      total: 'Total',
      fare_per_segment: 'Fare per segment',
    },
    summary: {
      requester: 'Requester',
      traveler: 'Traveler',
      origin: 'Origin',
      destination: 'Destination',
      request_date: 'Request date',
      travel_date: 'Travel date',
      consultant: 'Consultant',
      travel_cost: 'Travel cost',
      advance: 'Advance',
      within_politics: 'Within policy',
    },
    price_change: {
      text: 'The reservation price has changed from R$ {{old_amount}} to R$ {{amount}}',
    },
  },
}

export default ptTravelRequest
