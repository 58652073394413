import styled from 'styled-components'

export const SVFlightStyles = styled.div`
  width: 100%;
  padding: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .flight-container-header {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.005em;
  }

  .flight-cards {
    display: flex;
    gap: 24px;
  }

  .flight-card {
    display: flex;
    gap: 24px;
    justify-content: center;
  }

  .fare {
    width: 100%;
    border-top: 1px solid #999999;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 16px;

    .by-segment {
      display: flex;
      gap: 8px;
      align-items: center;

      .label {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.01em;
      }
      .value {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.005em;
      }
    }

    .total {
      display: flex;
      gap: 8px;
      align-items: center;

      .label {
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.0015em;
      }
      .value {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
      }
    }
  }
`
