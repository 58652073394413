import { useState, useEffect } from 'react'
import { STravelRequestDetails } from 'shared/interfaces/STravelRequestDetails'
import { getTravelRequestById } from 'services/travelRequest'
import { notifyError } from 'utils/handleRequestNotify'
import { getFromStorage } from 'utils/localStorage/storageUtils'
import { SMyProfile } from 'shared/interfaces/SMyProfile'
import { usePermissionsContext } from 'contexts/permissionsContext/usePermissionsContext'

interface State {
  details?: STravelRequestDetails
  loading: boolean
  isCostCenterApproverAndHasPermission: boolean
}

const approvePermissionId = '01HK59TVT702RXCMZ3B0EXB8T40'

const useFullRequest = (travelRequestId?: string) => {
  const [state, setState] = useState<State>({
    details: undefined,
    loading: false,
    isCostCenterApproverAndHasPermission: false,
  })

  const [status, setStatus] = useState<string>('teste')
  const { userPermissions } = usePermissionsContext()
  const myProfile: SMyProfile = getFromStorage('my_profile_data')

  const hasApprovePermission = () => {
    return (
      userPermissions?.some(
        (userPermission) => userPermission.id === approvePermissionId,
      ) ?? false
    )
  }

  const setDetails = (details: STravelRequestDetails) => {
    setState((prevState) => ({ ...prevState, details }))
  }

  const setLoading = (loading: boolean) => {
    setState((prevState) => ({ ...prevState, loading }))
  }

  const setApprovePermission = (
    isCostCenterApproverAndHasPermission: boolean,
  ) => {
    setState((prevState) => ({
      ...prevState,
      isCostCenterApproverAndHasPermission,
    }))
  }

  const fetchDetails = async (id: string) => {
    setLoading(true)

    try {
      const response = await getTravelRequestById(id)
      if (response) {
        setDetails(response)
        setStatus(response.travelRequest.status)
        const travelRequest = response.travelRequest
        const approvers =
          travelRequest.debitAllocations[0]?.costCenters[0]?.costCenter
            ?.approvals
        if (approvers) {
          const isApprover = approvers.some(
            (approver: any) => approver.approver_id === myProfile.id,
          )
          setApprovePermission(isApprover && hasApprovePermission())
        }
      }
    } catch (error) {
      notifyError()
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (travelRequestId) {
      fetchDetails(travelRequestId)
    }
  }, [travelRequestId])

  return {
    state,
    setDetails,
    setLoading,
    fetchDetails,
    status,
    setStatus,
  }
}

export default useFullRequest
