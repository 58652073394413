import styled from 'styled-components'

export const DebtAllocationStyles = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  gap: 32px;
  margin-bottom: 100px;

  .form_container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .checkbox {
      padding: 0 0 32px 0;

      .second-cost-center {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 24px;
        background: #ececec;
        width: fit-content;
        padding: 8px 16px;
        border-radius: 8px;
        cursor: default;

        .text {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0.0025em;

          span {
            font-weight: 400;
            margin-left: 8px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 24px;
  }
`

export const SearchBar = styled.div`
  .custom-antd-input {
    padding: 1.388vw 1.666vw 1.388vw 1.666vw;
    border-radius: 0.555vw;
    border: 0.138vw solid #999999;
  }
  .ant-input {
    margin-right: 0.555vw;
  }
  .ant-input-suffix {
    margin: 0;
  }
`
