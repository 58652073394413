import React from 'react'
import { MyProfileContainer } from './styles'
import { SMyProfile } from 'shared/interfaces/SMyProfile'
import { formatDate, formatCPF, formatRG } from 'utils/formatters'
import { useTranslation } from 'react-i18next'

interface UserInfoProps {
  userData: SMyProfile | null
}

const UserInfo: React.FC<UserInfoProps> = ({ userData }) => {
  const { t } = useTranslation()
  return (
    <MyProfileContainer>
      <h2 className="container-title">{t('myProfile.personalData.header')}</h2>
      <div className="container-wrapper">
        {userData && (
          <>
            <div className="user-info-box">
              <p className="user-info-label">
                {t('myProfile.personalData.fullName')}
              </p>
              <p className="user-info">{userData.name}</p>
            </div>
            <div className="user-info-box">
              <p className="user-info-label">
                {t('myProfile.personalData.dateOfBirth')}
              </p>
              <p className="user-info">{formatDate(userData.birth_date)}</p>
            </div>
            <div className="user-info-box">
              <p className="user-info-label">
                {t('myProfile.personalData.cpf')}
              </p>
              <p className="user-info">{formatCPF(userData.cpf)}</p>
            </div>
            <div className="user-info-box">
              <p className="user-info-label">
                {t('myProfile.personalData.rg')}
              </p>
              <p className="user-info">{formatRG(userData.rg)}</p>
            </div>
          </>
        )}
      </div>
    </MyProfileContainer>
  )
}

export default UserInfo
