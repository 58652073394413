import React from 'react'
import Button from 'components/button'
import TextArea from 'components/TextArea'
import { useTranslation } from 'react-i18next'

export interface SVDisapproveFormProps {
  onCancel: () => void
  onConfirm: () => void
  justification: string
  onJustificationChange: (value: string) => void
}

const SVDisapproveForm: React.FC<SVDisapproveFormProps> = ({
  onCancel,
  onConfirm,
  justification,
  onJustificationChange,
}) => {
  const { t } = useTranslation()
  const isConfirmDisabled = justification.length < 10

  return (
    <div className="form">
      <TextArea
        label={t('travel_request.justify_reproval')}
        placeholder={t('travel_request.justify_reproval_reason')}
        height="180px"
        onChange={(e) => onJustificationChange(e.target.value)}
        minLength={10}
        maxLength={300}
      />
      <div className="buttons">
        <Button
          buttonType="secondary"
          size="sm"
          width="100px"
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button
          size="sm"
          width="165px"
          disabled={isConfirmDisabled}
          onClick={onConfirm}
        >
          {t('travel_request.confirm_answer')}
        </Button>
      </div>
    </div>
  )
}

export default SVDisapproveForm
