import { SmilesIcon } from 'components/icons'
import React from 'react'

const AirlineIcon: React.FC = () => (
  <div className="airline-icon">
    <SmilesIcon type="gol" />
  </div>
)

export default AirlineIcon
