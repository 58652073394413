import React from 'react'
import { Total } from 'components/summaryModalContent/styles'
import { formatToReal } from 'utils/formatToReal/formateToReal'
import { useTranslation } from 'react-i18next'

export type RenderTotalProps = {
  total: number
}

const RenderTotal: React.FC<RenderTotalProps> = ({ total }) => {
  const { t } = useTranslation()
  return (
    <Total data-testid="render-cart-total">
      <p className="total">
        {t('component.request_summary.total')}{' '}
        <b className="amount">{formatToReal(total)}</b>
      </p>
    </Total>
  )
}

export default RenderTotal
