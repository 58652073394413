const ptMyProfile = {
  myProfile: {
    header: 'Meus dados',
    personalData: {
      header: 'Dados Pessoais',
      fullName: 'Nome completo',
      dateOfBirth: 'Data de nascimento',
      cpf: 'CPF',
      rg: 'RG',
    },
    contact: {
      header: 'Contato',
      cellPhone: 'Celular',
      altPhone: 'Telefone alternativo',
    },
    editButton: {
      edit: 'Editar',
      cancel: 'Cancelar',
      save: 'Salvar',
    },
  },
}
export default ptMyProfile
