import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { SimpleTab, SimpleTabsWrapper } from './styles'

export type SimpleTabsProps = {
  tabs: { label: string }[]
  onClick: (idx: number) => void
  currentTab?: number
}

const SimpleTabs: React.FC<SimpleTabsProps> = ({
  tabs,
  onClick,
  currentTab = 0,
}) => {
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    setActiveTab(currentTab)
  }, [currentTab])

  return (
    <SimpleTabsWrapper data-testid="smiles-simple-tabs">
      {tabs.map((tab, idx) => {
        return (
          <SimpleTab
            key={uuid()}
            $active={idx === activeTab}
            data-testid="smiles-simple-tab-item"
            onClick={() => {
              setActiveTab(idx)
              onClick(idx)
            }}
          >
            <p>{tab.label}</p> <div></div>
          </SimpleTab>
        )
      })}
    </SimpleTabsWrapper>
  )
}

export default SimpleTabs
