import React from 'react'

import { useTranslation } from 'react-i18next'

import { HeaderStyles } from './styles'
import { SmilesIcon } from 'components/icons'

export type DebtAllocationHeaderProps = {
  name?: string
  role: string
  onClick?: any
}

const Header: React.FC<DebtAllocationHeaderProps> = ({
  name,
  role,
  onClick,
}) => {
  const { t } = useTranslation()
  return (
    <HeaderStyles data-testid="debt-allocation-header">
      <div className="header_traveler">
        <div className="header_traveler_name">
          <SmilesIcon type="profile" size={22} />
          {name}
        </div>
        <div className="header_traveler_role">{role}</div>
      </div>
      <button
        onClick={onClick}
        className="header_view_reservation global-button-reset"
      >
        <div className="header_view_reservation_text">
          {t('viewReservation.resumeTitle')}
        </div>
        <div className="header_view_reservation_icon">
          <SmilesIcon size={22} type="travel" />
        </div>
      </button>
    </HeaderStyles>
  )
}

export default Header
