import React from 'react'
import { SVFlightStyles } from './styles'
import { SmilesIcon } from 'components/icons'
import SVFlightItem from './svFlightItem'
import { TravelRequest } from 'shared/interfaces/STravelRequestDetails'
import { formatToReal } from 'utils/formatToReal/formateToReal'
import { useTranslation } from 'react-i18next'

type SVFlightProps = {
  travelRequest: TravelRequest
}

const SVFlight: React.FC<SVFlightProps> = ({ travelRequest }) => {
  const { t } = useTranslation()
  return (
    <SVFlightStyles data-testid="sv-flight">
      <div className="flight-container-header">
        <SmilesIcon type="airplanes" />
        <p>{t('travel_request.flight.air')}</p>
      </div>
      {travelRequest.flights?.length > 1 && (
        <div className="flight-cards">
          <SVFlightItem
            flightTravelRequest={{
              ...travelRequest.flights[0],
              originCity: travelRequest.origin,
              destinationCity: travelRequest.destination,
            }}
            route={t('travel_request.flight.departure_ticket')}
          />
          <SVFlightItem
            flightTravelRequest={{
              ...travelRequest.flights[1],
              originCity: travelRequest.destination,
              destinationCity: travelRequest.origin,
            }}
            route={t('travel_request.flight.arrival_ticket')}
          />
        </div>
      )}
      {travelRequest.flights?.length == 1 && (
        <div className="flight-card">
          <SVFlightItem
            width="50%"
            flightTravelRequest={{
              ...travelRequest.flights[0],
              originCity: travelRequest.origin,
              destinationCity: travelRequest.destination,
            }}
            route={t('travel_request.flight.departure_ticket')}
          />
        </div>
      )}
      <div className="fare">
        <div className="by-segment">
          <p className="label">
            {t('travel_request.flight.fare_per_segment')}:
          </p>
          <p className="value">
            {formatToReal(travelRequest.flights[0]?.amount)}
          </p>
        </div>
        <div className="total">
          <p className="label">{t('travel_request.flight.total')}:</p>
          <p className="value">{formatToReal(travelRequest?.amount)}</p>
        </div>
      </div>
    </SVFlightStyles>
  )
}

export default SVFlight
