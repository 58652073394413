const esMyRequests = {
  myRequests: {
    'not-found': 'No se encontraron solicitudes.',
    requests: 'Solicitudes',
    filter: {
      'filter-requests': 'Filtrar solicitudes',
      only_my_requests: 'Mis solicitudes',
      only_my_travels: 'Mis viajes',
      requests_for_other_travelers: 'Solicitudes para otros viajeros',
      'pending-approval-travel-requests':
        'Solicitudes pendientes de aprobación',
    },
    status: {
      'all-statuses': 'Todos los status',
      waiting_for_approval: 'En espera de aprobación',
      approved: 'Aprobadas',
      confirmed: 'Confirmada',
      rejected: 'Rechazadas',
      canceled: 'Canceladas',
      opened: 'Abierto',
    },
    order: {
      'order-requests': 'Ordenar',
      'recent-requests': 'Solicitudes más recientes',
      'older-requests': 'Solicitudes más antiguas',
      'urgent-requests': 'Solicitudes urgentes',
      'close-expiration-requests': 'Solicitudes próximas a vencerse',
    },
    'search-travel-request': 'Buscar SV',
    card: {
      destination: 'Destino',
      requester: 'Solicitante',
      traveler: 'Viajero',
      'travel-price': 'Valor del viaje',
      'more-information': 'Ver solicitud completa',
    },
  },
}

export default esMyRequests
