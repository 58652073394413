export default `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.36328 2.99333C4.36328 4.45999 5.56328 5.65999 7.02995 
        5.65999C8.49661 5.65999 9.69661 4.45999 9.69661 2.99333C9.69661 1.52666 8.49661 0.32666 7.02995 0.32666C5.56328 
        0.32666 4.36328 1.52666 4.36328 2.99333ZM5.69661 2.99333C5.69661 2.25999 6.29661 1.65999 7.02995 1.65999C7.76328 
        1.65999 8.36328 2.25999 8.36328 2.99333C8.36328 3.72666 7.76328 4.32666 7.02995 4.32666C6.29661 4.32666 5.69661 3.72666 
        5.69661 2.99333ZM11.7634 11.2603C11.83 11.527 12.0967 11.6603 12.3634 11.6603C12.43 11.6603 12.5634 11.6603 12.5634 
        11.5937C12.9634 11.4603 13.0967 11.0603 12.9634 10.727C12.961 10.7222 12.9567 10.7128 12.9505 10.6992C12.7866 10.3375 
        11.2706 6.99365 7.03005 6.99365C3.09008 6.99365 1.50213 9.88023 1.11742 10.5796C1.0725 10.6612 1.04399 10.713 1.03005 10.727C0.896712 
        11.0603 1.09671 11.4603 1.43005 11.5937C1.76338 11.7937 2.16338 11.5937 2.29671 11.2603C2.29889 11.256 2.30228 11.2488 
        2.30691 11.2389C2.44417 10.9471 3.67659 8.32699 7.03005 8.32699C10.3835 8.32699 11.6159 10.9471 11.7532 11.2389C11.7578 
        11.2488 11.7612 11.256 11.7634 11.2603Z" />
    </svg>
`
