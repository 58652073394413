import styled from 'styled-components'

export const FlightRequestSummaryStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 32px;
  gap: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 16px;

  @media screen and (max-width: 745px) {
    .flights-info {
      flex-direction: column;
    }
  }
`
