import { useState, useEffect } from 'react'
import { getUser } from 'services/users'
import { SMyProfile } from 'shared/interfaces/SMyProfile'

const useProfileData = () => {
  const [userData, setUserData] = useState<SMyProfile | null>(null)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [newPhoneNumber, setNewPhoneNumber] = useState<string>('')
  const [newSecondaryPhoneNumber, setNewSecondaryPhoneNumber] =
    useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const getMyProfileData = async () => {
    try {
      const response = await getUser()
      if (response && !response.error) {
        setUserData(response)
        setNewPhoneNumber(response.phone_1)
        setNewSecondaryPhoneNumber(response.phone_2)
      } else {
        throw new Error(response.error || 'Error fetching user data')
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(err.message)
      } else {
        setError('An unknown error occurred')
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getMyProfileData()
  }, [])

  const isPhoneNumberValid = (phoneNumber: string, isPrimary: boolean) => {
    const digitsOnly = phoneNumber.replace(/\D/g, '')
    if (isPrimary) {
      return digitsOnly.length === 11
    }
    return digitsOnly.length === 10 || 11
  }

  const isSaveButtonDisabled = () => {
    if (!userData) {
      return true
    }

    const isPrimaryPhoneValid = isPhoneNumberValid(newPhoneNumber, true)
    const isSecondaryPhoneValid = isPhoneNumberValid(
      newSecondaryPhoneNumber,
      false,
    )

    if (
      (userData.phone_1 && !newPhoneNumber) ||
      (userData.phone_2 && !newSecondaryPhoneNumber)
    ) {
      return true
    }

    if (
      !isPrimaryPhoneValid ||
      !newPhoneNumber ||
      !isSecondaryPhoneValid ||
      !newSecondaryPhoneNumber
    ) {
      return true
    }

    if (
      !userData.phone_2 &&
      isPrimaryPhoneValid &&
      newPhoneNumber !== userData.phone_1
    ) {
      return false
    }

    const isPrimaryPhoneChanged = newPhoneNumber !== userData.phone_1
    const isSecondaryPhoneChanged = newSecondaryPhoneNumber !== userData.phone_2

    return (
      !(isPrimaryPhoneChanged && isPrimaryPhoneValid) &&
      !(isSecondaryPhoneChanged && isSecondaryPhoneValid)
    )
  }

  return {
    userData,
    isEditing,
    newPhoneNumber,
    newSecondaryPhoneNumber,
    error,
    isLoading,
    setIsEditing,
    setNewPhoneNumber,
    setNewSecondaryPhoneNumber,
    isSaveButtonDisabled,
  }
}

export default useProfileData
