import React from 'react'

import { useTranslation } from 'react-i18next'

import { BookingCompletedCardStyles } from './styles'

import { SmilesIcon } from 'components/icons'

const BookingCompletedCard: React.FC = () => {
  const { t } = useTranslation()

  return (
    <BookingCompletedCardStyles data-testid="smiles-booking-completed-card">
      <div className="content">
        <div className="header">
          <SmilesIcon type="check-circle-outline" />
          {t('reservationCompleted.title')}
        </div>
        <div className="text">{t('reservationCompleted.text')}</div>
      </div>
    </BookingCompletedCardStyles>
  )
}

export default BookingCompletedCard
