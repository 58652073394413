import styled from 'styled-components'

export const MainContainerStyles = styled.section<{ $clearpaddingx?: boolean }>`
  display: flex;
  justify-content: center;
  padding: 64px 156px;
  width: 100%;
  > div {
    width: 100%;
    max-width: 1440px;
  }

  ${({ $clearpaddingx }) =>
    $clearpaddingx &&
    `
    padding: 0 156px;
  `}

  @media screen and (max-width: 1270px) {
    padding: 64px 24px;

    ${({ $clearpaddingx }) =>
      $clearpaddingx &&
      `
      padding: 0 24px;
    `}
  }
`
