import React, { useEffect, useState } from 'react'
import {
  FlightPage,
  FlightPageContent,
  FlightsSection,
  OpenFiltersButton,
} from './styles'
import SimpleTabs from 'components/simpleTabs'
import FlightFilterSection from './components/flightFilterSection'
import {
  Flight,
  FlightAvailabilityResponse,
  FlightRoute,
} from 'services/flights/flightModel'
import { FLightRequestParams, getFlights } from 'services/flights'
import { filterFlightsByRoute } from './helpers/filterFlightsByRoute'
import RenderFlights from './components/renderFlights'
import { useFlowContext } from 'contexts/flowContext/useFlowContext'
import { useTranslation } from 'react-i18next'
import Skeleton from 'components/skeleton'
import Drawer from 'components/drawer'
import MobileFilterSection from './components/mobileFilterSection'
import { destroyCustomCookie } from 'utils/cookies/cookiesUtils'

export type FlightsData = {
  departure: (FlightRoute | undefined)[]
  return: (FlightRoute | undefined)[]
}

export const getSelectedFlight = (flights: (FlightRoute | undefined)[]) => {
  return flights[0]?.flights[0] ?? ({} as Flight)
}

const Flights: React.FC = () => {
  const {
    setSelectedFlights,
    setLoading,
    loading,
    searchFlights,
    setTravelRequestId,
  } = useFlowContext()
  const { t } = useTranslation()

  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [flights, setFlights] = useState<FlightsData>({
    departure: [],
    return: [],
  })

  const handleFlightTab = (idx: number) => {
    setCurrentTabIndex(idx)
  }

  const getFlightsData = async () => {
    if (!searchFlights) {
      return
    }

    setLoading(true)

    try {
      destroyCustomCookie('travel_request_id')
      setTravelRequestId(undefined)

      const params: FLightRequestParams = {
        origin: searchFlights.origin.value,
        destination: searchFlights.destination.value,
        start: searchFlights.start,
        occupancy: searchFlights.occupancy,
      }

      if (searchFlights.end) {
        params.end = searchFlights.end
      }

      const res: FlightAvailabilityResponse = await getFlights(params)

      if (res?.flightAvail?.length > 0) {
        const departureFlights = filterFlightsByRoute(res.flightAvail, 1)
        const returnFlights = filterFlightsByRoute(res.flightAvail, 2)

        setFlights({ departure: departureFlights, return: returnFlights })
        setSelectedFlights({
          departure: getSelectedFlight(departureFlights),
          return: getSelectedFlight(returnFlights),
        })
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const renderFlightsOrSkeleton = () => {
    if (loading) {
      return <Skeleton isLoading />
    }

    if (currentTabIndex === 0) {
      return (
        <RenderFlights
          flightData={flights.departure}
          isLoading={loading}
          flightType="departure"
        />
      )
    }

    if (currentTabIndex === 1) {
      return (
        <RenderFlights
          flightData={flights.return}
          isLoading={loading}
          flightType="return"
        />
      )
    }

    return null
  }

  const handleDrawer = (flag: boolean) => {
    setOpenDrawer(flag)
  }

  useEffect(() => {
    getFlightsData()
  }, [searchFlights])

  const tabs = [
    { label: t('flights.tabs.outboundFlight') },
    { label: t('flights.tabs.returnFlight') },
  ]

  return (
    <FlightPage data-testid="smiles-flights-page">
      <FlightPageContent>
        <div className="hidden-in-mobile">
          <FlightFilterSection />
        </div>

        <FlightsSection>
          <OpenFiltersButton onClick={() => handleDrawer(true)}>
            Filtros
          </OpenFiltersButton>

          <div>
            {searchFlights?.end && (
              <SimpleTabs tabs={tabs} onClick={handleFlightTab} />
            )}

            {renderFlightsOrSkeleton()}
          </div>
        </FlightsSection>
      </FlightPageContent>

      <Drawer onClose={() => handleDrawer(false)} open={openDrawer} width={470}>
        <MobileFilterSection onConfirm={() => handleDrawer(false)} />
      </Drawer>
    </FlightPage>
  )
}

export default Flights
