const esLogin = {
  login: {
    accessYourAccount: 'Accede a tu cuenta',
    form: {
      tenantInput: {
        label: 'Organización',
        placeholder: 'Ingrese el nombre de su organización',
        required: '*Campo obligatorio',
      },
      loginButton: 'Acceder',
    },
    errors: {
      login: {
        msg: '¡Ops!',
        desc: 'Algo salió mal, por favor inténtalo de nuevo más tarde.',
      },
      tenant: {
        msg: '¡Organización no válida!',
        desc: 'Verifica la información e inténtalo de nuevo.',
      },
    },
  },
}

export default esLogin
