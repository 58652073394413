import styled from 'styled-components'

export const RequestCostStyles = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #d9d9d9;
  justify-content: space-between;

  .content_unitary {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    gap: 8px;
  }

  .content_unitary_text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
  }

  .content_unitary_value {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
  }

  .content_total {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
  }

  .content_total_text {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.0015em;
  }

  .content_total_value {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }
`
