import { StatusEnum } from 'constants/statusEnum'
import { TypeEnum } from 'constants/typeEnum'
import dayjs from 'dayjs'
import { API } from 'services/config'
import { SMyTravel } from 'shared/interfaces/SMyTravel'

type FlightsInput = {
  departure_time?: string
}

type MyTravelsINput = {
  id?: string
  reservation_number?: string
  destination?: string
  flights: FlightsInput
  locator?: string
}

type RequesterInput = {
  name?: string
}

type MyTravelsParams = {
  status: StatusEnum[]
  limit?: number
  page?: number
  previous?: boolean
}

type TravelReqInput = {
  id?: string
  reservation_number?: string
  requester?: RequesterInput
  created_at?: string
  destination?: string
  status?: string
}

const approvalEndpoint = '/approval/v1/travel-request'
const point = '/open-travel-request/v1/travel-request'

const getMyTravels = async ({
  status,
  previous,
}: MyTravelsParams): Promise<SMyTravel[]> => {
  try {
    const params = new URLSearchParams()
    status.forEach((s) => params.append('status', s.toString()))
    if (previous) {
      params.append('previous', 'true')
    }

    const myTravelsUrl = `${point}/my-travels?${params.toString()}`
    const response = await API.get(myTravelsUrl)

    return response.data.data ?? []
  } catch (error) {
    console.error('Error fetching travels:', error)
    throw new Error('Error fetching my travels')
  }
}

const getTravelRequests = async (
  status?: StatusEnum[],
  order?: string,
  searchQuery?: string,
  types?: TypeEnum[],
) => {
  let statusFilter = ''
  status?.forEach((s, index) => {
    statusFilter += `status=${s.toString()}`

    if (index !== status.length - 1) {
      statusFilter += '&'
    }
  })

  order = order ? `&order=${order}` : ''
  searchQuery = searchQuery ? `&search=${searchQuery}` : ''

  let typeFilter = ''
  types?.forEach((type) => {
    typeFilter += `&filter=${type.toString()}`
  })

  try {
    const travelRequestsEndpoint = `/open-travel-request/v1/travel-request?${statusFilter}${order}${typeFilter}${searchQuery}`
    const response = await API.get(travelRequestsEndpoint)
    return response.data
  } catch (error) {
    throw new Error('Failed to get data')
  }
}

const emptyData = 'n/a'

const adaptTravelReqsToReqsTable = (travel: TravelReqInput) => ({
  key: travel.id ?? emptyData,
  svNumber: travel.reservation_number ?? emptyData,
  requester: travel.requester?.name ?? emptyData,
  requestDate: dayjs(travel.created_at).format('DD/MM/YYYY') ?? emptyData,
  destinationCity: travel.destination ?? emptyData,
  status: travel.status,
})

const adaptMyTravelsToMyTravelsTable = (travel: MyTravelsINput) => ({
  key: travel.id ?? 'n/a',
  departureCity: travel.destination ?? 'n/a',
  travelDate:
    dayjs(travel.flights?.departure_time).format('DD/MM/YYYY') ?? 'n/a',
  locator: travel.locator ?? 'n/a',
})

type FetchTravelProps = {
  status?: StatusEnum[]
  order?: string
  type?: TypeEnum[]
  searchQuery?: string
  maxItems?: number
  adapterDataFn?: any
  setLoading?: any
  setDataFn?: any
}

const fetchTravelData = async (props: FetchTravelProps) => {
  try {
    const response = await getTravelRequests(
      props.status,
      props.order,
      props.searchQuery,
      props.type,
    )
    const adaptedResponse = response.data
      .map(props.adapterDataFn)
      .slice(0, props.maxItems)
    props.setDataFn(adaptedResponse)
  } catch (error) {
    console.log('Error fetching data:', error)
  } finally {
    props.setLoading(false)
  }
}

const getTravelRequestById = async (id: string) => {
  try {
    const response = await API.get(`${approvalEndpoint}/${id}`)
    return response.data
  } catch (error) {
    throw new Error('Error on get travel request')
  }
}

const closeTravelRequest = async (travelRequestId?: string) => {
  const endpoint = '/close-travel-request/v1/close'
  const response = await API.post(endpoint, { travelRequestId })
  return response?.data
}

export {
  getTravelRequests,
  fetchTravelData,
  adaptTravelReqsToReqsTable,
  adaptMyTravelsToMyTravelsTable,
  getTravelRequestById,
  closeTravelRequest,
  getMyTravels,
  StatusEnum,
}
