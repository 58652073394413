import styled from 'styled-components'
import InputMask from 'react-input-mask'
import { Form } from 'antd'
import { Color, Size } from 'styles/global'

export const HandleCompanyFormContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;

  .header {
    width: 100%;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.08px;
    position: absolute;
    z-index: 1;
    background-color: #fff;
  }

  .form-container {
    margin-top: 72px;
  }

  .switch-container {
    display: flex;
    font-size: 16px;
    font-weight: 700;
    gap: 16px;
    align-items: center;

    span {
      padding-top: 4px;
    }
  }

  .two-columns {
    > * {
      flex-grow: 2;
    }
    display: flex;
    width: 100%;
    gap: 16px;

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  .form-container {
    margin-top: 48px;
    margin-bottom: 96px;
    z-index: 0;
    display: flex;
    flex-direction: column;
  }

  .form-row {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .outsourced-title {
    width: 100%;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.08px;
  }

  .outsourced-description {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 16px 0px 16px 0px;
  }

  .form-bottom-container {
    display: flex;
    justify-content: end;
    margin-bottom: 24px;
  }
`

export const StyledFormItem = styled(Form.Item)`
  display: flex;
  align-items: center;
  margin: 0;
`

export const PhoneInputWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 1rem;
  }
`

export const StyledInputMask = styled(InputMask)`
  width: 100%;
  height: 4rem;
  border: 0.125rem solid #999999;
  border-radius: 0.5rem;
  padding: 1.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: #595959;
  outline: none;

  @media (max-width: 820px) {
    width: 100%;
    height: 3rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`

export const ActionButton = styled.button`
  display: flex;
  height: 44px;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #737373;
  border-radius: 16px;
  padding: 8px 16px 8px 18px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  svg {
    margin-top: 2px;
  }
`
export const CardList = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Card = styled.div`
  border: 1px solid ${Color.LIGHT_BORDER};
  border-radius: ${Size.BORDER_RADIUS};
  padding: 12px;
`

export const RowContainer = styled.div`
  padding: 8px;
  height: 72px;
  display: flex;
  gap: 32px;
  align-items: center;

  > div:last-child {
    flex-grow: 2;
    display: flex;
    justify-content: end;
  }
`

export const Column = styled.div`
  p {
    font-size: 16px;
    font-color: #595959;
  }
`
export const CardAction = styled.div`
  display: flex;
  gap: 16px;
  cursor: pointer;
`

export const EmployeesList = styled.div`
  margin: 32px 0 32px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`
