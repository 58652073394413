import { notify } from 'components/notify'

export const notifyError = (desc?: string) => {
  notify({
    msg: 'Ops!',
    desc: desc ?? 'Ocorreu um erro inesperado, tente novamente mais tarde.',
    type: 'error',
  })
}

export const notifySuccess = (desc?: string) => {
  notify({
    msg: 'Sucesso!',
    desc: desc ?? 'A ação foi realizada com sucesso.',
    type: 'success',
  })
}
