import React from 'react'
import { CancelTravelModalContent } from './styles'
import DrawerBottomNavigation from 'components/drawerBottomNavigation'
import Button from 'components/button'
import TextArea from 'components/TextArea'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'

export type CancelTravelModalProps = {
  close: () => void
}

const CancelTravelModal: React.FC<CancelTravelModalProps> = ({ close }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  return (
    <CancelTravelModalContent data-testid="smiles-cancel-travel-modal">
      <div className="main">
        <p className="title">cara</p>

        <Form form={form} data-testid="smiles-cancel-travel-form">
          <Form.Item
            name="reason"
            rules={[
              {
                required: true,
                message: t('my_travels.cancel.required_field'),
                min: 10,
                max: 300,
              },
            ]}
          >
            <TextArea
              label={t('my_travels.cancel.cancel_reason')}
              placeholder={t('my_travels.cancel.detail_cancel_reason')}
              maxLength={300}
              minLength={10}
              rows={6}
            />
          </Form.Item>
          <p className="area-limit">
            {t('my_travels.cancel.field_constraints')}
          </p>
        </Form>
      </div>

      <DrawerBottomNavigation>
        <Button buttonType="secondary" onClick={close}>
          {t('my_travels.cancel.cancel')}
        </Button>
        <Button>{t('my_travels.cancel.proceed')}</Button>
      </DrawerBottomNavigation>
    </CancelTravelModalContent>
  )
}

export default CancelTravelModal
