const esUserGroups = {
  userGroups: {
    pageTitle: 'Grupos de Usuarios',
    searchGroupPlaceholder: 'Buscar nombre de grupo',
    newGroupButton: 'Nuevo grupo',
    breadcrumb: {
      home: 'Inicio',
      userGroups: 'Grupos de Usuarios',
    },
    notify: {
      groupRemoved: 'El grupo ha sido eliminado.',
    },
    table: {
      columns: {
        groupName: 'Nombre del grupo',
        description: 'Descripción',
        action: 'Acción',
      },
      deleteGroupPopConfirm: {
        title: 'Eliminar grupo',
        description: 'Esta acción es irreversible, ¿desea continuar?',
        okText: 'Sí',
        cancelText: 'Cancelar',
      },
    },
    alert: {
      noPermToViewGroupUsers: 'No tienes permiso para ver grupos de usuarios.',
    },
  },
  handleGroup: {
    newUserGroups: 'Nuevo grupo de usuarios',
    editUserGroups: 'Editar grupo de usuarios',
    groupPermissions: 'Permisos del grupo',
    form: {
      groupName: {
        label: 'Nombre del grupo',
        placeholder: 'Ingrese el nombre del grupo',
        required: 'Campo obligatorio',
      },
      groupDescription: {
        label: 'Descripción',
        placeholder: 'Breve descripción del grupo',
        required: 'Campo obligatorio',
      },
      permissions: {
        label: 'Permisos',
        placeholder: 'Seleccionar permisos',
      },
      confirmButton: 'Confirmar',
    },
    permissions: {
      permissions: 'Permisos',
      users: 'Usuarios',
      'travel-request': 'Solicitud de viaje',
      'debit-allocation': 'Asignación de débito',
      'close-travel-request': 'Cierre de solicitud de viaje',
      approval: 'Aprobación',
    },
    alert: {
      noPermToViewGroupPermissions:
        'No tienes permiso para ver permisos del grupo.',
      noPermToUpdateGroupPermissions:
        'No tienes permiso para actualizar permisos del grupo.',
    },
  },
}

export default esUserGroups
