import { useTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'
import { approveSV, disapproveSV } from 'services/approval'
import { notifyError, notifySuccess } from 'utils/handleRequestNotify'
import { useNavigate } from 'react-router-dom'

interface State {
  openApproveDoubleCheck: boolean
  openDisapproveDoubleCheck: boolean
  showDisapproveForm: boolean
  disapproveJustification: string
  loading: boolean
}

const useSVApproval = (fetchDetails: any) => {
  const [state, setState] = useState<State>({
    openApproveDoubleCheck: false,
    openDisapproveDoubleCheck: false,
    showDisapproveForm: false,
    disapproveJustification: '',
    loading: false,
  })

  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleApprove = useCallback(
    async (travelRequestId: string, checkRateId: number) => {
      setLoading(true)
      try {
        await approveSV(travelRequestId, checkRateId)

        setOpenApproveDoubleCheck(false)

        notifySuccess(t('travel_request.notification.successfully_approved'))
        await fetchDetails(travelRequestId)
        navigate('/')
      } catch {
        setOpenApproveDoubleCheck(false)
        notifyError(t('travel_request.notification.failed_to_approve'))
        await fetchDetails(travelRequestId)
      } finally {
        setLoading(false)
      }
    },
    [fetchDetails],
  )

  const handleDisapprove = useCallback(
    async (travelRequestId: string) => {
      setLoading(true)
      try {
        await disapproveSV(
          travelRequestId,
          'OTHER',
          state.disapproveJustification,
        )

        setOpenDisapproveDoubleCheck(false)
        setShowDisapproveForm(false)

        notifySuccess(t('travel_request.notification.successfully_reproved'))

        await fetchDetails(travelRequestId)
      } catch {
        notifyError(t('travel_request.notification.failed_to_reprove'))
      } finally {
        setLoading(false)
      }
    },
    [state.disapproveJustification, fetchDetails],
  )

  const setOpenApproveDoubleCheck = (value: boolean) => {
    setState((prevState) => ({ ...prevState, openApproveDoubleCheck: value }))
  }

  const setOpenDisapproveDoubleCheck = (value: boolean) => {
    setState((prevState) => ({
      ...prevState,
      openDisapproveDoubleCheck: value,
    }))
  }

  const setShowDisapproveForm = (value: boolean) => {
    setState((prevState) => ({ ...prevState, showDisapproveForm: value }))
  }

  const setDisapproveJustification = (value: string) => {
    setState((prevState) => ({ ...prevState, disapproveJustification: value }))
  }

  const setLoading = (flag: boolean) => {
    setState((prevState) => ({ ...prevState, loading: flag }))
  }

  return {
    state,
    handleApprove,
    handleDisapprove,
    setOpenApproveDoubleCheck,
    setOpenDisapproveDoubleCheck,
    setShowDisapproveForm,
    setDisapproveJustification,
    setLoading,
  }
}

export default useSVApproval
