import React from 'react'
import { useTranslation } from 'react-i18next'
import { TotalRequestValueStyles } from './styles'

export type TotalRequestValueProps = {
  currency: string
  total: string
}

const TotalRequestValue: React.FC<TotalRequestValueProps> = ({
  currency,
  total,
}) => {
  const { t } = useTranslation()

  return (
    <TotalRequestValueStyles data-testid="smiles-total-request-cost">
      <div className="content">
        <span className="text">{t('totalRequestValue.totalValue')}</span>
        <div className="total">
          {t('component.request_summary.total')}
          <span className="value">
            {currency} {total}
          </span>
        </div>
      </div>
    </TotalRequestValueStyles>
  )
}

export default TotalRequestValue
