import React from 'react'
import { MobileFilterSectionStyles } from './styles'
import FlightFilterSection from '../flightFilterSection'
import Button from 'components/button'
import { DrawerBottomNavigationStyled } from 'components/drawerBottomNavigation/styles'
import { useTranslation } from 'react-i18next'

type MobileFilterSectionProps = {
  onConfirm: () => void
}

const MobileFilterSection: React.FC<MobileFilterSectionProps> = ({
  onConfirm,
}) => {
  const { t } = useTranslation()

  return (
    <MobileFilterSectionStyles data-testid="smiles-mobile-flight-filters-section">
      <div className="content">
        <FlightFilterSection />
      </div>

      <DrawerBottomNavigationStyled>
        <Button onClick={onConfirm}>
          {t('flights.flightFilterSection.confirm')}
        </Button>
      </DrawerBottomNavigationStyled>
    </MobileFilterSectionStyles>
  )
}

export default MobileFilterSection
