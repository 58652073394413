import React, { useState } from 'react'
import {
  DropdownContainer,
  LangAndProfileContainer,
  LangAndProfileDropdown,
  NavBarStyled,
  NavLink,
  NavLinkContainer,
} from './styles'
import DropdownNavContent from './components/dropdownNavContent'
import SelectLanguage from './components/selectLanguage'
import Profile from './components/profile'
import DropdownProfileContent from './components/dropdownProfileContent'
import DropdownLangContent from './components/dropdownLangContent'
import MobileNavbar from 'components/mobileNavbar'
import { SmilesIcon } from 'components/icons'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

const NavBar: React.FC = () => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuth0()
  const navigate = useNavigate()

  const [showDropdown, setShowDropdown] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [dropdownContent, setDropdownContent] = useState<number | null>(null)
  const [isLanguage, setIsLanguage] = useState<boolean>(false)
  const [showLangAndProfileDropdown, setShowLangAndProfileDropdown] =
    useState(false)

  const navLabels = [
    { label: t('navbar.links.home'), path: '/', width: 100 },
    { label: t('navbar.links.makeRequest'), path: '/', width: 150 },
    { label: t('navbar.links.myRequests'), path: '/requests', width: 170 },
    { label: t('navbar.links.myTravels'), path: '/my-travels', width: 150 },
    // descomentar de acordo com o acompanhamento das features
    // { label: t('navbar.links.favorites'), path: '/' },
    { label: t('navbar.links.management'), path: '/management' },
  ]

  const shouldShowDropdown =
    showDropdown &&
    dropdownContent !== 0 &&
    dropdownContent !== 2 &&
    dropdownContent !== 3

  const handleLinkMouse = ({
    idx,
    event,
  }: {
    idx: number | null
    event: string
  }) => {
    if (event === 'mouseEnter') {
      setShowDropdown(true)
      setDropdownContent(idx)
      setShowLangAndProfileDropdown(false)
    } else {
      setShowDropdown(false)
      setDropdownContent(null)
    }
  }

  const handleLangProfileDropdown = (languageIsSelected: boolean) => {
    setShowLangAndProfileDropdown(!showLangAndProfileDropdown)
    setIsLanguage(languageIsSelected)
  }

  const handleOpenMenu = () => {
    setOpenMenu(true)
  }

  const handleCloseMenu = () => {
    setOpenMenu(false)
  }

  const handleNavigate = (path: string) => {
    navigate(path)
  }

  if (isAuthenticated) {
    return (
      <NavBarStyled data-testid="smiles-navbar">
        <div className="left-container">
          <SmilesIcon type="smiles-corp" size={48} />

          <NavLinkContainer>
            {navLabels.map((link, idx) => {
              return (
                <NavLink
                  data-testid={`nav-link-${idx}`}
                  key={link.label}
                  onMouseEnter={() =>
                    handleLinkMouse({ idx, event: 'mouseEnter' })
                  }
                  onMouseLeave={() =>
                    handleLinkMouse({ idx, event: 'mouseLeave' })
                  }
                  $iscurrentlink={idx === dropdownContent}
                  onClick={() => handleNavigate(link.path)}
                  style={{ width: link.width }}
                >
                  <p>{link.label}</p>
                </NavLink>
              )
            })}

            <DropdownContainer
              data-testid="dropdown-nav-content"
              $show={shouldShowDropdown}
              onMouseEnter={() =>
                handleLinkMouse({ idx: dropdownContent, event: 'mouseEnter' })
              }
              onMouseLeave={() =>
                handleLinkMouse({ idx: dropdownContent, event: 'mouseLeave' })
              }
            >
              <DropdownNavContent currentLink={dropdownContent} />
            </DropdownContainer>
          </NavLinkContainer>
        </div>

        <div className="right-container">
          <LangAndProfileContainer>
            <SelectLanguage onClick={() => handleLangProfileDropdown(true)} />
            <Profile onClick={() => handleLangProfileDropdown(false)} />
            <LangAndProfileDropdown
              $show={showLangAndProfileDropdown}
              onMouseLeave={() => setShowLangAndProfileDropdown(false)}
              $islanguage={isLanguage}
              data-testid="lang-and-profile-dropdown"
            >
              {isLanguage ? (
                <DropdownLangContent />
              ) : (
                <DropdownProfileContent />
              )}
            </LangAndProfileDropdown>
          </LangAndProfileContainer>

          <SmilesIcon
            type="burger-menu"
            className="burger-menu"
            size={32}
            onClick={handleOpenMenu}
          />

          {<MobileNavbar isOpen={openMenu} close={handleCloseMenu} />}
        </div>
      </NavBarStyled>
    )
  } else {
    return (
      <NavBarStyled
        data-testid="smiles-unlogged-navbar"
        className="centered-navbar"
      >
        <div className="icon-container">
          <SmilesIcon type="smiles-corp" size={48} />
        </div>
      </NavBarStyled>
    )
  }
}

export default NavBar
