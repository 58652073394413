import React from 'react'
import { StyledSlider } from './styles'

export type RangeProps = {
  defaultValue?: number[]
  disabled?: boolean
  onChange?: (e?: any) => void
  rangeValues: number[]
  min?: number
  max?: number
  step?: number
}

const Range: React.FC<RangeProps> = ({
  defaultValue,
  rangeValues,
  disabled = false,
  onChange,
  min,
  max,
  step,
}) => {
  return (
    <StyledSlider
      range
      defaultValue={defaultValue}
      value={rangeValues}
      onChange={onChange}
      disabled={disabled}
      min={min}
      max={max}
      step={step}
    />
  )
}

export default Range
