import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getReasons } from 'services/debtAllocation'
import { debounce } from 'utils/debounce'
import { notifyError } from 'utils/handleRequestNotify'
import { getTranslatedReasons } from './translatedReasons'

export const useReasons = () => {
  const { t, i18n } = useTranslation()
  const [reasonsList, setReasonsList] = useState([])
  const [loadingReasonsList, setLoadingReasonsList] = useState(false)

  const fetchReasons = useCallback(
    debounce(async () => {
      try {
        setLoadingReasonsList(true)
        const response = await getReasons()
        const translatedReasons = getTranslatedReasons(response, i18n.language)
        setReasonsList(translatedReasons as any)
        setLoadingReasonsList(false)
      } catch (error) {
        notifyError(t('debit_allocation.error.reasons'))
      } finally {
        setLoadingReasonsList(false)
      }
    }, 800),
    [t, i18n.language],
  )

  return {
    reasonsList,
    fetchReasons,
    loadingReasonsList,
  }
}
