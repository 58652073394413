export default `
<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.001 0.500977C4.48098 0.500977 0.000976562 4.98098 0.000976562 10.501C0.000976562 16.021 
4.48098 20.501 10.001 20.501C15.521 20.501 20.001 16.021 20.001 10.501C20.001 4.98098 15.521 0.500977 
10.001 0.500977ZM10.001 18.501C5.58098 18.501 2.00098 14.921 2.00098 10.501C2.00098 8.65098 2.63098 
6.95098 3.69098 5.60098L14.901 16.811C13.551 17.871 11.851 18.501 10.001 18.501ZM16.311 15.401L5.10098 
4.19098C6.45098 3.13098 8.15098 2.50098 10.001 2.50098C14.421 2.50098 18.001 6.08098 18.001 10.501C18.001 
12.351 17.371 14.051 16.311 15.401Z" fill="#1A1A1A"/>
</svg>
`
