import React from 'react'
import { PermissionTreeSelectContainer } from './styles'
import { Form, TreeSelect } from 'antd'

const { SHOW_PARENT } = TreeSelect

export type TreeData = {
  title: string
  value: string
  key: string
  group?: boolean
  children: {
    title: string
    value: string
    key: string
  }[]
}[]

export type PermissionTreeSelectProps = {
  label: string
  placeholder: string
  value?: any
  onChange?: (selectedItems: any[]) => void
  data?: TreeData
  formItemName: string
  isLoading?: boolean
  disabled?: boolean
}

const PermissionTreeSelect: React.FC<PermissionTreeSelectProps> = ({
  label,
  placeholder,
  value = [],
  onChange,
  data,
  formItemName,
  isLoading,
  disabled,
}) => {
  return (
    <PermissionTreeSelectContainer data-testid="smiles-permission-tree-select">
      <p>{label}</p>
      <Form.Item
        name={formItemName}
        rules={[
          {
            required: false,
          },
        ]}
      >
        <TreeSelect
          placeholder={placeholder}
          showCheckedStrategy={SHOW_PARENT}
          treeCheckable
          onChange={onChange}
          value={value}
          treeData={data}
          style={{ width: '100%' }}
          loading={isLoading}
          treeDefaultExpandAll
          multiple
          allowClear
          showSearch
          disabled={disabled}
        />
      </Form.Item>
    </PermissionTreeSelectContainer>
  )
}

export default PermissionTreeSelect
