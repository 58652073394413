import styled from 'styled-components'
import { Color, Size } from 'styles/global'

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Card = styled.div`
  border: 1px solid ${Color.LIGHT_BORDER};
  border-radius: ${Size.BORDER_RADIUS};
  padding: 12px;
  cursor: pointer;
`

export const RowContainer = styled.div`
  padding: 8px;
  height: 96px;
  display: flex;
  gap: 32px;
  align-items: center;

  > div:last-child {
    flex-grow: 2;
    display: flex;
    justify-content: end;
  }
`

export const Column = styled.div`
  p {
    font-size: 16px;
    font-color: #595959;
  }
`

export const Label = styled.div`
  font-size: 18px;
  font-weight: 600;
`

export const PaginationContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
`
