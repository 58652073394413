const enMyRequests = {
  myRequests: {
    'not-found': 'Requests not found.',
    requests: 'Requests',
    filter: {
      'filter-requests': 'Filter requests',
      only_my_requests: 'My requests',
      only_my_travels: 'My travels',
      requests_for_other_travelers: 'Requests for other travelers',
      'pending-approval-travel-requests': 'Pending approval requests',
    },
    status: {
      'all-statuses': 'All statuses',
      waiting_for_approval: 'Awaiting approval',
      approved: 'Approved',
      confirmed: 'Confirmed',
      rejected: 'Rejected',
      canceled: 'Cancelled',
      opened: 'Opened',
    },
    order: {
      'order-requests': 'Order',
      'recent-requests': 'Most recent requests',
      'older-requests': 'Oldest requests',
      'urgent-requests': 'Urgent requests',
      'close-expiration-requests': 'Close to expiration requests',
    },
    'search-travel-request': 'Search SV',
    card: {
      destination: 'Destination',
      requester: 'Requester',
      traveler: 'Traveler',
      'travel-price': 'Travel price',
      'more-information': 'View full request',
    },
  },
}

export default enMyRequests
