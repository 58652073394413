import { FlightFilters } from 'pages/flights/components/flightFilterSection'
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useMemo,
  useEffect,
} from 'react'
import { Flight } from 'services/flights/flightModel'
import { SCostCenter } from 'shared/interfaces/SCostCenter'
import { HotelAvail } from 'shared/interfaces/SHotelAvailability'
import { SMyTravelFlight } from 'shared/interfaces/SMyTravel'
import { SRoom } from 'shared/interfaces/SRoom'
import { getFlightPrice } from 'utils/getFlightPrice/getFlightPrice'

export type SelectedFlights = {
  departure: Flight
  return?: Flight
}

export type SearchFlightParams = {
  origin: { label: string; value: string }
  destination: { label: string; value: string }
  start: string
  end?: string
  occupancy: number
  isOneWay: boolean
}

export type SearchHotelParams = {
  start: string
  end: string
  destination: number
  occupancy: number
  city?: string
}

export type FlowContextState = {
  flightFilters: FlightFilters
  setFlightFilters: Dispatch<SetStateAction<FlightFilters>>
  totalValue: number
  setTotalValue: Dispatch<SetStateAction<number>>
  selectedFlights?: SelectedFlights
  setSelectedFlights: Dispatch<SetStateAction<SelectedFlights | undefined>>
  selectedHotel: HotelAvail | undefined
  setSelectedHotel: Dispatch<SetStateAction<HotelAvail | undefined>>
  selectedRoom: SRoom | undefined
  setSelectedRoom: Dispatch<SetStateAction<SRoom | undefined>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  searchFlights?: SearchFlightParams
  setSearchFlights: Dispatch<SetStateAction<SearchFlightParams | undefined>>
  searchHotels?: SearchHotelParams
  setSearchHotels: Dispatch<SetStateAction<SearchHotelParams | undefined>>
  travelers: {}[]
  addTravelers: (travelers: { label: string; value: string }[]) => void
  removeTraveler: (id: string) => void
  travelRequestId?: string
  setTravelRequestId: Dispatch<SetStateAction<string | undefined>>
  selectedCostCenters?: SCostCenter[]
  setSelectedCostCenters: Dispatch<SetStateAction<SCostCenter[] | undefined>>
  flightsCart?: SMyTravelFlight[]
  setFlightsCart: Dispatch<SetStateAction<SMyTravelFlight[] | undefined>>
}

const FlowContext = createContext<FlowContextState | undefined>(undefined)

type FlowContextProviderProps = {
  children: ReactNode
}

export const FlowContextProvider: React.FC<FlowContextProviderProps> = ({
  children,
}) => {
  const [totalValue, setTotalValue] = useState(0)
  const [loading, setLoading] = useState(false)
  const [searchFlights, setSearchFlights] = useState<
    SearchFlightParams | undefined
  >()
  const [flightFilters, setFlightFilters] = useState<FlightFilters>({
    minValue: 0,
    maxValue: Number.MAX_SAFE_INTEGER,
    flightClass: [],
    originAiport: [],
    destinationAiport: [],
    flightDepartureTime: [],
    flightReturnTime: [],
    flightStops: [],
  })
  const [selectedFlights, setSelectedFlights] = useState<SelectedFlights>()
  const [selectedHotel, setSelectedHotel] = useState<HotelAvail | undefined>()
  const [travelRequestId, setTravelRequestId] = useState<string>()
  const [selectedCostCenters, setSelectedCostCenters] =
    useState<SCostCenter[]>()
  const [flightsCart, setFlightsCart] = useState<SMyTravelFlight[]>()
  const [searchHotels, setSearchHotels] = useState<
    SearchHotelParams | undefined
  >()
  const [selectedRoom, setSelectedRoom] = useState<SRoom | undefined>()
  const [travelers, setTravelers] = useState<
    { label: string; value: string }[]
  >([])

  const getRoomPrice = (room: SRoom | undefined): number => {
    return room?.fares?.find((fare) => fare.type === 'FARE')?.price.amount ?? 0
  }

  useEffect(() => {
    const departureFlightPrice = getFlightPrice(selectedFlights?.departure)
    const returnFlightPrice = getFlightPrice(selectedFlights?.return)
    const roomPrice = getRoomPrice(selectedRoom)

    setTotalValue(departureFlightPrice + returnFlightPrice + roomPrice)
  }, [selectedFlights, selectedRoom])

  const addTravelers = (
    travelers: { label: string; value: string }[],
  ): void => {
    setTravelers((previousData) => [...previousData, ...travelers])
  }

  const removeTraveler = (id: string) => {
    setTravelers((previousData) =>
      previousData.filter((traveler) => traveler.value !== id),
    )
  }

  const contextValue: FlowContextState = useMemo(() => {
    return {
      flightFilters,
      setFlightFilters,
      totalValue,
      setTotalValue,
      selectedFlights,
      setSelectedFlights,
      selectedHotel,
      setSelectedHotel,
      selectedRoom,
      setSelectedRoom,
      loading,
      setLoading,
      searchFlights,
      setSearchFlights,
      searchHotels,
      setSearchHotels,
      travelers,
      setTravelers,
      addTravelers,
      removeTraveler,
      travelRequestId,
      setTravelRequestId,
      selectedCostCenters,
      setSelectedCostCenters,
      flightsCart,
      setFlightsCart,
    }
  }, [
    flightFilters,
    setFlightFilters,
    totalValue,
    setTotalValue,
    selectedFlights,
    setSelectedFlights,
    selectedHotel,
    setSelectedHotel,
    selectedRoom,
    setSelectedRoom,
    loading,
    setLoading,
    searchFlights,
    setSearchFlights,
    searchHotels,
    setSearchHotels,
    travelers,
    setTravelers,
    travelRequestId,
    setTravelRequestId,
    selectedCostCenters,
    setSelectedCostCenters,
    flightsCart,
    setFlightsCart,
  ])

  return (
    <FlowContext.Provider value={contextValue}>{children}</FlowContext.Provider>
  )
}

export const useFlowContext = () => {
  const context = useContext(FlowContext)
  if (!context) {
    throw new Error('useFlowContext must be used inside a FlowContextProvider')
  }
  return context
}
