import styled from 'styled-components'

export const RequestSummaryStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 24px 40px 24px;
  margin-bottom: 120px;

  .title_container {
    display: flex;
    gap: 16px;
    align-items: center;
    svg {
      margin-top: -4px;
    }
  }

  .title {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.08px;
  }
`
