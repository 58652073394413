import styled from 'styled-components'

export const FlightPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const FlightPageContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-bottom: 150px;

  .hidden-in-mobile {
    @media screen and (max-width: 1120px) {
      display: none;
    }
  }
`

export const FlightsSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
  > div {
    display: flex;
    flex-direction: column;
  }
`

export const OpenFiltersButton = styled.button`
  background: none;
  border: none;
  line-height: normal;
  overflow: visible;
  padding: 0;
  outline: none;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
  align-self: center;
  width: 150px;
  border: 2px solid var(--Neutral-Neutral-400, #7c7c7c);
  padding: 4px 8px;
  border-radius: 32px;

  @media screen and (min-width: 1121px) {
    display: none;
  }

  @media screen and (max-width: 768px) {
    font-size: 10px;
    width: 100px;
  }
`
